import AdminTermService from '../../../../services/admin-term.service'
export default {
  getAllTerms({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return AdminTermService.getAllTerms(payload.page, payload.limit, payload.search)
      .then((response) => {
        commit('GET_ALL_TERM_ADMIN', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  }
}
