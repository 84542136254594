import AdminSettingsService from '../../../../services/admin-settings.service'

export default {
  getAllSettings({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return AdminSettingsService.getAllSettings(payload.page, payload.limit, payload.search)
      .then((response) => {
        commit('SET_ERROR', '')
        commit('SET_ITEMS', response.data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  }
}
