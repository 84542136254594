import i18n from '../../../i18n'
export default {
  isEmpty: (state) => {
    return state.listPlayer ? !state.listPlayer.length : true
  },
  center: (state) => {
    return {
      lat: state.infoPlayerID.lat ? parseFloat(state.infoPlayerID.lat) : 0,
      lng: state.infoPlayerID.lng ? parseFloat(state.infoPlayerID.lng) : 0
    }
  },
  infoEditPlayer: (state) => {
    return {
      player: {
        name: state.infoPlayerID.name,
        description: state.infoPlayerID.description,
        campaign_id: state.infoPlayerID.campaign_id,
        rotate: state.infoPlayerID.rotate,
        displayname: state.infoPlayerID.displayname
      }
    }
  },
  stateFromUSA: (state) => {
    return state.allStates.filter((e_state) => {
      return e_state.country_name === 'United States'
    })
  },

  stateFromCanada: (state) => {
    return state.allStates.filter((e_state) => {
      return e_state.country_name === 'Canada'
    })
  },
  listTimezoneOptions: (state) => {
    const listTimezoneOptions = []
    state.listTimezone.map((time) => {
      return listTimezoneOptions.push({
        id: time,
        text: time
      })
    })
    return listTimezoneOptions
  },
  listCampaignOptions: (state) => {
    const listCampaignOptions = [
      {
        id: 0,
        text: i18n.t('prompt-select.campaign')
      }
    ]
    state.allCampaigns.map((campaign) => {
      return listCampaignOptions.push({
        id: campaign.id,
        text: campaign.displayname,
        campaign_code: campaign.campaign_code
      })
    })
    return listCampaignOptions
  },
  listCampaignOptionsForEditPlayer: (state) => {
    const listCampaignOptions = [
      {
        id: 0,
        text: i18n.t('prompt-select.unassign-campaign')
      }
    ]
    state.allCampaigns.map((campaign) => {
      return listCampaignOptions.push({
        id: campaign.id,
        text: campaign.displayname,
        campaign_code: campaign.campaign_code
      })
    })
    return listCampaignOptions
  },
  listPlayersWithOrientationOptionsForAddGroup: (state) => {
    const listPlayerOrirentationOptions = [
      {
        id: 0,
        text: i18n.t('prompt-select.player')
      }
    ]
    state.allPlayersWithOrientaion.map((player) => {
      return listPlayerOrirentationOptions.push({
        id: player.id,
        text: player.name,
        time: player.time,
        rotate: player.rotate
      })
    })
    listPlayerOrirentationOptions.sort((playerA, playerB) => {
      return playerB.time - playerA.time
    })
    return listPlayerOrirentationOptions
  },
  allStates: (state) => {
    const data = [{ id: 0, text: i18n.t('prompt-select.state') }]
    if (state.allStates) {
      state.allStates.map((exception) => {
        data.push({ id: exception.code, text: exception.name })
      })
    }
    return data
  }
}
