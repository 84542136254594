import AdminPlayerService from '../../../../services/admin-player.service'
import CampaignService from '@/services/campaign.service.js'
export default {
  getAllPlayersAdmin({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    AdminPlayerService.getAllPlayersAdmin(
      payload.page,
      payload.limit,
      payload.search,
      payload.organization,
      payload.applicationVersion,
      payload.applicationCode,
      payload.filterByHardware
    )
      .then((res) => {
        commit('SET_ERROR', '')
        commit('GET_ALL_PLAYERS_ADMIN', res.data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  },
  getDetailAdminPlayer({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    AdminPlayerService.getDetailAdminPlayer(payload)
      .then((res) => {
        commit('SET_ERROR', '')
        commit('GET_DETAIL_ADMIN_PLAYER', res.data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  },
  getCampaignByOrg({ state, commit, rootState }, payload) {
    CampaignService.getAllCampaignsNoPaging('', payload)
      .then((res) => {
        commit('GET_CAMPAIGN_BY_ORG', res.data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  },
  getAllApplicationVersion({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    AdminPlayerService.getAllApplicationVersion()
      .then((res) => {
        const data = [{ id: 0, text: 'Select Player Application' }]
        if (res.data.length > 0) {
          res.data.map((exception) => {
            const text = exception[1] ? `${exception[0]} (${exception[1]})` : exception[0]
            data.push({
              id: exception[0],
              text: text,
              version: exception[0],
              version_code: exception[1]
            })
          })
        }
        commit('SET_ERROR', '')
        commit('GET_ALL_APPLICATION_VERSIONS', data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  }
}
