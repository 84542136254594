<template>
  <div class="container-fluid">
    <div class="card-header top-bar">
      <div class="header-left">
        <div class="admin-search-box">
          <SfInputSearch
            placeholder="Search"
            v-debounce:500ms="searchInputChange"
            class="sf-input-search"
          />
        </div>
      </div>
      <div class="header-right">
        <button class="sf-primary-add" @click="showModalCreate()">Add New Category</button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 sf-table">
          <b-table
            :busy="loading"
            show-empty
            striped
            hover
            :items="items"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty="isEmpty">
              <p class="d-flex justify-content-center">No data available in table</p>
            </template>
            <template v-slot:head(name)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(name)="field">
              <p>
                <!-- <router-link :to="{path: `/admin/system/categorys/${field.item.id}/edit`}">{{field.item.displayname}}</router-link> -->
                {{ field.item.displayname }}
              </p>
            </template>

            <template v-slot:head(templates)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(templates)="field">
              <p>{{ field.item.templates }}</p>
            </template>

            <template v-slot:cell(action)="field">
              <button
                @click="shownModalEdit(field.item.id)"
                class="button-edit-table"
                title="Edit"
              ></button>
              <button
                @click="shownModalDelete(field.item.id)"
                class="button-delete-table"
                title="Delete"
              ></button>
            </template>
          </b-table>

          <div v-if="!isEmpty" class="my-3">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="pagination.limit * pagination.total"
              :per-page="pagination.limit"
              @change="changePage"
              :limit="6"
              v-model="currentPage"
            />
          </div>
        </div>
      </div>
    </div>
    <SfModal
      :title="'Add New Category'"
      :icon="'fa fa-code'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalCreate"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Name</label>
                </div>
                <input
                  v-model="newTemplateCategory.template_category.displayname"
                  placeholder="Name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newTemplateCategory.template_category.displayname.required"
              >
                Name required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newTemplateCategory.template_category.displayname.maxLength"
              >
                Use less than 250 characters
              </div>
            </div>
          </div>
          <div class="footer-button mt-3">
            <button class="sf-secondary" @click="isShownModalCreate = false">Cancel</button>
            <button class="sf-primary" @click="saveCategory()">Save</button>
          </div>
        </div>
      </div>
    </SfModal>

    <SfModal
      :title="'Edit Category'"
      :icon="'fa fa-code'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingTemplateDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Name</label>
                </div>
                <input
                  v-model="currentTemplateCategory.template_category.displayname"
                  placeholder="Name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="
                  submitted && !$v.currentTemplateCategory.template_category.displayname.required
                "
              >
                Name required
              </div>
              <div
                class="invalid-error"
                v-if="
                  submitted && !$v.currentTemplateCategory.template_category.displayname.maxLength
                "
              >
                Use less than 250 characters
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEdit = false">Cancel</button>
        <button class="sf-primary" @click="updateCategory()">Save</button>
      </div>
    </SfModal>

    <SfDeleteModal :title="'Confirm Delete'" closeOnOverlay :show.sync="isShownModalDelete">
      <div class="content-delete">
        Deleting this template category. Are you sure you want to delete this one?
      </div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShownModalDelete = false">Cancel</button>
        <button class="sf-primary" @click="removeCategory">OK</button>
      </div>
    </SfDeleteModal>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import AdminTemplateCategoryService from '@/services/admin-template-category.service'
import { required, maxLength } from 'vuelidate/lib/validators'
import SfInputSearch from '@/components/SfInputSearch.vue'

export default {
  name: 'admin-user-customer',

  components: {
    SfModal,
    SfDeleteModal,
    SfInputSearch
  },

  data() {
    return {
      msg: 'Welcome to Index!!!',
      isShownModalCreate: false,
      isShownModalDelete: false,
      isShownModalEdit: false,
      currentPage: 1,
      currentTemplateCategoryID: '',
      submitted: false,
      fields: [
        {
          key: 'name',
          sortable: true,
          label: 'Name',
          colType: 'name'
        },
        {
          key: 'templates',
          sortable: true,
          label: 'Templates',
          colType: 'templates'
        },
        {
          key: 'action',
          colType: 'button',
          label: 'Actions',
          class: 'sf-table-actions'
        }
      ],
      newTemplateCategory: {
        template_category: {
          displayname: ''
        }
      },
      currentTemplateCategory: {
        template_category: {
          displayname: ''
        }
      },
      defaultTemplateCategory: {
        template_category: {
          displayname: ''
        }
      },
      dataSearch: '',
      loadingTemplateDetail: false,
      listHardwareOptions: []
    }
  },

  validations: {
    newTemplateCategory: {
      template_category: {
        displayname: {
          required,
          maxLength: maxLength(250)
        }
      }
    },
    currentTemplateCategory: {
      template_category: {
        displayname: {
          required,
          maxLength: maxLength(250)
        }
      }
    }
  },

  methods: {
    fetchData(activePage, search) {
      this.$store.dispatch('admintemplatecategory/getAllTemplateCategory', {
        page: activePage,
        limit: 10,
        search
      })
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage)
    },
    showModalCreate() {
      this.setDataCategory(
        this.newTemplateCategory.template_category,
        this.defaultTemplateCategory.template_category
      )
      this.submitted = false
      this.isShownModalCreate = true
    },
    shownModalDelete(customer_id) {
      this.isShownModalDelete = true
      this.currentTemplateCategoryID = customer_id
    },
    shownModalEdit(customer_id) {
      this.currentTemplateCategoryID = customer_id
      this.submitted = false
      this.getItemByID()
      this.setDataCategory(
        this.currentTemplateCategory.template_category,
        this.defaultTemplateCategory.template_category
      )
      this.isShownModalEdit = true
    },
    saveCategory() {
      this.submitted = true
      if (this.$v.newTemplateCategory.$invalid) {
        return
      }
      AdminTemplateCategoryService.createTemplateCategory(this.newTemplateCategory)
        .then((response) => {
          this.$toast.success('Category successfully created')
          this.fetchData(this.currentPage)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalCreate = false
    },
    getItemByID() {
      this.loadingTemplateDetail = true
      AdminTemplateCategoryService.detailTemplateCategory(this.currentTemplateCategoryID)
        .then((response) => {
          this.setDataCategory(this.currentTemplateCategory.template_category, response.data)
          this.loadingTemplateDetail = false
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
    },
    updateCategory() {
      this.submitted = true
      if (this.$v.currentTemplateCategory.$invalid) {
        return
      }
      AdminTemplateCategoryService.updateTemplateCategory(
        this.currentTemplateCategoryID,
        this.currentTemplateCategory
      )
        .then((response) => {
          this.$toast.success('Category successfully updated')
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalEdit = false
    },
    setDataCategory(category, new_category) {
      category.displayname = new_category.displayname
    },
    removeCategory() {
      AdminTemplateCategoryService.removeTemplateCategory(this.currentTemplateCategoryID)
        .then((response) => {
          this.$toast.success('Category successfully deleted')
          if (this.items.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalDelete = false
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    }
  },

  computed: {
    ...mapState('admintemplatecategory', {
      items: 'items',
      pagination: 'pagination',
      optionCategoryType: 'optionCategoryType',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('admintemplatecategory', ['isEmpty'])
  },

  mounted() {
    this.fetchData(this.currentPage)
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    })
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  }
}
</script>

<style lang="scss" scoped>
@import '../../Adminpage.scss';
.text-area-control {
  padding: 6px 0px;
  width: 90%;
  border: none;
  outline: none;
  height: 100px;
  max-height: 150px;
  margin: 1px;
}

.custom-input-group-text {
  align-items: flex-start;
}

.input-group-text-area {
  height: auto !important;
}
</style>
