<template>
  <transition name="modaluppy">
    <div class="uppy" @click="onClickOverlay" :class="{ hidden: !dataShow }" ref="overlay">
      <div class="overlay-dashboard">
        <button id="closeUppy" class="close" @click="close"></button>
      </div>
      <div class="DashboardContainer"></div>
    </div>
  </transition>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { API_ASSET_URL } from '../../.env'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/src/style.scss'
const Uppy = require('@uppy/core/lib')
const Dashboard = require('@uppy/dashboard/lib')
const XHRUpload = require('@uppy/xhr-upload/lib')
const Dropbox = require('@uppy/dropbox/lib')
const Url = require('@uppy/url/lib')
const Webcam = require('@uppy/webcam/lib')
const en = require('@uppy/locales/lib/en_US')
const fr = require('@uppy/locales/lib/fr_FR')
const es = require('@uppy/locales/lib/es_ES')

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    endpoint: {
      type: String,
      required: false,
      default() {
        return '/upload'
      }
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    },
    folderID: {
      type: Number
    }
  },

  data() {
    return {
      dataShow: false,
      uppy: {}
    }
  },

  computed: {
    ...mapState('library', {
      smartAssetDataModal: 'smartAssetDataModal',
      organizationId: 'organizationId'
    })
  },

  watch: {
    show(value) {
      this.dataShow = value
      if (value) {
        this.setLocale()
      }
    }
  },

  mounted() {
    const accessToken = this.$cookies.get('accessToken')
    this.uppy = Uppy({
      restrictions: {
        maxFileSize: 2147483648,
        maxNumberOfFiles: 20,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*', '.mp4', '.m4v']
      },
      locale: this.getLocale()
    })
      .use(Dashboard, {
        trigger: '.UppyModalOpenerBtn',
        inline: true,
        target: '.DashboardContainer',
        replaceTargetContent: true,
        showProgressDetails: true,
        note: 'Images and video only up to 2 GB',
        height: 470,
        browserBackButtonClose: true
      })
      .use(Webcam, { target: Dashboard })
      .use(Dropbox, { target: Dashboard, companionUrl: 'https://uppy.screenfluence.com' })
      .use(Url, { target: Dashboard, companionUrl: 'https://uppy.screenfluence.com' })
      .use(XHRUpload, {
        endpoint: `${API_ASSET_URL}/library`,
        headers: {
          Authorization: accessToken
        },
        formData: true,
        timeout: 0,
        getResponseData(responseText, response) {
          // let data = JSON.parse(responseText)
          // if (data.code === 200) {
          //   if (self.filterOrientation === '' || self.filterOrientation === data.data.orientation) {
          //     self.updateCountAssetStatistical({ type: 'create', typeAsset: data.data.smart_asset_type })
          //     self.addNewItemTreeFolder({ folderData: self.folderID, data: data.data })
          //   }
          // }
          return response
        }
      })
    this.uppy.on('upload', (data) => {
      this.uppy.setMeta({
        current_folder: this.folderID || 0,
        organization_id: this.organizationId || ''
      })
    })
    this.uppy.on('upload-success', (file, response) => {
      if (response.status === 200) {
        const data = JSON.parse(response.body.responseText).data
        this.$emit('upload:success', data)
        this.$toast.open({
          message: 'New Smart Asset created',
          type: 'success'
        })
      }
    })
    this.uppy.on('upload-error', (file, error, response) => {
      const responseData = JSON.parse(response.body.responseText)
      this.$toast.open({
        message: responseData.message,
        type: 'error'
      })
    })
    this.uppy.on('complete', (result) => {
      const dashboard = this.uppy.getPlugin('Dashboard')
      dashboard.closeModal()
      this.uppy.reset()
      const closeUppy = document.getElementById('closeUppy')
      closeUppy.click()
    })
  },

  methods: {
    ...mapMutations('library', {
      addNewItemTreeFolder: 'SET_NEW_ITEM_TREE_FOLDER',
      updateCountAssetStatistical: 'UPDATE_COUNT_ASSET_STATISTICAL'
    }),
    close() {
      this.$emit('update:show', false)
    },
    onClickOverlay($event) {
      if (this.closeOnOverlay && $event && $event.target === this.$refs.overlay) {
        this.close()
      }
    },
    getLocale() {
      if (this.$i18n.locale === 'es') return es
      if (this.$i18n.locale === 'fr') return fr
      return en
    },
    setLocale() {
      this.uppy.setOptions({ locale: this.getLocale() })
    }
  }
}
</script>
<style lang="scss" scoped>
.uppy {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.DashboardContainer {
  margin-top: 5vh;
}
.overlay-dashboard {
  position: absolute;
  width: 750px;
  height: 470px;
  margin-top: 5vh;
  .close {
    position: absolute;
    right: -35px;
    top: -8px;
    width: 32px;
    height: 32px;
    background-image: url('~@/assets/images/cancel-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    justify-self: flex-end;
    opacity: 1;
  }
}
.hidden {
  display: none !important;
}
/* modal transition */
.modaluppy {
  &-enter-active,
  &-leave-active {
    transition: all 0.3s ease;
  }
  &-enter,
  &-leave-to {
    padding-top: 100px;
    opacity: 0;
  }
}
</style>
