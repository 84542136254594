<template>
  <div>
    <div class="d-flex justify-content-between my-3">
      <SfInputSearch
        v-model="searchValue"
        placeholder="Search"
        v-debounce:500ms="searchInputChange"
        class="sf-input-search"
      />
      <div>
        <button @click="shownModalExport" class="sf-primary mr-2">
          <img src="@/assets/images/oppsite-arrow.svg" />
          Export
        </button>
        <a class="d-none" id="link-export" download></a>
        <button @click="addNewCampaign" class="sf-primary">Add New</button>
      </div>
    </div>
    <div class="sf-table">
      <b-table
        :items="dataTableCampaign.items"
        responsive="true"
        show-empty
        striped
        hover
        :fields="fields"
        head-variant="none"
        thead-class="custom-b-table"
        table-variant="none"
        :busy="loadingCampaign"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:head(displayname)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(displayname)="field">
          <p class="me-link" @click="goToEditCampaign(field.item.campaign_code)">
            {{ field.item.displayname }}
          </p>
        </template>

        <template v-slot:head(main_loop)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(main_loop)="field">
          <p>{{ field.item.main_loop }}</p>
        </template>

        <template v-slot:head(number_of_loop)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(number_of_loop)="field">
          <p>{{ field.item.number_of_loop }}</p>
        </template>

        <template v-slot:head(player_count)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(player_count)="field">
          <p class="number-player sm-title">{{ field.item.player_count }}</p>
        </template>

        <template v-slot:head(actions)="field">
          <span>{{ field.label }}</span>
        </template>
        <template v-slot:cell(actions)="field">
          <button
            title="Edit"
            class="button-edit-table"
            @click="goToEditCampaign(field.item.campaign_code)"
          ></button>
          <button
            @click="shownModalDelete(field.item.campaign_code)"
            title="Delete"
            class="button-delete-table"
          ></button>
        </template>
      </b-table>
    </div>
    <!-- pagination -->
    <div class="my-3">
      <b-pagination
        class="sf-b-pagination"
        v-model="currentPage"
        :total-rows="10 * dataTableCampaign.total_pages"
        :per-page="10"
        @change="changePage"
        :limit="6"
      />
    </div>
    <!-- Modal Add new Campaign -->
    <SfModal
      title="Create Campaign"
      :width="768"
      :icon="'fas fa-plus-circle'"
      closeOnOverlay
      :show.sync="isShownModalAddCampaign"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Name</label>
                </div>
                <input
                  v-model="newNameCampaign.campaign.displayname"
                  placeholder="Enter New Campaign Name Here"
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newNameCampaign.campaign.displayname.required"
              >
                Name is required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newNameCampaign.campaign.displayname.maxLength"
              >
                Use less than 50 characters
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalAddCampaign = false">Cancel</button>
        <button class="sf-primary" @click="addCampaign">Save</button>
      </div>
    </SfModal>

    <!-- Admin Modal Export -->
    <SfModal
      title="Export File"
      :width="400"
      :icon="'fas fa-plus-circle'"
      closeOnOverlay
      :show.sync="isShownModalExport"
    >
      <div class="row mt-3 custom-row d-flex justify-content-center">
        <p>Do you want to export file?</p>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalExport = false">Cancel</button>
        <button class="sf-primary" @click="exportFile">Save</button>
      </div>
    </SfModal>

    <!-- Admin Modal Delete -->
    <SfDeleteModal title="Confirm Delete" closeOnOverlay :show.sync="isShownModalDelete">
      <div class="content-delete mt-3">
        Deleting this campaign. Are you sure you want to delete this one?
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalDelete = false">Cancel</button>
        <button class="sf-primary" @click="deleteCampaign">OK</button>
      </div>
    </SfDeleteModal>
    <!-- Admin Confirm Modal -->
    <SfModal
      title="Confirm Join Organization"
      :width="400"
      closeOnOverlay
      :show.sync="isShownModalConfirm"
    >
      <div class="content-delete">
        You have no permission campaign of {{ org_name }}. You should join {{ org_name }} before
        adding, editing or viewing. Are you sure you want to join?
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalConfirm = false">Cancel</button>
        <button class="sf-primary" @click="joinOrg">OK</button>
      </div>
    </SfModal>
  </div>
</template>
<script>
import SfModal from '@/components/SfModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import AdminOrganizationService from '@/services/admin-organization.service.js'
import Vue from 'vue'
import moment from 'moment-timezone'
import CampaignService from '@/services/campaign.service.js'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import SfInputSearch from '@/components/SfInputSearch.vue'

export default {
  name: 'campaignTable',
  components: {
    SfModal,
    SfDeleteModal,
    SfInputSearch
  },
  props: {
    similarOrg: {
      type: Boolean
    },
    org_id: {},
    org_name: {},
    user: {},
    show: {}
  },
  data() {
    return {
      fields: [
        {
          key: 'displayname',
          sortable: true,
          colType: 'displayname',
          label: 'Name'
        },
        {
          key: 'main_loop',
          sortable: true,
          colType: 'main_loop',
          label: 'Main Loop'
        },
        {
          key: 'number_of_loop',
          sortable: true,
          colType: 'number',
          label: 'Number Of Loops'
        },
        {
          key: 'player_count',
          sortable: true,
          colType: 'players',
          label: 'Active Player'
        },
        {
          key: 'ACTIONS',
          colType: 'button',
          label: 'Actions'
        }
      ],
      isShownModalAddCampaign: false,
      isShownModalExport: false,
      isShownModalDelete: false,
      isShownModalConfirm: false,
      currentPage: 1,
      currentCampaignID: '',
      newNameCampaign: {
        campaign: {
          displayname: ''
        }
      },
      canAddCampaign: false,
      statusCampaign: '',
      submitted: false,
      isItself: false,
      url: '',
      searchValue: ''
    }
  },
  validations: {
    newNameCampaign: {
      campaign: {
        displayname: {
          required,
          maxLength: maxLength(50)
        }
      }
    }
  },
  methods: {
    ...mapActions('adminorganization', {
      getDataTableOfOrganization: 'getDataTableOfOrganization'
    }),
    addNewCampaign() {
      this.submitted = false
      this.newNameCampaign.campaign.displayname = ''
      if (this.similarOrg || this.isItself) {
        this.isShownModalAddCampaign = true
      } else {
        this.statusCampaign = 'modal'
        this.isShownModalConfirm = true
      }
    },
    goToEditCampaign(id) {
      this.submitted = false
      this.currentCampaignID = id
      if (this.similarOrg || this.isItself) {
        this.$router.push(`/campaigns/${id}/edit`)
      } else {
        this.statusCampaign = 'link'
        this.isShownModalConfirm = true
      }
    },
    joinOrg() {
      AdminOrganizationService.joinOrganization(this.org_id).then((res) => {
        this.$bus.$emit('update_joins_organization', res.data)
        this.$toast.success('Joined Organization')
        // set cookies
        const tokenExpired = Vue.$cookies.get('tokenExpired')
        const remember = Vue.$cookies.get('rememberMe') === 'true'
        this.user.organization = res.data.organization.name
        this.user.is_parent_organization = res.data.organization.is_parent_organization
        if (tokenExpired && remember) {
          Vue.$cookies.set(
            'user',
            this.user,
            moment(tokenExpired).format('ddd, DD MMM YYYY HH:mm:ss ZZ')
          )
        } else {
          Vue.$cookies.set('user', this.user, 0)
        }
        this.isShownModalConfirm = false
        this.isItself = true
        // go to campaign page
        if (this.statusCampaign === 'link') {
          this.$router.push(`/campaigns/${this.currentCampaignID}/edit`)
        } else if (this.statusCampaign === 'modal') {
          this.isShownModalAddCampaign = true
        } else if (this.statusCampaign === 'delete') {
          this.isShownModalDelete = true
        }
      })
    },
    shownModalDelete(id) {
      this.currentCampaignID = id
      if (this.similarOrg || this.isItself) {
        this.isShownModalDelete = true
      } else {
        this.statusCampaign = 'delete'
        this.isShownModalConfirm = true
      }
    },
    addCampaign() {
      this.submitted = true
      if (this.$v.newNameCampaign.$invalid) {
        return
      }
      CampaignService.addNewCampaign(this.newNameCampaign)
        .then((response) => {
          this.isShownModalAddCampaign = false
          this.$toast.success('Campaign successfully created')
          this.$router.push(`/campaigns/${response.data.campaign_code}/edit`)
        })
        .catch((err) => {
          this.$toast.error(err.message)
          this.isShownModalAddCampaign = false
        })
    },
    shownModalExport() {
      this.isShownModalExport = true
      AdminOrganizationService.getDataToExport({ type: 'campaigns', id: this.org_id }).then(
        (res) => {
          this.url = res.data.url
        }
      )
    },
    exportFile() {
      const tag = document.getElementById('link-export')
      tag.setAttribute('target', '_blank')
      tag.setAttribute('href', this.url)
      tag.click()
      this.isShownModalExport = false
    },
    searchInputChange(val) {
      this.searchValue = val
      this.currentPage = 1
      this.fetchData(this.currentPage, this.searchValue)
    },
    fetchData(currentPage, search) {
      this.getDataTableOfOrganization({
        id: this.org_id,
        type: 'campaigns',
        page: currentPage,
        limit: 25,
        search: this.searchValue
      })
    },
    changePage(page) {
      this.currentPage = page
      this.getDataTableOfOrganization({
        id: this.org_id,
        type: 'campaigns',
        page: this.currentPage,
        limit: 25,
        search: this.searchValue
      })
    },
    deleteCampaign() {
      return CampaignService.remove(this.currentCampaignID)
        .then((res) => {
          this.isShownModalDelete = false
          if (this.dataTableCampaign.items.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          this.fetchData(this.currentPage, this.searchValue)
          this.$toast.success('Campaign successfully deleted')
        })
        .catch((err) => {
          this.$toast.error(err.message)
          this.isShownModalDelete = false
        })
    }
  },
  computed: {
    ...mapState('adminorganization', {
      dataTableCampaign: 'dataTableCampaign',
      loadingCampaign: 'loadingCampaign'
    })
  },
  watch: {
    show(value) {
      if (value === 'campaigns') {
        this.fetchData(this.currentPage, this.searchValue)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/pages/admin/Adminpage.scss';
</style>
