import AdminOrgTypeService from '../../../../services/admin-org-type.service'

export default {
  getAllOrgType({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return AdminOrgTypeService.getAllOrgType(payload.page, payload.limit, payload.search)
      .then((response) => {
        commit('SET_ITEMS', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  }
}
