<template>
  <div class="container-fluid">
    <div class="card-header top-bar">
      <div class="header-left">
        <div class="admin-search-box">
          <SfInputSearch
            placeholder="Search"
            v-debounce:500ms="searchInputChange"
            class="sf-input-search"
          />
        </div>
      </div>
      <div class="header-right">
        <button class="sf-primary-add" @click="showModalCreate">Add New Customer</button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 sf-table">
          <b-table
            show-empty
            striped
            hover
            :items="items"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
            :busy="loading"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty="isEmpty">
              <p class="d-flex justify-content-center">No data available in table</p>
            </template>
            <template v-slot:head(first_name)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(first_name)="field">
              <p>
                <router-link :to="{ path: `/admin/clients/users/customers/${field.item.id}` }">{{
                  field.item.first_name + ' ' + field.item.last_name
                }}</router-link>
              </p>
            </template>

            <template v-slot:head(email)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(email)="field">
              <p>{{ field.item.email }}</p>
            </template>

            <template v-slot:head(organization)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(organization)="field">
              <p v-if="field.item.organization">{{ field.item.organization.name }}</p>
            </template>

            <template v-slot:head(direct_login)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(direct_login)="field">
              <p>{{ field.item.direct_login ? 'Enabled' : 'Disabled' }}</p>
            </template>

            <template v-slot:head(is_2fa)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(is_2fa)="field">
              <p>{{ field.item.is_2fa ? 'Enabled' : 'Disabled' }}</p>
            </template>

            <template v-slot:cell(action)="field">
              <button @click="shownModalEdit(field.item.id)" class="button-edit-table"></button>
              <button
                @click="shownModalDelete(field.item.id)"
                class="button-delete-table"
                title="Delete"
              ></button>
            </template>
          </b-table>

          <div v-if="!isEmpty" class="my-3">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="pagination.limit * pagination.total"
              :per-page="pagination.limit"
              @change="changePage"
              :limit="6"
              v-model="currentPage"
            />
          </div>
        </div>
      </div>
    </div>
    <SfModal
      title="Add New Customer"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalCreate"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">First Name</label>
                </div>
                <input
                  v-model="newUser.user.first_name"
                  placeholder="First Name..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.first_name.required">
                First Name required
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.first_name.maxLength">
                Use less than 250 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Last Name</label>
                </div>
                <input
                  v-model="newUser.user.last_name"
                  placeholder="Last Name..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.last_name.required">
                Last Name required
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.last_name.maxLength">
                Use less than 250 characters.
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Email</label>
                </div>
                <input v-model="newUser.user.email" placeholder="Email..." class="sf-input-text" />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.email.required">
                Email Address required
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.email.maxLength">
                Use less than 100 characters.
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.email.email">
                Must be valid Email Address
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Organization</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    @change="selectOrganization(newUser.user.organization_id, 'add')"
                    v-model="newUser.user.organization_id"
                    :options="organizationOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newUser.user.organization_id.required"
              >
                Select an Organization.
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Role</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    v-model="newUser.user.role_id"
                    :options="roleListNoPagingOptions"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.role_id.required">
                Select a role
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Password</label>
                </div>
                <input
                  :type="'password'"
                  v-model="newUser.user.password"
                  placeholder="Create a password"
                  class="sf-input-text"
                  autocomplete="new-password"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newUser.user.password.strongPassword"
              >
                Password must be at least 8 characters, with at least 1 capital and 1 number
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Confirm Password</label>
                </div>
                <input
                  :type="'password'"
                  v-model="newUser.user.passowrd_confirm"
                  placeholder="Retype your Password"
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newUser.user.passowrd_confirm.sameAsPassword"
              >
                Passwords must be identical
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalCreate = false">Cancel</button>
        <button class="sf-primary" @click="saveUser()">Save</button>
      </div>
    </SfModal>

    <SfModal
      title="Edit Customer"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingCustomer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">First Name</label>
                </div>
                <input
                  v-model="currentUser.user.first_name"
                  placeholder="First Name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.first_name.required"
              >
                First Name required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.first_name.maxLength"
              >
                Use less than 250 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingCustomer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Last Name</label>
                </div>
                <input
                  v-model="currentUser.user.last_name"
                  placeholder="Last Name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.last_name.required"
              >
                Last Name required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.last_name.maxLength"
              >
                Use less than 250 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingCustomer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Email</label>
                </div>
                <input
                  v-on:keyup="checkEmailExist(currentUserID, currentUser.user.email)"
                  v-model="currentUser.user.email"
                  placeholder="Email..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentUser.user.email.required">
                Email Address required
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentUser.user.email.maxLength">
                Use less than 100 characters.
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentUser.user.email.email">
                Must be valid Email Address
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingCustomer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Organization</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    @change="selectOrganization"
                    v-model="currentUser.user.organization_id"
                    :options="organizationOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.organization_id.required"
              >
                Select an Organization.
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingCustomer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Direct Login</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="currentUser.user.direct_login"
                    :sync="true"
                    @change="onChangeToggleDetail"
                    :width="44"
                    :height="22"
                    :margin="2"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3" v-if="show2FaToogle">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingCustomer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">2FA</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="currentUser.user.is_2fa"
                    :sync="true"
                    :width="44"
                    :height="22"
                    :margin="2"
                    @change="onChangeToggleDetail2fa"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingCustomer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Role</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="currentUser.user.role_id"
                    :options="roleListNoPagingOptions"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentUser.user.role_id.required">
                Select a role
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingCustomer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Password</label>
                </div>
                <input
                  :type="'password'"
                  v-model="currentUser.user.password"
                  placeholder="Create a password"
                  class="sf-input-text"
                  autocomplete="new-password"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.password.strongPassword"
              >
                Password must be at least 8 characters, with at least 1 capital and 1 number
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingCustomer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Confirm Password</label>
                </div>
                <input
                  :type="'password'"
                  v-model="currentUser.user.passowrd_confirm"
                  placeholder="Retype your Password"
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.passowrd_confirm.sameAsPassword"
              >
                Passwords must be identical
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEdit = false">Cancel</button>
        <button class="sf-primary" @click="updateUser()">Save</button>
      </div>
    </SfModal>

    <SfDeleteModal :title="'Confirm Delete'" closeOnOverlay :show.sync="isShownModalDelete">
      <div class="content-delete">
        Deleting this customer. Are you sure you want to delete this one?
      </div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShownModalDelete = false">Cancel</button>
        <button class="sf-primary" @click="removeUser">OK</button>
      </div>
    </SfDeleteModal>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import Select2 from 'v-select2-component'
import AdminUserService from '@/services/admin-user.service'
import { required, maxLength, email, sameAs } from 'vuelidate/lib/validators'
import SfInputSearch from '@/components/SfInputSearch.vue'
export default {
  name: 'admin-user-customer',
  components: {
    SfModal,
    Select2,
    SfDeleteModal,
    SfInputSearch
  },

  data() {
    return {
      msg: 'Welcome to Index!!!',
      isShownModalCreate: false,
      isShownModalDelete: false,
      isShownModalEdit: false,
      currentPage: 1,
      currentUserID: '',
      submitted: false,
      fields: [
        {
          key: 'first_name',
          sortable: true,
          label: 'Name',
          colType: 'first_name'
        },
        {
          key: 'email',
          sortable: true,
          label: 'Email',
          colType: 'email'
        },
        {
          key: 'organization',
          sortable: true,
          label: 'Organization',
          colType: 'organization'
        },
        {
          key: 'direct_login',
          sortable: true,
          colType: 'direct_login',
          label: 'Direct Login'
        },
        {
          key: 'is_2fa',
          sortable: true,
          colType: 'is_2fa',
          label: '2FA'
        },
        {
          key: 'action',
          colType: 'button',
          label: 'Actions'
        }
      ],
      newUser: {
        user: {
          first_name: '',
          last_name: '',
          email: '',
          role_id: '',
          user_type: 'customer',
          organization_id: '',
          organization: {
            id: ''
          },
          password: '',
          passowrd_confirm: ''
        }
      },
      currentUser: {
        user: {
          first_name: '',
          last_name: '',
          email: '',
          role_id: 0,
          user_type: 'customer',
          organization_id: 0,
          organization: {
            id: 0
          },
          password: '',
          passowrd_confirm: '',
          direct_login: false,
          is_2fa: false
        }
      },
      defaultUser: {
        user: {
          first_name: '',
          last_name: '',
          email: '',
          role_id: 0,
          user_type: 'customer',
          organization_id: 0,
          organization: {
            id: 0
          },
          password: '',
          passowrd_confirm: '',
          direct_login: false,
          is_2fa: false
        }
      },
      dataSearch: '',
      roleListDefault: [
        {
          id: '',
          text: 'Select Role'
        }
      ],
      show2FaToogle: false,
      loadingCustomer: false
    }
  },

  validations: {
    newUser: {
      user: {
        first_name: {
          required,
          maxLength: maxLength(250)
        },
        last_name: {
          required,
          maxLength: maxLength(250)
        },
        email: {
          required,
          maxLength: maxLength(100),
          email
        },
        password: {
          strongPassword(password) {
            return (
              /[A-Z]/.test(password) && // checks for A-Z
              /[0-9]/.test(password) && // checks for 0-9
              password.length >= 8
            )
          }
        },
        passowrd_confirm: {
          sameAsPassword: sameAs('password')
        },
        organization_id: {
          required
        },
        role_id: {
          required
        }
      }
    },
    currentUser: {
      user: {
        first_name: {
          required,
          maxLength: maxLength(250)
        },
        last_name: {
          required,
          maxLength: maxLength(250)
        },
        email: {
          required,
          maxLength: maxLength(100),
          email
        },
        password: {
          strongPassword(password) {
            return (
              (/[A-Z]/.test(password) && // checks for A-Z
                /[0-9]/.test(password) && // checks for 0-9
                password.length >= 8 &&
                password !== '') ||
              password === ''
            )
          }
        },
        passowrd_confirm: {
          sameAsPassword: sameAs('password')
        },
        organization_id: {
          required
        },
        role_id: {
          required
        }
      }
    }
  },

  methods: {
    ...mapActions('adminorganization', {
      getRoleListByOrganizationNoPaging: 'getRoleListByOrganizationNoPaging'
    }),
    fetchData(activePage, search) {
      this.$store.dispatch('adminuser/getAllUser', {
        page: activePage,
        limit: 10,
        user_type: 'customer',
        search
      })
    },
    loadOrganization() {
      this.$store.dispatch('adminuser/getAllOrganizationNoPaging')
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.dataSearch)
    },
    showModalCreate() {
      this.submitted = false
      this.setDataUser(this.newUser.user, this.defaultUser.user)
      this.loadOrganization()
      this.isShownModalCreate = true
    },
    shownModalDelete(customer_id) {
      this.isShownModalDelete = true
      this.currentUserID = customer_id
    },
    shownModalEdit(customer_id) {
      this.submitted = false
      this.loadOrganization()
      this.isShownModalEdit = true
      this.currentUserID = customer_id
      this.getItemByID()
    },
    saveUser() {
      this.submitted = true
      if (this.$v.newUser.$invalid) {
        return
      }
      delete this.newUser.user.direct_login
      delete this.newUser.user.is_2fa
      AdminUserService.createUser(this.newUser)
        .then((response) => {
          this.$toast.success('Successfully created')
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalCreate = false
    },
    getItemByID() {
      this.loadingCustomer = true
      AdminUserService.detailUser(this.currentUserID)
        .then((response) => {
          this.loadingCustomer = false
          this.setDataUser(this.currentUser.user, response.data)
          this.getRoleListByOrganizationNoPaging(this.currentUser.user.organization_id)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
    },
    updateUser() {
      this.submitted = true
      if (this.$v.currentUser.$invalid) {
        return
      }
      AdminUserService.updateUser(this.currentUserID, this.currentUser)
        .then((response) => {
          this.$toast.success('Successfully updated')
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalEdit = false
    },
    setDataUser(user, new_user) {
      user.first_name = new_user.first_name
      user.last_name = new_user.last_name
      user.email = new_user.email
      user.role_id = new_user.role_id
      user.user_type = new_user.user_type
      user.direct_login = new_user.direct_login
      user.is_2fa = new_user.is_2fa
      user.organization_id = new_user.organization.id
      user.password = new_user.password ? new_user.password : ''
      user.passowrd_confirm = new_user.passowrd_confirm ? new_user.passowrd_confirm : ''
      this.show2FaToogle = new_user.is_2fa
    },
    removeUser() {
      AdminUserService.removeUser(this.currentUserID)
        .then((response) => {
          this.$toast.success('Successfully deleted')
          if (this.items.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalDelete = false
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    },
    selectOrganization(id, type) {
      if (id) {
        this.getRoleListByOrganizationNoPaging(id)
      }
      if (type === 'add') {
        this.newUser.user.role_id = ''
      } else if (type === 'edit') {
        this.currentUser.user.role_id = ''
      }
    },
    onChangeToggleDetail(value) {
      this.currentUser.user.direct_login = value.value
    },
    onChangeToggleDetail2fa(value) {
      this.currentUser.user.is_2fa = value.value
    }
  },

  computed: {
    ...mapState('adminuser', {
      items: 'items',
      pagination: 'pagination',
      organizationOptions: 'organizationOptions',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('adminuser', ['isEmpty']),
    ...mapGetters('adminorganization', {
      roleListNoPagingOptions: 'roleListNoPagingOptions'
    })
  },

  mounted() {
    this.fetchData(this.currentPage)
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    })
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  }
}
</script>

<style lang="scss" scoped>
@import '../../Adminpage.scss';
</style>
