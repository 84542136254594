import BaseService from './base.service'
import { IG_CLIENT_SECCRET } from '@/.env.js'
class SmartAssetsService extends BaseService {
  get entity() {
    return 'library'
  }

  getListSmartAsset(
    page = 1,
    limit = 25,
    search = '',
    page_name = 'library', // library || loop_edit
    loop_id, // Id loop asset if it is page loop_edit
    type = '',
    orientation = ''
  ) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          `${this.entity}?page=${page}&limit=${limit}${
            search ? '&s=' + search : ''
          }&orientation=${orientation}&type=${type}&page_name=${
            page_name === 'loop_edit' ? 'loop_edit' : 'library'
          }${loop_id ? '&loop_id=' + loop_id : ''}`
        )
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getLoopsByAssetID(assetID, page = 1, limit = 25, search = '') {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          `${this.entity}/${assetID}/loop_assets/?page=${page}&limit=${limit}${
            search ? '&s=' + search : ''
          }`
        )
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getCampaignsByAssetID(assetID, page = 1, limit = 25, search = '') {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          `${this.entity}/${assetID}/campaigns/?page=${page}&limit=${limit}${
            search ? '&s=' + search : ''
          }`
        )
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getPlayerByAssetID(assetID, page = 1, limit = 25, search = '') {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          `${this.entity}/${assetID}/players/?page=${page}&limit=${limit}${
            search ? '&s=' + search : ''
          }`
        )
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getSmartAssetsByID(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/${id}`)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // authorization and get token
  getInstagramToken(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/get_instagram_authorization`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  // get ig media
  getInstagramMedia(data) {
    const formData = new FormData()
    formData.append('user_id', data)
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/get_instagram_media`, formData)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  // get long live token
  getLongliveToken(assetToken) {
    const Url = `https://graph.instagram.com/access_token?grant_type=ig_exchange_token&client_secret=${IG_CLIENT_SECCRET}&access_token=${assetToken}`
    return new Promise((resolve, reject) => {
      return this.request({ auth: false })
        .get(Url)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data, null))
        })
        .catch((error) => {
          reject(this.errorWrapper(error))
        })
    })
  }

  getIgInfo(assetToken, user_id) {
    const Url = `https://graph.instagram.com/v11.0/${user_id}?fields=id,username&access_token=${assetToken}`
    return new Promise((resolve, reject) => {
      return this.request({ auth: false })
        .get(Url)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data, null))
        })
        .catch((error) => {
          reject(this.errorWrapper(error))
        })
    })
  }

  getInstagramPost(assetToken) {
    const Url = `https://graph.instagram.com/me/media?fields=caption,username,media_url&access_token=${assetToken}`
    return new Promise((resolve, reject) => {
      return this.request({ auth: false })
        .get(Url)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data, null))
        })
        .catch((error) => {
          reject(this.errorWrapper(error))
        })
    })
  }

  getIgMedia(assetToken) {
    const Url = `https://graph.instagram.com/me?fields=id,username,media_count,media{caption,media_url,media_type}&access_token=${assetToken}`
    return new Promise((resolve, reject) => {
      return this.request({ auth: false })
        .get(Url)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data, null))
        })
        .catch((error) => {
          reject(this.errorWrapper(error))
        })
    })
  }

  getUserPage(accessToken) {
    const Url = `https://graph.facebook.com/v14.0/me/accounts?access_token=${accessToken}`
    return new Promise((resolve, reject) => {
      return this.request({ auth: false })
        .get(Url)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, null))
        })
        .catch((error) => {
          reject(this.errorWrapper(error))
        })
    })
  }

  getFbLongliveToken(accessToken) {
    const clientId = process.env.VUE_APP_FACEBOOK_APP_ID
    const clientSecret = process.env.VUE_APP_FACEBOOK_APP_SECRET
    const Url = `https://graph.facebook.com/v14.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${clientId}&client_secret=${clientSecret}&fb_exchange_token=${accessToken}`
    return new Promise((resolve, reject) => {
      return this.request({ auth: false })
        .get(Url)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data, null))
        })
        .catch((error) => {
          reject(this.errorWrapper(error))
        })
    })
  }

  instagramBusinessAccount(accessToken, id) {
    const Url = `https://graph.facebook.com/v14.0/${id}?fields=instagram_business_account&access_token=${accessToken}`
    return new Promise((resolve, reject) => {
      return this.request({ auth: false })
        .get(Url)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data, null))
        })
        .catch((error) => {
          reject(this.errorWrapper(error))
        })
    })
  }

  getIgBusinessMediaObjects(accessToken, id) {
    const Url = `https://graph.facebook.com/v14.0/${id}/media?fields=id,caption,media_type,media_url,username,timestamp&access_token=${accessToken}`
    return new Promise((resolve, reject) => {
      return this.request({ auth: false })
        .get(Url)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data, null))
        })
        .catch((error) => {
          reject(this.errorWrapper(error))
        })
    })
  }

  async getInstagramAuthorization(params) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/get_instagram_authorization`, params)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data, null))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  async getInstagramData(formData) {
    try {
      const token = await this.getInstagramToken(formData)
      const userId = new FormData()
      userId.append('user_id', token.data.user_id)
      const instagram = await this.getInstagramMedia(token.data.user_id)
      instagram.data.access_token = token.data.access_token
      // instagram.data.userId = igInfo.data.id
      // instagram.data.userName = igInfo.data.username
      return instagram.data
    } catch (error) {
      return error
    }
  }

  getRssFromUrl(url) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/get_rss/?rss_url=${url}`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, null))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  createSmartAssets(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  editSmartAssets(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .patch(`${this.entity}`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  editSmartAssetsByID(data, id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .patch(`${this.entity}/${id}`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  deleteSmartAssetsByID(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .delete(`${this.entity}/${id}`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getPermissionCreate(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/premission_create`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  resizeImage(url) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = (e) => {
        if (e.target.width > 960) {
          if (e.target.width >= e.target.height) {
            const ratio = e.target.width / 960
            e.target.width = 960
            e.target.height = e.target.height / ratio
            if (e.target.height > 540) {
              const ratioHeight = e.target.height / 540
              e.target.height = 540
              e.target.width = e.target.width / ratioHeight
            }
          } else {
            const ratio = e.target.height / 540
            e.target.height = 540
            e.target.width = e.target.width / ratio
            if (e.target.width > 960) {
              const ratioWidth = e.target.width / 960
              e.target.width = 960
              e.target.height = e.target.height / ratioWidth
            }
          }
        }
        if (e.target.height > 540) {
          if (e.target.width <= e.target.height) {
            const ratio = e.target.height / 540
            e.target.height = 540
            e.target.width = e.target.width / ratio
            if (e.target.width > 960) {
              const ratioWidth = e.target.width / 960
              e.target.width = 960
              e.target.height = e.target.height / ratioWidth
            }
          } else {
            const ratio = e.target.width / 960
            e.target.width = 960
            e.target.height = e.target.height / ratio
            if (e.target.height > 540) {
              const ratioHeight = e.target.height / 540
              e.target.height = 540
              e.target.width = e.target.width / ratioHeight
            }
          }
        }
        const width = e.target.width
        const height = e.target.height
        return resolve({ width, height })
      }
      img.src = url
    })
  }

  resizeVideo(dataVideo) {
    return new Promise((resolve, reject) => {
      let width = dataVideo.videoWidth
      let height = dataVideo.videoHeight
      if (width > 960) {
        if (width >= height) {
          const ratio = width / 960
          width = 960
          height = height / ratio
          if (height > 540) {
            const ratioHeight = height / 540
            height = 540
            width = width / ratioHeight
          }
        } else {
          const ratio = height / 540
          height = 540
          width = width / ratio
          if (width > 960) {
            const ratioWidth = width / 960
            width = 960
            height = height / ratioWidth
          }
        }
      }
      if (height > 540) {
        if (width <= height) {
          const ratio = height / 540
          height = 540
          width = width / ratio
          if (width > 960) {
            const ratioWidth = width / 960
            width = 960
            height = height / ratioWidth
          }
        } else {
          const ratio = width / 960
          width = 960
          height = height / ratio
          if (height > 540) {
            const ratioHeight = height / 540
            height = 540
            width = width / ratioHeight
          }
        }
      }
      return resolve({ width, height })
    })
  }

  getListTimezone() {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/time_zone`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
}

export default new SmartAssetsService()
