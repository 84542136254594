import BaseService from './base.service'

class AdminRoleService extends BaseService {
  get entity() {
    return 'admin/roles'
  }

  createRole(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}`, data)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  updateRole(id, data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .patch(`${this.entity}/${id}`, data)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getRolesListNoPaging(payload) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/all_roles?role_type=${payload}`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getRole(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/${id}`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  deleteRole(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .delete(`${this.entity}/${id}`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  assignRole(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/assign_role`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  unassignRole(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .delete(`${this.entity}/unassign_role`, { data: data })
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getRoleList(page, limit, search) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}?page=${page}&limit=${limit}${search ? '&s=' + search : ''}`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getRolesListByOrganizationsNoPaging(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`admin/organizations/${id}/all_roles`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getRoleListByUser() {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get('roles/all_roles')
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }
}

export default new AdminRoleService()
