<template>
  <div class="instagram-editor" v-bind:class="{ portrait: isPortrait }" id="instagramEditor">
    <div v-if="getAuthentication" class="container container-ig-editor">
      <div class="row">
        <div class="col-4 left-block">
          <div class="row">
            <div class="col-3 toolbar-wrap">
              <LeftToolbar />
            </div>
            <div class="col-9 ig-settings-wrap">
              <div class="ig-settings">
                <div v-if="toolBar.overall">
                  <AccountLinked />
                </div>
                <div v-if="toolBar.edit">
                  <div v-if="elementActive.photo">
                    <EditPhoto v-if="numberData" :numberData="numberData" />
                  </div>
                  <div v-if="elementActive.profilePic">
                    <EditLogo />
                  </div>
                  <TextAttribute
                    :hasTabs="false"
                    :active="elementActive.titleIg"
                    :title="'Edit Handle'"
                    :description="'Style your instagram handle the way you want it to look'"
                  />
                  <TextAttribute
                    :hasTabs="true"
                    :active="elementActive.textIg"
                    :title="'Edit Caption'"
                    :description="'Style your caption any way you want. Use different styles for Text, Mentions, and Hashtags!'"
                  />
                  <TextAttribute
                    :hasTabs="true"
                    :title="'Edit First Self-Comment'"
                    :description="'You can show the first comment you write on your post. Style it here!'"
                    :active="elementActive.tagsIg"
                  />
                </div>
                <div v-if="toolBar.widget">
                  <Widget />
                </div>
                <div v-if="toolBar.background">
                  <BackgroundIg />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8 right-block">
          <div class="row">
            <div class="col-12">
              <Origin :active="elementActive.photo" :attributeEl="photo" />
              <Origin :active="elementActive.profilePic" :attributeEl="profilePic" />
              <Origin :active="elementActive.titleIg" :attributeEl="titleIg" />
              <Origin :active="elementActive.textIg" :attributeEl="textIg" />
              <Origin :active="elementActive.tagsIg" :attributeEl="tagsIg" />
            </div>
          </div>

          <EditArea
            v-if="photo && profilePic"
            :photo="photo"
            :profilePic="profilePic"
            :titleIg="titleIg"
            :isPortrait="isPortrait"
            :textIg="textIg"
            :tagsIg="tagsIg"
            :bgColorEditor="bgColorEditor"
          />
        </div>
      </div>
      <div class="col-12 ig-action">
        <div class="row">
          <div class="col-10">
            <div class="row">
              <div class="col-3">
                <div class="undo-redo">
                  <a @click="undo" class="undo"
                    ><img :src="undoIcon" /><span>{{ $t('undo') }}</span></a
                  >
                  <a @click="redo" class="redo"
                    ><img :src="redoIcon" /><span>{{ $t('redo') }}</span></a
                  >
                  <a @click="resetAll" class="reset"
                    ><img :src="resetIcon" /><span>{{ $t('reset') }}</span></a
                  >
                </div>
              </div>
              <div class="col-5">
                <div class="template-action template-action-save">
                  <TemplateIg :orientation="orientation" :options="templateList" />
                  <button
                    @click="saveTemplateModal(true)"
                    :disabled="templateAction"
                    class="save-template"
                  >
                    <img :src="saveIcon" /><span>{{ $t('save') }}</span>
                  </button>
                  <button
                    @click="deleteTemplateModal(true)"
                    :disabled="templateAction"
                    class="delete-template"
                  >
                    <img :src="deleteIcon" /><span>{{ $t('delete') }}</span>
                  </button>
                </div>
              </div>
              <div class="col-3">
                <div class="template-action create-new">
                  <a @click="createTemplateModal(true)" class="save-template"
                    ><span class="plus-symbol">+</span><span>{{ $t('new-template') }}</span></a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="smart-asset-save">
              <button id="cancel" @click="cancel" class="btn-cancel-ig">{{ $t('cancel') }}</button>
              <button v-if="isUpdate" class="btn-save-ig" @click="update">
                {{ $t('update') }}
              </button>
              <button v-else class="btn-save-ig" @click="save">{{ $t('save') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container pt-5 container-ig-editor">
      <div v-bind:class="{ 'd-none': isUpdate }" class="row full-width">
        <div class="col-7 left-block">
          <div class="row">
            <div class="col-6">
              <p>
                <b>{{ $t('instagram-account-linked') }}:</b>
              </p>
              <button class="btn btn-ig-add" @click="handleAuthenticationIG">
                {{ $t('click-to-link-instagram-account') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showSaveModal" class="ig-modal modal-save">
      <div class="ig-modal-header modal-header-save">
        <h4>{{ $t('save-changes-to-existing-template') }}</h4>
      </div>
      <div class="ig-modal-body">
        <p>{{ $t('template-save-note') }}</p>
      </div>
      <div class="ig-modal-footer">
        <button @click="saveTemplateModal(false)" class="ig-btn cancel">{{ $t('cancel') }}</button>
        <button :disabled="savingTemplate" @click="saveTemplate" class="ig-btn save">
          {{ $t('save') }}
        </button>
      </div>
    </div>

    <div v-if="showDeleteModal" class="ig-modal modal-delete">
      <div class="ig-modal-header modal-header-delete">
        <h4>{{ $t('delete-existing-template') }}</h4>
      </div>
      <div class="ig-modal-body">
        <p>{{ $t('delete-template-confirm') }}</p>
      </div>
      <div class="ig-modal-footer">
        <button @click="deleteTemplateModal(false)" class="ig-btn cancel">
          {{ $t('cancel') }}
        </button>
        <button :disabled="deletingTemplate" @click="deleteTemplate" class="ig-btn delete">
          {{ $('delete') }}
        </button>
      </div>
    </div>

    <div v-if="showCreateModal" class="ig-modal modal-create">
      <div class="ig-modal-header modal-header-create">
        <h4>{{ $t('create-a-new-template') }}</h4>
      </div>
      <div class="ig-modal-body">
        <p>{{ $t('new-template-name-ask') }}</p>
        <div class="ig-form-field">
          <TemplateName />
          <div v-if="templateError">
            <p class="error-message">{{ templateError }}</p>
          </div>
        </div>
      </div>
      <div class="ig-modal-footer">
        <button @click="deleteTemplateModal(false)" class="ig-btn cancel">
          {{ $t('cancel') }}
        </button>
        <button :disabled="creatingTemplate" @click="createTemplate" class="ig-btn create">
          {{ $t('create') }}
        </button>
      </div>
    </div>
    <div v-if="showCreateModal || showDeleteModal || showSaveModal" class="ig-modal-backgrop"></div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import SmartAssetsService from '@/services/smart-assets.service'
import SmartAssetsTemplateService from '@/services/smart-asset-template.service'
import AccountLinked from './AccountLinked.vue'
import EditArea from './EditArea.vue'
import Origin from './Origin.vue'
import TemplateIg from './TemplateIg.vue'
import EditPhoto from './EditPhoto.vue'
import BackgroundIg from './BackgroundIg.vue'
import TextAttribute from './TextAttribute.vue'
// import TypeOfPost from './TypeOfPost.vue'
import TemplateName from './TemplateName.vue'
import LeftToolbar from './LeftToolbar.vue'
import EditLogo from './EditLogo.vue'
import Widget from './Widget.vue'
import { SITE_DOMAIN } from '@/.env.js'
import undoIcon from '@/assets/images/undo.svg'
import redoIcon from '@/assets/images/redo.svg'
import resetIcon from '@/assets/images/reset.svg'
import saveIcon from '@/assets/images/save.svg'
import deleteIcon from '@/assets/images/ig-editor-delete.svg'
export default {
  name: 'instagram-editor',
  components: {
    EditArea,
    Origin,
    TemplateIg,
    EditPhoto,
    BackgroundIg,
    TextAttribute,
    AccountLinked,
    // TypeOfPost,
    TemplateName,
    LeftToolbar,
    EditLogo,
    Widget
  },
  data: function () {
    return {
      active: true,
      authenButton: false,
      popup: '',
      isUpdate: false,
      isPortrait: false,
      orientation: '',
      undoIcon,
      redoIcon,
      resetIcon,
      saveIcon,
      deleteIcon,
      showSaveModal: false,
      showDeleteModal: false,
      showCreateModal: false,
      templateError: '',
      templateList: [],
      savingTemplate: false,
      creatingTemplate: false,
      deletingTemplate: false
    }
  },
  created() {
    let instagram = localStorage.getItem('instagram')
    if (instagram) {
      instagram = JSON.parse(instagram)
      const orientation = instagram.orientation
      this.orientation = orientation
      this.$store.dispatch('instagrameditor/setInstagramMode', { orientation })
      if (orientation === 'portrait') {
        this.isPortrait = true
      }
      const igEditorID = instagram.smart_asset_id
      if (igEditorID) {
        this.isUpdate = true
        this.$store.dispatch('instagrameditor/loadIgEditorInfo')
      }
    }
    this.loadTemplateList()
  },
  mounted() {},
  updated() {},
  beforeDestroy() {
    localStorage.removeItem('instagram')
    localStorage.removeItem('instagramInfo')
    this.resetAuth()
    this.logoutIG()
    // location.reload()
  },
  computed: {
    screenType() {
      return this.isPortrait ? 'portrait' : 'lanscape'
    },
    ...mapState('instagrameditor', {
      photo: 'photo',
      profilePic: 'profilePic',
      titleIg: 'titleIg',
      textIg: 'textIg',
      tagsIg: 'tagsIg',
      bgColorEditor: 'bgEditor',
      numberData: 'numberData',
      elementActive: 'elementActive',
      toolBar: 'toolBar',
      templateAction: 'templateAction'
    }),
    ...mapGetters('instagrameditor', ['getAuthentication']),
    ...mapState('library', {
      organizationId: 'organizationId'
    })
  },
  methods: {
    ...mapMutations('instagrameditor', {
      resetAuth: 'RESET_AUTH'
    }),
    ...mapMutations('library', {
      updateItem: 'SET_UPDATE_ITEM',
      updateFolder: 'SET_UPDATE_FOLDER',
      updateCountAssetStatistical: 'UPDATE_COUNT_ASSET_STATISTICAL'
    }),
    handleAuthenticationIG() {
      const instagramRedirectUri = SITE_DOMAIN + 'library'
      const popupWidth = 700
      const popupHeight = 500
      const popupLeft = (window.screen.width - popupWidth) / 2
      const popupTop = (window.screen.height - popupHeight) / 2
      this.popup = window.open(
        'https://api.instagram.com/oauth/authorize/?client_id=581481562798275&response_type=code&scope=user_profile,user_media' +
          '&redirect_uri=' +
          encodeURIComponent(instagramRedirectUri),
        '_blank',
        'width=' +
          popupWidth +
          ',height=' +
          popupHeight +
          ',left=' +
          popupLeft +
          ',top=' +
          popupTop +
          ''
      )
      var interval = setInterval(() => {
        try {
          if (this.popup.location.search) {
            clearInterval(interval)
            const search = this.popup.location.search
            const arraySearch = search.split('=')
            if (arraySearch && arraySearch.length > 1) {
              const code = arraySearch[1]
              const payload = { code }
              this.$store.dispatch('instagrameditor/getInstagramToken', payload)
            }
            this.popup.close()
          }
        } catch (evt) {}
      }, 100)
    },
    save() {
      SmartAssetsService.create(this.createParam('create'))
        .then((response) => {
          // location.reload()
          this.$toast.open('New Instagram Template created!')
          const closeIGE = document.getElementById('closeIGE')
          closeIGE.click()
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    update() {
      const instagramStorage = JSON.parse(localStorage.getItem('instagram'))
      const smart_asset_id = instagramStorage.smart_asset_id
      SmartAssetsService.editSmartAssetsByID(this.createParam('update'), smart_asset_id)
        .then((response) => {
          this.$toast.open('Asset successfully updated')
          this.updateItem({
            id: response.data.id,
            name: response.data.displayname,
            orientation: response.data.orientation
          })
          this.updateFolder({ id: response.data.id, name: response.data.displayname })
          const closeIGE = document.getElementById('closeIGE')
          closeIGE.click()
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    cancel() {
      const closeIGE = document.getElementById('closeIGE')
      closeIGE.click()
    },
    logoutIG() {
      const idIframeLogoutIG = document.getElementById('iframeLogoutIG')
      if (idIframeLogoutIG) {
        idIframeLogoutIG.parentNode.removeChild(idIframeLogoutIG)
      }
      const iframeLogoutIG = document.createElement('iframe')
      iframeLogoutIG.style.display = 'none'
      iframeLogoutIG.id = 'iframeLogoutIG'
      iframeLogoutIG.src = 'https://instagram.com/accounts/logout'
      const appID = document.getElementById('app')
      appID.appendChild(iframeLogoutIG)
    },
    createTemplate() {
      this.creatingTemplate = true
      const state = this.$store.getters['instagrameditor/getState']
      const templateName = state.templateName
      if (templateName) {
        SmartAssetsService.create(this.createParam('draft'))
          .then((response) => {
            this.showCreateModal = false
            this.creatingTemplate = false
            this.$toast.open('New Instagram Template created!')
            this.loadTemplateList()
          })
          .catch((err) => {
            this.showCreateModal = false
            this.creatingTemplate = false
            this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          })
      } else {
        this.templateError = 'Please enter a name for new template!'
      }
    },
    deleteTemplate() {
      const templateID = this.$store.getters['instagrameditor/getTemplateID']
      this.deletingTemplate = true
      if (templateID) {
        SmartAssetsTemplateService.deleteAssetTemplate(templateID)
          .then((response) => {
            this.deletingTemplate = false
            this.showDeleteModal = false
            this.$toast.open('Instagram Template updated')
            this.loadTemplateList()
          })
          .catch((err) => {
            this.deletingTemplate = false
            this.showDeleteModal = false
            this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          })
      }
    },
    saveTemplate() {
      this.savingTemplate = true
      const state = this.$store.getters['instagrameditor/getState']
      const smartAssetTemplateID = state.smartAssetTemplateID
      SmartAssetsService.editSmartAssetsByID(this.createParam('update'), smartAssetTemplateID)
        .then((response) => {
          this.savingTemplate = false
          this.showSaveModal = false
          this.$toast.open('Instagram Template updated')
        })
        .catch((err) => {
          this.savingTemplate = false
          this.showSaveModal = false
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    loadTemplateList() {
      SmartAssetsTemplateService.getSmartAssetTemplate(this.orientation)
        .then((response) => {
          const defaultElement = { id: 'default', text: 'Template Default' }
          this.templateList = response.data.map((i) => {
            i.text = i.name
            return i
          })
          this.templateList = [defaultElement].concat(this.templateList)
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    saveTemplateModal(param) {
      this.showSaveModal = param
      this.showDeleteModal = false
      this.showCreateModal = false
    },
    deleteTemplateModal(param) {
      this.showDeleteModal = param
      this.showSaveModal = false
      this.showCreateModal = false
    },
    createTemplateModal(param) {
      this.showCreateModal = param
      this.showSaveModal = false
      this.showDeleteModal = false
    },
    resetAll() {
      this.$store.dispatch('instagrameditor/resetAll')
    },
    undo() {
      this.$store.dispatch('instagrameditor/undo')
    },
    redo() {
      this.$store.dispatch('instagrameditor/redo')
    },

    createParam(type) {
      const state = this.$store.getters['instagrameditor/getState']
      const instagramStorage = JSON.parse(localStorage.getItem('instagram'))
      const instagramInfo = JSON.parse(localStorage.getItem('instagramInfo'))
      let formdata = false
      if (window.FormData) {
        formdata = new FormData()
      }
      if (state.bgEditor.bgImage.file) {
        formdata.append('smart_asset_details[asset_file]', state.bgEditor.bgImage.file)
      }
      if (state.logo.file) {
        formdata.append('smart_asset_details[instgram_icon]', state.logo.file)
      }
      if (state.mask.file) {
        formdata.append('smart_asset_details[instgram_mask]', state.mask.file)
      }

      // info smart_asset
      if (type === 'create') {
        if (instagramStorage.currentFolderID) {
          formdata.append('current_folder', instagramStorage.currentFolderID)
          formdata.append('organization_id', this.organizationId)
        }
      }

      if (type === 'draft') {
        formdata.append('smart_asset[status]', 'draft')
        formdata.append('smart_asset_template[name]', state.templateName)
        formdata.append('smart_asset_template[template_type]', 'instagram')
        if (state.templateID) {
          if (state.bgEditor.bgImage.file === '') {
            formdata.append('smart_asset_details[asset_file]', 'usedTemplate')
          }
          if (state.logo.file === '') {
            formdata.append('smart_asset_details[instgram_icon]', 'usedTemplate')
          }
          if (state.mask.file === '') {
            formdata.append('smart_asset_details[instgram_mask]', 'usedTemplate')
          }
        }
      }
      let tags = instagramStorage.tags
      if (tags.length && tags[0].text) {
        tags = instagramStorage.tags.map((item) => item.text)
      }

      formdata.append('smart_asset[displayname]', instagramStorage.displayname)
      formdata.append('smart_asset[tag_list][]', tags)
      formdata.append('smart_asset[orientation]', instagramStorage.orientation)
      formdata.append('smart_asset[smart_asset_type]', instagramStorage.smart_asset_type)

      formdata.append('smart_asset_template[id]', state.templateID)
      formdata.append('smart_asset_template[type]', state.templateType)

      // only property
      formdata.append('property[period][data_property]', state.numberData.durationPerSlide)
      formdata.append('property[image_count][data_property]', state.numberData.numberOfSlide)
      formdata.append('property[query][data_property]', instagramInfo.user.id)
      formdata.append('property[ig_user_id][data_property]', instagramInfo.user.id)
      formdata.append('property[ig_expires_in][data_property]', instagramInfo.expires_in)
      formdata.append('property[new_logic][data_property]', true)
      formdata.append('property[background_color][data_property]', state.bgEditor.color)

      // properties and attributes
      // main_photo
      formdata.append('property[main_photo][data_property]', 'image')
      formdata.append(
        'property[main_photo][asset_attribute][visitable][visitable]',
        state.photo.visitable
      )
      formdata.append('property[main_photo][asset_attribute][origin][x]', state.photo.x)
      formdata.append('property[main_photo][asset_attribute][origin][y]', state.photo.y)
      formdata.append('property[main_photo][asset_attribute][dimensions][w]', state.photo.w)
      formdata.append('property[main_photo][asset_attribute][dimensions][h]', state.photo.h)

      // avtart_photo
      formdata.append('property[avtart_photo][data_property]', 'image')
      formdata.append(
        'property[avtart_photo][asset_attribute][visitable][visitable]',
        state.profilePic.visitable
      )
      formdata.append('property[avtart_photo][asset_attribute][origin][x]', state.profilePic.x)
      formdata.append('property[avtart_photo][asset_attribute][origin][y]', state.profilePic.y)
      formdata.append('property[avtart_photo][asset_attribute][dimensions][w]', state.profilePic.w)
      formdata.append('property[avtart_photo][asset_attribute][dimensions][h]', state.profilePic.h)

      // username
      formdata.append('property[username][data_property]', 'text')
      formdata.append(
        'property[username][asset_attribute][visitable][visitable]',
        state.titleIg.visitable
      )
      formdata.append('property[username][asset_attribute][origin][x]', state.titleIg.x)
      formdata.append('property[username][asset_attribute][origin][y]', state.titleIg.y)
      formdata.append('property[username][asset_attribute][dimensions][w]', state.titleIg.w)
      formdata.append('property[username][asset_attribute][dimensions][h]', state.titleIg.h)
      formdata.append(
        'property[username][asset_attribute][text_attr][textAlign]',
        state.titleIg.textAttr.textAlign
      )
      formdata.append(
        'property[username][asset_attribute][text_attr][textColor]',
        state.titleIg.textAttr.textColor
      )
      formdata.append(
        'property[username][asset_attribute][text_attr][backgroundColor]',
        state.titleIg.textAttr.backgroundColor
      )
      formdata.append(
        'property[username][asset_attribute][text_attr][fontFamily]',
        state.titleIg.textAttr.fontFamily
      )
      formdata.append(
        'property[username][asset_attribute][text_attr][fontWeight]',
        state.titleIg.textAttr.fontWeight
      )
      formdata.append(
        'property[username][asset_attribute][text_attr][fontSize]',
        state.titleIg.textAttr.fontSize
      )
      formdata.append(
        'property[username][asset_attribute][text_attr][alignItems]',
        state.titleIg.textAttr.alignItems
      )

      // caption
      formdata.append('property[caption][data_property]', 'text')
      formdata.append(
        'property[caption][asset_attribute][visitable][visitable]',
        state.textIg.visitable
      )
      formdata.append('property[caption][asset_attribute][origin][x]', state.textIg.x)
      formdata.append('property[caption][asset_attribute][origin][y]', state.textIg.y)
      formdata.append('property[caption][asset_attribute][dimensions][w]', state.textIg.w)
      formdata.append('property[caption][asset_attribute][dimensions][h]', state.textIg.h)
      formdata.append(
        'property[caption][asset_attribute][text_attr][textAlign]',
        state.textIg.textAttr.textAlign
      )
      formdata.append(
        'property[caption][asset_attribute][text_attr][textColor]',
        state.textIg.textAttr.textColor
      )
      formdata.append(
        'property[caption][asset_attribute][text_attr][backgroundColor]',
        state.textIg.textAttr.backgroundColor
      )
      formdata.append(
        'property[caption][asset_attribute][text_attr][fontFamily]',
        state.textIg.textAttr.fontFamily
      )
      formdata.append(
        'property[caption][asset_attribute][text_attr][fontWeight]',
        state.textIg.textAttr.fontWeight
      )
      formdata.append(
        'property[caption][asset_attribute][text_attr][fontSize]',
        state.textIg.textAttr.fontSize
      )
      formdata.append(
        'property[caption][asset_attribute][text_attr][alignItems]',
        state.textIg.textAttr.alignItems
      )
      formdata.append(
        'property[caption][asset_attribute][mentions][textColor]',
        state.textIg.mentions.textColor
      )
      formdata.append(
        'property[caption][asset_attribute][mentions][fontFamily]',
        state.textIg.mentions.fontFamily
      )
      formdata.append(
        'property[caption][asset_attribute][mentions][fontWeight]',
        state.textIg.mentions.fontWeight
      )
      formdata.append(
        'property[caption][asset_attribute][mentions][fontSize]',
        state.textIg.mentions.fontSize
      )
      formdata.append(
        'property[caption][asset_attribute][hashtags][textColor]',
        state.textIg.hashtags.textColor
      )
      formdata.append(
        'property[caption][asset_attribute][hashtags][fontFamily]',
        state.textIg.hashtags.fontFamily
      )
      formdata.append(
        'property[caption][asset_attribute][hashtags][fontWeight]',
        state.textIg.hashtags.fontWeight
      )
      formdata.append(
        'property[caption][asset_attribute][hashtags][fontSize]',
        state.textIg.hashtags.fontSize
      )

      // tags
      formdata.append('property[tags][data_property]', 'text')
      formdata.append(
        'property[tags][asset_attribute][visitable][visitable]',
        state.tagsIg.visitable
      )
      formdata.append('property[tags][asset_attribute][origin][x]', state.tagsIg.x)
      formdata.append('property[tags][asset_attribute][origin][y]', state.tagsIg.y)
      formdata.append('property[tags][asset_attribute][dimensions][w]', state.tagsIg.w)
      formdata.append('property[tags][asset_attribute][dimensions][h]', state.tagsIg.h)
      formdata.append(
        'property[tags][asset_attribute][text_attr][textAlign]',
        state.tagsIg.textAttr.textAlign
      )
      formdata.append(
        'property[tags][asset_attribute][text_attr][textColor]',
        state.tagsIg.textAttr.textColor
      )
      formdata.append(
        'property[tags][asset_attribute][text_attr][backgroundColor]',
        state.tagsIg.textAttr.backgroundColor
      )
      formdata.append(
        'property[tags][asset_attribute][text_attr][fontFamily]',
        state.tagsIg.textAttr.fontFamily
      )
      formdata.append(
        'property[tags][asset_attribute][text_attr][fontWeight]',
        state.tagsIg.textAttr.fontWeight
      )
      formdata.append(
        'property[tags][asset_attribute][text_attr][fontSize]',
        state.tagsIg.textAttr.fontSize
      )
      formdata.append(
        'property[tags][asset_attribute][text_attr][alignItems]',
        state.tagsIg.textAttr.alignItems
      )
      formdata.append(
        'property[tags][asset_attribute][mentions][textColor]',
        state.tagsIg.mentions.textColor
      )
      formdata.append(
        'property[tags][asset_attribute][mentions][fontFamily]',
        state.tagsIg.mentions.fontFamily
      )
      formdata.append(
        'property[tags][asset_attribute][mentions][fontWeight]',
        state.tagsIg.mentions.fontWeight
      )
      formdata.append(
        'property[tags][asset_attribute][mentions][fontSize]',
        state.tagsIg.mentions.fontSize
      )
      formdata.append(
        'property[tags][asset_attribute][hashtags][textColor]',
        state.tagsIg.hashtags.textColor
      )
      formdata.append(
        'property[tags][asset_attribute][hashtags][fontFamily]',
        state.tagsIg.hashtags.fontFamily
      )
      formdata.append(
        'property[tags][asset_attribute][hashtags][fontWeight]',
        state.tagsIg.hashtags.fontWeight
      )
      formdata.append(
        'property[tags][asset_attribute][hashtags][fontSize]',
        state.tagsIg.hashtags.fontSize
      )
      return formdata
    }
  }
}
</script>
