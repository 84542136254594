<template>
  <div class="container-fluid">
    <div class="card-header top-bar">
      <div class="header-left">
        <div class="admin-search-box">
          <SfInputSearch
            placeholder="Search"
            v-debounce:500ms="searchInputChange"
            class="sf-input-search"
          />
        </div>
      </div>
      <div class="header-right">
        <button @click="createRole" class="sf-primary-add">Add new role</button>
      </div>
    </div>
    <div class="sf-table">
      <b-table
        :busy="loading"
        :items="roleList.items"
        striped
        hover
        show-empty
        :fields="fields"
        head-variant="none"
        table-variant="none"
        thead-class="custom-b-table"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:head(displayname)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(displayname)="field">
          <p>{{ field.item.displayname }}</p>
        </template>

        <template v-slot:head(name)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(name)="field">
          <p>{{ field.item.name }}</p>
        </template>

        <template v-slot:head(role_type)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(role_type)="field">
          <p>{{ field.item.role_type }}</p>
        </template>

        <template v-slot:cell(actions)="field">
          <button
            @click="showModalEditRole(field.item.id)"
            title="Edit"
            class="button-edit-table"
          ></button>
          <button
            @click="showModalDeleteRole(field.item.id)"
            title="Delete"
            class="button-delete-table"
          ></button>
        </template>
      </b-table>
    </div>
    <div class="my-3">
      <b-pagination
        class="sf-b-pagination"
        v-model="currentPage"
        :total-rows="10 * roleList.total_pages"
        :per-page="10"
        @change="changePage"
        :limit="6"
      />
    </div>

    <SfModal :title="'New Role'" :width="768" closeOnOverlay :show.sync="inShownModalCreateRole">
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Display name</label>
                </div>
                <input
                  placeholder="Role"
                  v-model="roleData.role.displayname"
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error-text"
                v-if="submitted && !$v.roleData.role.displayname.required"
              >
                Display name required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Role name</label>
                </div>
                <input placeholder="Role" v-model="roleData.role.name" class="sf-input-text" />
              </div>
              <div class="invalid-error-text" v-if="submitted && !$v.roleData.role.name.required">
                Role name required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">System Roles</label>
                </div>
                <div class="w-100">
                  <label class="sf-checkbox">
                    <input v-model="roleData.role.is_system" type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row exception-row">
        <!-- <div class="col-6">
          <p class="role-name">Displayname</p>
          <input placeholder="Role" v-model="roleData.role.displayname" class="sf-input-text" />
          <div class="invalid-error-text" v-if="submitted && !$v.roleData.role.displayname.required">Displayname required</div>
          <label class="container system-checked">
            <p class="role-name label-checked">System Roles</p>
            <input v-model="roleData.role.is_system" type="checkbox">
            <span class="checkmark"></span>
          </label>
        </div> -->
        <!-- <div class="col-6">
          <p class="role-name">Role Name</p>
          <input placeholder="Role" v-model="roleData.role.name" class="sf-input-text" />
          <div class="invalid-error-text" v-if="submitted && !$v.roleData.role.name.required">Role name required</div>
        </div> -->
      </div>
      <div class="row exception-row">
        <div class="col-12">
          <p class="note-role">Set the permissions for each role here.</p>
        </div>
      </div>
      <div class="row exception-row">
        <table class="col-12 table-set-role">
          <tr class="header-role">
            <th>Permissions</th>
            <th>Menu</th>
            <th>List</th>
            <th>Create</th>
            <th>Read</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
          <!-- line 1 -->
          <tr v-for="(value, name, index) in permissionList" :key="index">
            <td class="title-role">
              <img class="image-role" :src="formartPermission(name, 'icon')" />
              <p class="name-asset">{{ formartPermission(name, 'displayname') }}</p>
            </td>
            <td v-for="(item, index) in value" :key="index">
              <label class="sf-checkbox" v-if="item.active">
                <input :value="item" v-model="roleData.permissions" type="checkbox" />
                <span class="checkmark"></span>
              </label>
            </td>
          </tr>
        </table>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="inShownModalCreateRole = false">Cancel</button>
        <button class="sf-primary" @click="saveRole">Save</button>
      </div>
    </SfModal>
    <!-- Modal Export File -->
    <SfModal
      title="Export File"
      :width="768"
      :icon="'fas fa-plus-circle'"
      closeOnOverlay
      :show.sync="isShownModalExport"
    >
      <div class="row exception-row custom-row">
        <p class="d-flex justify-content-center">Do you want to export file?</p>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalExport = false">Cancel</button>
        <button class="sf-primary">Save</button>
      </div>
    </SfModal>

    <SfModal
      title="Edit Role"
      :icon="'fa fa-building'"
      :width="768"
      closeOnOverlay
      :show.sync="isShowModalEditRole"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingRoldeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Display name</label>
                </div>
                <input
                  placeholder="Role"
                  v-model="roleDataUpdate.role.displayname"
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error-text"
                v-if="submitted && !$v.roleDataUpdate.role.displayname.required"
              >
                Role name required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingRoldeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Role Name</label>
                </div>
                <input
                  placeholder="Role"
                  v-model="roleDataUpdate.role.name"
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error-text"
                v-if="submitted && !$v.roleDataUpdate.role.name.required"
              >
                Role name required
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row exception-row">
        <div class="col-12 mt-3">
          <p class="note-role">Set the permissions for each role here.</p>
        </div>
      </div>
      <div v-if="loadingRoldeDetail">
        <table class="col-12 table-set-role">
          <tr class="header-role">
            <th>Permissions</th>
            <th>Menu</th>
            <th>List</th>
            <th>Create</th>
            <th>Read</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
          <!-- line 1 -->
          <tr v-for="j in 6" :key="j">
            <td class="title-role-placeholder">
              <content-placeholders>
                <content-placeholders-text :lines="1" />
              </content-placeholders>
            </td>
            <td v-for="i in 6" :key="i">
              <content-placeholders :class="'checkbox-placeholder'">
                <content-placeholders-text :lines="1" />
              </content-placeholders>
            </td>
          </tr>
        </table>
      </div>
      <div v-else class="row exception-row">
        <table class="col-12 table-set-role">
          <tr class="header-role">
            <th>Permissions</th>
            <th>Menu</th>
            <th>List</th>
            <th>Create</th>
            <th>Read</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
          <!-- line 1 -->
          <tr v-for="(value, name, index) in permissionList" :key="index">
            <td class="title-role">
              <img class="image-role" :src="formartPermission(name, 'icon')" />
              <p class="name-asset">{{ formartPermission(name, 'displayname') }}</p>
            </td>
            <td v-for="(item, index) in value" :key="index">
              <label class="sf-checkbox" v-if="item.active">
                <input :value="item" v-model="roleDataUpdate.permissions" type="checkbox" />
                <span class="checkmark"></span>
              </label>
            </td>
          </tr>
        </table>
      </div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShowModalEditRole = false">Cancel</button>
        <button class="sf-primary" @click="updateRole">Save</button>
      </div>
    </SfModal>
    <SfDeleteModal title="Delete Role" closeOnOverlay :show.sync="isShowModalDelete">
      <div class="content-delete">
        Deleting this role. Are you sure you want to delete this one?
      </div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShowModalDelete = false">Cancel</button>
        <button class="sf-primary" @click="deleteRole">Ok</button>
      </div>
    </SfDeleteModal>
  </div>
</template>
<script>
import SfModal from '@/components/SfModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import AdminRoleService from '@/services/admin-role.service'
import libraryIcon from '@/assets/images/library-rule.svg'
import loopIcon from '@/assets/images/role/loops-tree.svg'
import campaignIcon from '@/assets/images/role/overview-campaign.svg'
import playerIcon from '@/assets/images/role/overview-player.svg'
import folderIcon from '@/assets/images/role/folder-tree.svg'
import accountIcon from '@/assets/images/role/account-role.svg'
import organizationIcon from '@/assets/images/role/organization-role.svg'
import { mapState, mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import SfInputSearch from '@/components/SfInputSearch.vue'
export default {
  name: 'roleTable',
  components: {
    SfModal,
    SfDeleteModal,
    SfInputSearch
  },
  data() {
    return {
      searchRoleValue: '',
      isShowModalEditRole: false,
      isShowModalDelete: false,
      inShownModalCreateRole: false,
      isShownModalExport: false,
      icon: {
        libraryIcon,
        loopIcon,
        campaignIcon,
        playerIcon,
        folderIcon,
        accountIcon,
        organizationIcon
      },
      roleID: '',
      roleData: {
        role: {
          displayname: '',
          name: '',
          role_type: 'custom',
          is_system: false
        },
        permissions: []
      },
      roleDataUpdate: {
        role: {
          displayname: '',
          name: '',
          role_type: 'custom',
          is_system: false
        },
        permissions: []
      },

      submitted: false,
      fields: [
        {
          key: 'displayname',
          class: 'f-displayname',
          colType: 'displayname',
          label: 'Display name',
          sortable: true
        },
        {
          key: 'name',
          class: 'f-displayname',
          colType: 'name',
          label: 'Role Name',
          sortable: true
        },
        {
          key: 'role_type',
          colType: 'role_type',
          label: 'Type',
          sortable: true
        },
        {
          key: 'Actions',
          colType: 'button'
        }
      ],
      currentPage: 1,
      loadingRoldeDetail: false
    }
  },
  props: {
    show: {}
  },
  validations: {
    roleData: {
      role: {
        displayname: {
          required
        },
        name: {
          required
        }
      }
    },
    roleDataUpdate: {
      role: {
        displayname: {
          required
        },
        name: {
          required
        }
      }
    }
  },
  methods: {
    ...mapActions('adminorganization', {
      getRoleList: 'getRoleList'
    }),
    createRole() {
      this.inShownModalCreateRole = true
      this.resetData()
    },
    searchInputChange(val) {
      this.searchRoleValue = val
      this.currentPage = 1
      this.fetchData(this.currentPage, this.searchRoleValue)
    },
    fetchData(currentPage, search) {
      this.getRoleList({ page: currentPage, limit: 50, search: this.searchRoleValue })
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.searchRoleValue)
    },

    formartPermission(page, formatType) {
      let icon = ''
      let name = ''
      switch (page) {
        case 'SmartAsset':
          icon = this.icon.libraryIcon
          name = 'Library (Assets)'
          break
        case 'LoopAsset':
          icon = this.icon.loopIcon
          name = 'Loops'
          break
        case 'Campaign':
          icon = this.icon.campaignIcon
          name = 'Campaigns'
          break
        case 'Player':
          icon = this.icon.playerIcon
          name = 'Players'
          break
        case 'FolderSmartAsset':
          icon = this.icon.folderIcon
          name = 'Library Folders'
          break
        case 'FolderLoop':
          icon = this.icon.folderIcon
          name = 'Loop Folders'
          break
        case 'User':
          icon = this.icon.accountIcon
          name = 'User'
          break
        case 'Account':
          icon = this.icon.accountIcon
          name = 'Account'
          break
        case 'Organization':
          icon = this.icon.organizationIcon
          name = 'Organization'
          break
        default:
          break
      }
      return formatType === 'icon' ? icon : name
    },
    saveRole() {
      this.submitted = true
      if (this.$v.roleData.$invalid) {
        return
      }
      if (this.roleData.role.is_system) {
        this.roleData.role.role_type = 'system'
      }
      AdminRoleService.createRole(this.roleData)
        .then((data) => {
          this.$toast.success('New role created')
          this.fetchData(this.currentPage, this.searchRoleValue)
        })
        .catch(() => {
          this.$toast.error('Something went wrong. Please try again')
        })
        .finally(() => {
          this.inShownModalCreateRole = false
        })
    },
    updateRole() {
      this.submitted = true
      if (this.$v.roleDataUpdate.$invalid) {
        return
      }
      AdminRoleService.updateRole(this.roleID, this.roleDataUpdate)
        .then((data) => {
          this.fetchData(this.currentPage, this.searchRoleValue)
          this.$toast.success('Role updated')
        })
        .catch(() => {
          this.$toast.error('Something went wrong. Please try again')
        })
        .finally(() => {
          this.isShowModalEditRole = false
        })
    },
    resetData() {
      this.roleData.permissions = this.permissionList.Account.filter((x) => x.active)
      this.roleData.role.displayname = ''
      this.roleData.role.name = ''
      this.roleData.role.role_type = 'custom'
      this.roleData.role.is_system = false
      this.roleDataUpdate.permissions = []
      this.roleDataUpdate.role.displayname = ''
      this.roleDataUpdate.role.name = ''
      this.submitted = false
    },
    showModalEditRole(id) {
      this.resetData()
      const arrPermission = []
      this.loadingRoldeDetail = true
      AdminRoleService.getRole(id).then((res) => {
        this.loadingRoldeDetail = false
        for (const item in res.data.permissions) {
          res.data.permissions[item].forEach((el) => {
            if (el.checked) {
              arrPermission.push(el)
            }
          })
        }
        this.roleDataUpdate.permissions = arrPermission.map((i) => {
          return { id: i.id, permission: i.permission, active: true }
        })
        this.roleDataUpdate.role.displayname = res.data.displayname
        this.roleDataUpdate.role.name = res.data.name
        this.roleID = id
      })
      this.isShowModalEditRole = true
    },
    showModalDeleteRole(id) {
      this.isShowModalDelete = true
      this.roleID = id
    },
    deleteRole() {
      AdminRoleService.deleteRole(this.roleID)
        .then((res) => {
          this.isShowModalDelete = false
          this.$toast.success('Deleted role successfully')
          if (this.roleList.items.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          this.fetchData(this.currentPage, this.searchRoleValue)
        })
        .catch(() => {
          this.$toast.error('Something went wrong. Please try again')
        })
    }
  },
  mounted() {
    this.$store.dispatch('adminorganization/getPermission')
    this.fetchData(this.currentPage, this.searchRoleValue)
  },
  computed: {
    ...mapState('adminorganization', {
      permissionList: 'permissionList',
      roleList: 'roleList',
      loading: 'loading',
      error: 'error'
    }),
    ...mapGetters('adminorganization', ['isEmpty'])
  }
}
</script>
<style lang="scss" scoped>
@import '../../Adminpage.scss';
.role-name {
  font: 14px 'Opens Sans', sans-serif;
  font-weight: 600;
  color: #525f7f;
  padding-bottom: 10px;
}

.custom-row {
  flex-direction: column;
}

.note-role {
  font: 14px 'OpenSans', sans-serif;
  color: #8999aa;
}
.table-set-role {
  .header-role {
    height: 30px;
  }
  tr {
    th {
      font: 14px 'Opens Sans', sans-serif;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #525f7f;
      width: 40px;
      &:nth-child(1) {
        padding-left: 40px;
      }
    }
    td {
      height: 40px;
    }
    .title-role {
      display: flex;
      align-items: center;
      p {
        padding-left: 10px;
      }
    }
  }
}
.name-asset {
  font: 14px 'Opens Sans', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #525f7f;
}

/* Hide the browser's default checkbox */
.container input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.text-soon {
  height: 100px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font: 35px 'Opens Sans', sans-serif;
  }
}
.image-role {
  width: 30px;
  height: 30px;
}
</style>
