import AdminLanguageService from '../../../../services/admin-language.service'

export default {
  getAllLanguages({ commit }, payload) {
    AdminLanguageService.getAllLanguages()
      .then((res) => {
        commit('GET_ALL_LANGUAGES', res.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  }
}
