import AdminTemplateCategoryService from '../../../../services/admin-template-category.service'

export default {
  getAllTemplateCategory({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return AdminTemplateCategoryService.getAllTemplateCategory(
      payload.page,
      payload.limit,
      payload.search
    )
      .then((response) => {
        commit('SET_ERROR', '')
        commit('SET_ITEMS', response.data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  },

  getSlideTemplateType({ state, commit, rootState }, payload) {
    return AdminTemplateCategoryService.getSlideTemplateType()
      .then((response) => {
        const data = [{ id: 0, text: 'Select Slide Template Type' }]
        response.data.map((exception) => {
          data.push({ id: exception.value, text: exception.name })
        })
        commit('SET_ERROR', '')
        commit('SET_LIST_SLIDE_TEMPLATE_TYPE', data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  }
}
