<template>
  <div>
    <div class="container-fluid mt-3">
      <router-link class="sf-link" :to="`/players`">
        <img src="@/assets/images/chevron-left.svg" /><span>{{ $t('back-to-player') }}</span>
      </router-link>
    </div>
    <div class="container-fluid">
      <div class="tab-player">
        <div class="row my-3">
          <div class="col-12">
            <ul class="sf-tabs">
              <li>
                <a @click="changeTabOverview" :class="{ active: currentTab === 'tabA' }">{{
                  $t('overview')
                }}</a>
              </li>
              <li>
                <a @click="changeTabDiagnostic" :class="{ active: currentTab === 'tabB' }">{{
                  $t('diagnostics')
                }}</a>
              </li>
              <li v-if="this.user && this.user.user_type === 'employee'">
                <a @click="changeTabNotes" :class="{ active: currentTab === 'tabC' }">{{
                  $t('notes')
                }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div
          :class="{ 'dl-none': currentTab === 'tabB' || currentTab === 'tabC' }"
          class="overview-content"
        >
          <!-- left overview -->
          <div class="left-overview">
            <div class="player-overview">
              <p class="title-overview">{{ $t('player') }}</p>
              <div class="player-content">
                <div class="first-content">
                  <p class="small-title">{{ $t('player-name') }}</p>
                  <button @click="shownModalEditInfoPlayer" class="button-edit-table"></button>
                </div>
                <!-- Player Name -->
                <content-placeholders v-if="loading">
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
                <p v-else class="date-player">{{ infoPlayerID.name }}</p>

                <!-- Player Description -->
                <content-placeholders v-if="loading">
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
                <p v-else class="description">{{ infoPlayerID.description }}</p>
                <div class="second-content">
                  <img src="@/assets/images/campaign-account.svg" />
                  <div class="content assigned-campaign">
                    <p class="small-title">{{ $t('assigned-campaign') }}</p>
                    <p class="custom-text-link">
                      <!-- Assigned Campaign -->
                      <content-placeholders v-if="loading">
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                      <router-link v-else :to="`/campaigns/${infoPlayerID.campaign_code}/edit`">
                        {{
                          infoPlayerID &&
                          infoPlayerID.running_campaign &&
                          infoPlayerID.running_campaign.displayname
                        }}
                      </router-link>
                    </p>
                    <p class="small-title">{{ $t('orientation') }}</p>

                    <!-- Orientation -->
                    <content-placeholders v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <p v-else class="custom-text text-capitalize">
                      {{ $t(infoPlayerID.orientation) }}
                    </p>
                  </div>
                  <div class="content">
                    <p class="small-title">{{ $t('last-connected') }}</p>
                    <!-- Last Connected -->
                    <div v-if="loading" class="last-connect">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </div>
                    <div v-else class="d-flex align-items-center last-connect">
                      <img
                        v-if="
                          infoPlayerID &&
                          infoPlayerID.last_connection &&
                          infoPlayerID.diagnostics &&
                          infoPlayerID.diagnostics.connection_type === 'Ethernet'
                        "
                        class="wifi-img"
                        src="@/assets/images/ethernet.svg"
                      />
                      <img
                        v-if="
                          infoPlayerID &&
                          infoPlayerID.last_connection &&
                          infoPlayerID.diagnostics &&
                          infoPlayerID.diagnostics.connection_type === 'WiFi'
                        "
                        class="wifi-img"
                        src="@/assets/images/wifi.svg"
                      />

                      <p
                        v-if="infoPlayerID && infoPlayerID.last_connection"
                        class="custom-text"
                        id="popover-target-1"
                      >
                        {{ timeSince(infoPlayerID.last_connection) }}
                      </p>
                      <b-popover
                        v-if="infoPlayerID && infoPlayerID.last_connection"
                        target="popover-target-1"
                        triggers="hover"
                        placement="top"
                      >
                        <div class="row">
                          <div class="col-4">
                            <label>{{ $t('utc') }}:</label>
                            <label>{{ $t('time-zone') }}:</label>
                          </div>
                          <div class="col-8">
                            <label>
                              {{
                                moment
                                  .tz(infoPlayerID.last_connection, 'UTC')
                                  .format('YYYY-MM-DD hh:mm:ss z')
                              }}
                            </label>
                            <label>
                              {{
                                moment
                                  .tz(infoPlayerID.last_connection, infoPlayerID.time_zone_name)
                                  .format('YYYY-MM-DD hh:mm:ss Z')
                              }}
                            </label>
                          </div>
                        </div>
                      </b-popover>
                    </div>
                    <p class="small-title c1">{{ $t('rotation') }}</p>
                    <!-- Rotation -->
                    <div v-if="loading">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </div>
                    <p v-else-if="infoPlayerID.rotate" class="c1 custom-text text-capitalize">
                      {{ $t(infoPlayerID.rotate.toLowerCase()) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="authen-overview">
              <p class="title-overview">{{ $t('authentication') }}</p>
              <div class="content-player row">
                <div class="col-8">
                  <div class="d-flex authen">
                    <div>
                      <p class="small-title">{{ $t('player-id') }}</p>
                    </div>
                    <content-placeholders v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <div v-else>
                      <p class="code">{{ infoPlayerID.player_code }}</p>
                    </div>
                  </div>
                  <div class="d-flex authen">
                    <div>
                      <p class="small-title">{{ $t('access-code') }}</p>
                    </div>
                    <content-placeholders v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <div v-else>
                      <p class="code">{{ infoPlayerID.access_code }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="d-flex justify-content-end">
                    <button class="sf-primary" @click="showModalReboot">Reboot Player</button>
                  </div>
                  <div class="mt-2 d-flex justify-content-end">
                    <button class="sf-primary" @click="showModalScreenshot">Screenshot</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- right overview -->
          <div class="right-overview">
            <p class="title-overview">{{ $t('local-address-and-time') }}</p>
            <div class="content-player">
              <div class="d-flex align-items-center justify-content-between">
                <p class="custom-text">{{ $t('current-address-for-the-organization') }}</p>
                <button @click="shownModalEditLocation" class="button-edit-table"></button>
              </div>
              <p class="small-title">{{ $t('address') }}</p>
              <content-placeholders v-if="loading">
                <content-placeholders-text :lines="1" />
              </content-placeholders>
              <p v-else class="custom-text">{{ infoPlayerID.location }}</p>
              <div class="d-flex content-location">
                <!-- Placeholder loading in right column  -->
                <div class="content-location-country" v-if="loading">
                  <content-placeholders>
                    <p class="small-title">{{ $t('country') }}</p>
                    <content-placeholders-text :lines="1" />
                    <p class="small-title">{{ $t('city') }}</p>
                    <content-placeholders-text :lines="1" />
                    <p class="small-title">{{ $t('latitude') }}</p>
                    <content-placeholders-text :lines="1" />
                    <p class="small-title">{{ $t('time-zone') }}</p>
                    <content-placeholders-text :lines="1" />
                  </content-placeholders>
                </div>
                <div v-else class="content-location-country">
                  <p class="small-title">{{ $t('country') }}</p>
                  <p class="custom-text">{{ infoPlayerID.country }}</p>
                  <p class="small-title">{{ $t('city') }}</p>
                  <p class="custom-text">{{ infoPlayerID.city }}</p>
                  <p class="small-title">{{ $t('latitude') }}</p>
                  <p class="custom-text">{{ infoPlayerID.lat }}</p>
                  <p class="small-title">{{ $t('time-zone') }}</p>
                  <p class="custom-text">{{ infoPlayerID.time_zone_name }}</p>
                </div>

                <!-- Placeholder loading in left column  -->
                <div v-if="loading" class="content-location-zip">
                  <p class="small-title">{{ $t('province-state') }}</p>
                  <content-placeholders-text :lines="1" />
                  <p class="small-title">{{ $t('postalzip') }}</p>
                  <content-placeholders-text :lines="1" />
                  <p class="small-title">{{ $t('longitude') }}</p>
                  <content-placeholders-text :lines="1" />
                </div>
                <div v-else class="content-location-zip">
                  <p class="small-title">{{ $t('province-state') }}</p>
                  <p class="custom-text">{{ infoPlayerID.province_state }}</p>
                  <p class="small-title">{{ $t('postalzip') }}</p>
                  <p class="custom-text">{{ infoPlayerID.postal_zip }}</p>
                  <p class="small-title">{{ $t('longitude') }}</p>
                  <p class="custom-text">{{ infoPlayerID.lng }}</p>
                </div>
              </div>

              <!-- Maps -->
              <div v-if="loading" class="maps">
                <content-placeholders>
                  <content-placeholders-img />
                </content-placeholders>
              </div>
              <div v-else class="maps">
                <div class="clock">
                  <img src="@/assets/images/clock.svg" />
                  <p>{{ timeOfClock }}</p>
                </div>
                <gmap-map
                  :options="{
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false
                  }"
                  :center="center"
                  :zoom="12"
                  style="width: 100%; height: 200px; margin-top: 10px"
                >
                  <gmap-marker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    @click="center = m.position"
                  ></gmap-marker>
                </gmap-map>
              </div>
            </div>
          </div>
        </div>
        <div :class="{ 'dl-none': currentTab === 'tabA' || currentTab === 'tabC' }">
          <div v-if="infoPlayerID.activated">
            <div class="authen-overview diagnostic">
              <p class="title-overview">{{ $t('basic-information') }}</p>
              <div class="content-player info-diagnostic">
                <div class="row">
                  <div class="col-4 custom-title-infomation">{{ $t('hardware') }}</div>
                  <div class="col-8 custom-infomation">
                    {{ infoPlayerID.hardware_type.displayname }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 custom-title-infomation">{{ $t('device-name') }}</div>
                  <div class="col-8 custom-infomation">
                    {{ infoPlayerID.diagnostics ? infoPlayerID.diagnostics.deviceName : '' }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 custom-title-infomation">{{ $t('free-space') }}</div>
                  <div class="col-8 custom-infomation">
                    {{ infoPlayerID.diagnostics ? infoPlayerID.diagnostics.free_space : '' }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 custom-title-infomation">{{ $t('last-reboot') }}</div>
                  <div
                    class="col-8 custom-infomation"
                    v-if="infoPlayerID.diagnostics && infoPlayerID.diagnostics.lastReboot"
                  >
                    <p id="last-reboot-popover" class="float-left">
                      {{
                        moment
                          .tz(infoPlayerID.diagnostics.lastReboot, infoPlayerID.time_zone_name)
                          .format('YYYY-MM-DD HH:mm:ss')
                      }}
                    </p>
                    <b-popover target="last-reboot-popover" triggers="hover" placement="top">
                      <div>
                        {{
                          moment
                            .tz(infoPlayerID.diagnostics.lastReboot, 'UTC')
                            .format('YYYY-MM-DD HH:mm:ss z')
                        }}
                      </div>
                    </b-popover>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 custom-title-infomation">{{ $t('current-campaign') }}</div>
                  <div class="col-8 custom-infomation">
                    {{ infoPlayerID.diagnostics ? infoPlayerID.diagnostics.currentCampaign : '' }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 custom-title-infomation">{{ $t('last-update-stamp') }}</div>
                  <div class="col-8 custom-infomation">
                    {{ infoPlayerID.diagnostics ? infoPlayerID.diagnostics.lastUpdateStamp : '' }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 custom-title-infomation">{{ $t('application-version') }}</div>
                  <div class="col-8 custom-infomation">
                    {{
                      infoPlayerID.diagnostics ? infoPlayerID.diagnostics.applicationVersion : ''
                    }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 custom-title-infomation">{{ $t('device-id') }}</div>
                  <div class="col-8 custom-infomation">
                    {{ infoPlayerID.diagnostics ? infoPlayerID.diagnostics.deviceID : '' }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 custom-title-infomation">{{ $t('mac-address') }}</div>
                  <div class="col-8 custom-infomation">
                    {{ infoPlayerID.diagnostics ? infoPlayerID.diagnostics.macAddress : '' }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 custom-title-infomation">{{ $t('ip-address') }}</div>
                  <div class="col-8 custom-infomation">
                    {{ infoPlayerID.diagnostics ? infoPlayerID.diagnostics.ip_address : '' }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 custom-title-infomation">{{ $t('killed-at') }}</div>
                  <div
                    class="col-8 custom-infomation"
                    v-if="infoPlayerID.diagnostics && infoPlayerID.diagnostics.killed_at"
                  >
                    <p id="killed-at-popover" class="float-left">
                      {{
                        moment
                          .tz(infoPlayerID.diagnostics.killed_at, infoPlayerID.time_zone_name)
                          .format('YYYY-MM-DD HH:mm:ss')
                      }}
                    </p>
                    <b-popover target="killed-at-popover" triggers="hover" placement="top">
                      <div>
                        {{
                          moment
                            .tz(infoPlayerID.diagnostics.killed_at, 'UTC')
                            .format('YYYY-MM-DD HH:mm:ss z')
                        }}
                      </div>
                    </b-popover>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 custom-title-infomation">{{ $t('connection-type') }}</div>
                  <div class="col-8 custom-infomation">
                    {{ infoPlayerID.diagnostics ? infoPlayerID.diagnostics.connection_type : '' }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 custom-title-infomation">{{ $t('wifi-type') }}</div>
                  <div class="col-8 custom-infomation">
                    {{
                      infoPlayerID.diagnostics ? infoPlayerID.diagnostics.wifi_security_type : ''
                    }}
                  </div>
                </div>
              </div>
            </div>

            <div class="sf-table mt-3">
              <b-table
                :fields="fields"
                :items="files"
                show-empty
                striped
                hover
                table-variant="none"
                thead-class="custom-b-table"
                :busy="loadingFiles"
              >
                <template v-slot:empty="files">
                  <p class="d-flex justify-content-center">
                    {{ $t('no-data-available-in-table') }}
                  </p>
                </template>

                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>{{ $t('loading') }}...</strong>
                  </div>
                </template>

                <template v-slot:head(displayname)="field">
                  <span class="custome-arrows">{{ $t('display-file-name') }}</span>
                </template>
                <template v-slot:cell(displayname)="field">
                  <p>{{ field.item.displayname }}</p>
                </template>

                <template v-slot:head(type)="field">
                  <span class="custome-arrows">{{ $t('type') }}</span>
                </template>
                <template v-slot:cell(type)="field">
                  <img
                    v-if="field.item.type"
                    class="img-player"
                    :src="require('@/assets/images/' + field.item.type + '.svg')"
                  />
                </template>

                <template v-slot:head(name)="field">
                  <span class="custome-arrows">{{ $t('name') }}</span>
                </template>
                <template v-slot:cell(name)="field">
                  <p>{{ field.item.name }}</p>
                </template>

                <template v-slot:head(size)="field">
                  <span class="custome-arrows">{{ $t('size') }}</span>
                </template>
                <template v-slot:cell(size)="field">
                  <p>{{ field.item.size }}</p>
                </template>
              </b-table>
            </div>

            <div class="mt-3">
              <b-pagination
                class="sf-b-pagination"
                :total-rows="pagination.limit * pagination.total"
                :per-page="pagination.limit"
                @change="changePage"
                :limit="6"
                v-model="currentPage"
              />
            </div>
          </div>
          <div v-else>
            {{ $t('player-is-not-activated') }}
          </div>
        </div>
        <div :class="{ 'dl-none': currentTab === 'tabB' || currentTab === 'tabA' }">
          <PlayerNotes :data="infoPlayerID.notes" @update="updateNotes" />
        </div>
      </div>
    </div>

    <SfModal
      :title="$t('modal.title.edit-player')"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-10">
              <div class="sf-form-group">
                <div class="sf-label-group lg">
                  <label class="primary-label">{{ $t('name') }}</label>
                </div>
                <input v-model="editPlayer.player.name" class="sf-input-text" />
              </div>
              <p
                v-if="editPlayer.player.name && editPlayer.player.name.length > 50 && showError"
                class="error-text text-right"
              >
                {{ $t('text-over-50-validation') }}
              </p>
              <p v-else-if="!editPlayer.player.name && showError" class="error-text text-right">
                {{ $t('name-required') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-10">
              <div class="sf-form-group">
                <div class="sf-label-group lg">
                  <label class="primary-label">Pin</label>
                </div>
                <input v-model="editPlayer.player.pin" class="sf-input-text" :type="'number'" />
              </div>
              <p
                v-if="
                  editPlayer.player.pin &&
                  editPlayer.player.pin.toString().length !== 6 &&
                  showError
                "
                class="error-text text-right"
              >
                Use 6 characters
              </p>
              <p v-else-if="!editPlayer.player.pin && showError" class="error-text text-right">
                Enter Pin
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-10">
              <div class="sf-form-group">
                <div class="sf-label-group lg">
                  <label class="primary-label">{{ $t('player-description') }}</label>
                </div>
                <textarea v-model="editPlayer.player.description" class="sf-input-text" />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-10">
              <div class="sf-form-group">
                <div class="sf-label-group lg">
                  <label class="primary-label">{{ $t('assigned-campaign') }}</label>
                </div>
                <div class="sf-select-group select-campaign">
                  <Select2
                    class="sf-select2"
                    v-model="editPlayer.player.campaign_id"
                    :options="listCampaignOptionsForEditPlayer"
                  />
                  <span class="lb-right caret have-lable"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-10">
              <div class="sf-form-group">
                <div class="sf-label-group lg">
                  <label class="primary-label">{{ $t('rotation') }}</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    :value="editPlayer.player.rotate"
                    :options="[
                      { id: 'default', text: $t('default') },
                      { id: 'flipped', text: $t('flipped') }
                    ]"
                    @change="changeRotate"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret have-lable"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEdit = false">{{ $t('cancel') }}</button>
        <button class="sf-primary" @click="saveEditInfoPlayer">{{ $t('save') }}</button>
      </div>
    </SfModal>

    <SfModal
      :title="$t('modal.title.edit-contact')"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEditLocation"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('player-address') }}</label>
                </div>
                <gmap-autocomplete
                  class="sf-input-text"
                  @place_changed="getAddressData"
                  placeholder="Address ..."
                  :value="editPlayer.player.location"
                >
                </gmap-autocomplete>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('country') }}</label>
                </div>
                <input
                  v-model="editPlayer.player.country"
                  class="sf-input-text"
                  placeholder="Your Country"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('province-state') }}</label>
                </div>
                <input
                  v-model="editPlayer.player.province_state"
                  class="sf-input-text"
                  placeholder="Province/State"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('city') }}</label>
                </div>
                <input v-model="editPlayer.player.city" class="sf-input-text" placeholder="City" />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('postal-zip') }}</label>
                </div>
                <input
                  v-model="editPlayer.player.postal_zip"
                  class="sf-input-text"
                  placeholder="Postal zip"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('latitude') }}</label>
                </div>
                <input
                  v-model="editPlayer.player.lat"
                  class="sf-input-text"
                  placeholder="Latitude"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('longitude') }}</label>
                </div>
                <input
                  v-model="editPlayer.player.lng"
                  class="sf-input-text"
                  placeholder="Longtitude"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('time-zone') }}</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="editPlayer.player.time_zone_value"
                    :options="listTimezoneOptions"
                  />
                  <span class="lb-right caret have-lable"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEditLocation = false">
          {{ $t('cancel') }}
        </button>
        <button class="sf-primary" @click="saveEditLocationPlayer">{{ $t('save') }}</button>
      </div>
    </SfModal>

    <SfWarningModal
      :title="$t('modal.title.warning')"
      closeOnOverlay
      :show.sync="isShownModalUnSupport"
    >
      <div class="content-delete">
        <p
          class="attempting-use-asset mb-3"
          v-html="$t('player-attempting-use-asset', { asset: slide_template_unallow })"
        ></p>
        <p
          class="contact-to-upgrade mb-3"
          v-html="
            $t('player-contact-to-upgrade', {
              asset: slide_template_unallow,
              device: hardware_require
            })
          "
        ></p>
        <p class="cantact-number">{{ $t('tell') }}: (844) 772-7336</p>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-primary" @click="isShownModalUnSupport = false">{{ $t('ok') }}</button>
      </div>
    </SfWarningModal>
    <SfModal
      :title="'Confirm Reboot Player'"
      :width="400"
      closeOnOverlay
      :show.sync="isShownModalConfirmRebootPlayer"
    >
      <div class="content-delete">Reboot player?</div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalConfirmRebootPlayer = false">
          Cancel
        </button>
        <button class="sf-primary" @click="rebootPlayer">OK</button>
      </div>
    </SfModal>
    <SfModal
      :title="'Screenshot Player'"
      :width="400"
      closeOnOverlay
      :show.sync="isShownModalScreenshot"
    >
      <div class="content-delete">
        <div v-if="urlImageScreenshot">
          <a :href="urlImageScreenshot" target="_blank" download="abc.jpg">
            <div class="screenshot-img">
              <img class="icon-screenshot mb-3" :src="urlThumbImageScreenshot" />
            </div>
            Click to view screenshot.
          </a>
        </div>
        <div v-else class="loading">Waiting a few seconds</div>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-primary" @click="isShownModalScreenshot = false">OK</button>
      </div>
    </SfModal>
    <SfWarningModal
      :title="$t('modal.title.warning')"
      closeOnOverlay
      :show.sync="isNewUpdatedBySomeOne"
    >
      <div class="content-delete">
        <p class="cantact-number">
          Someone else has just finished editing this Player. Please reload the page to see their
          changes
        </p>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isNewUpdatedBySomeOne = false">
          {{ $t('keep') }}
        </button>
        <button class="sf-primary" @click="reloadPage">{{ $t('refresh') }}</button>
      </div>
    </SfWarningModal>
  </div>
</template>
<script>
import SfModal from '@/components/SfModal.vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
import PlayerService from '../../services/player.service'
import Select2 from 'v-select2-component'
import { timeSince } from '@/helper/helper'
import SfWarningModal from '@/components/SfWarningModal.vue'
import PlayerNotes from './PlayerNotes.vue'

export default {
  name: 'viewPlayer',
  components: {
    SfModal,
    Select2,
    SfWarningModal,
    PlayerNotes
  },
  data() {
    return {
      isShownModalEdit: false,
      isShownModalEditLocation: false,
      isShownModalConfirmRebootPlayer: false,
      isShownModalScreenshot: false,
      fields: [
        {
          key: 'displayname',
          sortable: true,
          colType: 'displayname',
          label: 'DISPLAY FILE NAME'
        },
        {
          key: 'type',
          sortable: true,
          colType: 'type',
          label: 'ASSET TYPE'
        },
        {
          key: 'name',
          sortable: true,
          colType: 'name',
          label: 'ACTUAL FILE NAME'
        },
        {
          key: 'size',
          sortable: true,
          colType: 'size',
          label: 'FILE SIZE'
        }
      ],
      playerID: '',
      editPlayer: {
        player: {
          name: '',
          campaign_id: '',
          description: '',
          rotate: '',
          displayname: '',
          location: '',
          city: '',
          postal_zip: '',
          province_state: '',
          lat: '',
          lng: '',
          time_zone_name: '',
          time_zone_value: '',
          pin: ''
        }
      },
      stateFilter: '',
      currentPage: 1,
      dataSearch: '',
      listCountries: [],
      timeOfClock: '',
      resetTime: '',
      showError: false,
      currentTab: 'tabA',
      user: {},
      slide_template_unallow: '',
      hardware_require: '',
      isShownModalUnSupport: false,
      urlImageScreenshot: '',
      urlThumbImageScreenshot: '',
      isNewUpdatedBySomeOne: false
    }
  },
  computed: {
    ...mapState('player', {
      allCampaigns: 'allCampaigns',
      infoPlayerID: 'infoPlayerID',
      listTimezone: 'listTimezone',
      pagination: 'pagination',
      files: 'files',
      error: 'error',
      loading: 'loading',
      isEmpty: 'isEmpty',
      loadingFiles: 'loadingFiles'
    }),
    ...mapGetters('player', {
      center: 'center',
      infoEditPlayer: 'infoEditPlayer',
      listCampaignOptionsForEditPlayer: 'listCampaignOptionsForEditPlayer',
      listTimezoneOptions: 'listTimezoneOptions',
      allStates: 'allStates'
    }),
    markers: function () {
      return [
        {
          position: this.center
        }
      ]
    }
  },
  methods: {
    timeSince,
    ...mapMutations('player', {
      setDataPlayerToStore: 'SET_DATA_PLAYER',
      setInfoPlayerByID: 'GET_INFO_PLAYER_BY_ID'
    }),
    getAllCampaigns() {
      this.$store.dispatch('player/getAllCampaigns')
    },
    getInfoPlayerByID() {
      this.$store.dispatch('player/getInfoPlayerByID', { id: this.playerID, setLoading: true })
    },
    changeRotate(e) {
      this.editPlayer.player.rotate = e
    },
    saveEditInfoPlayer() {
      if (
        this.editPlayer.player.name &&
        this.editPlayer.player.name.length < 50 &&
        this.editPlayer.player.pin &&
        this.editPlayer.player.pin.toString().length === 6
      ) {
        const params = JSON.parse(JSON.stringify(this.editPlayer))
        params.player.time_zone_name = params.player.time_zone_value
        PlayerService.updatePlayerByID(params, this.playerID)
          .then((res) => {
            // res.data.campaign_id = this.editPlayer.player.campaign_id
            // res.data.time_zone_value = this.editPlayer.player.time_zone_value
            this.setDataPlayerToStore(res.data)
            this.isShownModalEdit = false
            this.$toast.success(res.message || 'Player updated')
            this.showError = false
          })
          .catch((error) => {
            this.isShownModalEdit = false
            if (error.data && error.data.type === 'player-not-support') {
              const asset = error.data.slide_template_unallow.length > 1 ? ' assets' : ' asset'
              this.slide_template_unallow =
                `<span style="font-weight: 600;">${error.data.slide_template_unallow.join(
                  ', '
                )}</span>` + asset
              this.hardware_require = `<span style="font-weight: 600;">${error.data.hardware_require.displayname}</span>`
              this.isShownModalUnSupport = true
            } else {
              this.$toast.error(
                error.message ? `${error.message}` : 'Something went wrong. Please try again.'
              )
            }
          })
      } else {
        this.showError = true
      }
    },
    getListTimezone() {
      this.$store.dispatch('player/getListTimezone')
    },
    getAddressData: function (place) {
      this.formatAddress(place.address_components)
      this.editPlayer.player.country = this.addressDataFormat.country
      this.editPlayer.player.province_state = this.addressDataFormat.administrative_area_level_1
      this.editPlayer.player.lat = place.geometry.location.lat()
      this.editPlayer.player.lng = place.geometry.location.lng()
      this.editPlayer.player.postal_zip = this.addressDataFormat.postal_code
        ? this.addressDataFormat.postal_code
        : ''
      this.editPlayer.player.city = this.addressDataFormat.locality
        ? this.addressDataFormat.locality
        : ''
      this.editPlayer.player.location = place.formatted_address
    },
    saveEditLocationPlayer() {
      const params = JSON.parse(JSON.stringify(this.editPlayer))
      params.player.time_zone_name = params.player.time_zone_value
      PlayerService.updatePlayerByID(params, this.playerID)
        .then((res) => {
          res.data.campaign_id = this.editPlayer.player.campaign_id
          res.data.time_zone_value = this.editPlayer.player.time_zone_value
          this.isShownModalEditLocation = false
          this.$toast.success(res.message || 'Contact successfully updated')
          this.setDataPlayerToStore(res.data)
        })
        .catch((_) => {})
    },
    shownModalEditInfoPlayer() {
      this.isShownModalEdit = true
      this.getAllCampaigns()
      this.setDataPlayer(this.editPlayer, this.infoPlayerID)
    },
    shownModalEditLocation() {
      this.isShownModalEditLocation = true
      this.getListTimezone()
      this.setDataPlayer(this.editPlayer, this.infoPlayerID)
    },
    getAllFiles(id, page, limit, search) {
      const payload = {
        id: id,
        page: page,
        limit: limit,
        search: search
      }
      this.$store.dispatch('player/getAllFiles', payload)
    },
    changePage(page) {
      this.currentPage = page
      this.getAllFiles(this.playerID, this.currentPage, this.pagination.limit, this.dataSearch)
      this.currentTab = 'tabB'
    },
    changeTabDiagnostic() {
      this.$bus.$emit('showSearchBox', true)
      this.currentTab = 'tabB'
      this.getAllFiles(this.playerID, this.currentPage, this.pagination.limit, this.dataSearch)
    },
    changeTabNotes() {
      this.currentTab = 'tabC'
    },
    changeTabOverview() {
      this.$bus.$emit('showSearchBox', false)
      this.currentTab = 'tabA'
    },
    formatAddress(address) {
      const components = {}
      address.map((key, index) => {
        key.types.map((key1, index1) => {
          components[key1] = key.long_name
        })
      })
      this.addressDataFormat = components
    },
    setDataPlayer(newPlayer, currentPlayer) {
      // edit player
      newPlayer.player.name = currentPlayer.name
      newPlayer.player.campaign_id = currentPlayer.running_campaign
        ? currentPlayer.running_campaign.id
        : ''
      newPlayer.player.description = currentPlayer.description
      newPlayer.player.rotate = currentPlayer.rotate
      newPlayer.player.pin = currentPlayer.pin
      // edit location
      newPlayer.player.location = currentPlayer.location
      newPlayer.player.city = currentPlayer.city
      newPlayer.player.postal_zip = currentPlayer.postal_zip
      newPlayer.player.country = currentPlayer.country
      newPlayer.player.province_state = currentPlayer.province_state
      newPlayer.player.lat = currentPlayer.lat
      newPlayer.player.lng = currentPlayer.lng
      newPlayer.player.time_zone_name = currentPlayer.time_zone_name
      newPlayer.player.time_zone_value = currentPlayer.time_zone_value
    },
    getTime(value) {
      const oldTime = new Date()
      clearInterval(this.resetTime)
      this.resetTime = setInterval(() => {
        this.timeOfClock =
          this.moment(oldTime).tz(`${value}`) && this.moment().tz(`${value}`).format('HH:mm')
      }, 1000)
    },
    getUser() {
      const user = this.$cookies.get('user')
      if (user) {
        this.user = user
      }
    },
    rebootPlayer() {
      PlayerService.rebootPlayer(this.infoPlayerID.id).then((res) => {
        this.$toast.success('Reboot player successfully')
        this.isShownModalConfirmRebootPlayer = false
      })
    },
    showModalReboot() {
      this.isShownModalConfirmRebootPlayer = true
    },
    showModalScreenshot() {
      this.urlImageScreenshot = ''
      this.urlThumbImageScreenshot = ''
      this.isShownModalScreenshot = true
      PlayerService.requestScreenShot(this.infoPlayerID.id)
    },
    reloadPage() {
      this.getInfoPlayerByID()
      this.isNewUpdatedBySomeOne = false
    },
    async updateNotes(notes) {
      try {
        await PlayerService.updatePlayerByID({ notes }, this.playerID)
        this.$toast.success('Notes successfully updated')
        this.getInfoPlayerByID()
      } catch (error) {
        this.$toast.error('Something went wrong. Please try again.')
      }
    }
  },
  channels: {
    PlayersChannel: {
      connected() {},
      rejected() {},
      received(data) {
        this.isNewUpdatedBySomeOne =
          this.user.id !== data.user_id && this.playerID === data.data.player_code
      },
      disconnected() {}
    },
    AdminPlayersChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (data.url) {
          this.urlImageScreenshot = data.url
          this.urlThumbImageScreenshot = data.thumbnail
        }
      },
      disconnected() {}
    }
  },
  mounted() {
    this.getUser()
    this.$cable.subscribe({
      channel: 'PlayersChannel',
      organization: this.user.organization_id
    })
    this.playerID = this.$route.params.playerID
    this.getInfoPlayerByID()
    this.$bus.$on('onSearchInputChange', (value) => {
      this.currentPage = 1
      this.dataSearch = value
      this.getAllFiles(this.playerID, this.currentPage, this.pagination.limit, this.dataSearch)
    })
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
    this.$bus.$emit('showSearchBox', true)
    this.$cable.unsubscribe('PlayersChannel')
  },
  watch: {
    infoPlayerID(value) {
      this.getTime(value.time_zone_name)
      this.$cable.subscribe({
        channel: 'AdminPlayersChannel',
        player_id: this.infoPlayerID.id
      })
      if (value && this.isShownModalEdit) {
        this.setDataPlayer(this.editPlayer, value)
      }
    },
    'infoPlayerID.time_zone_name'(value) {
      this.getTime(value)
    }
  },
  created() {
    this.$bus.$emit('showSearchBox', false)
    this.oldTime = new Date()
  }
}
</script>
<style lang="scss" scoped>
@import './ViewPlayer.scss';
@import '../campaign/campaignPage.scss';
@import '../campaign/editCampaign.scss';
.cantact-number {
  font-size: 22px;
  font-weight: 600;
  color: #2c7fe1;
}
.custom-input {
  border: 0 !important;
  height: 34px !important;
  width: 100%;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}
.custom-textarea {
  border: 0 !important;
  max-height: 150px;
  height: 40px;
  width: 100%;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}
.select-location {
  background: none;
  width: 100%;
  height: 34px;
  transition: box-shadow 0.15s ease;
  border: 0;
  color: #4a4a4a;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  font: 14px Open Sans, sans-serif;
  background-color: white;
  padding: 6px 12px;
}
.custom-description {
  height: 75px !important;
}
.custom-title-infomation {
  font: 14px 'Open Sans', sans-serif;
  font-weight: 600;
  color: #212529;
}
.custom-infomation {
  font: 12px 'Open Sans', sans-serif;
  font-weight: 400;
  color: #212529;
}
.info-diagnostic {
  .row {
    &:not(:nth-child(1)) {
      margin-top: 10px;
    }
  }
}

.clock {
  position: absolute;
  z-index: 1;
  background: #fff;
  width: 91px;
  height: 32px;
  display: flex;
  align-items: center;
  top: 15px;
  left: 5px;
  display: flex;
  justify-content: space-around;
}
.maps {
  position: relative;
}
.img-player {
  max-width: 32px;
}
.loading {
  font-family: sans-serif;
}

.loading:after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: '';
}

@keyframes dotty {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}

.icon-screenshot {
  width: 250px;
  display: block;
  margin: auto;
}
.assigned-campaign {
  width: 66%;
  word-wrap: break-word;
}
.select-campaign {
  overflow: hidden;
  border-bottom: 1px solid #d8dde3;
  &:hover {
    border-bottom: 1px solid black;
  }
}
</style>
