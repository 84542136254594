<template>
  <div class="admin-page">
    <div class="admin-page-header">
      <div class="col-md-12 admin-page-menu">
        <ul class="nav nav-tabs nav-tabs-custom">
          <li><router-link to="/admin/clients/organizations">Organizations</router-link></li>
          <li><router-link to="/admin/clients/players">Players</router-link></li>
          <li><router-link to="/admin/clients/users/customers">Users</router-link></li>
          <li><router-link to="/admin/clients/settings">Settings</router-link></li>
        </ul>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'adminClients',
  components: {},
  data() {
    return {}
  },

  methods: {},
  computed: {},
  mounted() {}
}
</script>

<style lang="scss" scoped>
@import '../Adminpage.scss';
.player-active {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #76c322;
  margin-top: 15px;
}

.player-inactive {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #e00008;
  margin-top: 15px;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  height: 35px !important;
}
.text-area-control {
  padding: 12px 0px;
  width: 90%;
  border: none;
  outline: none;
  height: 100px;
  max-height: 150px;
  min-height: 37px;
  margin: 1px;
}

.input-group-text-area {
  height: auto !important;
  .custom-input-group-text {
    align-items: flex-start;
    .input-group-text {
      height: 37px !important;
    }
  }
}
</style>
