export default {
  userLogs: (state) => {
    return state.infoUser.user_logs
  },
  getCurrentUser: (state) => {
    return state.currentUser
  },
  isEmpty: (state) => {
    return state.allUsers ? !state.allUsers.length : true
  },
  roleListByUser: (state) => {
    const roleListOptions = [
      {
        id: '',
        text: 'Select Role'
      }
    ]
    if (state.listRole && state.listRole.length) {
      state.listRole.map((role) => {
        roleListOptions.push({
          id: role.id,
          text: role.displayname
        })
      })
    }
    return roleListOptions
  },
  listUserType: (state) => {
    const data = [{ id: 0, text: 'Select User Sub Type' }]
    if (state.listUserType) {
      state.listUserType.map((exception) => {
        data.push({ id: exception.value, text: exception.name })
      })
    }
    return data
  }
}
