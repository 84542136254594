<template>
  <div class="admin">
    <nav class="admin-menu">
      <ul class="admin-menu-list">
        <li class="admin-menu-item">
          <router-link :to="{ path: '/admin' }">Dashboard</router-link>
        </li>
        <li class="admin-menu-item">
          <router-link :to="{ path: '/admin/clients' }">Clients</router-link>
          <!-- <ul class="admin-menu-dropdown">
                  <li><router-link :to="{path: '/admin/organizations'}">Organizations</router-link></li>
                  <li><router-link :to="{path: '/admin/players'}">Players</router-link></li>
                  <li><router-link :to="{path: '/admin/users/customers'}">Customers</router-link></li>
              </ul> -->
        </li>
        <li class="admin-menu-item">
          <router-link :to="{ path: '/admin/management' }">Management</router-link>
          <!-- <a href="javascript:void(0)">Management</a>
                <ul class="admin-menu-dropdown">
                    <li><router-link :to="{path: '/admin/org_types'}">ORG Types</router-link></li>
                    <li><router-link :to="{path: '/admin/users/employees'}">Employees</router-link></li>
                    <li><router-link :to="{path: '/admin/users/posts'}">Posts</router-link></li>
                </ul> -->
        </li>
        <!-- <li class="admin-menu-item">
            <a href="javascript:void(0)">Financial</a>
              <ul class="admin-menu-dropdown">
                  <li><router-link :to="{path: '/admin/taxes'}">Taxes</router-link></li>
              </ul>
        </li> -->
        <li class="admin-menu-item">
          <router-link :to="{ path: '/admin/system' }">System</router-link>
          <!-- <a href="javascript:void(0)">System</a>
              <ul class="admin-menu-dropdown">
                  <li><router-link :to="{path: '/admin/pi_firmwares'}"><span class="fa fa-cogs"></span> Firmware</router-link></li>
                  <li><router-link :to="{path: '/admin/slide_templates'}"><span class="fa fa-code"></span> Slide Templates</router-link></li>
                  <li><router-link :to="{path: '/admin/terms'}"><span class="fa fa-check"></span> Term And Conditions</router-link></li>
              </ul> -->
        </li>
      </ul>
    </nav>

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'account',
  components: {},
  data() {
    return {
      idOrgan: 1,
      userID: 0,
      selected: undefined
    }
  },
  created() {
    // window.location.href = '/user/organization';
  },
  methods: {
    getUserID() {
      const userStr = localStorage.getItem('user')
      if (userStr) {
        const user = JSON.parse(userStr)
        this.userID = user.id
      }
    }
  },
  mounted() {
    const adminMenu = document.querySelectorAll('.admin-menu .admin-menu-item a')
    adminMenu.forEach((element) => {
      element.addEventListener('click', function () {
        adminMenu.forEach((element) => {
          element.classList.remove('admin-menu-active')
        })
        this.classList.add('admin-menu-active')
      })
    })
    this.getUserID()
  }
}
</script>

<style lang="scss" scoped>
.admin-menu {
  background-color: #fff;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: solid 1px #f0f0f0;
  .admin-menu-list {
    display: flex;
    .admin-menu-item {
      padding: 0 15px;
      min-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .admin-menu-active {
        font-weight: bold;
        color: #00325b;
      }
      &:first-child {
        border-left: none;
      }
      a {
        text-transform: uppercase;
        color: #4a4a4a;
        &.is-active {
          font-weight: bold;
          color: #00325b;
        }
        .admin-menu-arrow {
          margin-left: 5px;
        }
      }
      &:hover {
        .admin-menu-dropdown {
          display: block;
        }
      }
      .admin-menu-dropdown {
        position: absolute;
        z-index: 10;
        top: 40px;
        width: 200px;
        left: 0;
        background-color: #fff;
        padding: 0 15px;
        border: 1px solid #ebebeb;
        display: none;
        li {
          margin: 15px 0;
          a {
            color: #424242;
          }
        }
      }
    }
  }
}
</style>
