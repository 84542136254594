import AdminPostService from '../../../../services/admin-post.service'
export default {
  getAllPosts({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return AdminPostService.getAllPosts(payload.page, payload.limit, payload.search)
      .then((response) => {
        commit('SET_ERROR', '')
        commit('GET_ALL_POST_ADMIN', response.data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  }
}
