import Vue from 'vue'
export default {
  SET_CURRENT_USER(state, currentUserData) {
    state.currentUser = currentUserData
    // get info user
    state.infoUser = currentUserData
  },

  SET_ERROR(state, data) {
    state.error = data
  },

  SET_LOADING(state, data) {
    state.loading = data
  },
  SET_LOADING_USER(state, data) {
    state.loadingUser = data
  },

  GET_ALL_USERS(state, data) {
    state.allUsers = data.items
    state.pagination.page = data.current_page
    state.pagination.total = data.total_pages
  },

  GET_INFO_USER_BY_ID(state, data) {
    state.infoUserByID = data
  },

  GET_LIST_USER_SUB_TYPE(state, data) {
    state.listUserType = data
  },

  GET_LIST_ROLE_BY_USER(state, data) {
    state.listRole = data
  },

  SET_DATA_USER_TO_STORE(state, data) {
    state.infoUser.organization.name = data.name
    state.infoUser.organization.email = data.email
    state.infoUser.organization.phone = data.phone
    state.infoUser.organization.address = data.address
    state.infoUser.organization.province_state = data.province_state
    state.infoUser.organization.postal_zip = data.postal_zip
    state.infoUser.organization.city = data.city
    state.infoUser.organization.country = data.country
    state.infoUser.organization.slug = data.slug
  },

  SET_DEFAULT_SLIDE_DURATION(state, data) {
    state.infoUser.default_slide_duration = data
  },

  SET_DATA_PROFILE(state, data) {
    state.infoUser.first_name = data.first_name
    state.infoUser.last_name = data.last_name
    state.infoUser.email = data.email
  },

  CREATE_USER_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.allUsers)
    items.unshift(data.data)
    state.allUsers = items
  },

  DELETE_USER_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.allUsers)
    const foundIndexItems = items.findIndex((x) => x.id === data.data.id)
    items.splice(foundIndexItems, 1)
    state.allUsers = items
  },

  UPDATE_USER_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.allUsers)
    const foundIndexItems = items.findIndex((x) => x.id === data.data.id)
    if (foundIndexItems >= 0) {
      items[foundIndexItems] = data.data
    }
    state.allUsers = items
  },

  UPDATE_FROM_SOCKET(state, data) {
    state.infoUser = data
  }
}
