<template>
  <div class="container-fluid">
    <div class="card-header top-bar">
      <div class="header-left">
        <div class="admin-search-box">
          <SfInputSearch
            placeholder="Search"
            v-debounce:500ms="searchInputChange"
            class="sf-input-search"
          />
        </div>
      </div>
      <div class="header-right">
        <button class="sf-primary-add" @click="showModalCreate()">Add New Term</button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 sf-table">
          <b-table
            :busy="loading"
            show-empty
            striped
            hover
            :items="items"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty="isEmpty">
              <p class="d-flex justify-content-center">No data available in table</p>
            </template>
            <template v-slot:head(content)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(content)="field">
              <p>{{ field.item.content }}</p>
            </template>

            <template v-slot:head(version)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(version)="field">
              <p>{{ field.item.version }}</p>
            </template>

            <template v-slot:cell(action)="field">
              <button
                @click="shownModalEdit(field.item.id)"
                class="button-edit-table"
                title="Edit"
              ></button>
              <button
                @click="shownModalDelete(field.item.id)"
                class="button-delete-table"
                title="Delete"
              ></button>
            </template>
          </b-table>

          <div v-if="!isEmpty" class="my-3">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="pagination.limit * pagination.total"
              :per-page="pagination.limit"
              @change="changePage"
              :limit="6"
              v-model="currentPage"
            />
          </div>
        </div>
      </div>
    </div>
    <SfModal
      :title="'Create New Term'"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalCreate"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Content</label>
                </div>
                <textarea
                  v-model="newTerm.term.content"
                  placeholder="Content..."
                  class="sf-input-text tern"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newTerm.term.content.required">
                Content required
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalCreate = false">Cancel</button>
        <button class="sf-primary" @click="saveTerm()">Save</button>
      </div>
    </SfModal>
    <SfModal
      :title="'Edit Term'"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingTermDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">content</label>
                </div>
                <textarea
                  v-model="currentTerm.term.content"
                  placeholder="Content..."
                  class="sf-input-text tern"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentTerm.term.content.required">
                Content required
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEdit = false">Cancel</button>
        <button class="sf-primary" @click="updateTerm()">Save</button>
      </div>
    </SfModal>
    <SfDeleteModal :title="'Confirm Delete'" closeOnOverlay :show.sync="isShownModalDelete">
      <div class="content-delete">
        Deleting this term. Are you sure you want to delete this one?
      </div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShownModalDelete = false">Cancel</button>
        <button class="sf-primary" @click="removeTerm()">OK</button>
      </div>
    </SfDeleteModal>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import AdminTermService from '@/services/admin-term.service'
import { required } from 'vuelidate/lib/validators'
import SfInputSearch from '@/components/SfInputSearch.vue'

export default {
  name: 'admin-terms',

  components: {
    SfModal,
    SfDeleteModal,
    SfInputSearch
  },
  data() {
    return {
      msg: 'Welcome to Index!!!',
      isShownModalCreate: false,
      isShownModalDelete: false,
      isShownModalEdit: false,
      submitted: false,
      currentPage: 1,
      fields: [
        {
          key: 'content',
          sortable: true,
          label: 'Content',
          colType: 'content',
          tdClass: 'term-nowrap'
        },
        {
          key: 'version',
          sortable: true,
          label: 'Version',
          colType: 'version'
        },
        {
          key: 'ACTION',
          colType: 'button',
          label: 'Actions'
        }
      ],
      newTerm: {
        term: {
          content: ''
        }
      },
      currentTerm: {
        term: {
          content: ''
        }
      },
      defualtTerm: {
        term: {
          content: ''
        }
      },
      dataSearch: '',
      loadingTermDetail: false
    }
  },
  validations: {
    newTerm: {
      term: {
        content: {
          required
        }
      }
    },
    currentTerm: {
      term: {
        content: {
          required
        }
      }
    }
  },
  methods: {
    fetchData(activePage, search) {
      this.$store.dispatch('adminterm/getAllTerms', { page: activePage, limit: 10, search })
    },
    showModalCreate() {
      this.setDataTerm(this.newTerm.term, this.defualtTerm.term)
      this.isShownModalCreate = true
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.dataSearch)
    },
    saveTerm() {
      this.submitted = true
      if (this.$v.newTerm.$invalid) {
        return
      }
      AdminTermService.createTerm(this.newTerm)
        .then((response) => {
          this.$toast.success('Term and Condition successfully created.')
          this.fetchData(this.currentPage)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalCreate = false
    },
    shownModalEdit(term_id) {
      this.setDataTerm(this.currentTerm.term, this.defualtTerm.term)
      this.submitted = false
      this.isShownModalEdit = true
      this.currentTermID = term_id
      this.getItemByID()
    },
    shownModalDelete(term_id) {
      this.isShownModalDelete = true
      this.currentTermID = term_id
    },
    getItemByID() {
      this.loadingTermDetail = true
      AdminTermService.detailTerm(this.currentTermID)
        .then((response) => {
          this.setDataTerm(this.currentTerm.term, response.data)
          this.loadingTermDetail = false
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
    },
    setDataTerm(term, res) {
      term.content = res.content
    },
    updateTerm() {
      this.submitted = true
      if (this.$v.currentTerm.$invalid) {
        return
      }
      AdminTermService.updateTerm(this.currentTermID, this.currentTerm)
        .then((response) => {
          this.$toast.success('Term successfully updated.')
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalEdit = false
    },
    removeTerm() {
      AdminTermService.removeTerm(this.currentTermID)
        .then((response) => {
          this.$toast.success('Term successfully deleted.')
          if (this.items.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalDelete = false
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    }
  },
  computed: {
    ...mapState('adminterm', {
      items: 'items',
      pagination: 'pagination',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('adminterm', ['isEmpty'])
  },

  mounted() {
    this.fetchData(this.currentPage)
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    })
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  }
}
</script>

<style lang="scss" scoped>
@import '../../Adminpage.scss';
.term-nowrap {
  p {
    width: 100vh;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.text-area-control {
  width: 100%;
  border: 1px solid #ced4da;
  outline: none;
  height: 500px;
}
</style>
