import AdminSmartAssetsTemplateService from '../../../../services/admin-smart-asset-template.service'

export default {
  getAllTemplates({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return AdminSmartAssetsTemplateService.getAllTemplates(
      payload.activePage,
      payload.limit,
      payload.type,
      payload.orientation,
      payload.categoryId,
      payload.search
    )
      .then((response) => {
        commit('SET_ITEMS', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  }
}
