<template>
  <div class="editor-container">
    <div class="smart-asset-editor" id="smartAssetEditor">
      <div
        v-if="smartAssetDataModal && smartAssetDataModal.orientation"
        class="container-fluid"
        :class="smartAssetDataModal.orientation"
      >
        <div class="row">
          <div class="col-12 header-setting">
            <div class="header-setting-list">
              <div class="close-modal">
                <button id="closeSmaetAssetEditor" class="sf-close" @click="confirmClose"></button>
              </div>
              <div class="setting-group action-block add">
                <button @click="addBlock" class="sf-primary-add">Add block</button>
                <button
                  v-if="nodeEnv !== 'production'"
                  @click="onSourcesClick"
                  class="sf-primary-add ml-2"
                >
                  Sources
                </button>
              </div>
              <div class="setting-group delete ml-2">
                <a @click="cloneBlock" class="d-flex mr-3">
                  <span class="mr-2 text-color-white">Duplicate</span>
                  <img class="w-16px" src="@/assets/images/smart-assets/clone.svg" />
                </a>
                <a @click="deleteBlock" class="d-flex mr-3">
                  <span class="mr-2 text-color-white">Delete</span>
                  <img src="@/assets/images/smart-assets/trash-bin.svg" />
                </a>
              </div>

              <div class="setting-group undo-redo ml-2">
                <a @click="undo" class="mr-3">
                  <img src="@/assets/images/smart-assets/undo.svg" />
                </a>
                <a @click="redo" class="mr-3">
                  <img src="@/assets/images/smart-assets/redo.svg" />
                </a>
              </div>
              <div class="setting-group reorder ml-2">
                <a @click="updateZIndex('down')" class="mr-3"
                  ><img src="@/assets/images/smart-assets/reorder-down.svg"
                /></a>
                <a @click="updateZIndex('up')" class="mr-3"
                  ><img src="@/assets/images/smart-assets/reorder-up.svg"
                /></a>
              </div>
              <div class="setting-group ml-2 alignment">
                <a @click="updateBlockPositon('y', 0)" class="mr-3"
                  ><img src="@/assets/images/smart-assets/align-top.svg"
                /></a>
                <a @click="updateBlockPositon('y', 270 - Number(activeBlock.h) / 2)" class="mr-3"
                  ><img src="@/assets/images/smart-assets/align-middle.svg"
                /></a>
                <a @click="updateBlockPositon('y', 540 - Number(activeBlock.h))" class="mr-3"
                  ><img src="@/assets/images/smart-assets/align-bottom.svg"
                /></a>

                <a @click="updateBlockPositon('x', 0)" class="mr-3"
                  ><img class="rotate-90" src="@/assets/images/smart-assets/align-top.svg"
                /></a>
                <a @click="updateBlockPositon('x', 480 - Number(activeBlock.w) / 2)" class="mr-3"
                  ><img class="rotate-90" src="@/assets/images/smart-assets/align-middle.svg"
                /></a>
                <a @click="updateBlockPositon('x', 960 - Number(activeBlock.w))" class="mr-3"
                  ><img class="rotate-90" src="@/assets/images/smart-assets/align-bottom.svg"
                /></a>

                <div class="setting-position d-flex align-items-center mr-3">
                  <span class="mr-2">X</span>
                  <span v-if="activeBlock">{{ activeBlock.x * 2 }}</span>
                </div>
                <div class="setting-position d-flex align-items-center">
                  <span class="mr-2">Y</span>
                  <span v-if="activeBlock">{{ activeBlock.y * 2 }}</span>
                </div>
              </div>

              <div class="setting-group grid-setting d-flex">
                <div class="mr-3 grid-items">
                  <label class="sf-checkbox">
                    <span class="check-title">Show grid</span>
                    <input type="checkbox" v-model="smartAsset.showGrid" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="grid-items">
                  <label class="sf-checkbox">
                    <span class="check-title">Snap to grid</span>
                    <input type="checkbox" @click="handleSnapToGrid" />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3 bg-white sf-box-shadow left-content">
            <div class="row let-setting-bar" v-click-inside="disabledDeleteKey">
              <div v-if="activeAddBlock" class="col-12 mt-2">
                <div class="row">
                  <div class="col-12">
                    <div class="py-2">
                      <SfInputSearch
                        placeholder="Search"
                        v-debounce:200ms="searchInputChange"
                        class="sf-input-search"
                      />
                    </div>
                  </div>
                </div>
                <template v-if="navBlocks && navBlocks.length">
                  <div class="row" v-for="(el, index) in navBlocks" :key="index">
                    <div class="col-12 mt-2">
                      <h6 v-if="el.name !== 'RSS (Beta)'" class="semi-bold">{{ el.name }}</h6>
                      <h6
                        v-else
                        class="semi-bold d-inline-block"
                        v-b-tooltip.hover
                        title="RSS is a Beta feature and as such may not continue to be offered."
                      >
                        {{ el.name }}
                      </h6>
                    </div>
                    <div class="col-12">
                      <template v-if="el.items && el.items.length">
                        <ul class="add-block">
                          <li
                            class="sf-asset-card p-2"
                            v-for="(item, index) in el.items"
                            :key="index"
                            draggable="true"
                            :id="'drag' + index"
                            @dragstart="dragBlock($event, item.type)"
                          >
                            <a class="sf-text align-items-center" @click="item.eventClick">
                              <img class="nav-block-icon" :src="item.icon" />
                              <span class="text-color-primary-90">{{ item.title }}</span>
                            </a>
                          </li>
                        </ul>
                      </template>
                    </div>
                  </div>
                  <div class="bg-upload d-none">
                    <input
                      id="imageInput"
                      type="file"
                      name="myfile"
                      @change="handleUploadImage($event)"
                      accept=".png, .jpg, .jpeg"
                    />
                  </div>
                  <div class="bg-upload d-none">
                    <input
                      id="svgInput"
                      type="file"
                      name="svgfile"
                      @change="handleUploadSvg($event)"
                      accept=".svg"
                    />
                  </div>
                  <div class="bg-upload d-none">
                    <input
                      id="gifInput"
                      type="file"
                      name="giffile"
                      @change="handleUploadGif($event)"
                      accept=".gif"
                    />
                  </div>
                  <div class="bg-upload d-none">
                    <input
                      id="videoInput"
                      type="file"
                      name="videofile"
                      @change="handleUploadVideo($event)"
                      accept="video/mp4, video/quicktime, video/m4v, video/webm"
                    />
                  </div>
                </template>
              </div>
              <div v-else class="col-12 mt-3">
                <template v-if="activeBlock">
                  <div class="sf-box-shadow px-3 py-2">
                    <div class="row">
                      <div class="col-12 py-2">
                        <h5 class="semi-bold uppercase">{{ activeBlock.name }}</h5>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="activeBlock.type === 'igImage' || activeBlock.type === 'igCaption'"
                    class="sf-box-shadow px-3 py-2"
                  >
                    <template>
                      <div
                        class="row cursor-pointer mt-1"
                        :class="expandCard.setting ? null : 'collapsed'"
                        :aria-expanded="expandCard.setting ? 'true' : 'false'"
                        v-b-toggle="`collapse-${activeBlock.type}-setting`"
                      >
                        <div class="col-9 d-flex align-items-center">
                          <h6 class="semi-bold uppercase">SETTINGS</h6>
                        </div>
                        <div class="col-3 p-0">
                          <div class="d-flex align-items-center text-align justify-content-end">
                            <a class="p-1 sf-link">
                              <img
                                v-if="expandCard.setting"
                                src="@/assets/images/smart-assets/chevron-down.svg"
                              />
                              <img v-else src="@/assets/images/smart-assets/chevron-right.svg" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <b-collapse
                        v-model="expandCard.setting"
                        :id="`collapse-${activeBlock.type}-setting`"
                      >
                        <div class="animate__animated animate__fadeIn">
                          <div class="row mt-3">
                            <div class="col-12">
                              <div class="w-100">
                                <div class="mb-2 w-100px">General</div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="sf-select-group">
                                <Select2
                                  class="sf-select2"
                                  v-model="activeBlock.userId"
                                  :options="igList"
                                  @change="igActiveBlockChange"
                                />
                                <span class="lb-right caret"></span>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-6">
                              <div class="w-100">
                                <div class="mb-2 w-100px">Loops</div>
                                <input
                                  class="sf-input-text"
                                  v-model="numberOfSlides"
                                  type="number"
                                />
                              </div>
                              <div
                                class="invalid-error-text text-right"
                                v-if="!$v.numberOfSlides.required"
                              >
                                Loop required
                              </div>
                              <div
                                class="invalid-error-text text-right"
                                v-if="!$v.numberOfSlides.minValue"
                              >
                                {{ $t('must-be-more-than-0') }}
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="w-100">
                                <div class="mb-2 w-100px">Duration</div>
                                <input class="sf-input-text" v-model="duration" type="number" />
                              </div>
                              <div
                                class="invalid-error-text text-right"
                                v-if="!$v.duration.required"
                              >
                                Duration required
                              </div>
                              <div
                                class="invalid-error-text text-right"
                                v-if="!$v.duration.minValue"
                              >
                                {{ $t('must-be-more-than-0') }}
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-12">
                              <div class="mb-2 w-100px">Preview</div>
                              <div class="d-flex align-items-center text-align">
                                <button
                                  @click="igSlider('previous')"
                                  class="sf-secondary d-flex align-items-center"
                                >
                                  <img class="img-sm" src="@/assets/images/previous.svg" /> Previous
                                </button>
                                <button
                                  @click="igSlider('next')"
                                  class="ml-2 sf-secondary d-flex align-items-center"
                                >
                                  Next <img class="img-sm" src="@/assets/images/next.svg" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-collapse>
                    </template>
                  </div>

                  <div v-if="activeBlock.type === 'embed'" class="sf-box-shadow px-3 py-2">
                    <template>
                      <div
                        class="row cursor-pointer mt-1"
                        :class="expandCard.setting ? null : 'collapsed'"
                        :aria-expanded="expandCard.setting ? 'true' : 'false'"
                        v-b-toggle="`collapse-${activeBlock.type}-setting`"
                      >
                        <div class="col-9 d-flex align-items-center">
                          <h6 class="semi-bold uppercase">SETTINGS</h6>
                        </div>
                        <div class="col-3 p-0">
                          <div class="d-flex align-items-center text-align justify-content-end">
                            <a class="p-1 sf-link">
                              <img
                                v-if="expandCard.setting"
                                src="@/assets/images/smart-assets/chevron-down.svg"
                              />
                              <img v-else src="@/assets/images/smart-assets/chevron-right.svg" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <b-collapse
                        v-model="expandCard.setting"
                        :id="`collapse-${activeBlock.type}-setting`"
                      >
                        <div class="animate__animated animate__fadeIn">
                          <div class="row mt-3">
                            <div class="col-12">
                              <div class="w-100">
                                <div class="mb-2 w-100px">General</div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="sf-select-group">
                                <Select2
                                  class="sf-select2"
                                  v-model="activeBlock.website_url"
                                  :options="websiteList"
                                  @change="webSiteActiveBlockChange"
                                />
                                <span class="lb-right caret"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-collapse>
                    </template>
                  </div>

                  <div
                    v-if="activeBlock.type === 'rssTitle' || activeBlock.type === 'rssDesc'"
                    class="sf-box-shadow px-3 py-2"
                  >
                    <template>
                      <div
                        class="row cursor-pointer mt-1"
                        :class="expandCard.setting ? null : 'collapsed'"
                        :aria-expanded="expandCard.setting ? 'true' : 'false'"
                        v-b-toggle="`collapse-${activeBlock.type}-setting`"
                      >
                        <div class="col-9 d-flex align-items-center">
                          <h6 class="semi-bold uppercase">SETTINGS</h6>
                        </div>
                        <div class="col-3 p-0">
                          <div class="d-flex align-items-center text-align justify-content-end">
                            <a class="p-1 sf-link">
                              <img
                                v-if="expandCard.setting"
                                src="@/assets/images/smart-assets/chevron-down.svg"
                              />
                              <img v-else src="@/assets/images/smart-assets/chevron-right.svg" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <b-collapse
                        v-model="expandCard.setting"
                        :id="`collapse-${activeBlock.type}-setting`"
                      >
                        <div class="animate__animated animate__fadeIn">
                          <div class="row mt-3">
                            <div class="col-12">
                              <div class="w-100">
                                <div class="mb-2 w-100px">General</div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="sf-select-group">
                                <Select2
                                  class="sf-select2"
                                  v-model="activeBlock.rssUrl"
                                  :options="rssList"
                                  @change="rssActiveBlockChange"
                                />
                                <span class="lb-right caret"></span>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-6">
                              <div class="w-100">
                                <div class="mb-2 w-100px">Loops</div>
                                <input
                                  class="sf-input-text"
                                  v-model="numberRssPost"
                                  type="number"
                                />
                              </div>
                              <div
                                class="invalid-error-text text-right"
                                v-if="!$v.numberRssPost.required"
                              >
                                Loop required
                              </div>
                              <div
                                class="invalid-error-text text-right"
                                v-if="!$v.numberRssPost.minValue"
                              >
                                {{ $t('must-be-more-than-0') }}
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="w-100">
                                <div class="mb-2 w-100px">Duration</div>
                                <input class="sf-input-text" v-model="rssDuration" type="number" />
                              </div>
                              <div
                                class="invalid-error-text text-right"
                                v-if="!$v.rssDuration.required"
                              >
                                Duration required
                              </div>
                              <div
                                class="invalid-error-text text-right"
                                v-if="!$v.rssDuration.minValue"
                              >
                                {{ $t('must-be-more-than-0') }}
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-12">
                              <div class="mb-2 w-100px">Preview</div>
                              <div class="d-flex align-items-center text-align">
                                <button
                                  @click="rssSlider('previous')"
                                  class="sf-secondary d-flex align-items-center"
                                >
                                  <img class="img-sm" src="@/assets/images/previous.svg" /> Previous
                                </button>
                                <button
                                  @click="rssSlider('next')"
                                  class="ml-2 sf-secondary d-flex align-items-center"
                                >
                                  Next <img class="img-sm" src="@/assets/images/next.svg" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-collapse>
                    </template>
                  </div>
                  <div
                    v-if="
                      activeBlock.type === 'qr' ||
                      activeBlock.type === 'video' ||
                      activeBlock.type === 'image'
                    "
                    class="sf-box-shadow px-3 py-2 position-relative z-index-3"
                  >
                    <template>
                      <div
                        class="row cursor-pointer mt-1"
                        :class="expandCard.setting ? null : 'collapsed'"
                        :aria-expanded="expandCard.setting ? 'true' : 'false'"
                        v-b-toggle="`collapse-${activeBlock.type}-setting`"
                      >
                        <div class="col-9 d-flex align-items-center">
                          <h6 class="semi-bold uppercase">SETTINGS</h6>
                        </div>
                        <div class="col-3 p-0">
                          <div class="d-flex align-items-center text-align justify-content-end">
                            <a class="p-1 sf-link">
                              <img
                                v-if="expandCard.setting"
                                src="@/assets/images/smart-assets/chevron-down.svg"
                              />
                              <img v-else src="@/assets/images/smart-assets/chevron-right.svg" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <b-collapse
                        v-model="expandCard.setting"
                        :id="`collapse-${activeBlock.type}-setting`"
                      >
                        <template v-if="activeBlock.type === 'qr'">
                          <div class="animate__animated animate__fadeIn">
                            <div class="row mt-3">
                              <div class="col-6">
                                <div class="w-100">
                                  <div class="mb-2 w-100px">Player Tracking</div>
                                  <toggle-button
                                    :color="'#76c322'"
                                    :value="activeBlock.player_tracking"
                                    :sync="true"
                                    @change="onChangeTogglePlayerTracking(activeBlock, $event)"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-6">
                                <div class="w-100">
                                  <div class="mb-2 w-100px">Bars</div>
                                </div>
                                <div class="d-flex align-items-center h-36">
                                  <div class="mr-2 d-flex w-60px justify-content-end">Color</div>
                                  <div class="w-100">
                                    <ColorPicker
                                      :key="activeBlockIndex"
                                      :color="activeBlock.color"
                                      @onColorChange="changeColorQrCode($event, 'bars')"
                                      v-model="activeBlock.color"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="col-6">
                                <div class="w-100">
                                  <div class="mb-2 w-100px">Background</div>
                                </div>
                                <div class="d-flex align-items-center h-36">
                                  <div class="mr-2 d-flex w-60px justify-content-end">Color</div>
                                  <div class="w-100">
                                    <ColorPicker
                                      :key="activeBlockIndex"
                                      :postion="'left'"
                                      :color="activeBlock.background_color"
                                      @onColorChange="changeColorQrCode($event, 'background')"
                                      v-model="activeBlock.background_color"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="activeBlock.type === 'video'">
                          <div class="animate__animated animate__fadeIn">
                            <div class="row mt-3">
                              <div class="col-6">
                                <div class="w-100">
                                  <div class="mb-2 w-100px">Loop Video</div>
                                  <toggle-button
                                    :color="'#76c322'"
                                    :value="activeBlock.loop_video"
                                    :sync="true"
                                    @change="onChangeToggleLoopVideo(activeBlock, $event)"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-12">
                                <div class="d-flex align-items-center">
                                  <a @click="$refs.videoChangeInput.click()">
                                    <img
                                      class="media-change-icon"
                                      src="@/assets/images/smart-assets/video-player.svg"
                                    />
                                    <span class="ml-2">Change Video</span>
                                  </a>
                                </div>
                                <div class="bg-upload d-none">
                                  <input
                                    ref="videoChangeInput"
                                    type="file"
                                    name="videofile"
                                    @change="handleUploadChangeVideo($event)"
                                    accept="video/mp4, video/quicktime, video/m4v, video/webm"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="animate__animated animate__fadeIn">
                            <div class="row mt-3">
                              <div class="col-12">
                                <div class="d-flex align-items-center">
                                  <a @click="handleClickChangeImage(activeBlock.name)">
                                    <img
                                      class="media-change-icon"
                                      src="@/assets/images/smart-assets/img-icon.svg"
                                    />
                                    <span class="ml-2">Change Image</span>
                                  </a>
                                </div>
                                <div class="bg-upload d-none">
                                  <input
                                    v-if="activeBlock.name === 'Gif Image'"
                                    ref="gifChangeInput"
                                    type="file"
                                    name="giffile"
                                    @change="handleUploadChangeGif($event)"
                                    accept=".gif"
                                  />
                                  <input
                                    v-else-if="activeBlock.name === 'SVG Image'"
                                    ref="svgChangeInput"
                                    type="file"
                                    name="svgfile"
                                    @change="handleUploadChangeSvg($event)"
                                    accept=".svg"
                                  />
                                  <input
                                    v-else
                                    ref="imageChangeInput"
                                    type="file"
                                    name="myfile"
                                    @change="handleUploadChangeImage($event)"
                                    accept=".png, .jpg, .jpeg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </b-collapse>
                    </template>
                  </div>
                  <div
                    v-if="activeBlock.type === dateTimeType"
                    class="sf-box-shadow px-3 py-2 position-relative z-index-3"
                  >
                    <template>
                      <div
                        class="row cursor-pointer mt-1"
                        :class="expandCard.setting ? null : 'collapsed'"
                        :aria-expanded="expandCard.setting ? 'true' : 'false'"
                        v-b-toggle="`collapse-${activeBlock.type}-setting`"
                      >
                        <div class="col-9 d-flex align-items-center">
                          <h6 class="semi-bold uppercase">SETTINGS</h6>
                        </div>
                        <div class="col-3 p-0">
                          <div class="d-flex align-items-center text-align justify-content-end">
                            <a class="p-1 sf-link">
                              <img
                                v-if="expandCard.setting"
                                src="@/assets/images/smart-assets/chevron-down.svg"
                              />
                              <img v-else src="@/assets/images/smart-assets/chevron-right.svg" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <b-collapse
                        v-model="expandCard.setting"
                        :id="`collapse-${activeBlock.type}-setting`"
                      >
                        <template>
                          <div class="animate__animated animate__fadeIn">
                            <div class="row mt-3">
                              <div class="col-6">
                                <div class="w-100">
                                  <div class="mb-2 w-100px">Background</div>
                                </div>
                                <div class="d-flex align-items-center h-36">
                                  <div class="mr-2 d-flex w-60px justify-content-end">Color</div>
                                  <div class="w-100">
                                    <ColorPicker
                                      :key="activeBlockIndex"
                                      position="right"
                                      :color="activeBlock.background_color"
                                      @onColorChange="changeBgColorDateTime($event)"
                                      v-model="activeBlock.background_color"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="w-100">
                                  <div class="mb-2 w-100px">Clock</div>
                                </div>
                                <div class="d-flex align-items-center h-36">
                                  <label class="sf-radio"
                                    >12 hour
                                    <input
                                      checked
                                      id="hour-clock-radio"
                                      name="hour-clock-radio"
                                      type="radio"
                                      :value="'true'"
                                      class="sf-radio"
                                      v-model="activeBlock.hour12"
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </div>
                                <div class="d-flex align-items-center h-36">
                                  <label class="sf-radio"
                                    >24 hour
                                    <input
                                      checked
                                      id="hour-clock-radio"
                                      name="hour-clock-radio"
                                      type="radio"
                                      :value="'false'"
                                      class="sf-radio"
                                      v-model="activeBlock.hour12"
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-12">
                                <div class="sf-form-group">
                                  <label class="mr-2">Timezone</label>
                                  <div class="sf-select-group">
                                    <Select2
                                      class="sf-select2"
                                      v-model="activeBlock.timezone"
                                      :options="listTimezoneOptions"
                                    />
                                    <span class="lb-right caret"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </b-collapse>
                    </template>
                  </div>
                  <div class="sf-box-shadow px-3 py-2">
                    <div
                      class="row mt-1 cursor-pointer"
                      :class="expandCard.position ? null : 'collapsed'"
                      :aria-expanded="expandCard.position ? 'true' : 'false'"
                      v-b-toggle="`collapse-${activeBlock.type}-position`"
                    >
                      <div class="col-9 d-flex align-items-center">
                        <h6 class="semi-bold uppercase">POSITION</h6>
                      </div>
                      <div class="col-3 p-0">
                        <div class="d-flex align-items-center text-align justify-content-end">
                          <a class="p-1 sf-link">
                            <img
                              v-if="expandCard.position"
                              src="@/assets/images/smart-assets/chevron-down.svg"
                            />
                            <img v-else src="@/assets/images/smart-assets/chevron-right.svg" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <b-collapse
                      v-model="expandCard.position"
                      :id="`collapse-${activeBlock.type}-position`"
                    >
                      <div class="animate__animated animate__fadeIn">
                        <div class="row mt-3">
                          <div class="col-5 pr-0">
                            <div class="d-flex align-items-center">
                              <div class="mr-2 position-title">W</div>
                              <input
                                class="sf-input-text"
                                v-model="activeBlock.w"
                                type="number"
                                @input="updateSizeInput($event, 'w')"
                              />
                            </div>
                          </div>
                          <div class="col-2 p-0 d-flex justify-content-center">
                            <div class="d-flex align-items-center">
                              <a
                                @click="activeBlock.aspectRatio = !activeBlock.aspectRatio"
                                :class="activeBlock.aspectRatio ? 'active' : ''"
                                class="rotate-90 mr-2 p-1 sf-link"
                              >
                                <img src="@/assets/images/smart-assets/lock-position.svg" />
                              </a>
                            </div>
                          </div>
                          <div class="col-5 pl-0">
                            <div class="d-flex align-items-center">
                              <div class="mr-2 position-title">H</div>
                              <input
                                class="sf-input-text"
                                v-model="activeBlock.h"
                                type="number"
                                @input="updateSizeInput($event, 'h')"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-5 pr-0">
                            <div class="d-flex align-items-center">
                              <div class="mr-2 position-title">X</div>
                              <input class="sf-input-text" v-model="activeBlock.x" type="number" />
                            </div>
                          </div>
                          <div class="col-2 pl-0"></div>
                          <div class="col-5 pl-0">
                            <div class="d-flex align-items-center">
                              <div class="mr-2 d-flex position-title justify-content-end">Y</div>
                              <input class="sf-input-text" v-model="activeBlock.y" type="number" />
                            </div>
                          </div>
                        </div>
                        <div v-if="activeBlock.type !== dateTimeType" class="row mt-3">
                          <div class="col-12">
                            <h6 class="semi-bold uppercase">Border</h6>
                          </div>
                        </div>
                        <div v-if="activeBlock.type !== dateTimeType" class="row mt-3">
                          <div class="col-6">
                            <div class="w-100">
                              <div class="mb-2 w-100px">Border Width</div>
                              <input
                                v-if="
                                  activeBlock.borderValue && activeBlock.borderValue.borderWidth
                                "
                                class="sf-input-text"
                                @change="updateBorder"
                                v-model="activeBlock.borderValue.borderWidth"
                                type="number"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="w-100">
                              <div class="mb-2 w-100px">Border Color</div>
                            </div>
                            <div class="d-flex align-items-center h-36">
                              <div class="mr-2 d-flex w-60px justify-content-end">Color</div>
                              <div class="w-100">
                                <ColorPicker
                                  :key="activeBlockIndex"
                                  v-if="activeBlock.borderValue && activeBlock.borderValue.color"
                                  :postion="'left'"
                                  :colorPickerType="'borderColor'"
                                  :color="activeBlock.borderValue.color"
                                  @onColorChange="updateBorder"
                                  v-model="activeBlock.borderValue.color"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-12">
                            <h6 class="semi-bold uppercase">Corners</h6>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-6">
                            <div class="w-100">
                              <div class="mb-2 w-100px">Top left</div>
                              <input
                                v-if="activeBlock.borderRadiusValue"
                                class="sf-input-text"
                                @change="updateBorderRadius"
                                v-model="activeBlock.borderRadiusValue.topLeft"
                                type="number"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="w-100">
                              <div class="mb-2 w-100px">Top Right</div>
                              <input
                                v-if="activeBlock.borderRadiusValue"
                                class="sf-input-text"
                                @change="updateBorderRadius"
                                v-model="activeBlock.borderRadiusValue.topRight"
                                type="number"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-6">
                            <div class="w-100">
                              <div class="mb-2 w-100px">Bottom left</div>
                              <input
                                v-if="activeBlock.borderRadiusValue"
                                class="sf-input-text"
                                @change="updateBorderRadius"
                                v-model="activeBlock.borderRadiusValue.bottomLeft"
                                type="number"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="w-100">
                              <div class="mb-2 w-100px">Bottom right</div>
                              <input
                                v-if="activeBlock.borderRadiusValue"
                                class="sf-input-text"
                                @change="updateBorderRadius"
                                v-model="activeBlock.borderRadiusValue.bottomRight"
                                type="number"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                  <div v-if="activeBlock.type === 'shape'" class="sf-box-shadow px-3 py-2">
                    <div
                      class="row mt-1 cursor-pointer"
                      :class="expandCard.shapeStyle ? null : 'collapsed'"
                      :aria-expanded="expandCard.shapeStyle ? 'true' : 'false'"
                      v-b-toggle="`collapse-${activeBlock.type}-shapeStyle`"
                    >
                      <div class="col-9 d-flex align-items-center">
                        <h6 class="semi-bold uppercase">SHAPE STYLE</h6>
                      </div>
                      <div class="col-3 p-0">
                        <div class="d-flex align-items-center text-align justify-content-end">
                          <a class="p-1 sf-link">
                            <img
                              v-if="expandCard.shapeStyle"
                              src="@/assets/images/smart-assets/chevron-down.svg"
                            />
                            <img v-else src="@/assets/images/smart-assets/chevron-right.svg" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <b-collapse
                      v-model="expandCard.shapeStyle"
                      :id="`collapse-${activeBlock.type}-shapeStyle`"
                    >
                      <div class="animate__animated animate__fadeIn">
                        <div class="row mt-3">
                          <div class="col-12">
                            <h6 class="semi-bold uppercase">Background</h6>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-12">
                            <div class="pl-3 d-flex align-items-center">
                              <ColorPicker
                                :key="activeBlockIndex"
                                :colorPickerType="'textBgColor'"
                                v-model="activeBlock.style.backgroundColor"
                                :color="activeBlock.style.backgroundColor"
                                @onColorChange="changeBgTextColor"
                              />
                              <span class="ml-2">Background color</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </template>
                <template
                  v-if="
                    activeBlock &&
                    (activeBlock.type == 'text' ||
                      activeBlock.type == 'igCaption' ||
                      activeBlock.type == 'rssTitle' ||
                      activeBlock.type == dateTimeType ||
                      activeBlock.type == 'rssDesc')
                  "
                >
                  <div class="sf-box-shadow px-3 py-2 position-relative z-index-2">
                    <div
                      class="row mt-1 cursor-pointer"
                      :class="expandCard.textStyle ? null : 'collapsed'"
                      :aria-expanded="expandCard.textStyle ? 'true' : 'false'"
                      v-b-toggle="`collapse-${activeBlock.type}-textStyle`"
                    >
                      <div class="col-9 d-flex align-items-center">
                        <h6 class="semi-bold uppercase">TEXT STYLE</h6>
                      </div>
                      <div class="col-3 p-0">
                        <div class="d-flex align-items-center text-align justify-content-end">
                          <a class="p-1 sf-link">
                            <img
                              v-if="expandCard.textStyle"
                              src="@/assets/images/smart-assets/chevron-down.svg"
                            />
                            <img v-else src="@/assets/images/smart-assets/chevron-right.svg" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <b-collapse
                      v-model="expandCard.textStyle"
                      :id="`collapse-${activeBlock.type}-textStyle`"
                    >
                      <div class="animate__animated animate__fadeIn">
                        <div class="row mt-3">
                          <div class="col-12">
                            <div class="sf-select-group">
                              <Select2
                                class="sf-select2"
                                v-model="activeBlock.textStyle.fontFamily"
                                :options="fonts"
                                @change="activeBlock.textStyle.fontFamily = $event"
                              />
                              <span class="lb-right caret"></span>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-12">
                            <button class="sf-primary w-100" @click="clickUploadNewFont">
                              <div class="ico">
                                <div class="cloud-upload" />
                              </div>
                              Upload New Font
                            </button>
                            <div class="bg-upload d-none">
                              <input
                                ref="uploadNewFont"
                                type="file"
                                name="myfile"
                                @change="handleUploadNewFont($event)"
                                accept=".ttf, .otf, .woff, .woff2"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-7">
                            <div class="sf-select-group">
                              <Select2
                                class="sf-select2"
                                v-model="activeBlock.textStyle.fontWeight"
                                :options="[
                                  { id: '400', text: 'Regular' },
                                  { id: '700', text: 'Bold' }
                                ]"
                                :settings="{ minimumResultsForSearch: -1 }"
                                @change="activeBlock.textStyle.fontWeight = $event"
                              />
                              <span class="lb-right caret"></span>
                            </div>
                          </div>
                          <div class="col-5">
                            <div class="sf-select-group">
                              <Select2
                                class="sf-select2"
                                v-model="activeBlock.textStyle.fontSize"
                                :options="fontSizeLargeOptions"
                                :settings="{ minimumResultsForSearch: -1 }"
                                @change="changeFontSize($event)"
                              />
                              <span class="lb-right caret"></span>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-7">
                            <div class="sf-form-group">
                              <div class="sf-label-group icon">
                                <div class="primary-label">
                                  <img
                                    class="mr-1"
                                    src="@/assets/images/smart-assets/line-height.svg"
                                  />
                                </div>
                              </div>
                              <div class="w-100">
                                <div class="sf-select-group">
                                  <Select2
                                    class="sf-select2"
                                    v-model="activeBlock.textStyle.lineHeight"
                                    :options="[
                                      { id: '50%', text: '50%' },
                                      { id: '100%', text: '100%' },
                                      { id: '110%', text: '110%' },
                                      { id: '120%', text: '120%' },
                                      { id: '140%', text: '140%' },
                                      { id: '160%', text: '160%' }
                                    ]"
                                    :settings="{ minimumResultsForSearch: -1 }"
                                    @change="activeBlock.textStyle.lineHeight = $event"
                                  />
                                  <span class="lb-right caret"></span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-5">
                            <div class="sf-form-group">
                              <div class="sf-label-group icon">
                                <div class="primary-label">
                                  <img
                                    class="mr-1"
                                    src="@/assets/images/smart-assets/character-spacing.svg"
                                  />
                                </div>
                              </div>
                              <div class="w-100">
                                <div class="sf-select-group">
                                  <Select2
                                    class="sf-select2"
                                    v-model="activeBlock.textStyle.letterSpacing"
                                    :options="[
                                      { id: '0', text: '0' },
                                      { id: '1px', text: '1px' },
                                      { id: '2px', text: '2px' },
                                      { id: '3px', text: '3px' },
                                      { id: '4px', text: '4px' },
                                      { id: '5px', text: '5px' }
                                    ]"
                                    :settings="{ minimumResultsForSearch: -1 }"
                                    @change="activeBlock.textStyle.letterSpacing = $event"
                                  />
                                  <span class="lb-right caret"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mt-4">
                          <div class="col-5">
                            <div class="d-flex align-items-center">
                              <ColorPicker
                                :key="activeBlockIndex"
                                :colorPickerType="'textColor'"
                                v-model="activeBlock.textStyle.color"
                                :color="activeBlock.textStyle.color"
                                @onColorChange="changeTextColor"
                              />
                              <span class="ml-2">Text Color</span>
                            </div>
                          </div>
                        </div>
                        <div v-if="activeBlock.type !== dateTimeType" class="row mt-3">
                          <div
                            class="col-12 d-flex align-items-center cursor-pointer"
                            @click="expandTextBackground = !expandTextBackground"
                          >
                            <a class="expand d-flex align-items-center">
                              <img
                                v-if="expandTextBackground"
                                src="@/assets/images/smart-assets/minus.svg"
                              />
                              <img v-else src="@/assets/images/smart-assets/plus.svg" />
                            </a>
                            <span class="ml-1">Background</span>
                          </div>
                          <div v-if="expandTextBackground" class="col-12 mt-2">
                            <div class="pl-3 d-flex align-items-center">
                              <ColorPicker
                                :key="activeBlockIndex"
                                :colorPickerType="'textBgColor'"
                                v-model="activeBlock.style.backgroundColor"
                                :color="activeBlock.style.backgroundColor"
                                @onColorChange="changeBgTextColor"
                              />
                              <span class="ml-2">Background color</span>
                            </div>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div
                            class="col-12 d-flex align-items-center cursor-pointer"
                            @click="expandTextShadow = !expandTextShadow"
                          >
                            <a class="expand d-flex align-items-center">
                              <img
                                v-if="expandTextShadow"
                                src="@/assets/images/smart-assets/minus.svg"
                              />
                              <img v-else src="@/assets/images/smart-assets/plus.svg" />
                            </a>
                            <span class="ml-1">Text shadow</span>
                          </div>
                        </div>
                        <div v-if="expandTextShadow" class="mt-2">
                          <div class="row">
                            <div class="col-6 d-flex align-items-center">
                              <div class="d-flex align-items-center">
                                <div class="mr-2 d-flex w-60px justify-content-end">X</div>
                                <input
                                  class="sf-input-text"
                                  type="number"
                                  v-model="activeBlock.textShadowValue.h"
                                  @change="updateTextShadow"
                                  min="0"
                                  max="20"
                                />
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="d-flex align-items-center">
                                <div class="mr-2 d-flex w-60px justify-content-end">Y</div>
                                <input
                                  class="sf-input-text"
                                  type="number"
                                  v-model="activeBlock.textShadowValue.v"
                                  @change="updateTextShadow"
                                  min="0"
                                  max="20"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-6">
                              <div class="d-flex align-items-center">
                                <div class="mr-2 d-flex w-60px justify-content-end">Blur</div>
                                <input
                                  class="sf-input-text"
                                  type="number"
                                  v-model="activeBlock.textShadowValue.blur"
                                  @change="updateTextShadow"
                                  min="0"
                                  max="20"
                                />
                              </div>
                            </div>
                            <div class="col-6 d-flex align-items-center">
                              <div class="d-flex align-items-center">
                                <div class="mr-2 d-flex w-60px justify-content-end">Color</div>
                                <div class="w-100">
                                  <ColorPicker
                                    :key="activeBlockIndex"
                                    :colorPickerType="'textShadowColor'"
                                    :color="activeBlock.textStyle.color"
                                    @onColorChange="updateTextShadow"
                                    v-model="activeBlock.textShadowValue.color"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <template>
                          <div class="row mt-3">
                            <div class="col-12 mb-2">
                              <div class="d-flex align-items-center text-align">
                                <a
                                  @click="updateStyle('justifyContent', 'flex-start')"
                                  class="mr-2 p-1 sf-link"
                                  ><img src="@/assets/images/smart-assets/align-top-blue.svg"
                                /></a>
                                <a
                                  @click="updateStyle('justifyContent', 'center')"
                                  class="mr-2 p-1 sf-link"
                                  ><img src="@/assets/images/smart-assets/align-middle-blue.svg"
                                /></a>
                                <a
                                  @click="updateStyle('justifyContent', 'flex-end')"
                                  class="mr-2 p-1 sf-link"
                                  ><img src="@/assets/images/smart-assets/align-bottom-blue.svg"
                                /></a>
                                <a
                                  @click="updateStyle('alignItems', 'flex-start')"
                                  class="mr-2 p-1 sf-link"
                                  ><img
                                    class="rotate-90"
                                    src="@/assets/images/smart-assets/align-top-blue.svg"
                                /></a>
                                <a
                                  @click="updateStyle('alignItems', 'center')"
                                  class="mr-2 p-1 sf-link"
                                  ><img
                                    class="rotate-90"
                                    src="@/assets/images/smart-assets/align-middle-blue.svg"
                                /></a>
                                <a
                                  @click="updateStyle('alignItems', 'flex-end')"
                                  class="mr-2 p-1 sf-link"
                                  ><img
                                    class="rotate-270"
                                    src="@/assets/images/smart-assets/align-bottom-blue.svg"
                                /></a>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="d-flex align-items-center text-align">
                                <a
                                  @click="updateTextStyle('textAlign', 'left')"
                                  class="mr-2 p-1 sf-link"
                                  ><img src="@/assets/images/smart-assets/align-left.svg"
                                /></a>
                                <a
                                  @click="updateTextStyle('textAlign', 'center')"
                                  class="mr-2 p-1 sf-link"
                                  ><img src="@/assets/images/smart-assets/align-center.svg"
                                /></a>
                                <a
                                  @click="updateTextStyle('textAlign', 'right')"
                                  class="p-1 sf-link"
                                  ><img
                                    class="flip-icon"
                                    src="@/assets/images/smart-assets/align-left.svg"
                                /></a>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </b-collapse>
                  </div>
                  <div
                    v-if="activeBlock.mentions || activeBlock.hashtags"
                    class="sf-box-shadow px-3 py-2 mt-2"
                  >
                    <div
                      class="row mt-1 cursor-pointer"
                      :class="expandCard.mention ? null : 'collapsed'"
                      :aria-expanded="expandCard.mention ? 'true' : 'false'"
                      v-b-toggle="`collapse-${activeBlock.type}-mention`"
                    >
                      <div class="col-9 d-flex align-items-center">
                        <h6 class="semi-bold uppercase">MENTIONS & HASHTAGS</h6>
                      </div>
                      <div class="col-3 p-0">
                        <div class="d-flex align-items-center text-align justify-content-end">
                          <a class="p-1 sf-link">
                            <img
                              v-if="expandCard.mention"
                              src="@/assets/images/smart-assets/chevron-down.svg"
                            />
                            <img v-else src="@/assets/images/smart-assets/chevron-right.svg" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <b-collapse
                      v-model="expandCard.mention"
                      :id="`collapse-${activeBlock.type}-mention`"
                    >
                      <div class="animate__animated animate__fadeIn">
                        <div class="row mt-3">
                          <div class="col-12">
                            <ul class="sf-tabs">
                              <li>
                                <a
                                  @click="igCaptionSettingTab = 'mentions'"
                                  :class="{ active: igCaptionSettingTab === 'mentions' }"
                                  >Mentions</a
                                >
                              </li>
                              <li>
                                <a
                                  @click="igCaptionSettingTab = 'hashtags'"
                                  :class="{ active: igCaptionSettingTab === 'hashtags' }"
                                  >Hashtags</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                        <template v-if="igCaptionSettingTab === 'mentions'">
                          <div class="row mt-3">
                            <div class="col-12">
                              <div class="sf-select-group">
                                <Select2
                                  class="sf-select2"
                                  v-model="activeBlock.mentions.fontFamily"
                                  :options="fonts"
                                  :settings="{ minimumResultsForSearch: -1 }"
                                  @change="igCaptionChange($event, 'mentions', 'fontFamily')"
                                />
                                <span class="lb-right caret"></span>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-7">
                              <div class="sf-select-group">
                                <Select2
                                  class="sf-select2"
                                  v-model="activeBlock.mentions.fontWeight"
                                  :options="[
                                    { id: '400', text: 'Regular' },
                                    { id: '700', text: 'Bold' }
                                  ]"
                                  :settings="{ minimumResultsForSearch: -1 }"
                                  @change="igCaptionChange($event, 'mentions', 'fontWeight')"
                                />
                                <span class="lb-right caret"></span>
                              </div>
                            </div>
                            <div class="col-5">
                              <div class="sf-select-group">
                                <Select2
                                  class="sf-select2"
                                  v-model="activeBlock.mentions.fontSize"
                                  :options="fontSizeOptions"
                                  :settings="{ minimumResultsForSearch: -1 }"
                                  @change="igCaptionChange($event, 'mentions', 'fontSize')"
                                />
                                <span class="lb-right caret"></span>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4">
                            <div class="col-3">
                              <div class="d-flex align-items-center">
                                <ColorPicker
                                  :key="activeBlockIndex"
                                  :colorPickerType="'textColor'"
                                  v-model="activeBlock.mentions.textColor"
                                  :color="activeBlock.mentions.textColor"
                                  @onColorChange="changeMentionsColor"
                                />
                                <span class="ml-2">Mention</span>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-if="igCaptionSettingTab === 'hashtags'">
                          <div class="row mt-3">
                            <div class="col-12">
                              <div class="sf-select-group">
                                <Select2
                                  class="sf-select2"
                                  v-model="activeBlock.hashtags.fontFamily"
                                  :options="fonts"
                                  :settings="{ minimumResultsForSearch: -1 }"
                                  @change="igCaptionChange($event, 'hashtags', 'fontFamily')"
                                />
                                <span class="lb-right caret"></span>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-7">
                              <div class="sf-select-group">
                                <Select2
                                  class="sf-select2"
                                  v-model="activeBlock.hashtags.fontWeight"
                                  :options="[
                                    { id: '400', text: 'Regular' },
                                    { id: '700', text: 'Bold' }
                                  ]"
                                  :settings="{ minimumResultsForSearch: -1 }"
                                  @change="igCaptionChange($event, 'hashtags', 'fontWeight')"
                                />
                                <span class="lb-right caret"></span>
                              </div>
                            </div>
                            <div class="col-5">
                              <div class="sf-select-group">
                                <Select2
                                  class="sf-select2"
                                  v-model="activeBlock.hashtags.fontSize"
                                  :options="fontSizeOptions"
                                  :settings="{ minimumResultsForSearch: -1 }"
                                  @change="igCaptionChange($event, 'hashtags', 'fontSize')"
                                />
                                <span class="lb-right caret"></span>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4">
                            <div class="col-3">
                              <div class="d-flex align-items-center">
                                <ColorPicker
                                  :key="activeBlockIndex"
                                  :colorPickerType="'hashtags'"
                                  v-model="activeBlock.hashtags.textColor"
                                  :color="activeBlock.hashtags.textColor"
                                  @onColorChange="changeHashtagsColor"
                                />
                                <span class="ml-2">Hashtag</span>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </b-collapse>
                  </div>
                  <div class="sf-box-shadow px-3 py-2 mt-2 position-relative z-index-1">
                    <div
                      class="row mt-1 cursor-pointer"
                      :class="expandCard.padding ? null : 'collapsed'"
                      :aria-expanded="expandCard.padding ? 'true' : 'false'"
                      v-b-toggle="`collapse-${activeBlock.type}-padding`"
                    >
                      <div class="col-9 d-flex align-items-center">
                        <h6 class="semi-bold uppercase">PADDING</h6>
                      </div>
                      <div class="col-3 p-0">
                        <div class="d-flex align-items-center text-align justify-content-end">
                          <a class="p-1 sf-link">
                            <img
                              v-if="expandCard.padding"
                              src="@/assets/images/smart-assets/chevron-down.svg"
                            />
                            <img v-else src="@/assets/images/smart-assets/chevron-right.svg" />
                          </a>
                        </div>
                      </div>
                    </div>

                    <b-collapse
                      v-model="expandCard.padding"
                      :id="`collapse-${activeBlock.type}-padding`"
                    >
                      <div class="col-12">
                        <div class="block-padding mt-3">
                          <div class="line1"></div>
                          <div class="line2"></div>
                          <div class="line3"></div>
                          <div class="line4"></div>
                          <div class="block-padding-item top">
                            <input
                              v-model.number="activeBlock.paddingValue.top"
                              class="sf-input-text"
                              @change="updateTextPadding"
                              type="number"
                            />
                          </div>
                          <div class="block-padding-item middle">
                            <div class="left">
                              <input
                                v-model.number="activeBlock.paddingValue.left"
                                class="sf-input-text"
                                @change="updateTextPadding"
                                type="number"
                              />
                            </div>
                            <div class="blank-block">
                              <div class="padding-preview"></div>
                            </div>
                            <div class="right">
                              <input
                                v-model.number="activeBlock.paddingValue.right"
                                @change="updateTextPadding"
                                class="sf-input-text"
                                type="number"
                              />
                            </div>
                          </div>
                          <div class="block-padding-item bottom">
                            <input
                              v-model.number="activeBlock.paddingValue.bottom"
                              @change="updateTextPadding"
                              class="sf-input-text"
                              type="number"
                            />
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div
            v-if="isLoadingTemplate"
            class="col-9 bg-color-base right-content d-flex justify-content-center align-items-center vh-100"
          >
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div
            v-else
            class="col-9 bg-color-base right-content"
            @click="contentAreaClickOuside($event)"
          >
            <div class="row">
              <div v-if="activeBlock" class="full-size">
                <span v-if="isPortrait">(1080x1920) | </span>
                <span v-else>(1920x1080) | </span>
                <span>X: </span><span>{{ activeBlock.x * 2 }}px</span>; <span>Y: </span
                ><span>{{ activeBlock.y * 2 }}px</span>; <span>W: </span
                ><span>{{ activeBlock.w * 2 }}px</span>; <span>H: </span
                ><span>{{ activeBlock.h * 2 }}px</span>
              </div>
              <div class="col-12 mt-2 d-flex justify-content-center">
                <div class="bg-white sf-box-shadow mt-3">
                  <div
                    :style="{ backgroundColor: smartAsset.backgroundColor || 'rgb(255, 255, 255)' }"
                    id="smartAssetContainer"
                    class="content-area-wrapper position-relative"
                  >
                    <img
                      class="smart-asset-bg-img"
                      crossorigin="anonymous"
                      v-if="getBackgroundImage.dataImage || smartAsset.backgroundImage.url"
                      :style="{
                        width:
                          smartAssetDataModal.orientation &&
                          smartAssetDataModal.orientation === 'landscape'
                            ? '960px'
                            : null,
                        height:
                          smartAssetDataModal.orientation &&
                          smartAssetDataModal.orientation === 'landscape'
                            ? null
                            : '960px',
                        zIndex: 0,
                        objectFit: 'cover',
                        position: 'absolute'
                      }"
                      :src="
                        getBackgroundImage.dataImage || formatImgUrl(smartAsset.backgroundImage.url)
                      "
                    />
                    <div
                      :style="contentAreaStyle"
                      @dragover="allowDropBlock($event)"
                      @drop="dropBlock($event)"
                      @click="contentAreaClick($event)"
                      :class="{ grid: smartAsset.showGrid }"
                      class="content-area"
                    >
                      <template v-for="(item, index) in smartAsset.blocks">
                        <template v-if="item && !item.is_destroy">
                          <template v-if="item.type === 'text'">
                            <vue-draggable-resizable
                              :key="index"
                              :class="{ selected: index === activeBlockIndex }"
                              @activated="onActivated(index, item)"
                              :active="index === activeBlockIndex"
                              :lock-aspect-ratio="item.aspectRatio"
                              :style="{
                                zIndex: item.zIndex,
                                borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`
                              }"
                              @dragstop="onDragstop"
                              @dragging="onDragging"
                              @resizestop="onResizstop"
                              @resizing="onResize"
                              :grid="smartAsset.grid"
                              :x="item.x"
                              :y="item.y"
                              :w="item.w >= 0 ? item.w : null"
                              :h="item.h >= 0 ? item.h : null"
                            >
                              <div
                                class="content-editable-container content-editable"
                                :style="{
                                  backgroundColor: item.style.backgroundColor,
                                  padding: `${item.paddingValue.top}px ${item.paddingValue.right}px ${item.paddingValue.bottom}px ${item.paddingValue.left}px`,
                                  border: `${item.borderValue.borderWidth}px solid ${item.borderValue.color}`,
                                  borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`,
                                  textAlign: item.textStyle.textAlign,
                                  color: item.textStyle.color,
                                  fontSize: item.textStyle.fontSize,
                                  fontFamily: item.textStyle.fontFamily,
                                  fontWeight: item.textStyle.fontWeight,
                                  lineHeight: item.textStyle.lineHeight,
                                  letterSpacing: item.textStyle.letterSpacing,
                                  textShadow: item.textStyle.textShadow,
                                  alignItems: item.style.alignItems,
                                  justifyContent: item.style.justifyContent,
                                  flexDirection: item.style.flexDirection
                                    ? item.style.flexDirection
                                    : 'column',
                                  whiteSpace: 'pre-line',
                                  display: item.style.display ? item.style.display : 'flex'
                                }"
                                contenteditable="true"
                                @input="contenteditableInput($event)"
                                v-html="item.displayText"
                              ></div>
                              <!-- <ContentEditable
                                :style="{
                                  alignItems: item.style.alignItems,
                                  justifyContent: item.style.justifyContent,
                                  backgroundColor: item.style.backgroundColor,
                                  border: `${item.borderValue.borderWidth}px solid ${item.borderValue.color}`,
                                  borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`,
                                }"
                                :class="item.type"
                                class="text-block"
                                :elId="'contentEditable' + index"
                                :content="{text: item.text}"
                                :itemStyle="item.textStyle"
                                @onInput="item.text = $event"

                              /> -->
                            </vue-draggable-resizable>
                          </template>
                          <template v-if="item.type === dateTimeType">
                            <DateTimeBlock
                              :item="item"
                              :activeBlockIndex="activeBlockIndex"
                              :index="index"
                              :key="index"
                              :smartAsset="smartAsset"
                              @onDragStop="onDragstop"
                              @onDragging="onDragging"
                              @onResizeStop="onResizstop"
                              @onResize="onResize"
                              @onActivated="onActivated"
                            />
                          </template>

                          <template v-if="item.type === 'image'">
                            <vue-draggable-resizable
                              :key="index"
                              :class="{ selected: index === activeBlockIndex }"
                              :style="{
                                zIndex: item.zIndex
                              }"
                              @activated="onActivated(index, item)"
                              :lock-aspect-ratio="item.aspectRatio"
                              @dragstop="onDragstop"
                              @resizestop="onResizstop"
                              @dragging="onDragging"
                              @resizing="onResize"
                              :grid="smartAsset.grid"
                              :x="item.x"
                              :y="item.y"
                              :w="item.w >= 0 ? item.w : null"
                              :h="item.h >= 0 ? item.h : null"
                            >
                              <div
                                :style="{
                                  position: 'relative',
                                  alignItems: item.style.alignItems,
                                  justifyContent: item.style.justifyContent,
                                  backgroundColor: item.style.backgroundColor,
                                  border: `${item.borderValue.borderWidth}px solid ${item.borderValue.color}`,
                                  borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`,
                                  width: item.w - 2 + 'px',
                                  height: item.h - 2 + 'px',
                                  overflow: 'hidden',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }"
                              >
                                <img
                                  crossorigin="anonymous"
                                  :style="{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`
                                  }"
                                  :src="item.dataImage ? item.dataImage : item.image_url"
                                />
                                <!-- <img :style="{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'contain',
                                  borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`,
                                }"
                                v-if="item.image_url" :src="item.image_url"> -->
                              </div>
                            </vue-draggable-resizable>
                          </template>
                          <template v-if="item.type === 'embed'">
                            <vue-draggable-resizable
                              :key="index"
                              :class="{ selected: index === activeBlockIndex }"
                              :style="{
                                zIndex: item.zIndex
                              }"
                              @activated="onActivated(index, item)"
                              :lock-aspect-ratio="item.aspectRatio"
                              @dragstop="onDragstop"
                              @resizestop="onResizstop"
                              @dragging="onDragging"
                              @resizing="onResize"
                              :grid="smartAsset.grid"
                              :x="item.x"
                              :y="item.y"
                              :w="item.w >= 0 ? item.w : null"
                              :h="item.h >= 0 ? item.h : null"
                            >
                              <div
                                :style="{
                                  position: 'relative',
                                  alignItems: item.style.alignItems,
                                  justifyContent: item.style.justifyContent,
                                  backgroundColor: item.style.backgroundColor,
                                  border: `${item.borderValue.borderWidth}px solid ${item.borderValue.color}`,
                                  borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`,
                                  width: item.w + 'px',
                                  height: item.h + 'px',
                                  overflow: 'hidden'
                                }"
                              >
                                <div
                                  :style="{
                                    width: item.w - 15 + 'px',
                                    height: item.h + 'px',
                                    position: 'absolute',
                                    zIndex: 1000,
                                    opacity: 0
                                  }"
                                ></div>
                                <iframe
                                  :style="{
                                    width: item.w + 'px',
                                    height: item.h + 'px'
                                  }"
                                  :src="item.website_url"
                                ></iframe>
                              </div>
                            </vue-draggable-resizable>
                          </template>
                          <template v-if="item.type === 'igCaption'">
                            <vue-draggable-resizable
                              :key="index"
                              :class="{ selected: index === activeBlockIndex }"
                              @activated="onActivated(index, item)"
                              :active="index === activeBlockIndex"
                              :lock-aspect-ratio="item.aspectRatio"
                              :style="{
                                zIndex: item.zIndex,
                                borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`
                              }"
                              @dragstop="onDragstop"
                              @resizestop="onResizstop"
                              @dragging="onDragging"
                              @resizing="onResize"
                              :grid="smartAsset.grid"
                              :x="item.x"
                              :y="item.y"
                              :w="item.w >= 0 ? item.w : null"
                              :h="item.h >= 0 ? item.h : null"
                            >
                              <div
                                :style="{
                                  alignItems: item.style.alignItems,
                                  justifyContent: item.style.justifyContent,
                                  backgroundColor: item.style.backgroundColor,
                                  padding: `${item.paddingValue.top}px ${item.paddingValue.right}px ${item.paddingValue.bottom}px ${item.paddingValue.left}px`,
                                  borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`,
                                  border: `${item.borderValue.borderWidth}px solid ${item.borderValue.color}`
                                }"
                                :class="item.type"
                                class="text-block source-caption"
                              >
                                <template v-for="(data, index) in item.instagramData">
                                  <div
                                    :key="index"
                                    v-html="data.captionRender"
                                    :data-index="index + 1"
                                    class="ig-blocks"
                                    :style="{
                                      textAlign: item.textStyle.textAlign,
                                      color: item.textStyle.color,
                                      fontSize: item.textStyle.fontSize,
                                      fontFamily: item.textStyle.fontFamily,
                                      fontWeight: item.textStyle.fontWeight,
                                      lineHeight: item.textStyle.lineHeight,
                                      letterSpacing: item.textStyle.letterSpacing,
                                      textShadow: item.textStyle.textShadow,
                                      display: `${index === 0 ? 'block' : 'none'}`
                                    }"
                                  ></div>
                                </template>
                              </div>
                            </vue-draggable-resizable>
                          </template>
                          <template v-if="item.type === 'igImage'">
                            <vue-draggable-resizable
                              :key="index"
                              :class="{ selected: index === activeBlockIndex }"
                              :style="{
                                zIndex: item.zIndex
                              }"
                              @activated="onActivated(index, item)"
                              :lock-aspect-ratio="item.aspectRatio"
                              @dragstop="onDragstop"
                              @resizestop="onResizstop"
                              @dragging="onDragging"
                              @resizing="onResize"
                              :grid="smartAsset.grid"
                              :x="item.x"
                              :y="item.y"
                              :w="item.w >= 0 ? item.w : null"
                              :h="item.h >= 0 ? item.h : null"
                            >
                              <div
                                :style="{
                                  position: 'relative',
                                  alignItems: item.style.alignItems,
                                  justifyContent: item.style.justifyContent,
                                  backgroundColor: item.style.backgroundColor,
                                  borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`,
                                  border: `${item.borderValue.borderWidth}px solid ${item.borderValue.color}`,
                                  width: item.w + 'px',
                                  height: item.h + 'px',
                                  overflow: 'hidden',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }"
                              >
                                <template v-for="(data, index) in item.instagramData">
                                  <img
                                    class="ig-blocks"
                                    :data-index="index + 1"
                                    :key="index"
                                    :style="{
                                      display: `${index === 0 ? 'block' : 'none'}`,
                                      width: '100%',
                                      height: '100%',
                                      objectFit: 'contain',
                                      borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`
                                    }"
                                    v-if="data.media_url"
                                    :src="data.media_url"
                                  />
                                </template>
                              </div>
                            </vue-draggable-resizable>
                          </template>

                          <template v-if="item.type === 'rssTitle'">
                            <vue-draggable-resizable
                              :key="index"
                              :class="{ selected: index === activeBlockIndex }"
                              @activated="onActivated(index, item)"
                              :active="index === activeBlockIndex"
                              :lock-aspect-ratio="item.aspectRatio"
                              :style="{
                                zIndex: item.zIndex,
                                borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`
                              }"
                              @dragstop="onDragstop"
                              @resizestop="onResizstop"
                              @dragging="onDragging"
                              @resizing="onResize"
                              :grid="smartAsset.grid"
                              :x="item.x"
                              :y="item.y"
                              :w="item.w >= 0 ? item.w : null"
                              :h="item.h >= 0 ? item.h : null"
                            >
                              <div
                                :style="{
                                  alignItems: item.style.alignItems,
                                  justifyContent: item.style.justifyContent,
                                  backgroundColor: item.style.backgroundColor,
                                  padding: `${item.paddingValue.top}px ${item.paddingValue.right}px ${item.paddingValue.bottom}px ${item.paddingValue.left}px`,
                                  border: `${item.borderValue.borderWidth}px solid ${item.borderValue.color}`,
                                  borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`
                                }"
                                :class="item.type"
                                class="text-block source-rss"
                              >
                                <template v-for="(data, index) in item.rssData">
                                  <div
                                    :key="index"
                                    v-html="data.title"
                                    :data-index="index + 1"
                                    class="rss-blocks"
                                    :style="{
                                      textAlign: item.textStyle.textAlign,
                                      color: item.textStyle.color,
                                      fontSize: item.textStyle.fontSize,
                                      fontFamily: item.textStyle.fontFamily,
                                      fontWeight: item.textStyle.fontWeight,
                                      lineHeight: item.textStyle.lineHeight,
                                      letterSpacing: item.textStyle.letterSpacing,
                                      textShadow: item.textStyle.textShadow,
                                      display: `${index === 0 ? 'block' : 'none'}`,
                                      maxHeight: item.h + 'px'
                                    }"
                                  ></div>
                                </template>
                              </div>
                            </vue-draggable-resizable>
                          </template>

                          <template v-if="item.type === 'rssDesc'">
                            <vue-draggable-resizable
                              :key="index"
                              :class="{ selected: index === activeBlockIndex }"
                              @activated="onActivated(index, item)"
                              :active="index === activeBlockIndex"
                              :lock-aspect-ratio="item.aspectRatio"
                              :style="{
                                zIndex: item.zIndex,
                                borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`
                              }"
                              @dragstop="onDragstop"
                              @resizestop="onResizstop"
                              @dragging="onDragging"
                              @resizing="onResize"
                              :grid="smartAsset.grid"
                              :x="item.x"
                              :y="item.y"
                              :w="item.w >= 0 ? item.w : null"
                              :h="item.h >= 0 ? item.h : null"
                            >
                              <div
                                :style="{
                                  alignItems: item.style.alignItems,
                                  justifyContent: item.style.justifyContent,
                                  backgroundColor: item.style.backgroundColor,
                                  padding: `${item.paddingValue.top}px ${item.paddingValue.right}px ${item.paddingValue.bottom}px ${item.paddingValue.left}px`,
                                  border: `${item.borderValue.borderWidth}px solid ${item.borderValue.color}`,
                                  borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`
                                }"
                                :class="item.type"
                                class="text-block source-rss"
                              >
                                <div
                                  :style="{
                                    width: item.w - 5 + 'px',
                                    height: item.h + 'px',
                                    position: 'absolute',
                                    zIndex: 1000,
                                    opacity: 0
                                  }"
                                ></div>
                                <template v-for="(data, index) in item.rssData">
                                  <div
                                    :key="index"
                                    v-html="data.description"
                                    :data-index="index + 1"
                                    class="rss-blocks"
                                    :style="{
                                      textAlign: item.textStyle.textAlign,
                                      color: item.textStyle.color,
                                      fontSize: item.textStyle.fontSize,
                                      fontFamily: item.textStyle.fontFamily,
                                      fontWeight: item.textStyle.fontWeight,
                                      lineHeight: item.textStyle.lineHeight,
                                      letterSpacing: item.textStyle.letterSpacing,
                                      textShadow: item.textStyle.textShadow,
                                      display: `${index === 0 ? 'block' : 'none'}`,
                                      maxHeight: item.h + 'px'
                                    }"
                                  ></div>
                                </template>
                              </div>
                            </vue-draggable-resizable>
                          </template>

                          <template v-if="item.type === 'qr'">
                            <vue-draggable-resizable
                              :key="index"
                              :class="{ selected: index === activeBlockIndex }"
                              :style="{
                                zIndex: item.zIndex
                              }"
                              @activated="onActivated(index, item)"
                              :lock-aspect-ratio="item.aspectRatio"
                              @dragstop="onDragstop"
                              @resizestop="onResizstop"
                              @dragging="onDragging"
                              @resizing="onResize"
                              :grid="smartAsset.grid"
                              :x="item.x"
                              :y="item.y"
                              :w="item.w >= 0 ? item.w : null"
                              :h="item.h >= 0 ? item.h : null"
                            >
                              <div
                                :style="{
                                  position: 'relative',
                                  alignItems: item.style.alignItems,
                                  justifyContent: item.style.justifyContent,
                                  backgroundColor: item.style.backgroundColor,
                                  border: `${item.borderValue.borderWidth}px solid ${item.borderValue.color}`,
                                  borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`,
                                  width: item.w + 'px',
                                  height: item.h + 'px',
                                  overflow: 'hidden',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }"
                              >
                                <img
                                  crossorigin="anonymous"
                                  :style="{
                                    width: '100%',
                                    height: '100%',
                                    padding: '4px',
                                    objectFit: 'contain',
                                    borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`
                                  }"
                                  :src="item.image_string"
                                />
                              </div>
                            </vue-draggable-resizable>
                          </template>

                          <template v-if="item.type === 'video'">
                            <vue-draggable-resizable
                              :key="index"
                              :class="{ selected: index === activeBlockIndex }"
                              :style="{
                                zIndex: item.zIndex
                              }"
                              @activated="onActivated(index, item)"
                              :lock-aspect-ratio="item.aspectRatio"
                              @dragstop="onDragstop"
                              @resizestop="onResizstop"
                              @dragging="onDragging"
                              @resizing="onResize"
                              :grid="smartAsset.grid"
                              :x="item.x"
                              :y="item.y"
                              :w="item.w >= 0 ? item.w : null"
                              :h="item.h >= 0 ? item.h : null"
                            >
                              <div
                                :style="{
                                  position: 'relative',
                                  alignItems: item.style.alignItems,
                                  justifyContent: item.style.justifyContent,
                                  backgroundColor: item.style.backgroundColor,
                                  border: `${item.borderValue.borderWidth}px solid ${item.borderValue.color}`,
                                  borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`,
                                  width: item.w + 'px',
                                  height: item.h + 'px',
                                  overflow: 'hidden',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }"
                              >
                                <video
                                  controls
                                  :id="`video-tag-${index}`"
                                  :style="{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`
                                  }"
                                >
                                  <source
                                    :id="`video-source-${index}`"
                                    :src="item.dataVideo ? item.dataVideo : item.image_url"
                                  />
                                </video>
                                <!-- <video :style="{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'contain',
                                  borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`,
                                }"
                                v-if="item.image_url" :src="item.image_url">
                                </video> -->
                              </div>
                            </vue-draggable-resizable>
                          </template>

                          <template v-if="item.type === 'shape'">
                            <vue-draggable-resizable
                              :key="index"
                              :class="{ selected: index === activeBlockIndex }"
                              @activated="onActivated(index, item)"
                              :lock-aspect-ratio="item.aspectRatio"
                              :style="{
                                zIndex: item.zIndex,
                                borderRadius: `${item.borderRadiusValue.topLeft}% ${item.borderRadiusValue.topRight}% ${item.borderRadiusValue.bottomRight}% ${item.borderRadiusValue.bottomLeft}%`
                              }"
                              @dragstop="onDragstop"
                              @dragging="onDragging"
                              @resizing="onResize"
                              @resizestop="onResizstop"
                              :grid="smartAsset.grid"
                              :x="item.x"
                              :y="item.y"
                              :w="item.w >= 0 ? item.w : null"
                              :h="item.h >= 0 ? item.h : null"
                            >
                              <div
                                class="content-editable-container"
                                :style="{
                                  alignItems: item.style.alignItems,
                                  justifyContent: item.style.justifyContent,
                                  backgroundColor: item.style.backgroundColor,
                                  border: `${item.borderValue.borderWidth}px solid ${item.borderValue.color}`,
                                  borderRadius: `${item.borderRadiusValue.topLeft}% ${item.borderRadiusValue.topRight}% ${item.borderRadiusValue.bottomRight}% ${item.borderRadiusValue.bottomLeft}%`
                                }"
                              ></div>
                            </vue-draggable-resizable>
                          </template>
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 my-4">
                <div class="w-100 d-flex align-items-center justify-content-center">
                  <div class="bg-upload d-none">
                    <input
                      id="uploadBackgroundInput"
                      type="file"
                      name="myfile"
                      @change="handleUploadBackground($event)"
                      accept="image/*"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="d-flex justify-content-center">
                      <div class="select-background">
                        <multiselect
                          v-model="selectBackgroundValue"
                          class="mx-0 sf-multiselect multi-select"
                          label="title"
                          track-by="title"
                          :placeholder="'Update Slide Settings'"
                          :options="backgroundOptions"
                          :option-height="40"
                          :option-width="160"
                          @select="onSelectBackground"
                          :show-labels="false"
                        >
                          <template slot="option" slot-scope="props">
                            <div class="d-flex">
                              <img class="option__image" :src="props.option.img" />
                              <div class="option__desc">
                                <span class="option__title">{{ props.option.title }}</span>
                              </div>
                            </div>
                          </template>
                        </multiselect>
                      </div>
                      <div
                        v-if="
                          !selectBackgroundValue ||
                          (selectBackgroundValue && selectBackgroundValue.id === 'useImage')
                        "
                        class="ml-2 d-flex align-items-center"
                      >
                        <a @click="uploadBackground">
                          <img
                            class="bg-image-thumb"
                            v-if="getBackgroundImage.dataImage || getBackgroundImage.url"
                            :src="getBackgroundImage.dataImage || getBackgroundImage.url"
                          />
                          <img
                            class="img-icon"
                            v-else
                            src="@/assets/images/smart-assets/img-icon.svg"
                          />
                        </a>
                      </div>
                      <div
                        v-if="selectBackgroundValue && selectBackgroundValue.id === 'useColor'"
                        class="ml-2 pt-2"
                      >
                        <ColorPicker
                          :postion="'top'"
                          class="color-picker-background"
                          :colorPickerType="'backgroundColor'"
                          v-model="smartAsset.backgroundColor"
                          :color="smartAsset.backgroundColor"
                          @onColorChange="changeBackgroundColor"
                        />
                      </div>
                      <div
                        v-if="selectBackgroundValue && selectBackgroundValue.id === 'useTextFont'"
                      >
                        <div class="sf-select-group ml-2 pl-2">
                          <Select2
                            class="sf-select2 default-font-text"
                            v-model="smartAsset.textStyle.fontFamily"
                            :options="fonts"
                            :settings="{ placeholder: 'Select font' }"
                            @change="smartAsset.textStyle.fontFamily = $event"
                          />
                          <span class="lb-right caret"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid bg-white smart-asset-footer">
        <div class="row">
          <div class="col-3"></div>
          <div class="col-9">
            <div class="row">
              <div class="col-6 d-flex align-items-center justify-content-start">
                <template v-if="!isAdminTemplate">
                  <div class="mr-2">
                    <button @click="showTemplateModal = true" class="sf-primary">
                      Select a template
                    </button>
                  </div>
                  <div>
                    <button
                      :disabled="isLoading"
                      @click="showModalCreateTemplate()"
                      class="sf-primary"
                    >
                      <div v-if="loadingCreateTemplate" class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <span v-else>Create template</span>
                    </button>
                  </div>

                  <div v-if="smartAssetTemplateID || templateJustCreatedData" class="ml-2">
                    <button :disabled="isLoading" @click="updateTemplate()" class="sf-primary">
                      <div v-if="loadingUpdateTemplate" class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <span v-else>Update template</span>
                    </button>
                  </div>
                </template>
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end py-3">
                  <div class="botton-group">
                    <button @click="confirmClose" class="sf-secondary mx-2">Cancel</button>
                    <template v-if="editData.id">
                      <button
                        :disabled="isLoading"
                        v-if="isAdminTemplate"
                        @click="updateTemplate()"
                        class="sf-primary"
                      >
                        <div v-if="loadingUpdateTemplate" class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>Update template</span>
                      </button>
                      <button
                        :disabled="isLoading"
                        v-else
                        @click="updateAsset()"
                        class="sf-primary"
                      >
                        <div v-if="loading" class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>Update asset</span>
                      </button>
                    </template>
                    <template v-else>
                      <button
                        :disabled="isLoading"
                        v-if="isAdminTemplate"
                        @click="saveTemplate"
                        class="sf-primary"
                      >
                        <div v-if="loadingCreateTemplate" class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>Save template</span>
                      </button>
                      <button
                        :disabled="isLoading"
                        v-else
                        @click="createAsset()"
                        class="sf-primary"
                      >
                        <div v-if="loading" class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>Save asset</span>
                      </button>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SfModal
        :title="'Create new template'"
        :width="786"
        closeOnOverlay
        :show.sync="showCreateTemplateModal"
        @onClose="cancelCreateTemplate"
      >
        <div v-click-inside="disabledDeleteKey" class="sf-modal-body mb-3">
          <div class="container">
            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">Name</label>
                  </div>
                  <input
                    v-model.trim="templateName"
                    placeholder="Custom template"
                    class="sf-input-text"
                    :class="{
                      'input-error-form': submitTemplateModal && !$v.templateName.minLength
                    }"
                  />
                </div>
                <div
                  v-show="submitTemplateModal && !$v.templateName.required"
                  class="invalid-error-text text-right"
                >
                  Name can't be blank
                </div>
                <div
                  v-show="submitTemplateModal && !$v.templateName.minLength"
                  class="invalid-error-text text-right"
                >
                  {{ $t('name-minLength-2') }}
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">Description</label>
                  </div>
                  <input
                    v-model="templateDescription"
                    placeholder="A quick idea of the template"
                    class="sf-input-text"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">Tags</label>
                  </div>
                  <vue-tags-input
                    v-model="tag"
                    class="sf-tag"
                    :tags="templateTags"
                    @tags-changed="(newTags) => (templateTags = newTags)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sf-modal-footer">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button class="sf-secondary" type="button" @click="cancelCreateTemplate">
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="sf-primary"
                    :disabled="loadingCreateTemplate"
                    @click="saveTemplate"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SfModal>

      <SfModal :title="'Embed HTML'" :width="480" closeOnOverlay :show.sync="showEmbedHTMLModal">
        <div class="sf-modal-body mb-3">
          <div class="container">
            <div class="row mt-3">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group">
                    <label class="primary-label">Website URL</label>
                  </div>
                  <input v-model="website_url" placeholder="URL" class="sf-input-text" />
                </div>
                <div v-if="urlError" class="error d-flex justify-content-end">
                  Please inter a valid URL
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sf-modal-footer">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button class="sf-secondary" type="button" @click="showEmbedHTMLModal = false">
                    Cancel
                  </button>
                  <button type="button" class="sf-primary" @click="handleAddWebsite">Add</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SfModal>

      <SfModal :title="'RSS URL'" :width="480" closeOnOverlay :show.sync="showAddRssModal">
        <div class="sf-modal-body mb-3">
          <div class="container">
            <div class="row mt-3">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group">
                    <label class="primary-label">RSS Url</label>
                  </div>
                  <input v-model="rssUrl" placeholder="RSS Url" class="sf-input-text" />
                </div>
                <div v-if="urlError" class="error d-flex justify-content-end">
                  Please inter a valid URL
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sf-modal-footer">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button
                    class="sf-secondary"
                    type="button"
                    @click=";(showAddRssModal = false), (loadingAddRss = false), (rssUrl = '')"
                  >
                    Cancel
                  </button>
                  <button
                    :disabled="loadingAddRss"
                    type="button"
                    @click="handleAddRss"
                    class="sf-primary"
                  >
                    <div v-if="loadingAddRss" class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <span v-else>Add</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SfModal>
      <SfModal :title="'Facebook'" :width="480" closeOnOverlay :show.sync="showAuthFbModal">
        <div class="sf-modal-body mb-3">
          <div class="container">
            <div class="row mt-3">
              <div class="col-12">
                <div class="d-flex w-100 justify-content-center my-5">
                  <button v-if="!fbLogin" class="sf-primary" @click="logInWithFacebook(false)">
                    Login with Facebook
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sf-modal-footer">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button class="sf-secondary" type="button" @click="showAuthFbModal = false">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SfModal>
      <SfModal :title="'Facebook Pages'" :width="480" closeOnOverlay :show.sync="showFbPagesModal">
        <div class="sf-modal-body mb-3">
          <div class="container">
            <div class="row mt-3">
              <b-overlay class="col-12" spinner-small :show="loadingGetIgMedia" rounded>
                <div
                  class="col-12"
                  v-for="(item, index) in igUserPages"
                  :key="index"
                  :aria-hidden="loadingGetIgMedia ? 'true' : null"
                >
                  <div class="sf-chip lg cursor-pointer">
                    <div
                      @click="getIgMediaObjects(item.id, item.name)"
                      class="d-flex flex-1 flex-column ml-2"
                    >
                      <label class="sf-primary-label cursor-pointer">{{ item.name }}</label>
                      <label class="sf-secondary-label cursor-pointer">{{ item.category }}</label>
                    </div>
                    <div class="d-flex align-items-center">
                      <a @click="getIgMediaObjects(item.id, item.name)">
                        <img
                          class="nav-block-icon"
                          src="@/assets/images/smart-assets/edit-pen-source.svg"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
        </div>

        <div class="sf-modal-footer">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button
                    class="sf-secondary"
                    type="button"
                    @click=";(showFbPagesModal = false), (loadingGetIgMedia = false)"
                  >
                    Cancel
                  </button>
                  <button class="sf-secondary" @click="logOutFacebook(false)">
                    Logout Facebook
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SfModal>
      <TemplateModal
        :title="'Template'"
        closeOnOverlay
        :show.sync="showTemplateModal"
        @update:selectedTemplate="useTemplateById($event)"
        :orientation="orientation"
      >
      </TemplateModal>

      <SourceModal :title="'Sources'" :width="768" closeOnOverlay :show.sync="showAddSourceModal">
        <div class="sf-modal-header">
          <div v-if="showSourcesAdded">
            <h3 class="title">Sources</h3>
          </div>
          <div class="d-flex w-100 position-relative" v-if="showAddNewSource">
            <a
              @click=";(showSourcesAdded = true), (showAddNewSource = false)"
              class="d-flex align-items-center"
              ><img class="img-sm" src="@/assets/images/previous-white.svg"
            /></a>
            <h3 class="title text-center w-100">Add New Source</h3>
          </div>
          <div class="d-flex w-100 position-relative" v-if="showDetailSource">
            <a
              @click="
                ;(showSourcesAdded = true), (showAddNewSource = false), (showDetailSource = false)
              "
              class="d-flex align-items-center"
              ><img class="img-sm" src="@/assets/images/previous-white.svg"
            /></a>
            <h3 class="title text-center w-100">Edit Source</h3>
          </div>

          <button class="close" @click="showAddSourceModal = false"></button>
        </div>
        <div v-click-inside="disabledDeleteKey" class="sf-modal-body modal-sources px-3 mb-3">
          <div v-if="showSourcesAdded" class="container">
            <template v-if="igList.length <= 0 && rssList.length <= 0 && websiteList.length <= 0">
              <div class="row mt-3">
                <div class="col-12">
                  <div class="text-center">
                    <p>Add a live data source to dynamically</p>
                    <p>populate content from the web</p>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <div class="d-flex justify-content-center">
                    <button
                      @click=";(showSourcesAdded = false), (showAddNewSource = true)"
                      type="button"
                      class="sf-secondary-add"
                    >
                      Add new
                    </button>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="row mt-4">
                <div class="col-7">
                  <button
                    @click=";(showSourcesAdded = false), (showAddNewSource = true)"
                    type="button"
                    class="sf-primary-add"
                  >
                    Add new source
                  </button>
                </div>
                <div class="col-5">
                  <SfInputSearch placeholder="Search" class="sf-input-search" />
                </div>
              </div>
              <div class="row mt-3">
                <template v-if="igList && igList.length">
                  <div class="col-12" v-for="(item, index) in igList" :key="index">
                    <div class="sf-chip lg cursor-pointer">
                      <div class="d-flex align-items-center">
                        <a @click="sourceDetailClick('instagram', item.id)">
                          <img class="nav-block-icon" :src="icon.instagram" />
                        </a>
                      </div>
                      <div
                        @click="sourceDetailClick('instagram', item.id)"
                        class="d-flex flex-1 flex-column ml-2"
                      >
                        <label class="sf-primary-label cursor-pointer">{{ item.name }}</label>
                        <label class="sf-secondary-label cursor-pointer"
                          >@{{ item.userName }}</label
                        >
                      </div>
                      <div class="d-flex align-items-center">
                        <!-- <a @click="deleteSource('instagram', item.userName)">
                            <img class="nav-block-icon" :src="icon.instagram">
                          </a> -->
                        <a @click="sourceDetailClick('instagram', item.id)">
                          <img
                            class="nav-block-icon"
                            src="@/assets/images/smart-assets/edit-pen-source.svg"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="rssList && rssList.length">
                  <div class="col-12" v-for="(item, index) in rssList" :key="index">
                    <div class="sf-chip lg cursor-pointer">
                      <div class="d-flex align-items-center">
                        <a @click="sourceDetailClick('rss', item.url)">
                          <img class="nav-block-icon" :src="icon.rss" />
                        </a>
                      </div>
                      <div
                        @click="sourceDetailClick('rss', item.url)"
                        class="d-flex flex-1 flex-column ml-2"
                      >
                        <label class="sf-primary-label cursor-pointer">{{ item.name }}</label>
                        <label class="sf-secondary-label cursor-pointer">@{{ item.url }}</label>
                      </div>
                      <div class="d-flex align-items-center">
                        <a @click="sourceDetailClick('rss', item.url)">
                          <img
                            class="nav-block-icon"
                            src="@/assets/images/smart-assets/edit-pen-source.svg"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="websiteList && websiteList.length">
                  <div class="col-12" v-for="(item, index) in websiteList" :key="index">
                    <div class="sf-chip lg cursor-pointer">
                      <div class="d-flex align-items-center">
                        <a @click="sourceDetailClick('website', item.website_url)">
                          <img class="nav-block-icon" :src="icon.rss" />
                        </a>
                      </div>
                      <div
                        @click="sourceDetailClick('website', item.website_url)"
                        class="d-flex flex-1 flex-column ml-2"
                      >
                        <label class="sf-primary-label cursor-pointer">{{ item.name }}</label>
                        <label class="sf-secondary-label cursor-pointer"
                          >@{{ item.website_url }}</label
                        >
                      </div>
                      <div class="d-flex align-items-center">
                        <a @click="sourceDetailClick('website', item.website_url)">
                          <img
                            class="nav-block-icon"
                            src="@/assets/images/smart-assets/edit-pen-source.svg"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>

          <div v-if="showAddNewSource" class="container">
            <div class="row mt-3">
              <div class="col-12 d-flex justify-content-center">
                <div class="col-10">
                  <SfInputSearch
                    placeholder="Search"
                    v-debounce:200ms="searchSources"
                    class="sf-input-search"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4" v-for="(item, index) in navSources" :key="index">
                <div @click="addSource(item.type)" class="sf-chip lg cursor-pointer">
                  <div class="d-flex flex-1 align-items-center">
                    <img class="nav-block-icon" :src="item.icon" />
                  </div>
                  <div class="d-flex flex-10 flex-column ml-2">
                    <label class="sf-primary-label cursor-pointer">{{ item.title }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="showDetailSource" class="container">
            <div
              v-if="sourceDetail && sourceDetail.id && showSourceType === 'instagram'"
              class="row mt-4"
            >
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">User Name</label>
                  </div>
                  <input readonly :value="sourceDetail.userName" class="sf-input-text" />
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">Source Name</label>
                  </div>
                  <input
                    @change="updateSourceName('instagram', sourceDetail)"
                    v-model="sourceDetail.name"
                    class="sf-input-text"
                  />
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">Duration</label>
                  </div>
                  <input v-model="duration" class="sf-input-text" />
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">Number of slides</label>
                  </div>
                  <input v-model="numberOfSlides" class="sf-input-text" />
                </div>
              </div>
            </div>
            <div
              v-if="sourceDetail && sourceDetail.url && showSourceType === 'rss'"
              class="row mt-4"
            >
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">Url</label>
                  </div>
                  <input readonly :value="sourceDetail.url" class="sf-input-text" />
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">Source Name</label>
                  </div>
                  <input
                    @change="updateSourceName('rss', sourceDetail)"
                    v-model="sourceDetail.name"
                    class="sf-input-text"
                  />
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">Duration</label>
                  </div>
                  <input
                    @change="updateSourceInfo('rss', sourceDetail, 'duration')"
                    v-model="sourceDetail.duration"
                    class="sf-input-text"
                  />
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">Number of slides</label>
                  </div>
                  <input
                    @change="updateSourceInfo('rss', sourceDetail, 'numberOfSlides')"
                    v-model="sourceDetail.numberOfSlides"
                    class="sf-input-text"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="sourceDetail && sourceDetail.website_url && showSourceType === 'website'"
              class="row mt-4"
            >
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">Url</label>
                  </div>
                  <input readonly :value="sourceDetail.website_url" class="sf-input-text" />
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">Source Name</label>
                  </div>
                  <input
                    @change="updateSourceName('website', sourceDetail)"
                    v-model="sourceDetail.name"
                    class="sf-input-text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sf-modal-footer">
          <div class="container">
            <div class="row">
              <div class="col-4 d-flex align-items-center">
                <button
                  v-if="showDetailSource"
                  class="sf-btn-delete"
                  type="button"
                  @click="deleteSource(showSourceType, sourceDetail)"
                >
                  Delete
                </button>
              </div>
              <div class="col-8">
                <div class="sf-modal-action">
                  <button class="sf-secondary" type="button" @click="showAddSourceModal = false">
                    Cancel
                  </button>
                  <button
                    v-if="showDetailSource"
                    class="sf-primary"
                    type="button"
                    @click="sourceSaveChangeClick"
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SfModal :title="'RSS URL'" :width="480" closeOnOverlay :show.sync="showAddNewRssModal">
          <div class="sf-modal-body mb-3">
            <div class="container">
              <div class="row mt-3">
                <div class="col-12">
                  <div class="sf-form-group">
                    <div class="sf-label-group sf-label-group">
                      <label class="primary-label">RSS Url</label>
                    </div>
                    <input v-model="rssUrl" placeholder="RSS Url" class="sf-input-text" />
                  </div>
                  <div v-if="urlError" class="error d-flex justify-content-end">
                    Please inter a valid URL
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sf-modal-footer">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="sf-modal-action">
                    <button
                      class="sf-secondary"
                      type="button"
                      @click=";(showAddNewRssModal = false), (loadingAddRss = false), (rssUrl = '')"
                    >
                      Cancel
                    </button>
                    <button
                      :disabled="loadingAddRss"
                      type="button"
                      @click="handleAddRss"
                      class="sf-primary"
                    >
                      <div v-if="loadingAddRss" class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <span v-else>Add</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SfModal>
        <SfModal
          :title="'Website URL'"
          :width="480"
          closeOnOverlay
          :show.sync="showAddNewWebsiteModal"
        >
          <div class="sf-modal-body mb-3">
            <div class="container">
              <div class="row mt-3">
                <div class="col-12">
                  <div class="sf-form-group">
                    <div class="sf-label-group sf-label-group">
                      <label class="primary-label">Website Url</label>
                    </div>
                    <input v-model="website_url" placeholder="Website Url" class="sf-input-text" />
                  </div>
                  <div v-if="urlError" class="error d-flex justify-content-end">
                    Please inter a valid URL
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sf-modal-footer">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="sf-modal-action">
                    <button
                      class="sf-secondary"
                      type="button"
                      @click="showAddNewWebsiteModal = false"
                    >
                      Cancel
                    </button>
                    <button type="button" @click="handleAddWebsite" class="sf-primary">Add</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SfModal>
        <SfModal :title="'Facebook'" :width="480" closeOnOverlay :show.sync="showNewAuthFbModal">
          <div class="sf-modal-body mb-3">
            <div class="container">
              <div class="row mt-3">
                <div class="col-12">
                  <div class="d-flex w-100 justify-content-center my-5">
                    <button v-if="!fbLogin" class="sf-primary" @click="logInWithFacebook(true)">
                      Login with Facebook
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="sf-modal-footer">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="sf-modal-action">
                    <button class="sf-secondary" type="button" @click="showNewAuthFbModal = false">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SfModal>
        <SfModal
          :title="'Facebook Pages'"
          :width="480"
          closeOnOverlay
          :show.sync="showNewFbPagesModal"
        >
          <div class="sf-modal-body mb-3">
            <div class="container">
              <div class="row mt-3">
                <b-overlay class="col-12" spinner-small :show="loadingGetIgMedia" rounded>
                  <div
                    class="col-12"
                    v-for="(item, index) in igUserPages"
                    :key="index"
                    :aria-hidden="loadingGetIgMedia ? 'true' : null"
                  >
                    <div class="sf-chip lg cursor-pointer">
                      <div
                        @click="getIgMediaObjects(item.id, item.name)"
                        class="d-flex flex-1 flex-column ml-2"
                      >
                        <label class="sf-primary-label cursor-pointer">{{ item.name }}</label>
                        <label class="sf-secondary-label cursor-pointer">{{ item.category }}</label>
                      </div>
                      <div class="d-flex align-items-center">
                        <a @click="getIgMediaObjects(item.id, item.name)">
                          <img
                            class="nav-block-icon"
                            src="@/assets/images/smart-assets/edit-pen-source.svg"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </b-overlay>
              </div>
            </div>
          </div>

          <div class="sf-modal-footer">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="sf-modal-action">
                    <button
                      class="sf-secondary"
                      type="button"
                      @click=";(showNewFbPagesModal = false), (loadingGetIgMedia = false)"
                    >
                      Cancel
                    </button>
                    <button class="sf-secondary" @click="logOutFacebook(true)">
                      Logout Facebook
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SfModal>
      </SourceModal>

      <SfModal :title="'QR code content'" :width="480" closeOnOverlay :show.sync="showAddQRModal">
        <div class="sf-modal-body mb-3">
          <div class="container">
            <div class="row mt-3">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group">
                    <label class="primary-label">URL</label>
                  </div>
                  <input v-model="contentQrCode" placeholder="URL" class="sf-input-text" />
                </div>
                <div
                  class="invalid-error-text text-right"
                  v-if="submitQrModal && !$v.contentQrCode.required"
                >
                  Please enter a URL.
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">Player Tracking</label>
                  </div>
                  <div class="w-100">
                    <toggle-button
                      :color="'#76c322'"
                      :value="qrPlayerTracking"
                      :sync="true"
                      @change="onChangeTogglePlayerTracking('', $event)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3 mb-3">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">Color</label>
                  </div>
                  <div class="w-100 d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                      <ColorPicker
                        :colorPickerType="'borderColor'"
                        :color="qrColor"
                        v-model="qrColor"
                      />
                      <span class="ml-2">Bar</span>
                    </div>
                    <div class="d-flex align-items-center">
                      <ColorPicker
                        :colorPickerType="'borderColor'"
                        :color="qrBackgroundColor"
                        v-model="qrBackgroundColor"
                      />
                      <span class="ml-2">Background</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="sf-modal-footer">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="sf-modal-action">
                    <button class="sf-secondary" type="button" @click="showAddQRModal = false">
                      Cancel
                    </button>
                    <button type="button" class="sf-primary" @click="addQrBlock">Add</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SfModal>

      <SfDeleteModal :title="'Unsaved Changes'" closeOnOverlay :show.sync="showConfirmCloseModal">
        <div class="p-5">
          <p class="pb-2">There are unsaved changes.</p>
          <p>Go back to save or close without saving.</p>
        </div>
        <div class="sf-modal-footer">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button
                    class="sf-secondary delete"
                    type="button"
                    @click="showConfirmCloseModal = false"
                  >
                    Back
                  </button>
                  <button type="button" class="sf-primary delete" @click="closeEditorModal">
                    Yes, close without saving
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SfDeleteModal>
      <!-- <div class="container" id="canvasContainer"></div> -->
    </div>
  </div>
</template>

<script>
import { IG_CLIENT_ID, SITE_DOMAIN } from '@/.env.js'
import SfInputSearch from '@/components/SfInputSearch.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import { mathRound10 } from '@/helper/mathHelper'
import { fonts } from '@/constant/font'
import ColorPicker from './ColorPicker.vue'
import SourceModal from './SourceModal.vue'
import Select2 from 'v-select2-component'
import {
  isValidImage,
  isValidSvg,
  isValidGif,
  isValidVideo,
  isValidFont
} from '@/helper/imageHelper'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import CustomFontService from '@/services/custom-font.service'
import SmartAssetsService from '@/services/smart-assets.service'
import SmartAssetsTemplateService from '@/services/smart-asset-template.service'
import AdminSmartAssetsTemplateService from '@/services/admin-smart-asset-template.service'
import SfModal from '@/components/SfModal.vue'
import TemplateModal from './TemplateModal.vue'

import VueTagsInput from '@johmun/vue-tags-input'
import Multiselect from 'vue-multiselect'
import { required, minValue, minLength } from 'vuelidate/lib/validators'
import imgIcon from '@/assets/images/smart-assets/img-icon.svg'
import textIcon from '@/assets/images/smart-assets/text-style.svg'
import rssIcon from '@/assets/images/smart-assets/rss-icon.svg'
import websiteIcon from '@/assets/images/smart-assets/network-browser.svg'
import colorPaletteIcon from '@/assets/images/smart-assets/color-palette.svg'
import resetIcon from '@/assets/images/smart-assets/reset.svg'
import instagramThumb from '@/assets/images/smart-assets/instagram-icon.svg'
import twitterThumb from '@/assets/images/smart-assets/twitter-icon.png'
import instagramIcon from '@/assets/images/smart-assets/social-instagram.svg'
import svgIcon from '@/assets/images/smart-assets/image-file-svg.svg'
import gifIcon from '@/assets/images/smart-assets/picture-stack-landscape.svg'
import qrIcon from '@/assets/images/smart-assets/qr-code-scan.svg'
import videoIcon from '@/assets/images/smart-assets/video-player.svg'
import paragraphIcon from '@/assets/images/smart-assets/paragraph-normal.svg'
import shapeIcon from '@/assets/images/smart-assets/shape-icon.svg'
import clockIcon from '@/assets/images/smart-assets/clock-icon.png'
import find from 'lodash/find'
import concat from 'lodash/concat'
import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'
import set from 'lodash/set'
import isEmpty from 'lodash/isEmpty'

import {
  SMART_ASSET_TYPE_TEXT,
  SMART_ASSET_TYPE_PARAGRAPH,
  SMART_ASSET_TYPE_DATE_TIME,
  FONT_SIZE_DATE_TIME_DEFAULT,
  WIDTH_12H_DATE_TIME_DEFAULT,
  WIDTH_24H_DATE_TIME_DEFAULT,
  HEIGHT_DATE_TIME_DEFAULT,
  FONT_SIZE_OPTIONS,
  FONT_SIZE_LARGE_OPTIONS
} from '@/constant'
import DateTimeBlock from './DateTimeBlock.vue'
import moment from 'moment-timezone'

const QRCode = require('qrcode')
export default {
  components: {
    SfInputSearch,
    Multiselect,
    SfDeleteModal,
    ColorPicker,
    Select2,
    SfModal,
    VueTagsInput,
    SourceModal,
    TemplateModal,
    DateTimeBlock
  },
  props: {
    editData: {
      type: Object
    },
    isAdminTemplate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      nodeEnv: process.env.NODE_ENV,
      backgroundOptions: [
        {
          id: 'useColor',
          title: 'Use a default background color',
          img: colorPaletteIcon,
          placeholder: 'Background'
        },
        { id: 'useImage', title: 'Use an image', img: imgIcon, placeholder: 'Background' },
        { id: 'reset', title: 'Reset', img: resetIcon }
      ],
      icon: {
        instagramThumb: instagramThumb,
        twitterThumb: twitterThumb,
        instagram: instagramIcon,
        image: imgIcon,
        text: textIcon,
        rss: rssIcon,
        svg: svgIcon,
        gif: gifIcon,
        website: websiteIcon,
        paragraph: paragraphIcon,
        qr: qrIcon,
        video: videoIcon,
        shape: shapeIcon,
        datetime: clockIcon
      },
      navSources: [],
      navBlocks: [],
      fonts: fonts,
      selectBackgroundValue: null,
      showConfirmCloseModal: false,
      showEmbedHTMLModal: false,
      showAddRssModal: false,
      showAddSourceModal: false,
      showSourcesAdded: true,
      showAddNewSource: false,
      showDetailSource: false,
      showAddNewRssModal: false,
      showAddNewWebsiteModal: false,
      showAuthFbModal: false,
      showFbPagesModal: false,
      showNewAuthFbModal: false,
      showNewFbPagesModal: false,
      showSourceType: '',
      expandCard: {
        position: false,
        textStyle: false,
        mention: false,
        padding: false,
        setting: false,
        shapeStyle: false
      },
      activeSource: {
        instagram: true,
        rss: false
      },
      website_url: '',
      rssUrl: '',
      dragBlockType: '',
      urlError: false,
      aspectRatio: false,
      expandTextBackground: false,
      expandTextShadow: false,
      isPortrait: false,
      loading: false,
      loadingCreateTemplate: false,
      loadingUpdateTemplate: false,
      isLoadingTemplate: false,
      loadingAddRss: false,
      activeAddBlock: true,
      activeEditBlock: false,
      activeBlockIndex: 0,
      activeBlockId: 0,
      totalBlock: 0,
      showCreateTemplateModal: false,
      showTemplateModal: false,
      templateName: '',
      templateDescription: '',
      templateTags: [],
      instagramOauth: false,
      instagramTemp: null,
      sourceDetail: null,
      rssDetail: null,
      igList: [],
      igListDeleted: [],
      rssList: [],
      rssListDeleted: [],
      websiteList: [],
      websiteListDeleted: [],
      selectedIg: null,
      selectedRss: null,
      selectedWebsite: null,
      addSourceType: '',
      rssActiveBlockData: null,
      igIndex: 1,
      rssIndex: 1,
      numberOfSlides: 5,
      duration: 10,
      assetToken: '',
      igCaptionSettingTab: 'mentions',
      rssData: [],
      addRssType: '',
      numberRssPost: 5,
      rssDuration: 10,
      thumbnail: null,
      orientation: '',
      dragPosition: {
        x: 0,
        y: 0
      },
      smartAsset: {
        showGrid: false,
        snapToGrid: false,
        grid: ['1', '1'],
        backgroundImage: {
          id: '',
          is_destroy: false,
          parent_id: '',
          aspectRatio: false,
          url: '',
          dataImage: '',
          file: ''
        },
        backgroundColor: '#ffffff',
        textStyle: {
          color: '#223144',
          fontFamily: 'Roboto'
        },
        blocks: []
      },
      step: 0,
      smartAssetHistory: [],
      templateList: [],
      templateID: 0,
      smartAssetTemplateID: '',
      tag: '',
      showAddQRModal: false,
      contentQrCode: '',
      submitQrModal: false,
      submitTemplateModal: false,
      qrColor: '#000000ff',
      qrBackgroundColor: '#ffffffff',
      qrPlayerTracking: false,
      enableDeleteByKey: false,
      fbLogin: false,
      selectedTemplate: {},
      loadingGetIgMedia: false,
      templateJustCreatedData: null
    }
  },
  validations: {
    numberOfSlides: {
      required,
      minValue: minValue(0)
    },
    duration: {
      required,
      minValue: minValue(0)
    },
    numberRssPost: {
      required,
      minValue: minValue(0)
    },
    rssDuration: {
      required,
      minValue: minValue(0)
    },
    contentQrCode: {
      required
    },
    templateName: {
      required,
      minLength: minLength(2)
    }
  },
  created() {
    this.$store.dispatch('smartasseteditor/getListTimezone')
    if (this.smartAssetDataModal.orientation === 'portrait') {
      this.isPortrait = true
    }
  },
  mounted() {
    this.getCustomFont()
    this.navBlocks = this.createNavBlocks()
    this.navSources = this.createNavSources()
    this.smartAsset.backgroundColor = this.infoUser.default_settings.default_background_color
    this.smartAsset.textStyle.color = this.infoUser.default_settings.default_text_color
    this.smartAsset.textStyle.fontFamily = this.infoUser.default_settings.default_text_font
    this.selectBackgroundValue = { id: 'useImage', title: 'Use an image', img: imgIcon }

    if (this.editData && this.editData.id) {
      if (this.editData.background.url) {
        // this.selectBackgroundValue = { id: 'useImage', title: 'Use an image', img: imgIcon }
        this.smartAsset.backgroundImage.url = this.editData.background.url
      }
      const textBlocks = this.editData.blocks.filter((item) => item.type === 'text')
      if (textBlocks && textBlocks.length) {
        textBlocks.forEach((el) => {
          el.style.display = 'flex'
          el.style.flexDirection = 'column'
          el.displayText = el.text
        })
      }

      if (this.editData.sources && this.editData.sources.length > 0) {
        this.editData.sources.forEach((source) => {
          if (source.source_type === 'embed') {
            const website = {
              website_url: source.url,
              id: source.url,
              text: source.displayname,
              name: source.displayname,
              sourceId: source.id
            }
            this.websiteList.push(website)
            this.selectedWebsite = this.website_url
            this.webData = website
          }
          if (source.source_type === 'rss') {
            const rss = {
              url: source.url,
              id: source.url,
              text: source.displayname,
              name: source.displayname,
              sourceId: source.id
            }
            this.rssList.push(rss)
          }
          if (source.source_type === 'instagram') {
            const ig = {
              id: source.url,
              text: source.displayname,
              name: source.displayname,
              sourceId: source.id
            }
            this.igList.push(ig)
          }
        })
      }
      const igBlocks = this.editData.blocks.filter((item) => item.type === 'instagram')
      if (igBlocks && igBlocks.length) {
        igBlocks.forEach((el) => {
          this.initIgBlock(el)
        })
      }
      const rssBlock = this.editData.blocks.filter((item) => item.type === 'rss')
      if (rssBlock && rssBlock.length) {
        rssBlock.forEach((el) => {
          this.initRssBlock(el)
        })
      }
      this.editData.blocks = this.editData.blocks.map((block) => {
        block.block_id = block.id
        block.id = ''
        return block
      })
      this.smartAsset.backgroundImage.id = this.editData.background.id
      this.smartAsset.backgroundColor = this.editData.properties.background_color
      this.smartAsset.textStyle.color = this.editData.properties.color
      this.smartAsset.textStyle.fontFamily = this.editData.properties.fontFamily
      this.smartAsset.blocks = this.editData.blocks
      this.smartAsset.grid = this.editData.properties.grid.split(',')
      this.smartAsset.showGrid = this.editData.properties.show_grid === 'true'
      this.smartAsset.snapToGrid = this.editData.properties.snap_to_grid
      this.getAssetTemplates(this.editData.orientation)
      this.orientation = this.editData.orientation
    } else {
      this.getAssetTemplates(this.smartAssetDataModal.orientation)
      this.orientation = this.smartAssetDataModal.orientation
    }
    this.smartAssetHistory.push(this._.cloneDeep(this.smartAsset))
    this.onWindowKeyPress()

    this.loadFacebookSDK(document, 'script', 'facebook-jssdk').then(() => this.initFacebook())
  },
  computed: {
    ...mapState('library', {
      smartAssetDataModal: 'smartAssetDataModal',
      organizationId: 'organizationId'
    }),
    ...mapState('smartasseteditor', {
      fbLongLivedAccessToken: 'fbLongLivedAccessToken',
      igBusinessAccountId: 'igBusinessAccountId',
      igUserPages: 'igUserPages'
    }),
    ...mapState('user', {
      infoUser: 'infoUser'
    }),
    ...mapGetters('smartasseteditor', {
      listTimezoneOptions: 'listTimezoneOptions'
    }),
    getTemplateList() {
      if (this.templateList.length) {
        return this.templateList.map((item) => {
          item.text = item.name
          return item
        })
      } else {
        return []
      }
    },
    getSmartAssetBlocks() {
      if (this.smartAsset.blocks && this.smartAsset.blocks.length) {
        return this.smartAsset.blocks
      } else {
        return []
      }
    },
    getPositionX() {
      if (this.smartAsset && this.smartAsset.blocks && this.smartAsset.blocks.length) {
        return this.smartAsset.blocks[this.activeBlockIndex].x
      } else {
        return 0
      }
    },
    getTotalBlocks() {
      if (this.smartAsset && this.smartAsset.blocks && this.smartAsset.blocks.length) {
        return this.smartAsset.blocks.length
      } else {
        return 0
      }
    },
    getPositionY() {
      if (this.smartAsset && this.smartAsset.blocks && this.smartAsset.blocks.length) {
        return this.smartAsset.blocks[this.activeBlockIndex].y
      } else {
        return 0
      }
    },
    activeBlock() {
      if (this.smartAsset && this.smartAsset.blocks && this.smartAsset.blocks.length) {
        return this.smartAsset.blocks[this.activeBlockIndex]
      } else {
        return null
      }
    },
    getBackgroundImage() {
      return this.smartAsset.backgroundImage || null
    },
    isLoading() {
      return this.loading || this.loadingCreateTemplate || this.loadingUpdateTemplate
    },
    contentAreaStyle() {
      let currentWidth = 1920
      let currentHeight = 1080
      if (this.isPortrait) {
        currentWidth = 1080
        currentHeight = 1920
      }
      return {
        height: this.isPortrait ? '962px' : '542px',
        backgroundColor: this.smartAsset.backgroundColor || 'rgb(255, 255, 255)',
        borderTop:
          this.activeEditBlock && this.activeBlock
            ? this.checkCanvasBorderTopLeftStyle(this.activeBlock.y, this.activeBlock.h)
            : 'none',
        borderRight:
          this.activeEditBlock && this.activeBlock
            ? this.checkCanvasBorderBottomRightStyle(
                this.activeBlock.x,
                this.activeBlock.w,
                currentWidth
              )
            : 'none',
        borderBottom:
          this.activeEditBlock && this.activeBlock
            ? this.checkCanvasBorderBottomRightStyle(
                this.activeBlock.y,
                this.activeBlock.h,
                currentHeight
              )
            : 'none',
        borderLeft:
          this.activeEditBlock && this.activeBlock
            ? this.checkCanvasBorderTopLeftStyle(this.activeBlock.x, this.activeBlock.w)
            : 'none'
      }
    },
    dateTimeType() {
      return SMART_ASSET_TYPE_DATE_TIME
    },
    fontSizeLargeOptions() {
      return FONT_SIZE_LARGE_OPTIONS
    },
    fontSizeOptions() {
      return FONT_SIZE_OPTIONS
    },
    widthDateTimeDefault() {
      if (isEmpty(this.activeBlock) || this.activeBlock.type !== SMART_ASSET_TYPE_DATE_TIME)
        return WIDTH_24H_DATE_TIME_DEFAULT
      return this.activeBlock.hour12 === 'true'
        ? WIDTH_12H_DATE_TIME_DEFAULT
        : WIDTH_24H_DATE_TIME_DEFAULT +
            parseInt(this.activeBlock.paddingValue.left) +
            parseInt(this.activeBlock.paddingValue.right)
    }
  },
  watch: {
    'smartAsset.showGrid': function (value) {
      if (!value) {
        this.smartAsset.snapToGrid = false
      }
    },
    'smartAsset.backgroundColor': function (value) {
      if (value) {
        this.$emit('onUpdateBackgroundColor', this.smartAsset.backgroundColor)
      }
    },
    activeBlockIndex: function (val) {
      // TO DO
      if (this.activeBlock && this.activeBlock.type === 'text') {
        this.$emit('onUpdateTextColor', this.activeBlock.textStyle.color)
        this.$emit('onUpdateTextBgColor', this.activeBlock.style.backgroundColor)
        this.$emit('onUpdateTextShadowColor', this.activeBlock.textShadowValue.color)
        this.$emit('onUpdateBorderColor', this.activeBlock.borderValue.color)
      }
      if (this.activeBlock && this.activeBlock.type === 'igCaption') {
        this.$emit('onUpdateTextColor', this.activeBlock.textStyle.color)
        this.$emit('onUpdateTextBgColor', this.activeBlock.style.backgroundColor)
        this.$emit('onUpdateTextShadowColor', this.activeBlock.textShadowValue.color)
        this.$emit('onupdateIgCaptionColor', this.activeBlock.mentions.textColor)
        this.$emit('onUpdateHashtagsColor', this.activeBlock.hashtags.textColor)
        this.$emit('onUpdateBorderColor', this.activeBlock.borderValue.color)
      }
    },

    'activeBlock.x': function (val) {
      if (val) {
        this.activeBlock.x = Number(val)
      }
    },
    'activeBlock.y': function (val) {
      if (val) {
        this.activeBlock.y = Number(val)
      }
    },
    numberOfSlides: function (val) {
      if (val) {
        if (val < this.igIndex) {
          this.setDisplayImage()
        }
      }
    },
    numberRssPost: function (val) {
      if (val) {
        if (val < this.rssIndex) {
          this.setDisplayRssPost(this.rssIndex)
        }
      }
    },
    fbLongLivedAccessToken: {
      immediate: true,
      handler() {
        this.fbLogin = !!this.fbLongLivedAccessToken
      }
    },
    isLoading: {
      immediate: true,
      handler() {
        if (this.isLoading) {
          this.$toast.open({
            message: 'Please wait while your asset is done saving. Do not refresh the page.',
            type: 'info',
            duration: 6000,
            position: 'top-right'
          })
        }
      }
    },
    'activeBlock.hour12': function (val) {
      if (this.activeBlock && this.activeBlock.type === SMART_ASSET_TYPE_DATE_TIME) {
        this.calcWidthHeightDateTime(this.activeBlock.textStyle.fontSize)
      }
    }
  },

  methods: {
    ...mapActions('smartasseteditor', {
      getFbLongLivedAccessToken: 'getFbLongLivedAccessToken',
      getUserPage: 'getUserPage'
    }),
    ...mapMutations('library', {
      updateItem: 'SET_UPDATE_ITEM',
      updateFolder: 'SET_UPDATE_FOLDER'
    }),
    ...mapMutations('smartasseteditor', {
      setFbLongLivedAccessToken: 'SET_FB_LONG_LIVED_ACCESS_TOKEN',
      setIgBusinessAccountId: 'SET_INSTAGRAM_BUSINESS_ACCOUNT_ID'
    }),
    getInfoUser() {
      this.$store.dispatch('user/getCurrent')
    },
    preventUserClick() {
      document.querySelectorAll('*').forEach((el) => (el.style.pointerEvents = 'none'))
    },
    allowUserClick() {
      document.querySelectorAll('*').forEach((el) => (el.style.pointerEvents = ''))
    },
    mathRound10,
    createTextBlock(name, type, text, x, y) {
      const block = {
        id: '',
        blockId: this.totalBlock,
        is_destroy: false,
        parent_id: '',
        aspectRatio: false,
        x: x,
        y: y,
        w: 500,
        h: 100,
        type: type,
        text: text,
        name: name,
        zIndex: this.smartAsset.blocks.length + 1,
        style: {
          alignItems: type === SMART_ASSET_TYPE_PARAGRAPH ? 'center' : 'flex-start',
          justifyContent:
            type === 'rssTitle' ||
            type === SMART_ASSET_TYPE_TEXT ||
            name === 'Paragraph' ||
            type === SMART_ASSET_TYPE_TEXT
              ? 'flex-start'
              : 'center',
          backgroundColor: 'transparent',
          flexDirection: 'column'
        },
        textStyle: {
          textAlign: 'left',
          color: this.smartAsset.textStyle.color || '#223144',
          fontSize: type === 'rssDesc' ? '18px' : '22px',
          fontFamily: this.smartAsset.textStyle.fontFamily || 'Roboto',
          fontWeight: type === 'rssTitle' ? 'bold' : '400',
          lineHeight: '140%',
          letterSpacing: '0',
          textShadow: '0px 0px 0px transparent',
          padding: '10px 10px 10px 10px'
        },
        textShadowValue: {
          h: 0,
          v: 0,
          blur: 0,
          color: '#223144'
        },
        borderRadiusValue: {
          topLeft: 0,
          topRight: 0,
          bottomLeft: 0,
          bottomRight: 0
        },
        borderValue: {
          borderWidth: 0,
          color: '#223144'
        },
        paddingValue: {
          top: 10,
          left: 10,
          right: 10,
          bottom: 10
        },
        fontFamily: {
          id: 'Roboto',
          text: 'Roboto'
        }
      }
      block.ratio = block.w / block.h
      return block
    },
    createShapeBlock(name, type, x, y) {
      const block = {
        id: '',
        is_destroy: false,
        parent_id: '',
        aspectRatio: false,
        x: x,
        y: y,
        w: 300,
        h: 100,
        type: type,
        name: name,
        zIndex: this.smartAsset.blocks.length + 1,
        style: {
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#2196F3'
        },
        borderRadiusValue: {
          topLeft: 0,
          topRight: 0,
          bottomLeft: 0,
          bottomRight: 0
        },
        borderValue: {
          borderWidth: 0,
          color: '#223144'
        }
      }
      block.ratio = block.w / block.h
      return block
    },
    createDateTimeBlock(name, type, text, x, y) {
      const block = {
        id: '',
        blockId: this.totalBlock,
        is_destroy: false,
        parent_id: '',
        aspectRatio: false,
        x: x,
        y: y,
        w: this.widthDateTimeDefault,
        type: type,
        text: text,
        name: name,
        zIndex: this.smartAsset.blocks.length + 1,
        style: {
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: this.smartAsset.backgroundColor || 'transparent',
          flexDirection: 'column'
        },
        textStyle: {
          textAlign: 'center',
          color: this.smartAsset.textStyle.color || '#223144',
          fontSize: `${FONT_SIZE_DATE_TIME_DEFAULT}px`,
          fontFamily: this.smartAsset.textStyle.fontFamily || 'Roboto',
          fontWeight: '700',
          lineHeight: '100%',
          letterSpacing: '0',
          textShadow: '0px 0px 0px transparent',
          padding: '10px 10px 10px 10px'
        },
        textShadowValue: {
          h: 0,
          v: 0,
          blur: 0,
          color: '#223144'
        },
        borderRadiusValue: {
          topLeft: 0,
          topRight: 0,
          bottomLeft: 0,
          bottomRight: 0
        },
        paddingValue: {
          top: 10,
          left: 10,
          right: 10,
          bottom: 10
        },
        fontFamily: {
          id: 'Roboto',
          text: 'Roboto'
        }
      }
      block.ratio = block.w / block.h
      return block
    },
    initIgBlock(igData) {
      SmartAssetsService.getInstagramMedia(igData.ig_user_id)
        .then((res) => {
          this.assetToken = res.data.access_token
          this.instagramTemp = res.data
          this.instagramTemp.items = res.data.media.data
          this.instagramOauth = true
          this.setIgBusinessAccountId(igData.ig_user_id)
          igData.assetToken = igData.ig_access_token
          igData.children.forEach((el) => {
            el.block_id = el.id
            el.id = ''
            el.source = 'instagram'
            el.isChild = true
            el.userId = igData.ig_user_id
            el.assetToken = igData.ig_access_token
            if (el.type === 'text') {
              el.type = 'igCaption'
              el.instagramData = this.instagramTemp.items.map((item) => {
                item.captionRender = this.renderText(item.caption, el)
                return item
              })
              this.pushBlock(el)
            } else if (el.type === 'image') {
              el.type = 'igImage'
              el.instagramData = this.instagramTemp.items
              this.pushBlock(el)
            }
          })
          if (this.igList.length > 0) {
            const igIdx = this.igList.findIndex((item) => item.id === igData.ig_user_id)
            if (igIdx !== -1) {
              this.igList[igIdx].items = res.data.media.data
              this.igList[igIdx].token = res.data.access_token
              this.igList[igIdx].userName = res.data.username
              this.selectedIg = igData.ig_user_id
            }
          }
        })
        .catch((err) => {
          this.$toast.error(
            err.message ? err.message : 'Unable to load Instagram. Please refresh or try later.'
          )
        })
    },
    initRssBlock(rssData) {
      SmartAssetsService.getRssFromUrl(rssData.url)
        .then((res) => {
          rssData.rssUrl = rssData.url
          rssData.children.forEach((el) => {
            el.block_id = el.id
            el.id = ''
            el.source = 'rss'
            el.isChild = true
            el.rssUrl = rssData.url
            if (el.name === 'Rss Title') {
              el.type = 'rssTitle'
              el.rssData = res.data.items
              this.pushBlock(el)
            } else {
              el.type = 'rssDesc'
              el.rssData = res.data.items
              this.pushBlock(el)
            }
          })

          if (this.rssList.length > 0) {
            const rssIdx = this.rssList.findIndex((item) => item.id === rssData.url)
            if (rssIdx !== -1) {
              this.rssList[rssIdx].items = res.data.items
              this.selectedRss = rssData.url
              this.rssData = this.rssList[rssIdx]
              return
            }
          }
          const rss = {
            url: rssData.url,
            items: res.data.items,
            id: rssData.url,
            text: rssData.url
          }
          this.rssList.push(rss)
          this.selectedRss = rssData.url
          this.rssData = rss
        })
        .catch((err) => {
          this.$toast.error(
            err.message ? err.message : 'Unable to load RSS. Please refresh or try later.'
          )
        })
    },
    renderText(text, activeItem) {
      if (text) {
        const textRedered = text
          .replace(/#(\w+)/g, function (_, $1) {
            return `<span
                    class="tag"
                    style="color: ${
                      activeItem && activeItem.hashtags ? activeItem.hashtags.textColor : ''
                    };
                           font-family: ${
                             activeItem && activeItem.hashtags ? activeItem.hashtags.fontFamily : ''
                           };
                           font-weight: ${
                             activeItem && activeItem.hashtags ? activeItem.hashtags.fontWeight : ''
                           };
                           font-size: ${
                             activeItem && activeItem.hashtags ? activeItem.hashtags.fontSize : ''
                           };
                           ">#${$1}</span>`
          })
          .replace(/@(\w+)/g, function (_, $1) {
            return `<span
                    class="mention"
                    style="color: ${
                      activeItem && activeItem.mentions ? activeItem.mentions.textColor : ''
                    };
                           font-family: ${
                             activeItem && activeItem.mentions ? activeItem.mentions.fontFamily : ''
                           };
                           font-weight: ${
                             activeItem && activeItem.mentions ? activeItem.mentions.fontWeight : ''
                           };
                           font-size: ${
                             activeItem && activeItem.mentions ? activeItem.mentions.fontSize : ''
                           };
                           ">@${$1}</span>`
          })
        return textRedered
      }
    },

    updateIgCaption() {
      if (this.activeBlock.instagramData) {
        this.activeBlock.instagramData.forEach((item) => {
          item.captionRender = this.renderText(item.caption, this.activeBlock)
        })
      }
    },
    igCaptionChange(e, type, attr) {
      this.activeBlock[type][attr] = e
      this.updateIgCaption()
    },
    createImageBlock(name, url, w, h) {
      const block = {
        id: '',
        is_destroy: false,
        parent_id: '',
        aspectRatio: true,
        type: 'image',
        x: 0,
        y: 0,
        w: w,
        h: h,
        name: name,
        zIndex: this.smartAsset.blocks.length + 1,
        borderRadiusValue: {
          topLeft: 0,
          topRight: 0,
          bottomLeft: 0,
          bottomRight: 0
        },
        style: {
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          borderRadius: 0
        },
        imageStyle: {
          top: 0,
          left: 0,
          imageWidth: w,
          imageHeight: h
        },
        borderValue: {
          borderWidth: 0,
          color: '#223144'
        },
        dataImage: url
      }
      block.ratio = block.w / block.h
      return block
    },
    createVideoBlock(name, url, w, h) {
      const block = {
        id: '',
        is_destroy: false,
        parent_id: '',
        aspectRatio: true,
        type: 'video',
        x: 0,
        y: 0,
        w: w,
        h: h,
        name: name,
        zIndex: this.smartAsset.blocks.length + 1,
        loop_video: true,
        borderRadiusValue: {
          topLeft: 0,
          topRight: 0,
          bottomLeft: 0,
          bottomRight: 0
        },
        style: {
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          borderRadius: 0
        },
        imageStyle: {
          top: 0,
          left: 0,
          imageWidth: w,
          imageHeight: h
        },
        borderValue: {
          borderWidth: 0,
          color: '#223144'
        },
        dataVideo: url
      }
      block.ratio = block.w / block.h
      return block
    },
    createInstagramBlock() {
      const instagramBlocks = this.smartAsset.blocks.filter((item) => item.source === 'instagram')
      if (instagramBlocks && instagramBlocks.length > 0) {
        const igByUserName = instagramBlocks.reduce(function (r, i) {
          r[i.userName] = r[i.userName] || []
          r[i.userName].push(i)
          return r
        }, Object.create(null))
        for (const item in igByUserName) {
          const instagramBlock = {
            id: '',
            block_id: igByUserName[item][0].parent_id ? igByUserName[item][0].parent_id : '',
            is_destroy: false,
            parent_id: '',
            aspectRatio: false,
            type: 'instagram',
            name: 'Instagram',
            assetToken: igByUserName[item][0].token,
            userName: igByUserName[item][0].userName,
            userId: igByUserName[item][0].userId,
            children: igByUserName[item],
            duration: igByUserName[item][0].duration,
            numberOfSlides: igByUserName[item][0].numberOfSlides
          }
          this.pushBlock(instagramBlock)
        }
      }
    },

    createRssBlock() {
      const rssBlocks = this.smartAsset.blocks.filter((item) => item.source === 'rss')
      if (rssBlocks && rssBlocks.length > 0) {
        const rssByUrl = rssBlocks.reduce(function (r, i) {
          r[i.rssUrl] = r[i.rssUrl] || []
          r[i.rssUrl].push(i)
          return r
        }, Object.create(null))
        for (const item in rssByUrl) {
          const rssBlock = {
            id: '',
            block_id: rssByUrl[item][0].parent_id ? rssByUrl[item][0].parent_id : '',
            zIndex: 0,
            is_destroy: false,
            parent_id: '',
            aspectRatio: false,
            type: 'rss',
            name: 'Rss',
            rssUrl: rssByUrl[item][0].rssUrl,
            children: rssByUrl[item],
            duration: rssByUrl[item][0].duration,
            numberOfSlides: rssByUrl[item][0].numberOfSlides
          }
          this.pushBlock(rssBlock)
        }
      }
    },
    updateDragPositon(block, x, y) {
      const blockWidth = block.w
      const blockHeight = block.h
      const maxPositionX = 960 - blockWidth
      const maxPositionY = 540 - blockHeight
      if (x > maxPositionX) {
        block.x = maxPositionX
      }
      if (y > maxPositionY) {
        block.y = maxPositionY
      }
    },
    addTitleText() {
      const block = this.createTextBlock('Title', SMART_ASSET_TYPE_TEXT, '', 30, 50)
      block.displayText = ' '
      block.textStyle.fontSize = '36px'
      block.textStyle.fontWeight = 'bold'
      this.pushBlock(block)
    },
    addDateTime() {
      const block = this.createDateTimeBlock('DateTime', SMART_ASSET_TYPE_DATE_TIME, '', 30, 50)
      block.h = HEIGHT_DATE_TIME_DEFAULT
      block.hour12 = 'false'
      const timezoneName = this.findTimezoneName()
      block.timezone = timezoneName ? `${moment.tz.guess()}|${timezoneName}` : 'Etc/UTC|UTC'
      this.pushBlock(block)
    },
    findTimezoneName() {
      const timezoneStateList = get(this.$store, 'state.smartasseteditor.listTimezone', [])
      const timezoneData = timezoneStateList.find((tz) => tz.value === moment.tz.guess())
      return timezoneData ? timezoneData.name : null
    },
    dragAndAddTitleText(x, y) {
      const block = this.createTextBlock('Title', SMART_ASSET_TYPE_TEXT, '', x, y)
      block.displayText = ''
      block.textStyle.fontSize = '36px'
      block.textStyle.fontWeight = 'bold'
      this.updateDragPositon(block, x, y)
      this.pushBlock(block)
    },

    addParagraphText() {
      const defaultText = ''
      const block = this.createTextBlock('Paragraph', SMART_ASSET_TYPE_TEXT, defaultText, 30, 50)
      block.displayText = defaultText
      block.w = 500
      block.h = 200
      this.pushBlock(block)
    },

    dragAndAddParagraphText(x, y) {
      const defaultText = ''
      const block = this.createTextBlock('Paragraph', SMART_ASSET_TYPE_TEXT, defaultText, x, y)
      block.displayText = defaultText
      block.w = 500
      block.h = 200
      this.updateDragPositon(block, x, y)
      this.pushBlock(block)
    },
    deleteBlock() {
      if (this.smartAsset && this.smartAsset.blocks && this.smartAsset.blocks.length > 0) {
        const block = this.smartAsset.blocks[this.activeBlockIndex]
        if (block.id || block.block_id) {
          block.is_destroy = true
          if (this.activeBlockIndex > this.smartAsset.blocks.length) {
            this.activeBlockIndex = 0
          }
          this.pushToHistory()
        } else {
          this.smartAsset.blocks.splice(this.activeBlockIndex, 1)
          if (this.activeBlockIndex > this.smartAsset.blocks.length) {
            this.activeBlockIndex = 0
          }
          this.pushToHistory()
        }
        this.addBlock()
      }
    },
    cloneBlock() {
      const cloneBlock = this._.cloneDeep(this.smartAsset.blocks[this.activeBlockIndex])
      if (cloneBlock.id) {
        cloneBlock.block_id = cloneBlock.id
      }
      cloneBlock.id = ''
      cloneBlock.x = 10
      cloneBlock.y = 10
      cloneBlock.isCloned = true
      this.pushBlock(cloneBlock)
    },
    pushBlock(block) {
      this.activeDefaultPropertiesTab(block.type)
      this.smartAsset.blocks.push(block)
      this.activeBlockIndex = this.smartAsset.blocks.length - 1
      this.activeAddBlock = false
      this.activeEditBlock = true
      this.pushToHistory()
    },
    onActivated(index, item) {
      this.enableDeleteByKey = true
      this.activeBlockIndex = index
      this.activeAddBlock = false
      this.activeEditBlock = true
      this.activeDefaultPropertiesTab(item.type)
    },
    onWindowKeyPress() {
      window.addEventListener('keydown', (e) => {
        // Shift key down
        if (e.keyCode === 16) {
          this.activeBlock.aspectRatio = true
        }

        if (e.keyCode === 46 || e.keyCode === 8) {
          if (!(e.target.contentEditable === 'true')) {
            if (this.enableDeleteByKey) {
              this.deleteBlock()
            }
          }
        }
      })

      window.addEventListener('keyup', (e) => {
        // Shift key up
        if (e.keyCode === 16) {
          this.activeBlock.aspectRatio = false
        }
      })
    },
    disabledDeleteKey() {
      this.enableDeleteByKey = false
    },
    lockAspectRatio() {
      return this.aspectRatio || this.activeBlock.aspectRatio
    },
    onDragging(x, y) {
      if (this.activeBlock) {
        this.activeBlock.x = x
        this.activeBlock.y = y
      }
    },
    onDragstop(x, y) {
      if (this.activeBlock) {
        if (this.activeBlock.x !== x || this.activeBlock.y !== y) {
          this.activeBlock.x = x
          this.activeBlock.y = y
          this.editBlock()
          this.pushToHistory()
        }
      }
    },
    onResize(x, y, w, h) {
      if (this.activeBlock) {
        // calc font size
        if (this.activeBlock.type === SMART_ASSET_TYPE_DATE_TIME) {
          const fontSize = parseInt(this.activeBlock.textStyle.fontSize, 10)
          const minHeight = (fontSize * HEIGHT_DATE_TIME_DEFAULT) / FONT_SIZE_DATE_TIME_DEFAULT
          const minWidth = (fontSize * this.widthDateTimeDefault) / FONT_SIZE_DATE_TIME_DEFAULT
          if (h < minHeight) {
            this.activeBlock.textStyle.fontSize = `${
              (FONT_SIZE_DATE_TIME_DEFAULT * h) / HEIGHT_DATE_TIME_DEFAULT
            }px`
          } else if (w < minWidth) {
            this.activeBlock.textStyle.fontSize = `${
              (FONT_SIZE_DATE_TIME_DEFAULT * w) / this.widthDateTimeDefault
            }px`
          }
        }
        this.activeBlock.x = x
        this.activeBlock.y = y
        this.activeBlock.w = parseInt(w.toFixed(0))
        this.activeBlock.h = parseInt(h.toFixed(0))
      }
    },
    onResizstop(x, y, w, h) {
      if (this.activeBlock) {
        this.activeBlock.x = x
        this.activeBlock.y = y
        this.activeBlock.w = parseInt(w.toFixed(0))
        this.activeBlock.h = parseInt(h.toFixed(0))
        this.editBlock()
        this.pushToHistory()
      }
    },
    checkCanvasBorderTopLeftStyle(position, wH) {
      return (position >= -2 && position <= 0) || (position <= -(wH - 2) && position >= -wH)
        ? '1px solid red'
        : null
    },
    checkCanvasBorderBottomRightStyle(position, wH, limit) {
      return 2 * (position + wH + 2) >= limit && 2 * (position + wH) <= limit + 2
        ? '1px solid red'
        : null
    },
    updateSizeInput(event, type) {
      if (!this.activeBlock.aspectRatio) {
        return
      }
      const value = event.target.value
      if (type === 'w') {
        this.activeBlock.h = parseInt(value) / this.activeBlock.ratio
        this.activeBlock.ratio = this.activeBlock.w / this.activeBlock.h
      } else {
        this.activeBlock.w = parseInt(value) * this.activeBlock.ratio
        this.activeBlock.ratio = this.activeBlock.w / this.activeBlock.h
      }
    },
    dragBlock(ev, type) {
      this.dragBlockType = type
    },
    dropBlock(e) {
      e.preventDefault()
      this.dragPosition.x = e.offsetX
      this.dragPosition.y = e.offsetY
      switch (this.dragBlockType) {
        case 'title':
          this.dragAndAddTitleText(e.offsetX, e.offsetY)
          break
        case 'paragraph':
          this.dragAndAddParagraphText(e.offsetX, e.offsetY)
          break
        case 'image':
          this.uploadImage()
          break
        case 'svg':
          this.uploadSvg()
          break
        case 'gif':
          this.uploadGif()
          break
        case 'video':
          this.uploadVideo()
          break
        case 'website':
          this.addWebsite()
          break
        case 'qrCode':
          this.addQrCode()
          break
        case 'igCaption':
          this.addIgCaption()
          break
        case 'igImage':
          this.addIgImage()
          break
        case 'rssTitle':
          this.addRssTitle()
          break
        case 'rssDesc':
          this.addRssDesc()
          break
        case 'shape':
          this.dragAndAddShape(e.offsetX, e.offsetY)
          break

        default:
          break
      }
    },
    allowDropBlock(ev) {
      ev.preventDefault()
    },
    editBlock() {
      this.activeAddBlock = false
      this.activeEditBlock = true
    },
    addBlock() {
      this.activeAddBlock = true
      this.activeEditBlock = false
      this.resetDefaultPropertiesTab()
      // this.navBlocks = this.createNavBlocks()
    },
    addSource(type) {
      switch (type) {
        case 'instagram':
          // this.addInstagram()
          if (this.fbLogin) {
            this.showNewFbPagesModal = true
          } else {
            this.showNewAuthFbModal = true
          }
          break
        case 'rss':
          this.showAddNewRssModal = true
          break
        case 'website':
          this.showAddNewWebsiteModal = true
          break

        default:
          break
      }
    },
    activeSourceClick(val) {
      for (const item in this.activeSource) {
        // compare
        if (item === val) {
          this.activeSource[item] = true
        } else {
          this.activeSource[item] = false
        }
      }
    },

    onSourcesClick() {
      this.showAddSourceModal = true
      this.showSourcesAdded = true
      this.showAddNewSource = false
      this.showDetailSource = false
    },
    backToSources() {
      this.showSourcesAdded = true
      this.showAddNewSource = false
      this.showDetailSource = false
    },
    rssActiveBlockChange(url) {
      const selectedRss = this.rssList.filter((item) => item.id === url)
      if (selectedRss[0].items && selectedRss[0].items.length > 0) {
        this.activeBlock.rssData = selectedRss[0].items
      } else {
        SmartAssetsService.getRssFromUrl(url).then((res) => {
          const rssIdx = this.rssList.findIndex((item) => item.id === url)
          if (rssIdx !== -1) {
            this.rssList[rssIdx].items = res.data.items
          }
          this.activeBlock.rssData = res.data.items
        })
      }
      this.activeBlock.rssUrl = url
    },
    webSiteActiveBlockChange(website_url) {
      const selectedWebsite = this.websiteList.filter((item) => item.id === website_url)
      this.activeBlock.website_url = selectedWebsite[0].website_url
    },
    igActiveBlockChange(userId) {
      const selectedIg = this.igList.filter((item) => item.id === userId)
      this.activeBlock.instagramData = selectedIg[0].items.map((item) => {
        item.captionRender = this.renderText(item.caption, this.activeBlock)
        return item
      })
      this.activeBlock.userId = selectedIg[0].id
      this.activeBlock.userName = selectedIg[0].userName
      this.activeBlock.token = selectedIg[0].token
    },
    addRssTitle() {
      if (this.rssList.length) {
        if (this.rssData) {
          this.addRss('rssTitle')
        }
      } else {
        this.addSourceType = 'rssTitle'
        this.showAddRssModal = true
        // this.onSourcesClick()
      }
    },
    addRssDesc() {
      if (this.rssList.length) {
        if (this.rssData) {
          this.addRss('rssDesc')
        }
      } else {
        this.addSourceType = 'rssDesc'
        this.showAddRssModal = true
        // this.onSourcesClick()
      }
    },
    addQrCode() {
      this.showAddQRModal = true
    },
    addRss(type) {
      const rssName = type === 'rssTitle' ? 'Rss Title' : 'Rss Description'
      const block = this.createTextBlock(rssName, type, '', 30, 50)
      block.source = 'rss'
      block.isChild = true
      block.rssUrl = this.rssData.url
      block.rssData = this.rssData.items
      block.duration = this.rssData.duration
      block.numberOfSlides = this.rssData.numberOfSlides
      if (this.dragPosition.x > 0 || this.dragPosition.y > 0) {
        block.x = this.dragPosition.x
        block.y = this.dragPosition.y
        this.updateDragPositon(block, this.dragPosition.x, this.dragPosition.y)
        this.dragPosition.x = 0
        this.dragPosition.y = 0
      }
      this.pushBlock(block)
    },

    handleAddRss() {
      this.loadingAddRss = true
      this.$toast.open({
        message: 'Please wait while your RSS is done saving. Do not refresh the page.',
        type: 'info',
        duration: 6000,
        position: 'top-right'
      })
      SmartAssetsService.getRssFromUrl(this.rssUrl)
        .then((res) => {
          if (res) {
            const rssExist = this.rssList.filter((item) => item.id === this.rssUrl)
            if (rssExist && rssExist.length) {
              return this.$toast.error('The rss url is being used.')
            } else {
              const rss = {
                url: this.rssUrl,
                items: res.data.items,
                id: this.rssUrl,
                text: 'RSS ' + (this.rssList.length + 1),
                name: 'RSS ' + (this.rssList.length + 1),
                duration: 10,
                numberOfSlides: 5
              }
              this.rssList.push(rss)
              this.selectedRss = this.rssUrl
              this.rssData = rss
            }
          }
          if (this.addSourceType) {
            this.showAddSourceModal = true
          }
          this.showAddRssModal = false
          this.showAddNewRssModal = false
          this.sourceDetailClick('rss', this.rssUrl)
          this.showDetailSource = true
          this.showSourcesAdded = false
          this.showAddNewSource = false
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'The URL is invalid. Please try again.')
        })
        .finally(() => {
          this.loadingAddRss = false
        })
    },
    handleAddWebsite() {
      const webExist = this.websiteList.filter((item) => item.id === this.website_url)
      if (webExist && webExist.length) {
        return this.$toast.error('The website url is being used.')
      } else {
        const website = {
          website_url: this.website_url,
          id: this.website_url,
          text: 'Website ' + (this.websiteList.length + 1),
          name: 'Website ' + (this.websiteList.length + 1)
        }
        this.websiteList.push(website)
        this.selectedWebsite = this.website_url
        this.webData = website
        if (this.addSourceType) {
          this.showAddSourceModal = true
        }
      }

      this.showAddNewWebsiteModal = false
      this.showEmbedHTMLModal = false
      this.sourceDetailClick('website', this.website_url)
      this.showDetailSource = true
      this.showSourcesAdded = false
      this.showAddNewSource = false
    },
    addWebsite() {
      if (this.websiteList.length) {
        const block = {
          id: '',
          is_destroy: false,
          parent_id: '',
          aspectRatio: false,
          type: 'embed',
          x: 30,
          y: 50,
          w: 400,
          h: 400,
          website_url: this.webData.website_url,
          zIndex: this.smartAsset.blocks.length + 1,
          name: 'Embed website',
          borderRadiusValue: {
            topLeft: 0,
            topRight: 0,
            bottomLeft: 0,
            bottomRight: 0
          },
          borderValue: {
            borderWidth: 0,
            color: '#223144'
          },
          style: {
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            top: 0,
            left: 0,
            borderRadius: 0
          }
        }
        if (this.dragPosition.x > 0 || this.dragPosition.y > 0) {
          block.x = this.dragPosition.x
          block.y = this.dragPosition.y
          this.updateDragPositon(block, this.dragPosition.x, this.dragPosition.y)
          this.dragPosition.x = 0
          this.dragPosition.y = 0
        }
        block.ratio = block.w / block.h
        this.pushBlock(block)
      } else {
        this.addSourceType = 'website'
        this.showEmbedHTMLModal = true
      }
    },
    igSelectChange(e) {
      const currentIg = this.igList.filter((item) => item.id === e)
      this.instagramTemp = currentIg[0]
    },
    rssSelectChange(e) {
      const currentRss = this.rssList.filter((item) => item.id === e)
      this.rssData = currentRss[0]
    },
    addIgCaption() {
      if (this.igList.length) {
        if (this.instagramTemp) {
          const block = this.createIgCaptionBlock()
          if (this.dragPosition.x > 0 || this.dragPosition.y > 0) {
            block.x = this.dragPosition.x
            block.y = this.dragPosition.y
            this.updateDragPositon(block, this.dragPosition.x, this.dragPosition.y)
            this.dragPosition.x = 0
            this.dragPosition.y = 0
          }
          this.pushBlock(block)
        }
      } else {
        this.addSourceType = 'igCaption'
        // this.addInstagram()
        if (this.fbLogin) {
          this.showFbPagesModal = true
        } else {
          this.showAuthFbModal = true
        }
        // this.onSourcesClick()
      }
    },
    createIgCaptionBlock() {
      const caption = this.instagramTemp.items[0].caption ? this.instagramTemp.items[0].caption : ''
      const captionBlock = this.createTextBlock('Caption', 'igCaption', caption, 30, 50)
      captionBlock.source = 'instagram'
      captionBlock.isChild = true
      captionBlock.token = this.instagramTemp.token
      captionBlock.userId = this.instagramTemp.id
      captionBlock.userName = this.instagramTemp.userName
      captionBlock.duration = this.instagramTemp.duration
      captionBlock.numberOfSlides = this.instagramTemp.numberOfSlides
      captionBlock.mentions = {
        textColor: '#126CDB',
        fontSize: '22px',
        fontWeight: '400',
        fontFamily: 'Roboto'
      }
      captionBlock.hashtags = {
        textColor: '#126CDB',
        fontSize: '22px',
        fontWeight: '400',
        fontFamily: 'Roboto'
      }
      captionBlock.instagramData = this.instagramTemp.items.map((item) => {
        item.captionRender = this.renderText(item.caption, captionBlock)
        return item
      })
      return captionBlock
    },
    addIgImage() {
      if (this.igList.length) {
        if (this.instagramTemp) {
          // this.addInstagramBlock(this.instagramTemp, 'igImage')
          this.createIgImageBlock(this.dragPosition.x, this.dragPosition.y)
        }
      } else {
        this.addSourceType = 'igImage'
        // this.addInstagram()
        if (this.fbLogin) {
          this.showFbPagesModal = true
        } else {
          this.showAuthFbModal = true
        }
        // this.onSourcesClick()
      }
    },
    createIgImageBlock(x, y) {
      const instagramData = this.instagramTemp
      const firstIgImage = instagramData.items[0].media_url
      SmartAssetsService.resizeImage(firstIgImage).then((data) => {
        const imageblock = this.createImageBlock(
          'Instagram images',
          firstIgImage,
          data.width,
          data.height
        )
        imageblock.type = 'igImage'
        imageblock.source = 'instagram'
        imageblock.isChild = true
        imageblock.instagramData = instagramData.items
        imageblock.token = instagramData.token
        imageblock.userId = instagramData.id
        imageblock.userName = instagramData.userName
        imageblock.numberOfSlides = instagramData.numberOfSlides
        imageblock.duration = instagramData.duration
        imageblock.x = x
        imageblock.y = y
        this.pushBlock(imageblock)
      })
    },
    addIgDisplayImage() {
      if (!this.instagramOauth) {
        // this.addInstagram('displayIcon')
      }
    },
    igSlider(type) {
      if (type === 'next') {
        if (this.igIndex < this.numberOfSlides) {
          this.igIndex++
        } else {
          this.igIndex = 1
        }
        this.setDisplayImage()
      } else if (type === 'previous') {
        if (this.igIndex > 1) {
          this.igIndex--
        } else {
          this.igIndex = this.numberOfSlides
        }
        this.setDisplayImage()
      }
    },
    setDisplayImage() {
      const igImages = document.querySelectorAll('.ig-blocks')
      if (igImages && igImages.length) {
        igImages.forEach((e) => {
          e.style.display = 'none'
          if (Number(e.dataset.index) === this.igIndex) {
            e.style.display = 'block'
          }
        })
      }
    },

    rssSlider(type) {
      if (type === 'next') {
        if (this.rssIndex < this.numberRssPost) {
          this.rssIndex++
          this.setDisplayRssPost(this.rssIndex)
        } else {
          this.rssIndex = 1
          this.setDisplayRssPost(this.rssIndex)
        }
      } else if (type === 'previous') {
        if (this.rssIndex > 1) {
          this.rssIndex--
          this.setDisplayRssPost(this.rssIndex)
        } else {
          this.rssIndex = this.numberRssPost
          this.setDisplayRssPost(this.rssIndex)
        }
      }
    },
    setDisplayRssPost(index) {
      const posts = document.querySelectorAll('.rss-blocks')
      if (posts && posts.length) {
        posts.forEach((e) => {
          e.style.display = 'none'
          if (Number(e.dataset.index) === index) {
            e.style.display = 'block'
          }
        })
      }
    },
    addInstagram() {
      const instagramRedirectUri = SITE_DOMAIN + 'library'
      const popupWidth = 700
      const popupHeight = 500
      const popupLeft = (window.screen.width - popupWidth) / 2
      const popupTop = (window.screen.height - popupHeight) / 2
      this.popup = window.open(
        'https://api.instagram.com/oauth/authorize/?client_id=' +
          IG_CLIENT_ID +
          '&response_type=code&scope=user_profile,user_media' +
          '&redirect_uri=' +
          encodeURIComponent(instagramRedirectUri),
        '_blank',
        'width=' +
          popupWidth +
          ',height=' +
          popupHeight +
          ',left=' +
          popupLeft +
          ',top=' +
          popupTop +
          ''
      )
      var interval = setInterval(() => {
        try {
          if (this.popup.location.search) {
            clearInterval(interval)
            const search = this.popup.location.search
            const arraySearch = search.split('=')
            if (arraySearch && arraySearch.length > 1) {
              const code = arraySearch[1]
              const formData = new FormData()
              formData.append('code', code)
              formData.append('redirect_uri', instagramRedirectUri)
              SmartAssetsService.getInstagramData(formData).then((res) => {
                // this.addigImageBlock(instagramData.media_url)
                // this.addSourceCaptionBlock(instagramData.caption)
                if (res && res.id) {
                  const existIg = this.igList.filter((item) => item.id === res.userId)
                  if (existIg && existIg.length) {
                    return this.$toast.error('This instagram account has been added')
                  } else {
                    const ig = {
                      token: res.access_token,
                      items: res.media.data,
                      id: res.id,
                      text: 'Instagram ' + (this.igList.length + 1),
                      userName: res.username,
                      name: 'Instagram ' + (this.igList.length + 1),
                      duration: 10,
                      numberOfSlides: 5
                    }
                    this.instagramTemp = ig
                    this.igList.push(ig)
                    this.instagramOauth = true
                    this.selectedIg = res.userId
                  }
                }
                if (this.addSourceType) {
                  this.showAddSourceModal = true
                }
                this.sourceDetailClick('instagram', res.username)
                this.showDetailSource = true
                this.showSourcesAdded = false
                this.showAddNewSource = false
              })
            }
            this.popup.close()
          }
        } catch (err) {}
      }, 100)
    },
    sourceSaveChangeClick() {
      if (this.addSourceType === 'igCaption') {
        this.addIgCaption()
      } else if (this.addSourceType === 'igImage') {
        this.addIgImage()
      } else if (this.addSourceType === 'rssTitle') {
        this.addRssTitle()
      } else if (this.addSourceType === 'rssDesc') {
        this.addRssDesc()
      } else if (this.addSourceType === 'website') {
        this.addWebsite()
      }
      this.addSourceType = ''
      this.showAddSourceModal = false
    },

    sourceDetailClick(type, value) {
      this.showSourceType = type
      this.showDetailSource = true
      this.showSourcesAdded = false
      switch (type) {
        case 'instagram':
          this.sourceDetail = this.igList.filter((item) => item.id === value)[0]
          break
        case 'rss':
          this.sourceDetail = this.rssList.filter((item) => item.url === value)[0]
          break
        case 'website':
          this.sourceDetail = this.websiteList.filter((item) => item.website_url === value)[0]
          break

        default:
          break
      }
    },
    deleteSource(type, value) {
      switch (type) {
        case 'instagram':
          // TODO
          // let igIndex = this.igList.findIndex(i => i.userName === value.userName)
          const igIndex = this.igList.findIndex((i) => i.id === value.id)
          const igDeleted = this.igList[igIndex]
          if (igDeleted.sourceId) {
            igDeleted.is_destroy = true
            this.igListDeleted.push(igDeleted)
          }
          this.igList.splice(igIndex, 1)
          break
        case 'rss':
          const rssIndex = this.rssList.findIndex((i) => i.url === value.url)
          const rssDeleted = this.rssList[rssIndex]
          if (rssDeleted.sourceId) {
            rssDeleted.is_destroy = true
            this.rssListDeleted.push(rssDeleted)
          }
          this.rssList.splice(rssIndex, 1)
          break
        case 'website':
          const websiteIndex = this.websiteList.findIndex(
            (i) => i.website_url === value.website_url
          )
          const webDeleted = this.websiteList[websiteIndex]
          if (webDeleted.sourceId) {
            webDeleted.is_destroy = true
            this.websiteListDeleted.push(webDeleted)
          }
          this.websiteList.splice(websiteIndex, 1)
          break

        default:
          break
      }
      this.showDetailSource = false
      this.showSourcesAdded = true
    },
    updateSourceName(type, sources) {
      switch (type) {
        case 'instagram':
          const igIndex = this.igList.findIndex((i) => i.id === sources.id)
          if (igIndex !== -1) {
            this.$set(this.igList, igIndex, {
              ...this.igList[igIndex],
              name: sources.name,
              text: sources.name
            })
          }
          break
        case 'rss':
          const rssIndex = this.rssList.findIndex((i) => i.url === sources.url)
          if (rssIndex !== -1) {
            this.$set(this.rssList, rssIndex, {
              ...this.rssList[rssIndex],
              name: sources.name,
              text: sources.name
            })
          }
          break
        case 'website':
          const websiteIndex = this.websiteList.findIndex(
            (i) => i.website_url === sources.website_url
          )
          if (websiteIndex !== -1) {
            this.$set(this.websiteList, websiteIndex, {
              ...this.websiteList[websiteIndex],
              name: sources.name,
              text: sources.name
            })
          }
          break
        default:
          break
      }
    },
    updateSourceInfo(type, sources, key) {
      switch (type) {
        case 'instagram':
          this.igList.forEach((item) => {
            if (item.userName === sources.userName) {
              item[key] = sources[key]
            }
          })
          break
        case 'rss':
          this.rssList.forEach((item) => {
            if (item.url === sources.url) {
              item[key] = sources[key]
            }
          })
          break
        case 'website':
          this.websiteList.forEach((item) => {
            if (item.website_url === sources.website_url) {
              item.name = sources.name
            }
          })
          break
        default:
          break
      }
    },
    addInstagramBlock(instagramData, type) {
      const instagram = instagramData.items[0]
      SmartAssetsService.resizeImage(instagram.media_url).then((data) => {
        const captionBlock = this.createTextBlock('Caption', 'igCaption', instagram.caption, 30, 50)
        captionBlock.source = 'instagram'
        captionBlock.isChild = true
        captionBlock.token = instagramData.token
        captionBlock.userId = instagramData.id
        captionBlock.userName = instagramData.userName
        captionBlock.duration = instagramData.duration
        captionBlock.numberOfSlides = instagramData.numberOfSlides
        captionBlock.mentions = {
          textColor: '#126CDB',
          fontSize: '22px',
          fontWeight: '400',
          fontFamily: 'Roboto'
        }
        captionBlock.hashtags = {
          textColor: '#126CDB',
          fontSize: '22px',
          fontWeight: '400',
          fontFamily: 'Roboto'
        }
        captionBlock.instagramData = instagramData.items.map((item) => {
          item.captionRender = this.renderText(item.caption, captionBlock)
          return item
        })
        const imageblock = this.createImageBlock(
          'Instagram images',
          instagram.media_url,
          data.width,
          data.height
        )
        imageblock.type = 'igImage'
        imageblock.source = 'instagram'
        imageblock.isChild = true
        imageblock.instagramData = instagramData.items
        imageblock.token = instagramData.token
        imageblock.userId = instagramData.id
        imageblock.userName = instagramData.userName
        imageblock.numberOfSlides = instagramData.numberOfSlides
        imageblock.duration = instagramData.duration
        if (type === 'igCaption') {
          this.pushBlock(captionBlock)
        } else if (type === 'igImage') {
          this.pushBlock(imageblock)
        } else {
          this.pushBlock(imageblock)
          this.pushBlock(captionBlock)
        }
      })
    },

    contenteditableInput(e) {
      if (this.activeBlock) {
        this.activeBlock.text = e.target.innerText
        this.pushToHistory()
      }
    },
    updateZIndex(type) {
      if (type === 'up') {
        if (this.activeBlock.zIndex >= this.smartAsset.blocks.length) {
          this.activeBlock.zIndex = this.smartAsset.blocks.length
          return
        }
        this.activeBlock.zIndex++
      } else {
        if (this.activeBlock.zIndex <= 1) {
          this.activeBlock.zIndex = 1
          return
        }
        this.activeBlock.zIndex--
      }
      let no = 1
      this.smartAsset.blocks
        .filter((item) => item !== this.activeBlock)
        .sort((a, b) => a.zIndex - b.zIndex)
        .forEach((el, index) => {
          if (this.activeBlock.zIndex === no) {
            no++
          }
          el.zIndex = no
          no++
        })
    },
    updateStyle(attr, value) {
      if (this.activeBlock) {
        if (
          (this.activeBlock.type === SMART_ASSET_TYPE_TEXT ||
            this.activeBlock.type === SMART_ASSET_TYPE_PARAGRAPH) &&
          (attr === 'justifyContent' || attr === 'alignItems')
        ) {
          this.activeBlock.style.display = 'flex'
          this.activeBlock.style[attr] = value
        } else {
          this.activeBlock.style[attr] = value
        }
        this.pushToHistory()
      }
    },
    updateBlockPositon(attr, value) {
      if (this.activeBlock) {
        this.activeBlock[attr] = value
        this.pushToHistory()
      }
    },
    getFlexDirection(direction) {
      return direction === 'left' ? 'flex-start' : direction === 'right' ? 'flex-end' : 'center'
    },
    updateTextStyle(attr, value) {
      if (this.activeBlock) {
        if (this.activeBlock.type === SMART_ASSET_TYPE_DATE_TIME && attr === 'textAlign') {
          this.activeBlock.textStyle.textAlign = this.getFlexDirection(value)
        } else if (
          (this.activeBlock.type === SMART_ASSET_TYPE_TEXT ||
            this.activeBlock.type === SMART_ASSET_TYPE_PARAGRAPH) &&
          attr === 'textAlign'
        ) {
          this.activeBlock.style.alignItems = this.getFlexDirection(value)
          this.activeBlock.style.display = 'flex'
        } else {
          this.activeBlock.textStyle[attr] = value
        }

        this.pushToHistory()
      }
    },
    updateTextShadow() {
      if (this.activeBlock && this.activeBlock.textShadowValue) {
        const { h, v, blur, color } = this.activeBlock.textShadowValue
        this.activeBlock.textStyle.textShadow = `${h}px ${v}px ${blur}px ${color}`
        this.pushToHistory()
      }
    },
    updateBorderRadius() {
      if (this.activeBlock && this.activeBlock.borderRadiusValue) {
        const { topLeft, topRight, bottomLeft, bottomRight } = this.activeBlock.borderRadiusValue
        this.activeBlock.style.borderRadius = `${topLeft}px ${topRight}px ${bottomRight}px ${bottomLeft}px`
        this.pushToHistory()
      }
    },
    updateBorder() {
      if (this.activeBlock && this.activeBlock.borderValue) {
        const { borderWidth, color } = this.activeBlock.borderValue
        this.activeBlock.style.border = `${borderWidth}px solid ${color}`
        this.pushToHistory()
      }
    },
    updateTextPadding() {
      if (this.activeBlock && this.activeBlock.paddingValue) {
        const { top, left, right, bottom } = this.activeBlock.paddingValue
        this.activeBlock.textStyle.padding = `${top}px ${right}px ${bottom}px ${left}px`
        this.pushToHistory()
      }
    },
    handleSnapToGrid(e) {
      const checked = e.target.checked
      if (checked) {
        this.smartAsset.blocks.forEach((element) => {
          element.w = this.mathRound10(element.w, 1)
          element.h = this.mathRound10(element.h, 1)
          element.x = this.mathRound10(element.x, 1)
          element.y = this.mathRound10(element.y, 1)
        })
        setTimeout(() => {
          this.smartAsset.grid = ['10', '10']
          this.smartAsset.showGrid = true
        }, 100)
      } else {
        this.smartAsset.grid = ['1', '1']
      }
    },
    changeTextColor(e) {
      this.updateTextStyle('color', e)
    },
    changeMentionsColor(color) {
      this.activeBlock.mentions.textColor = color
      this.updateIgCaption()
    },
    changeHashtagsColor(color) {
      this.activeBlock.hashtags.textColor = color
      this.updateIgCaption()
    },
    changeBgTextColor(e) {
      this.updateStyle('backgroundColor', e)
    },
    changeBgColorDateTime(e) {
      this.updateStyle('backgroundColor', e)
    },
    changeBackgroundColor(e) {
      this.removeBackground()
      this.smartAsset.backgroundColor = e
      this.pushToHistory()
    },
    changeDefaultTextColor(value, attr) {
      this.smartAsset.textStyle[attr] = value
    },
    removeBackground() {
      const backgroundImage = this._.cloneDeep(this.smartAsset.backgroundImage)
      backgroundImage.id = this.smartAsset.backgroundImage.id
      backgroundImage.is_destroy = true
      backgroundImage.url = ''
      backgroundImage.dataImage = ''
      backgroundImage.file = ''
      document.getElementById('uploadBackgroundInput').value = ''
      this.smartAsset.backgroundImage = backgroundImage
    },
    handleUploadChangeImage(e) {
      const input = e.target
      if (input.files && input.files[0]) {
        const fileName = input.files[0].name
        if (isValidImage(fileName)) {
          const reader = new FileReader()
          reader.onload = (e) => {
            const dataImage = e.target.result
            this.$set(this.smartAsset.blocks[this.activeBlockIndex], 'dataImage', dataImage)
            this.$set(this.smartAsset.blocks[this.activeBlockIndex], 'file', input.files[0])
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.$toast.error('Invaid image! please upload a png or jpg file')
        }
      }
    },
    handleUploadImage(e) {
      const input = e.target
      if (input.files && input.files[0]) {
        const fileName = input.files[0].name
        if (isValidImage(fileName)) {
          const reader = new FileReader()
          reader.onload = (e) => {
            const dataImage = e.target.result
            SmartAssetsService.resizeImage(dataImage).then((data) => {
              const imageblock = this.createImageBlock(
                'Simple Image',
                dataImage,
                data.width,
                data.height
              )
              imageblock.file = input.files[0]
              imageblock.x = this.dragPosition.x
              imageblock.y = this.dragPosition.y
              this.updateDragPositon(imageblock, this.dragPosition.x, this.dragPosition.y)
              this.dragPosition.x = 0
              this.dragPosition.y = 0
              this.pushBlock(imageblock)
            })
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.$toast.error('Invaid image! please upload a png or jpg file')
        }
      }
    },
    handleUploadChangeVideo(e) {
      const input = e.target
      const maxSize = 100 * 1024 * 1024
      if (input.files && input.files[0]) {
        if (input.files[0].size > maxSize) {
          this.$toast.error('Video files greater than 100 MB are not allowed.')
          return
        }
        const fileName = input.files[0].name
        if (isValidVideo(fileName)) {
          const reader = new FileReader()
          reader.onload = (e) => {
            const video = document.getElementById(`video-tag-${this.activeBlockIndex}`)
            video.pause()
            const source = document.getElementById(`video-source-${this.activeBlockIndex}`)
            const dataVideo = e.target.result
            this.$set(this.smartAsset.blocks[this.activeBlockIndex], 'dataVideo', dataVideo)
            this.$set(this.smartAsset.blocks[this.activeBlockIndex], 'file', input.files[0])

            source.src = dataVideo
            video.load()
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.$toast.error('Invaid file! please upload a mp4 or webm file')
        }
      }
    },
    handleUploadVideo(e) {
      const input = e.target
      const maxSize = 100 * 1024 * 1024
      if (input.files && input.files[0]) {
        if (input.files[0].size > maxSize) {
          this.$toast.error('Video files greater than 100 MB are not allowed.')
          return
        }
        const fileName = input.files[0].name
        if (isValidVideo(fileName)) {
          const reader = new FileReader()
          reader.onload = (e) => {
            const video = document.createElement('video')
            const url = URL.createObjectURL(input.files[0])
            video.onloadedmetadata = (evt) => {
              URL.revokeObjectURL(url)
              SmartAssetsService.resizeVideo(video).then((data) => {
                const dataImage = e.target.result
                const imageblock = this.createVideoBlock(
                  'Video',
                  dataImage,
                  data.width,
                  data.height
                )
                imageblock.file = input.files[0]
                imageblock.x = this.dragPosition.x
                imageblock.y = this.dragPosition.y
                this.updateDragPositon(imageblock, this.dragPosition.x, this.dragPosition.y)
                this.dragPosition.x = 0
                this.dragPosition.y = 0
                this.pushBlock(imageblock)
              })
            }
            video.src = url
            video.load()
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.$toast.error('Invaid file! please upload a mp4 or webm file')
        }
      }
    },
    handleUploadSvg(e) {
      const input = e.target
      if (input.files && input.files[0]) {
        const fileName = input.files[0].name
        if (isValidSvg(fileName)) {
          const reader = new FileReader()
          reader.onload = (e) => {
            const dataImage = e.target.result
            SmartAssetsService.resizeImage(dataImage).then((data) => {
              const imageblock = this.createImageBlock(
                'SVG Image',
                dataImage,
                data.width,
                data.height
              )
              imageblock.x = this.dragPosition.x
              imageblock.y = this.dragPosition.y
              this.updateDragPositon(imageblock, this.dragPosition.x, this.dragPosition.y)
              this.dragPosition.x = 0
              this.dragPosition.y = 0
              imageblock.file = input.files[0]
              this.pushBlock(imageblock)
            })
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.$toast.error('Invaid image! please upload a SVG file')
        }
      }
    },
    handleUploadChangeSvg(e) {
      const input = e.target
      if (input.files && input.files[0]) {
        const fileName = input.files[0].name
        if (isValidSvg(fileName)) {
          const reader = new FileReader()
          reader.onload = (e) => {
            const dataImage = e.target.result
            this.$set(this.smartAsset.blocks[this.activeBlockIndex], 'dataImage', dataImage)
            this.$set(this.smartAsset.blocks[this.activeBlockIndex], 'file', input.files[0])
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.$toast.error('Invaid image! please upload a SVG file')
        }
      }
    },
    handleUploadGif(e) {
      const input = e.target
      if (input.files && input.files[0]) {
        const fileName = input.files[0].name
        if (isValidGif(fileName)) {
          const reader = new FileReader()
          reader.onload = (e) => {
            const dataImage = e.target.result
            SmartAssetsService.resizeImage(dataImage).then((data) => {
              const imageblock = this.createImageBlock(
                'Gif Image',
                dataImage,
                data.width,
                data.height
              )
              imageblock.file = input.files[0]
              imageblock.x = this.dragPosition.x
              imageblock.y = this.dragPosition.y
              this.updateDragPositon(imageblock, this.dragPosition.x, this.dragPosition.y)
              this.dragPosition.x = 0
              this.dragPosition.y = 0
              this.pushBlock(imageblock)
            })
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.$toast.error('Invaid image! please upload a Gif file')
        }
      }
    },
    handleUploadChangeGif(e) {
      const input = e.target
      if (input.files && input.files[0]) {
        const fileName = input.files[0].name
        if (isValidGif(fileName)) {
          const reader = new FileReader()
          reader.onload = (e) => {
            const dataImage = e.target.result
            this.$set(this.smartAsset.blocks[this.activeBlockIndex], 'dataImage', dataImage)
            this.$set(this.smartAsset.blocks[this.activeBlockIndex], 'file', input.files[0])
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.$toast.error('Invaid image! please upload a Gif file')
        }
      }
    },
    clickUploadNewFont() {
      this.$refs.uploadNewFont.value = null
      this.$refs.uploadNewFont.click()
    },
    handleUploadNewFont(e) {
      const self = this
      const input = e.target
      if (input.files && input.files[0]) {
        const fileName = input.files[0].name
        if (isValidFont(fileName)) {
          const reader = new FileReader()
          reader.onload = (e) => {
            const dataFont = e.target.result
            const extension = fileName.split('.')
            const fontName = fileName.slice(0, -(extension[extension.length - 1].length + 1))

            const myFont = new FontFace(fontName, `url(${dataFont})`)
            myFont.load().then(function (font) {
              // with canvas, if this is ommited won't work
              document.fonts.add(font)
              const uploadFont = {
                id: fontName,
                text: fontName
              }
              if (!find(self.fonts, uploadFont)) {
                // self.fonts = orderBy(self.fonts, ['text'], ['asc'])

                const data = {
                  name: fontName,
                  file: input.files[0]
                }
                self.createCustomFont(data, uploadFont)
              } else {
                self.$toast.error('This font is already exist')
              }
            })
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.$toast.error('Invaid font! please upload a WOFF, TTF or OTF file')
        }
      }
    },
    handleClickChangeImage(blockName) {
      if (blockName === 'Gif Image') {
        this.$refs.gifChangeInput.click()
      } else if (blockName === 'SVG Image') {
        this.$refs.svgChangeInput.click()
      } else {
        this.$refs.imageChangeInput.click()
      }
    },
    uploadImage() {
      document.getElementById('imageInput').click()
    },
    uploadVideo() {
      document.getElementById('videoInput').click()
    },
    uploadSvg() {
      document.getElementById('svgInput').click()
    },
    uploadGif() {
      document.getElementById('gifInput').click()
    },
    embedHtmlClick() {
      this.showEmbedHTMLModal = true
    },
    addEmbedBlock() {
      if (this.validURL(this.website_url)) {
        const block = {
          id: '',
          is_destroy: false,
          parent_id: '',
          aspectRatio: false,
          type: 'embed',
          x: 30,
          y: 50,
          w: 400,
          h: 400,
          website_url: this.website_url,
          zIndex: this.smartAsset.blocks.length + 1,
          name: 'Embed website',
          borderRadiusValue: {
            topLeft: 0,
            topRight: 0,
            bottomLeft: 0,
            bottomRight: 0
          },
          borderValue: {
            borderWidth: 0,
            color: '#223144'
          },
          style: {
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            top: 0,
            left: 0,
            borderRadius: 0
          }
        }
        if (this.dragPosition.x > 0 || this.dragPosition.y > 0) {
          block.x = this.dragPosition.x
          block.y = this.dragPosition.y
          this.updateDragPositon(block, this.dragPosition.x, this.dragPosition.y)
          this.dragPosition.x = 0
          this.dragPosition.y = 0
        }
        block.ratio = block.w / block.h
        this.pushBlock(block)
        this.showEmbedHTMLModal = false
        this.website_url = ''
        this.urlError = false
      } else {
        this.urlError = true
      }
    },
    addShape() {
      const block = this.createShapeBlock('Shape', 'shape', 30, 50)
      this.pushBlock(block)
    },
    dragAndAddShape(x, y) {
      const block = this.createShapeBlock('Shape', 'shape', x, y)
      this.updateDragPositon(block, x, y)
      this.dragPosition.x = 0
      this.dragPosition.y = 0
      this.pushBlock(block)
    },
    validURL(str) {
      try {
        const url = new URL(str)
        return url.protocol === 'http:' || url.protocol === 'https:'
      } catch (_) {
        return false
      }
    },
    addQrBlock(e) {
      this.submitQrModal = true
      if (this.$v.contentQrCode.$invalid) {
        return
      }
      QRCode.toDataURL(
        this.contentQrCode,
        {
          color: {
            dark: this.qrColor,
            light: this.qrBackgroundColor
          }
        },
        (_, image_string) => {
          const block = {
            id: '',
            is_destroy: false,
            parent_id: '',
            aspectRatio: false,
            type: 'qr',
            x: 30,
            y: 50,
            w: 160,
            h: 160,
            image_string: image_string,
            zIndex: this.smartAsset.blocks.length + 1,
            color: this.qrColor,
            background_color: this.qrBackgroundColor,
            player_tracking: this.qrPlayerTracking,
            name: 'Qr Code',
            content: this.contentQrCode,
            borderRadiusValue: {
              topLeft: 0,
              topRight: 0,
              bottomLeft: 0,
              bottomRight: 0
            },
            borderValue: {
              borderWidth: 0,
              color: '#223144'
            },
            style: {
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'transparent',
              top: 0,
              left: 0,
              borderRadius: 0
            }
          }
          this.contentQrCode = ''
          this.qrColor = '#000000ff'
          this.qrBackgroundColor = '#ffffffff'
          this.qrPlayerTracking = false
          if (this.dragPosition.x > 0 || this.dragPosition.y > 0) {
            block.x = this.dragPosition.x
            block.y = this.dragPosition.y
            block.ratio = block.w / block.h
            this.updateDragPositon(block, this.dragPosition.x, this.dragPosition.y)
            this.dragPosition.x = 0
            this.dragPosition.y = 0
          }
          this.pushBlock(block)
          this.showAddQRModal = false
          this.submitQrModal = false
        }
      )
    },
    changeColorQrCode(event, type) {
      if (type === 'bars') {
        this.activeBlock.color = event
      } else {
        this.activeBlock.background_color = event
      }
      if (this.activeBlock.type === 'qr') {
        QRCode.toDataURL(
          this.activeBlock.content,
          {
            color: {
              dark: this.activeBlock.color,
              light: this.activeBlock.background_color
            }
          },
          (_, image_string) => {
            this.activeBlock.image_string = image_string
          }
        )
      }
    },
    onChangeTogglePlayerTracking(value = '', event) {
      if (value) {
        value.player_tracking = event.value
      } else {
        this.qrPlayerTracking = event.value
      }
    },
    onChangeToggleLoopVideo(value, event) {
      value.loop_video = event.value
    },
    handleUploadBackground(e) {
      const input = event.target
      if (input.files && input.files[0]) {
        const fileName = input.files[0].name
        if (isValidImage(fileName)) {
          const reader = new FileReader()
          reader.onload = (e) => {
            const dataImage = e.target.result
            const backgroundImage = {
              is_destroy: false,
              parent_id: '',
              aspectRatio: false,
              dataImage,
              file: input.files[0],
              id: ''
            }
            this.smartAsset.backgroundImage = backgroundImage
            this.smartAsset.backgroundImage.url = ''
            this.smartAsset.backgroundImage.id = ''
            this.smartAsset.backgroundColor = 'transparent'
            this.pushToHistory()
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.$toast.error('Invaid image! please upload a png or jpg file')
        }
      }
    },
    uploadBackground() {
      document.getElementById('uploadBackgroundInput').click()
    },
    // updateBackgroundColor () {
    //   document.getElementById('backgroundColorId').click()
    // },
    resetBackground() {
      this.removeBackground()
      this.smartAsset.backgroundColor = 'transparent'
      this.smartAsset.textStyle.color =
        this.infoUser.default_settings.default_text_color || '#223144'
      this.smartAsset.textStyle.fontFamily =
        this.infoUser.default_settings.default_text_font || 'Roboto'
      this.pushToHistory()
    },
    onSelectBackground(val) {
      const value = val
      switch (value.id) {
        case 'useImage':
          this.smartAsset.backgroundColor = 'transparent'
          break
        case 'useColor':
          this.removeBackground()
          this.smartAsset.backgroundColor = this.infoUser.default_settings.default_background_color
          break
        case 'reset':
          this.resetBackground()
          break
        default:
          break
      }
    },
    pushToHistory() {
      const smartAsset = this.smartAsset
      this.step++
      if (this.step < this.smartAssetHistory.length) {
        this.smartAssetHistory.length = this.step
      }
      let lastSmartAsset = ''
      if (this.smartAssetHistory.length) {
        lastSmartAsset = this.smartAssetHistory[this.smartAssetHistory.length - 1]
      }
      if (JSON.stringify(smartAsset) !== JSON.stringify(lastSmartAsset)) {
        this.smartAssetHistory.push(this._.cloneDeep(smartAsset))
      }
    },
    undo() {
      if (this.step > 0) {
        this.step = this.step - 1
        const strSmartAsset = this.smartAssetHistory[this.step]
        if (strSmartAsset) {
          this.smartAsset = this._.cloneDeep(strSmartAsset)
        }
      }
    },
    redo() {
      if (this.step < this.smartAssetHistory.length - 1) {
        this.step++
        const strSmartAsset = this.smartAssetHistory[this.step]
        if (strSmartAsset) {
          this.smartAsset = this._.cloneDeep(strSmartAsset)
        }
      }
    },
    getAssetTemplates(orientation) {
      SmartAssetsTemplateService.getAllTemplates('custom_asset', orientation)
        .then((response) => {
          // location.reload()
          this.templateList = response.data
          const defaultSelect = { id: 0, text: 'Select a template', name: 'Select a template' }
          this.templateList.unshift(defaultSelect)
          // this.$toast.open('New smart asset created!')
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    searchSources(val) {
      if (val) {
        this.navSources = this.createNavSources().filter((item) =>
          item.title.toLowerCase().includes(val.toLowerCase().trim())
        )
      } else {
        this.navSources = this.createNavSources()
      }
    },
    searchInputChange(val) {
      if (val) {
        const resultsByBlock = this.createNavBlocks()
          .map((el) => {
            const results = el.items.filter((item) =>
              item.title.toLowerCase().includes(val.toLowerCase().trim())
            )
            if (results && results.length) {
              el.items = results
              return el
            }
          })
          .filter((i) => i)
        if (resultsByBlock && resultsByBlock.length) {
          this.navBlocks = resultsByBlock
        } else {
          const resultsByBlockTitle = this.createNavBlocks().filter((item) =>
            item.name.toLowerCase().includes(val.toLowerCase().trim())
          )
          this.navBlocks = resultsByBlockTitle
        }
      } else {
        this.navBlocks = this.createNavBlocks()
      }
    },
    createNavSources() {
      return [
        {
          title: 'Instagram',
          type: 'instagram',
          icon: this.icon.instagram
        },
        {
          title: 'RSS',
          type: 'rss',
          icon: this.icon.rss
        },
        {
          title: 'Website',
          type: 'website',
          icon: this.icon.website
        }
      ]
    },
    createNavBlocks() {
      const blocks = [
        {
          name: 'Text',
          items: [
            {
              title: 'Title',
              type: 'title',
              eventClick: this.addTitleText,
              icon: this.icon.text
            },
            {
              title: 'Paragraph',
              type: 'paragraph',
              eventClick: this.addParagraphText,
              icon: this.icon.paragraph
            }
          ]
        },
        {
          name: 'Media',
          items: [
            {
              title: 'Image',
              type: 'image',
              eventClick: this.uploadImage,
              icon: this.icon.image
            },
            {
              title: 'SVG',
              type: 'svg',
              eventClick: this.uploadSvg,
              icon: this.icon.svg
            },
            {
              title: 'GIF',
              type: 'gif',
              eventClick: this.uploadGif,
              icon: this.icon.gif
            },
            {
              title: 'Video',
              type: 'video',
              eventClick: this.uploadVideo,
              icon: this.icon.video
            }
          ]
        },
        {
          name: 'Shape',
          items: [
            {
              title: 'Shape',
              type: 'shape',
              eventClick: this.addShape,
              icon: this.icon.shape
            }
          ]
        },
        {
          name: 'QR code',
          items: [
            {
              title: 'QR code',
              type: 'qrCode',
              eventClick: this.addQrCode,
              icon: this.icon.qr
            }
          ]
        },
        {
          name: 'Date Time',
          items: [
            {
              title: 'Date Time',
              type: this.dateTimeType,
              eventClick: this.addDateTime,
              icon: this.icon.datetime
            }
          ]
        }
      ]
      if (this.nodeEnv !== 'production') {
        blocks.push(
          {
            name: 'Embed',
            items: [
              {
                title: 'Website',
                type: 'website',
                eventClick: this.addWebsite,
                icon: this.icon.website
              }
            ]
          },
          {
            name: 'Instagram',
            items: [
              {
                title: 'Caption',
                type: 'igCaption',
                eventClick: this.addIgCaption,
                icon: this.icon.paragraph
              },
              {
                title: 'Image',
                type: 'igImage',
                eventClick: this.addIgImage,
                icon: this.icon.image
              }
            ]
          },
          {
            name: 'RSS (Beta)',
            items: [
              {
                title: 'RSS Title',
                type: 'rssTitle',
                eventClick: this.addRssTitle,
                icon: this.icon.text
              },
              {
                title: 'RSS Body',
                type: 'rssDesc',
                eventClick: this.addRssDesc,
                icon: this.icon.paragraph
              }
            ]
          }
        )
      }
      return blocks
    },
    contentAreaClickOuside(e) {
      const activeNodeList = document.querySelectorAll('.active.draggable.resizable')
      if (activeNodeList.length < 1) {
        this.disabledDeleteKey()
        this.activeAddBlock = true
        this.activeEditBlock = false
      }
    },
    contentAreaClick(e) {
      if (e.target.className === 'content-area') {
        const draggable = document.querySelectorAll('.draggable')
        if (draggable) {
          // this.activeAddBlock = true
          // this.activeEditBlock = false
          draggable.forEach((el) => {
            el.classList.remove('selected')
          })
        }
        // this.addBlock()
      }
    },
    resetDefaultPropertiesTab() {
      for (const i in this.expandCard) {
        this.expandCard[i] = false
      }
    },
    activeDefaultPropertiesTab(value) {
      if (['image'].includes(value)) {
        this.expandCard.position = true
      } else if (
        ['qr', 'video', 'embed', 'rssTitle', 'rssDesc', 'igCaption', 'igImage'].includes(value)
      ) {
        this.expandCard.setting = true
      } else if (['text'].includes(value)) {
        this.expandCard.textStyle = true
        this.expandCard.position = false
      } else if (['shape'].includes(value)) {
        this.expandCard.shapeStyle = true
        this.expandCard.position = false
      }
    },
    service() {
      if (this.isAdminTemplate) {
        return AdminSmartAssetsTemplateService
      } else {
        return SmartAssetsService
      }
    },
    createAsset() {
      this.loading = true
      this.preventUserClick()
      this.service()
        .create(this.createParam('create'))
        .then((response) => {
          this.$emit('create:success', response.data)
          this.closeEditorModal()
          this.$toast.open('New smart asset created!')
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
        .finally(() => {
          this.loading = false
          this.allowUserClick()
        })
    },
    updateAsset() {
      this.loading = true
      this.preventUserClick()
      this.$emit('edit:reset', this.editData)
      this.service()
        .editSmartAssetsByID(this.createParam('update'), this.editData.id)
        .then((response) => {
          this.updateItem({
            id: response.data.id,
            name: response.data.displayname,
            orientation: response.data.orientation,
            updatedAt: response.data.updated_at
          })
          this.updateFolder({ id: response.data.id, name: response.data.displayname })
          this.closeEditorModal()
          this.$toast.open('Asset successfully updated')
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
        .finally(() => {
          this.loading = false
          this.allowUserClick()
        })
    },
    showModalCreateTemplate() {
      this.showCreateTemplateModal = true
    },
    cancelCreateTemplate() {
      this.showCreateTemplateModal = false
      this.submitTemplateModal = false
      this.clearInfoCreateTemplate()
    },
    clearInfoCreateTemplate() {
      this.submitTemplateModal = false
      this.templateName = ''
      this.templateDescription = ''
      this.templateTags = []
    },
    saveTemplate() {
      this.submitTemplateModal = true
      if (this.$v.templateName.$invalid) {
        return
      }
      this.loadingCreateTemplate = true
      this.preventUserClick()
      let smartAssetContainer = document.querySelector('#smartAssetContainer')
      // this.smartAsset.blocks = this._.cloneDeep(this.smartAsset.blocks)
      this.html2canvas(smartAssetContainer, {
        onclone: (documentClone) => {
          smartAssetContainer = documentClone.querySelector('#smartAssetContainer')
          smartAssetContainer.style.display = 'block'
          if (smartAssetContainer.style.backgroundColor === 'transparent') {
            const backgroundImg = smartAssetContainer.querySelector('.smart-asset-bg-img')
            if (backgroundImg) {
              backgroundImg.style.backgroundColor = '#fff'
            }
          }
          const contentArea = documentClone.querySelector('#smartAssetContainer .content-area')
          if (
            (!contentArea.style.backgroundColor ||
              contentArea.style.backgroundColor === 'transparent') &&
            !this.smartAsset.backgroundImage.url &&
            !this.smartAsset.backgroundImage.dataImage
          )
            contentArea.style.backgroundColor = '#fff'
        },
        scale: 1,
        allowTaint: true,
        useCORS: true
      })
        .then((canvas) => {
          // let canvasContainer = document.getElementById('canvasContainer')
          // canvasContainer.appendChild(canvas)
          canvas.style.background = '#ffffff'
          canvas.toBlob((blob) => {
            const thumbnail = new File([blob], 'smart-asset.png', {
              type: 'image/png',
              lastModified: new Date()
            })
            this.thumbnail = thumbnail
            const newImg = document.createElement('img')
            const url = URL.createObjectURL(blob)
            newImg.onload = () => {
              URL.revokeObjectURL(url)
            }
            this.service()
              .create(this.createParam('draft'))
              .then((response) => {
                // location.reload()
                this.$toast.open('New smart asset template created!')
                this.isAdminTemplate && this.closeEditorModal()
                let templateTags = this.templateTags
                if (templateTags.length && templateTags[0].text) {
                  templateTags = this.templateTags.map((item) => item.text)
                }
                const smartAssetTemplate = get(response, 'data.smart_asset_template', null)
                if (smartAssetTemplate) {
                  this.formatBlockSmartAsset(response.data)
                  const dataFormat = {
                    data: {
                      description: smartAssetTemplate.description,
                      id: smartAssetTemplate.id,
                      name: smartAssetTemplate.name,
                      smart_asset: { ...response.data, blocks: this.smartAsset.blocks },
                      tags: smartAssetTemplate.tag_list
                    }
                  }
                  this.templateJustCreatedData = dataFormat
                }
                this.getAssetTemplates(this.smartAssetDataModal.orientation)
                this.clearInfoCreateTemplate()
              })
              .catch((err) => {
                this.$toast.error(
                  err.message ? err.message : 'Something went wrong. Please try again.'
                )
              })
              .finally(() => {
                // --- Testing: May cause issue
                // this.smartAsset.blocks = this.smartAsset.blocks.map(block => {
                //   block.id = block.block_id
                //   block.block_id = ''
                //   return block
                // })
                this.loadingCreateTemplate = false
                this.allowUserClick()
                this.addBlock()
              })
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          this.loadingCreateTemplate = false
        })
        .finally(() => {
          this.showCreateTemplateModal = false
        })
    },

    // saveTemplate () {
    //   SmartAssetsService.create(this.createParam('draft'))
    //     .then(response => {
    //       // location.reload()
    //       this.$toast.open('New smart asset template created!')
    //       this.getAssetTemplates(this.smartAssetDataModal.orientation)
    //       this.showCreateTemplateModal = false
    //     })
    //     .catch(err => {
    //       this.showCreateTemplateModal = false
    //       this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
    //     })
    // },
    updateTemplate() {
      const smartAssetClone = this._.cloneDeep(this.smartAsset)
      const textBlocks = smartAssetClone.blocks.filter((item) => item.type === 'text')
      if (textBlocks && textBlocks.length) {
        textBlocks.forEach((el) => {
          el.displayText = el.text
        })
      }
      set(this.templateJustCreatedData, 'data.smart_asset.blocks', smartAssetClone.blocks)
      const smartAssetTemplateIdClone = this._.cloneDeep(this.smartAssetTemplateID)
      const selectBackgroundValueClone = this._.cloneDeep(this.selectBackgroundValue)
      const selectedTemplateClone = this._.cloneDeep(this.selectedTemplate)
      if (!isEmpty(this.templateJustCreatedData)) {
        this.updateSmartAsset(this._.cloneDeep(this.templateJustCreatedData))
      }
      this.loadingUpdateTemplate = true
      this.preventUserClick()
      let smartAssetContainer = document.querySelector('#smartAssetContainer')
      // this.smartAsset.blocks = this._.cloneDeep(this.smartAsset.blocks)
      this.html2canvas(smartAssetContainer, {
        onclone: (documentClone) => {
          smartAssetContainer = documentClone.querySelector('#smartAssetContainer')
          smartAssetContainer.style.display = 'block'
          if (smartAssetContainer.style.backgroundColor === 'transparent') {
            const backgroundImg = smartAssetContainer.querySelector('.smart-asset-bg-img')
            if (backgroundImg) {
              backgroundImg.style.backgroundColor = '#fff'
            }
          }
          const contentArea = documentClone.querySelector('#smartAssetContainer .content-area')
          if (
            (!contentArea.style.backgroundColor ||
              contentArea.style.backgroundColor === 'transparent') &&
            !this.smartAsset.backgroundImage.url &&
            !this.smartAsset.backgroundImage.dataImage
          )
            contentArea.style.backgroundColor = '#fff'
        },
        scale: 1,
        allowTaint: true,
        useCORS: true
      })
        .then((canvas) => {
          // let canvasContainer = document.getElementById('canvasContainer')
          // canvasContainer.appendChild(canvas)
          canvas.style.background = '#ffffff'
          canvas.toBlob((blob) => {
            const thumbnail = new File([blob], 'smart-asset.png', {
              type: 'image/png',
              lastModified: new Date()
            })
            this.thumbnail = thumbnail
            const newImg = document.createElement('img')
            const url = URL.createObjectURL(blob)
            newImg.onload = () => {
              URL.revokeObjectURL(url)
            }

            const templateId = this.isAdminTemplate
              ? this.editData.templateId
              : this.selectedTemplate.id
            AdminSmartAssetsTemplateService.editSmartAssetsByID(
              this.createParam('update-draft'),
              templateId
            )
              .then((response) => {
                this.updateSmartAsset(response)
                this.getAssetTemplates(this.smartAssetDataModal.orientation)
                this.$toast.open('Template successfully updated')
                this.isAdminTemplate && this.closeEditorModal()

                // Sync with smart asset
                this.templateJustCreatedData = response

                // Update smart asset block from response
                const oldBlockId = this._.cloneDeep(smartAssetClone.blocks)[this.activeBlockIndex]
                  .id

                // If user is focusing on new block, the block id will be empty
                if (oldBlockId === '') {
                  this.addBlock()
                } else {
                  // Find new active block index in new smart asset blocks
                  const newActiveBlockIndex = response.data.smart_asset.blocks.findIndex(
                    (item) => item.id === oldBlockId
                  )
                  if (newActiveBlockIndex > -1) {
                    this.activeBlockIndex = newActiveBlockIndex
                  }
                }

                smartAssetClone.blocks = response.data.smart_asset.blocks
              })
              .catch((err) => {
                this.$toast.error(
                  err.message ? err.message : 'Something went wrong. Please try again.'
                )
              })
              .finally(() => {
                this.loadingUpdateTemplate = false
                this.allowUserClick()
                if (!isEmpty(this.templateJustCreatedData)) {
                  this.smartAsset = smartAssetClone
                  this.smartAssetTemplateID = smartAssetTemplateIdClone
                  this.selectBackgroundValue = selectBackgroundValueClone
                  this.selectedTemplate = selectedTemplateClone
                }
              })
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          this.loadingUpdateTemplate = false
        })
    },
    updateSmartAsset(res) {
      this.selectedTemplate = res.data
      const smartAsset = res.data.smart_asset
      this.smartAssetTemplateID = smartAsset.id

      this.smartAsset.backgroundImage.dataImage = ''
      this.smartAsset.backgroundImage.file = ''

      if (smartAsset.background.url) {
        this.selectBackgroundValue = { id: 'useImage', title: 'Use an image', img: imgIcon }
        this.smartAsset.backgroundImage.url = smartAsset.background.url
      } else if (smartAsset.properties.background_color !== 'transparent') {
        this.selectBackgroundValue = {
          id: 'useColor',
          title: 'Use a default background color',
          img: colorPaletteIcon,
          placeholder: 'Background'
        }
      } else {
        this.selectBackgroundValue = { id: 'useImage', title: 'Use an image', img: imgIcon }
      }
      const textBlocks = smartAsset.blocks.filter((item) => item.type === 'text')
      if (textBlocks && textBlocks.length) {
        textBlocks.forEach((el) => {
          el.displayText = el.text
        })
      }
      this.websiteList = []
      this.rssList = []
      this.igList = []
      if (smartAsset.sources && smartAsset.sources.length > 0) {
        smartAsset.sources.forEach((source) => {
          if (source.source_type === 'embed') {
            const website = {
              website_url: source.url,
              id: source.url,
              text: source.displayname,
              name: source.displayname,
              sourceId: source.id
            }
            this.websiteList.push(website)
            this.selectedWebsite = this.website_url
            this.webData = website
          }
          if (source.source_type === 'rss') {
            const rss = {
              url: source.url,
              id: source.url,
              text: source.displayname,
              name: source.displayname,
              sourceId: source.id
            }
            this.rssList.push(rss)
          }
          if (source.source_type === 'instagram') {
            const ig = {
              id: source.url,
              text: source.displayname,
              name: source.displayname,
              sourceId: source.id
            }
            this.igList.push(ig)
          }
        })
      }

      const igBlocks = smartAsset.blocks.filter((item) => item.type === 'instagram')
      if (igBlocks && igBlocks.length) {
        igBlocks.forEach((el) => {
          this.initIgBlock(el)
        })
      }
      const rssBlock = smartAsset.blocks.filter((item) => item.type === 'rss')
      if (rssBlock && rssBlock.length) {
        rssBlock.forEach((el) => {
          this.initRssBlock(el)
        })
      }
      const dateTimeBlock = smartAsset.blocks.filter((item) => item.type === 'datetime')
      if (dateTimeBlock && dateTimeBlock.length) {
        dateTimeBlock.forEach((el) => {
          el.timezone = el.timezoneName ? `${el.timezone}|${el.timezoneName}` : el.timezone
        })
      }
      this.smartAsset.backgroundImage.id = smartAsset.background.id
      this.smartAsset.backgroundImage.url = smartAsset.background.url
      this.smartAsset.backgroundColor = smartAsset.properties.background_color
      this.smartAsset.blocks = smartAsset.blocks.map((block) => {
        return {
          ...block,
          block_id: block.id || block.block_id,
          id: ''
        }
      })

      this.smartAsset.grid = smartAsset.properties.grid.split(',')
      this.smartAsset.showGrid = smartAsset.properties.show_grid === 'true'
      this.smartAsset.snapToGrid = smartAsset.properties.snap_to_grid
    },
    formatBlockSmartAsset(smartAsset) {
      const textBlocks = smartAsset.blocks.filter((item) => item.type === 'text')
      if (textBlocks && textBlocks.length) {
        textBlocks.forEach((el) => {
          el.displayText = el.text
        })
      }
      const igBlocks = smartAsset.blocks.filter((item) => item.type === 'instagram')
      if (igBlocks && igBlocks.length) {
        igBlocks.forEach((el) => {
          this.initIgBlock(el)
        })
      }
      const rssBlock = smartAsset.blocks.filter((item) => item.type === 'rss')
      if (rssBlock && rssBlock.length) {
        rssBlock.forEach((el) => {
          this.initRssBlock(el)
        })
      }
      const dateTimeBlock = smartAsset.blocks.filter((item) => item.type === 'datetime')
      if (dateTimeBlock && dateTimeBlock.length) {
        dateTimeBlock.forEach((el) => {
          el.timezone = el.timezoneName ? `${el.timezone}|${el.timezoneName}` : el.timezone
        })
      }
      this.smartAsset.blocks = smartAsset.blocks.map((block) => {
        return {
          ...block,
          block_id: block.id,
          id: ''
        }
      })
    },
    useTemplateById(val) {
      this.templateJustCreatedData = null
      this.isLoadingTemplate = true
      SmartAssetsTemplateService.getById(val)
        .then((jsonData) => {
          this.templateID = val
          this.updateSmartAsset(jsonData)
          this.showTemplateModal = false
        })
        .catch((err) => {
          this.showTemplateModal = false
          this.$toast.error(err.message ? err.message : 'Some things went wrong. Please try again')
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoadingTemplate = false
          }, 1500)
        })
    },
    onSelectTemplate(e) {
      const self = this
      if (e === '0') {
        self.smartAssetTemplateID = ''
        return
      }
      SmartAssetsTemplateService.getById(e)
        .then((jsonData) => {
          self.smartAssetTemplateID = jsonData.data.smart_asset.id
          self.smartAsset.backgroundImage.file = ''
          if (jsonData.data.smart_asset.background) {
            self.smartAsset.backgroundImage = jsonData.data.smart_asset.background
          } else {
            self.smartAsset.backgroundImage.is_destroy = true
          }
          if (this.editData.id) {
            self.smartAsset.blocks.map((block) => {
              block.is_destroy = true
            })
          } else {
            self.smartAsset.blocks = []
          }
          self.smartAsset.blocks = [
            ...jsonData.data.smart_asset.blocks.map((block) => {
              block.block_id = block.id
              block.id = ''
              return block
            }),
            ...self.smartAsset.blocks
          ]
          self.pushToHistory()
        })
        .catch((_) => {})
    },
    confirmClose() {
      const strSmartAsset = this.smartAssetHistory[0]
      if (JSON.stringify(this.smartAsset) !== strSmartAsset) {
        this.showConfirmCloseModal = true
      } else {
        this.closeEditorModal()
      }
    },
    closeEditorModal() {
      this.$emit('update:show', false)
      this.$bus.$emit('closeModal')
      this.$emit('page:refresh', true)
      this.$emit('edit:reset', this.editData)
      this.showConfirmCloseModal = false
    },
    formatImgUrl(imgUrl) {
      const id = Date.now().toString(36) + Math.random().toString(36).substr(2)
      const arrUrl = imgUrl.split('?')
      return arrUrl[0] + '?' + id
    },
    resetData() {
      this.smartAsset = null
      this.thumbnail = null
    },
    createParam(type = '') {
      this.clearBlock()
      this.createInstagramBlock()
      // // TODO
      this.createRssBlock()
      let formdata = false
      if (window.FormData) {
        formdata = new FormData()
      }

      let tags = this.smartAssetDataModal.tags
      if (tags.length && tags[0].text) {
        tags = this.smartAssetDataModal.tags.map((item) => item.text)
      }

      let templateTags = this.templateTags
      if (templateTags.length && templateTags[0].text) {
        templateTags = this.templateTags.map((item) => item.text)
      }

      if (type === 'create') {
        formdata.append('current_folder', this.smartAssetDataModal.currentFolderID)
        formdata.append('organization_id', this.organizationId)
        formdata.append('smart_asset[tag_list][]', tags)
        this.smartAsset.blocks = this.smartAsset.blocks.map((block) => {
          block.block_id = block.block_id || block.id
          block.id = ''
          return block
        })
      }

      if (type === 'draft') {
        if (this.isAdminTemplate) {
          this.templateName = this.smartAssetDataModal.displayname
          this.templateDescription = this.smartAssetDataModal.templateDescription
          this.category = this.smartAssetDataModal.category
        }
        formdata.append('smart_asset[status]', 'draft')
        formdata.append('smart_asset_template[name]', this.templateName)
        formdata.append('smart_asset_template[template_type]', 'custom_asset')
        formdata.append('smart_asset_template[description]', this.templateDescription)
        this.isAdminTemplate &&
          formdata.append('smart_asset_template[template_category_id]', this.category.id)
        this.isAdminTemplate
          ? formdata.append('smart_asset_template[tag_list][]', tags)
          : formdata.append('smart_asset_template[tag_list][]', templateTags)
        // formdata.append('smart_asset[thumbnail]]', this.thumbnail)
        formdata.append('smart_asset_details[asset_file]', this.thumbnail)
        // --- Testing: May cause issue
        this.smartAsset.blocks = this.smartAsset.blocks.map((block) => {
          block.block_id = block.block_id || block.id
          block.id = ''
          return block
        })
      }
      if (type === 'update') {
        formdata.append('smart_asset[tag_list][]', tags)
        if (this.smartAssetTemplateID) {
          const editDataBlocks = this.editData.blocks.map((block) => {
            block.id = block.block_id
            block.block_id = ''
            block.is_destroy = true
            return block
          })
          this.smartAsset.blocks = this.smartAsset.blocks
            .map((block) => {
              block.block_id = block.id || block.block_id
              block.id = ''
              return block
            })
            .concat(editDataBlocks)

          this.editData.sources.forEach((source) => {
            formdata.append('smart_asset[sources_attributes][][id]', source.id)
            formdata.append('smart_asset[sources_attributes][][_destroy]', true)
          })
        } else {
          this.smartAsset.blocks = this.smartAsset.blocks.map((block) => {
            block.id = block.isCloned ? '' : block.block_id
            block.block_id = block.isCloned ? block.block_id : ''
            return block
          })
        }
      }

      if (type === 'update-draft') {
        if (this.isAdminTemplate) {
          let editTags = this.editData.tags
          if (editTags.length && editTags[0].text) {
            editTags = this.editData.tags.map((item) => item.text)
          }
          formdata.append('smart_asset[status]', 'draft')
          formdata.append('smart_asset_template[name]', this.editData.displayname)
          formdata.append('smart_asset_template[template_type]', 'custom_asset')
          formdata.append('smart_asset_template[description]', this.editData.templateDescription)
          formdata.append('smart_asset_template[template_category_id]', this.editData.category.id)
          formdata.append('smart_asset_template[tag_list][]', editTags)
          formdata.append('smart_asset_details[asset_file]', this.thumbnail)
        } else {
          let editTags = this.selectedTemplate.tags
          if (editTags.length && editTags[0].name) {
            editTags = this.selectedTemplate.tags.map((item) => item.name)
          }
          formdata.append('smart_asset[status]', 'draft')
          formdata.append('smart_asset_template[name]', this.selectedTemplate.name)
          formdata.append('smart_asset_template[template_type]', 'custom_asset')
          formdata.append('smart_asset_template[description]', this.selectedTemplate.description)
          if (this.selectedTemplate.category) {
            formdata.append(
              'smart_asset_template[template_category_id]',
              this.selectedTemplate.category.id
            )
          }
          formdata.append('smart_asset_template[tag_list][]', editTags)
          formdata.append('smart_asset_details[asset_file]', this.thumbnail)
          if (this.smartAsset.backgroundImage.id) {
            formdata.append(
              'smart_asset[blocks_attributes][][id]',
              this.smartAsset.backgroundImage.id
            )
          }
        }
        this.smartAsset.blocks = this.smartAsset.blocks.map((block) => {
          block.id = block.isCloned ? '' : block.block_id
          block.block_id = block.isCloned ? block.block_id : ''
          return block
        })
      }
      // else {
      //   // formdata.append('smart_asset[thumbnail]]', this.thumbnail)
      //   formdata.append('smart_asset_details[asset_file]', this.thumbnail)
      // }
      formdata.append('smart_asset[displayname]', this.smartAssetDataModal.displayname)
      // formdata.append('smart_asset[tag_list][]', tags)
      formdata.append('smart_asset[orientation]', this.smartAssetDataModal.orientation)
      formdata.append('smart_asset[smart_asset_type]', this.smartAssetDataModal.smart_asset_type)

      formdata.append('property[show_grid][data_property]', this.smartAsset.showGrid)
      formdata.append('property[grid][data_property]', this.smartAsset.grid)
      formdata.append('property[snap_to_grid][data_property]', this.smartAsset.snapToGrid)
      formdata.append('property[background_color][data_property]', this.smartAsset.backgroundColor)
      formdata.append('property[color][data_property]', this.smartAsset.textStyle.color)
      formdata.append('property[fontFamily][data_property]', this.smartAsset.textStyle.fontFamily)
      const isCreate =
        type === 'create' || type === 'draft' || (type === 'update' && this.smartAssetTemplateID)

      if (!isCreate) {
        if (this.websiteListDeleted.length > 0) {
          this.websiteListDeleted.forEach((web) => {
            formdata.append('smart_asset[sources_attributes][][id]', web.sourceId)
            formdata.append('smart_asset[sources_attributes][][_destroy]', web.is_destroy)
          })
        }
        if (this.rssListDeleted.length > 0) {
          this.rssListDeleted.forEach((rss) => {
            formdata.append('smart_asset[sources_attributes][][id]', rss.sourceId)
            formdata.append('smart_asset[sources_attributes][][_destroy]', rss.is_destroy)
          })
        }
        if (this.igListDeleted.length > 0) {
          this.igListDeleted.forEach((ig) => {
            formdata.append('smart_asset[sources_attributes][][id]', ig.sourceId)
            formdata.append('smart_asset[sources_attributes][][_destroy]', ig.is_destroy)
          })
        }
      }
      if (this.websiteList.length > 0) {
        this.websiteList.forEach((web) => {
          formdata.append(
            'smart_asset[sources_attributes][][id]',
            isCreate ? '' : web.sourceId ? web.sourceId : ''
          )
          formdata.append('smart_asset[sources_attributes][][displayname]', web.name)
          formdata.append('smart_asset[sources_attributes][][url]', web.website_url)
          formdata.append('smart_asset[sources_attributes][][source_type]', 'embed')
        })
      }
      if (this.rssList.length > 0) {
        this.rssList.forEach((rss) => {
          formdata.append(
            'smart_asset[sources_attributes][][id]',
            isCreate ? '' : rss.sourceId ? rss.sourceId : ''
          )
          formdata.append('smart_asset[sources_attributes][][displayname]', rss.name)
          formdata.append('smart_asset[sources_attributes][][url]', rss.url)
          formdata.append('smart_asset[sources_attributes][][source_type]', 'rss')
        })
      }
      if (this.igList.length > 0) {
        this.igList.forEach((ig) => {
          formdata.append(
            'smart_asset[sources_attributes][][id]',
            isCreate ? '' : ig.sourceId ? ig.sourceId : ''
          )
          formdata.append('smart_asset[sources_attributes][][displayname]', ig.name)
          formdata.append('smart_asset[sources_attributes][][url]', ig.id)
          formdata.append('smart_asset[sources_attributes][][source_type]', 'instagram')
        })
      }
      if (this.smartAsset.backgroundImage.dataImage && this.smartAsset.backgroundImage.file) {
        if (this.smartAsset.backgroundImage.id) {
          formdata.append(
            'smart_asset[blocks_attributes][][id]',
            this.smartAsset.backgroundImage.id
          )
        } else {
          formdata.append('smart_asset[blocks_attributes][][id]', '')
        }
        formdata.append(
          'smart_asset[blocks_attributes][][asset_file]',
          this.smartAsset.backgroundImage.file
        )
        formdata.append(
          'smart_asset[blocks_attributes][][_destroy]',
          this.smartAsset.backgroundImage.is_destroy
        )
        formdata.append('smart_asset[blocks_attributes][][block_type]', 'background')
        formdata.append('smart_asset[blocks_attributes][][content]', '')
      } else if (this.smartAsset.backgroundImage.url && (this.templateID || type === 'draft')) {
        formdata.append('smart_asset[blocks_attributes][][block_type]', 'background')
        if (this.smartAsset.backgroundImage.id) {
          formdata.append(
            'smart_asset[blocks_attributes][][block_id]',
            this.smartAsset.backgroundImage.id
          )
        }
      } else if (this.smartAsset.backgroundImage.id && this.smartAsset.backgroundColor) {
        if (this.smartAsset.backgroundImage.id) {
          formdata.append(
            'smart_asset[blocks_attributes][][id]',
            this.smartAsset.backgroundImage.id
          )
        }
        formdata.append(
          'smart_asset[blocks_attributes][][asset_file]',
          this.smartAsset.backgroundImage.file
        )
        formdata.append(
          'smart_asset[blocks_attributes][][_destroy]',
          this.smartAsset.backgroundImage.is_destroy
        )
        formdata.append('smart_asset[blocks_attributes][][block_type]', 'background')
      }

      this.smartAsset.blocks.map((value) => {
        if (!value.isChild) {
          this.blockParams(formdata, value, type)
        }
      })

      return formdata
    },
    clearBlock() {
      this.smartAsset.blocks = this.smartAsset.blocks.filter(
        (value) => !(value.children && value.children.length > 0)
      )
    },
    imageBlockParams(paramName, formdata, value) {
      if (value.file) {
        formdata.append(`${paramName}[asset_file]`, value.file)
      }
      formdata.append(`${paramName}[content]`, '')
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][data_property]`,
        value.blockType
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][imageStyle][imageWidth]`,
        value.imageStyle.imageWidth
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][imageStyle][imageHeight]`,
        value.imageStyle.imageHeight
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][imageStyle][top]`,
        value.imageStyle.top
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][imageStyle][left]`,
        value.imageStyle.left
      )
    },
    textBlockParams(paramName, formdata, value) {
      formdata.append(`${paramName}[content]`, value.text)
      formdata.append(`${paramName}[asset_file]`, '')
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][data_property]`,
        value.blockType
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][paddingValue][bottom]`,
        value.paddingValue.bottom
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][paddingValue][left]`,
        value.paddingValue.left
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][paddingValue][right]`,
        value.paddingValue.right
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][paddingValue][top]`,
        value.paddingValue.top
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textShadowValue][blur]`,
        value.textShadowValue.blur
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textShadowValue][color]`,
        value.textShadowValue.color
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textShadowValue][h]`,
        value.textShadowValue.h
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textShadowValue][v]`,
        value.textShadowValue.v
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][color]`,
        value.textStyle.color
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][fontFamily]`,
        value.textStyle.fontFamily
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][fontSize]`,
        value.textStyle.fontSize
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][fontWeight]`,
        value.textStyle.fontWeight
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][letterSpacing]`,
        value.textStyle.letterSpacing
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][lineHeight]`,
        value.textStyle.lineHeight
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][padding]`,
        value.textStyle.padding
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][textAlign]`,
        value.textStyle.textAlign
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][textShadow]`,
        value.textStyle.textShadow
      )
      if (value.mentions) {
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][mentions][textColor]`,
          value.mentions.textColor
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][mentions][fontFamily]`,
          value.mentions.fontFamily
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][mentions][fontWeight]`,
          value.mentions.fontWeight
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][mentions][fontSize]`,
          value.mentions.fontSize
        )
      }
      if (value.hashtags) {
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][hashtags][textColor]`,
          value.hashtags.textColor
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][hashtags][fontFamily]`,
          value.hashtags.fontFamily
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][hashtags][fontWeight]`,
          value.hashtags.fontWeight
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][hashtags][fontSize]`,
          value.hashtags.fontSize
        )
      }
    },
    dateTimeBlockParams(paramName, formdata, value) {
      const timezoneParts = value.timezone.split('|')
      formdata.append(`${paramName}[content]`, value.text)
      formdata.append(`${paramName}[asset_file]`, '')
      formdata.append(
        `${paramName}[property_attributes][timezone][data_property]`,
        timezoneParts[0]
      )
      formdata.append(
        `${paramName}[property_attributes][timezoneName][data_property]`,
        timezoneParts[1] ? timezoneParts[1] : ''
      )
      formdata.append(`${paramName}[property_attributes][hour12][data_property]`, value.hour12)
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][data_property]`,
        value.blockType
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][paddingValue][bottom]`,
        value.paddingValue.bottom
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][paddingValue][left]`,
        value.paddingValue.left
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][paddingValue][right]`,
        value.paddingValue.right
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][paddingValue][top]`,
        value.paddingValue.top
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textShadowValue][blur]`,
        value.textShadowValue.blur
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textShadowValue][color]`,
        value.textShadowValue.color
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textShadowValue][h]`,
        value.textShadowValue.h
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textShadowValue][v]`,
        value.textShadowValue.v
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][color]`,
        value.textStyle.color
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][fontFamily]`,
        value.textStyle.fontFamily
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][fontSize]`,
        value.textStyle.fontSize
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][fontWeight]`,
        value.textStyle.fontWeight
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][letterSpacing]`,
        value.textStyle.letterSpacing
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][lineHeight]`,
        value.textStyle.lineHeight
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][padding]`,
        value.textStyle.padding
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][textAlign]`,
        value.textStyle.textAlign
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][textShadow]`,
        value.textStyle.textShadow
      )
      if (value.mentions) {
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][mentions][textColor]`,
          value.mentions.textColor
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][mentions][fontFamily]`,
          value.mentions.fontFamily
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][mentions][fontWeight]`,
          value.mentions.fontWeight
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][mentions][fontSize]`,
          value.mentions.fontSize
        )
      }
      if (value.hashtags) {
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][hashtags][textColor]`,
          value.hashtags.textColor
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][hashtags][fontFamily]`,
          value.hashtags.fontFamily
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][hashtags][fontWeight]`,
          value.hashtags.fontWeight
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][hashtags][fontSize]`,
          value.hashtags.fontSize
        )
      }
    },
    embedBlockParams(paramName, formdata, value) {
      formdata.append(`${paramName}[content]`, '')
      formdata.append(`${paramName}[website_url]`, value.website_url)
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][data_property]`,
        value.blockType
      )
    },
    qrBlockParams(paramName, formdata, value) {
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][data_property]`,
        value.blockType
      )
      formdata.append(`${paramName}[property_attributes][color][data_property]`, value.color)
      formdata.append(
        `${paramName}[property_attributes][image_string][data_property]`,
        value.image_string
      )
      formdata.append(`${paramName}[property_attributes][content][data_property]`, value.content)
      formdata.append(
        `${paramName}[property_attributes][background_color][data_property]`,
        value.background_color
      )
      formdata.append(
        `${paramName}[property_attributes][player_tracking][data_property]`,
        value.player_tracking
      )
    },
    videoBlockParams(paramName, formdata, value) {
      if (value.file) {
        formdata.append(`${paramName}[asset_file]`, value.file)
      }
      formdata.append(
        `${paramName}[property_attributes][loop_video][data_property]`,
        value.loop_video
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][data_property]`,
        value.blockType
      )
    },
    instagramBlockParams(paramName, formdata, value) {
      formdata.append(`${paramName}[property_attributes][period][data_property]`, value.duration)
      formdata.append(
        `${paramName}[property_attributes][image_count][data_property]`,
        value.numberOfSlides
      )
      formdata.append(
        `${paramName}[property_attributes][ig_user_id][data_property]`,
        value.ig_user_id || value.userId || this.igBusinessAccountId
      )
      formdata.append(
        `${paramName}[property_attributes][ig_access_token][data_property]`,
        value.ig_access_token || value.assetToken || this.fbLongLivedAccessToken
      )
      formdata.append(`${paramName}[property_attributes][new_logic][data_property]`, true)
      value.children.map((item) => {
        this.blockParams(formdata, item)
      })
    },
    rssBlockParams(paramName, formdata, value) {
      formdata.append(`${paramName}[property_attributes][period][data_property]`, value.duration)
      formdata.append(
        `${paramName}[property_attributes][image_count][data_property]`,
        value.numberOfSlides
      )
      formdata.append(`${paramName}[property_attributes][url][data_property]`, value.rssUrl)
      value.children.map((item) => {
        this.blockParams(formdata, item)
      })
    },
    shapeBlockParams(paramName, formdata, value) {
      formdata.append(`${paramName}[content]`, '')
      formdata.append(`${paramName}[asset_file]`, '')
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][data_property]`,
        value.blockType
      )
    },
    blockParams(formdata, value, type) {
      let paramName = 'smart_asset[blocks_attributes][]'
      // genarel params
      value.blockType = value.type
      if (['igCaption', 'rssTitle', 'rssDesc'].includes(value.blockType)) {
        value.blockType = 'text'
        paramName = 'smart_asset[blocks_attributes][][children_attributes][]'
      } else if (value.blockType === 'igImage') {
        paramName = 'smart_asset[blocks_attributes][][children_attributes][]'
        value.blockType = 'image'
      }
      if (value.id && !['create', 'draft'].includes(type)) {
        formdata.append(`${paramName}[id]`, value.id)
        formdata.append(`${paramName}[block_id]`, '')
      } else if (value.block_id) {
        // TODO
        formdata.append(`${paramName}[id]`, '')
        formdata.append(`${paramName}[block_id]`, value.block_id)
      } else {
        formdata.append(`${paramName}[id]`, '')
        formdata.append(`${paramName}[block_id]`, '')
      }
      formdata.append(`${paramName}[_destroy]`, value.is_destroy)
      formdata.append(`${paramName}[z_index]`, value.zIndex)
      formdata.append(`${paramName}[parent_id]`, value.parent_id)
      formdata.append(`${paramName}[block_type]`, value.blockType)
      formdata.append(`${paramName}[content_type]`, value.contentType)
      formdata.append(`${paramName}[name]`, value.name)
      formdata.append(
        `${paramName}[property_attributes][aspectRatio][data_property]`,
        value.aspectRatio
      )
      formdata.append(`${paramName}[property_attributes][ratio][data_property]`, value.ratio)
      // own params
      if (value.blockType === 'embed') {
        this.embedBlockParams(paramName, formdata, value)
      } else if (value.blockType === 'image') {
        this.imageBlockParams(paramName, formdata, value)
      } else if (value.blockType === 'qr') {
        this.qrBlockParams(paramName, formdata, value)
      } else if (value.blockType === 'video') {
        this.videoBlockParams(paramName, formdata, value)
      } else if (value.blockType === 'instagram') {
        this.instagramBlockParams(paramName, formdata, value)
      } else if (value.blockType === 'rss') {
        this.rssBlockParams(paramName, formdata, value)
      } else if (value.blockType === 'text') {
        this.textBlockParams(paramName, formdata, value)
      } else if (value.blockType === SMART_ASSET_TYPE_DATE_TIME) {
        this.dateTimeBlockParams(paramName, formdata, value)
      } else if (value.blockType === 'shape') {
        this.shapeBlockParams(paramName, formdata, value)
      }
      if (value.blockType !== 'instagram' && value.blockType !== 'rss') {
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][origin][x]`,
          value.x
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][origin][y]`,
          value.y
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][dimensions][w]`,
          value.w
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][dimensions][h]`,
          value.h
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][style][alignItems]`,
          value.style.alignItems
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][style][backgroundColor]`,
          value.style.backgroundColor
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][style][justifyContent]`,
          value.style.justifyContent
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][borderRadiusValue][bottomRight]`,
          value.borderRadiusValue.bottomRight
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][borderRadiusValue][bottomLeft]`,
          value.borderRadiusValue.bottomLeft
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][borderRadiusValue][topRight]`,
          value.borderRadiusValue.topRight
        )
        formdata.append(
          `${paramName}[property_attributes][${value.blockType}][asset_attribute][borderRadiusValue][topLeft]`,
          value.borderRadiusValue.topLeft
        )
        if (value.blockType !== SMART_ASSET_TYPE_DATE_TIME) {
          // border param
          formdata.append(
            `${paramName}[property_attributes][${value.blockType}][asset_attribute][borderValue][borderWidth]`,
            value.borderValue.borderWidth
          )
          formdata.append(
            `${paramName}[property_attributes][${value.blockType}][asset_attribute][borderValue][color]`,
            value.borderValue.color
          )
        }
      }
    },

    async logInWithFacebook(isSourceMap) {
      window.FB.login(
        (response) => {
          if (response.authResponse && response.status === 'connected') {
            this.fbLogin = true
            this.$toast.open('Login success!')
            if (isSourceMap) {
              this.showNewAuthFbModal = false
              this.showNewFbPagesModal = true
            } else {
              this.showAuthFbModal = false
              this.showFbPagesModal = true
            }
            this.getFbLongLivedAccessToken(response.authResponse.accessToken).catch((err) => {
              this.$toast.error(
                err.message ? err.message : 'Unable to get Facebook access token. Please try again.'
              )
            })
            this.getUserPage(response.authResponse.accessToken).catch((err) => {
              this.$toast.error(
                err.message ? err.message : 'Unable to get Facebook pages. Please try again.'
              )
            })
          } else {
            return this.$toast.error('User cancelled login or did not fully authorize.')
          }
        },
        {
          scope: 'pages_show_list, instagram_basic, pages_read_engagement'
        }
      )
      return false
    },
    async getIgMediaObjects(fbPageId, username) {
      if (this.loadingGetIgMedia) {
        return
      }

      this.loadingGetIgMedia = true
      this.$store
        .dispatch('smartasseteditor/getIgMediaByFacebook', fbPageId)
        .then((res) => {
          if (res && res.data) {
            const existIg = this.igList.filter((item) => item.id === fbPageId)
            if (existIg && existIg.length) {
              return this.$toast.error('This instagram account has been added')
            } else {
              const ig = {
                token: this.fbLongLivedAccessToken,
                items: res.data.data,
                // TODO
                // id: fbPageId,
                id: this.igBusinessAccountId,
                text: 'Instagram ' + (this.igList.length + 1),
                userName: username || res.data.data[0].username,
                name: 'Instagram ' + (this.igList.length + 1),
                duration: 10,
                numberOfSlides: 5
              }
              this.instagramTemp = ig
              this.igList.push(ig)
              this.instagramOauth = true
              // this.selectedIg = fbPageId
              this.selectedIg = this.igBusinessAccountId
              this.igBusinessAccountId &&
                this.$store.dispatch('smartasseteditor/getInstagramAuthorization').catch((err) => {
                  this.$toast.error(
                    err.message
                      ? err.message
                      : 'Unable to load Instagram authorization. Please try again.'
                  )
                })
            }
          }
          if (this.addSourceType) {
            this.showAddSourceModal = true
          }
          this.sourceDetailClick('instagram', this.igBusinessAccountId)
          this.showDetailSource = true
          this.showSourcesAdded = false
          this.showAddNewSource = false
        })
        .catch((err) => {
          this.$toast.error(
            err.message ? err.message : 'Unable to load Instagram source. Please try again.'
          )
        })
        .finally(() => {
          this.loadingGetIgMedia = false
          this.showFbPagesModal = false
          this.showNewFbPagesModal = false
        })
    },
    logOutFacebook(isSourceMap) {
      window.FB.logout((response) => {
        this.fbLogin = false
        this.setFbLongLivedAccessToken(null)
        if (isSourceMap) {
          this.showNewAuthFbModal = true
          this.showNewFbPagesModal = false
        } else {
          this.showAuthFbModal = true
          this.showFbPagesModal = false
        }
      })
    },
    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '2284586011684115', // You will need to change this
          cookie: true, // This is important, it's not enabled by default
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v12.0'
        })
      }
    },
    async loadFacebookSDK(d, s, id) {
      let js
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    },
    async getCustomFont() {
      CustomFontService.getCustomFont()
        .then((res) => {
          if (res.data.length > 0) {
            res.data.forEach((item) => {
              const font = new FontFace(item.name, `url(${item.file})`)
              font.load().then(() => {
                document.fonts.add(font)
              })
            })
            const customFontInOrg = res.data.map((font) => ({ id: font.name, text: font.name }))
            this.fonts = uniqBy(concat(this.fonts, customFontInOrg), 'id')
          }
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    createCustomFont(data, uploadFont) {
      const formData = new FormData()
      formData.append('custom_font[name]', data.name)
      formData.append('custom_font[file]', data.file)
      CustomFontService.createCustomFont(formData)
        .then((res) => {
          this.fonts.push(uploadFont)
          this.$toast.open('The new font is uploaded successfully.')
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    changeFontSize(e) {
      this.activeBlock.textStyle.fontSize = e
      // calc width, height
      if (this.activeBlock.type === SMART_ASSET_TYPE_DATE_TIME) {
        this.calcWidthHeightDateTime(e)
      }
    },
    calcWidthHeightDateTime(e) {
      const fontSize = parseInt(e, 10)
      const minWidth = (fontSize * this.widthDateTimeDefault) / FONT_SIZE_DATE_TIME_DEFAULT
      this.activeBlock.w = this.activeBlock.w < minWidth ? minWidth : this.activeBlock.w
      const minHeight = (fontSize * HEIGHT_DATE_TIME_DEFAULT) / FONT_SIZE_DATE_TIME_DEFAULT
      this.activeBlock.h = this.activeBlock.h < minHeight ? minHeight : this.activeBlock.h
    }
  },
  beforeDestroy() {
    this.resetData()
  }
}
</script>
<style scoped lang="scss">
@import './SmartAssetEditor.scss';
</style>
