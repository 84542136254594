<template>
  <div>
    <div class="d-flex justify-content-between my-3">
      <!-- <input v-debounce:400ms="searchInputChange" v-model="searchRoleValue" class="search-input" type="text" placeholder="Search"> -->
      <SfInputSearch
        v-model="searchRoleValue"
        placeholder="Search"
        v-debounce:500ms="searchInputChange"
        class="sf-input-search"
      />
      <div>
        <button @click="createRole" class="sf-primary">Add New</button>
      </div>
    </div>
    <div class="sf-table">
      <b-table
        :items="roleListByOrg.items"
        show-empty
        hover
        :fields="fields"
        head-variant="none"
        table-variant="none"
        thead-class="custom-b-table"
        :busy="loadingRole"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template v-slot:head(displayname)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(displayname)="field">
          <p>{{ field.item.displayname }}</p>
        </template>

        <template v-slot:head(name)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(name)="field">
          <p>{{ field.item.name }}</p>
        </template>

        <template v-slot:head(role_type)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(role_type)="field">
          <p>{{ field.item.role_type }}</p>
        </template>

        <template v-slot:cell(actions)="field">
          <button
            @click="showModalEditRole(field.item.id)"
            title="Edit"
            class="button-edit-table"
          ></button>
          <button
            @click="showModalDeleteRole(field.item.id)"
            title="Delete"
            class="button-delete-table"
          ></button>
        </template>
      </b-table>
    </div>
    <div class="my-3">
      <b-pagination
        class="sf-b-pagination"
        v-model="currentPage"
        :total-rows="5 * roleListByOrg.total_pages"
        :per-page="5"
        @change="changePage"
        :limit="6"
      />
    </div>
    <SfModal
      :title="'New Role'"
      :width="768"
      :icon="'fas fa-plus-circle'"
      closeOnOverlay
      :show.sync="inShownModalCreateRole"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Role</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="roleData.role.role_id"
                    :options="roleListNoPaging"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div class="invalid-error" v-if="submitted && !$v.roleData.role.role_id.required">
                Select a role
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="inShownModalCreateRole = false">Cancel</button>
        <button class="sf-primary" @click="saveRole">Save</button>
      </div>
    </SfModal>

    <SfModal
      :title="'Edit Role'"
      :icon="'fa fa-building'"
      :width="768"
      closeOnOverlay
      :show.sync="isShowModalEditRole"
    >
      <div class="row exception-row custom-row">
        <p class="sf-label mb-2">Role Name</p>
        <div v-if="loadingEditRole" class="placeholder-modal">
          <content-placeholders>
            <content-placeholders-text :lines="1" />
          </content-placeholders>
        </div>
        <input
          v-else
          placeholder="Role"
          v-model="roleDataUpdate.role.displayname"
          class="sf-input-text"
        />
        <div
          class="invalid-error-text"
          v-if="submitted && !$v.roleDataUpdate.role.displayname.required"
        >
          Role name required
        </div>
      </div>
      <div class="row exception-row">
        <p class="note-role">Set the permissions for each role here.</p>
      </div>
      <div v-if="loadingEditRole">
        <table class="col-12 table-set-role">
          <tr class="header-role sf-label">
            <th>Permissions</th>
            <th>Menu</th>
            <th>List</th>
            <th>Create</th>
            <th>Read</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
          <!-- line 1 -->
          <tr v-for="j in 6" :key="j">
            <td class="title-role-placeholder">
              <content-placeholders>
                <content-placeholders-text :lines="1" />
              </content-placeholders>
            </td>
            <td v-for="i in 6" :key="i">
              <content-placeholders :class="'checkbox-placeholder'">
                <content-placeholders-text :lines="1" />
              </content-placeholders>
            </td>
          </tr>
        </table>
      </div>
      <div v-else class="row exception-row">
        <table class="col-12 table-set-role">
          <tr class="header-role sf-label">
            <th>Permissions</th>
            <th>Menu</th>
            <th>List</th>
            <th>Create</th>
            <th>Read</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
          <tr v-for="(value, name, index) in permissionList" :key="index">
            <td class="title-role">
              <img class="image-role" :src="formartPermission(name, 'icon')" />
              <p class="name-asset">{{ formartPermission(name, 'displayname') }}</p>
            </td>
            <td v-for="(item, index) in value" :key="index">
              <label class="sf-checkbox" v-if="item.active">
                <input :value="item" v-model="roleDataUpdate.permissions" type="checkbox" />
                <span class="checkmark"></span>
              </label>
            </td>
          </tr>
        </table>
      </div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShowModalEditRole = false">Cancel</button>
        <button class="sf-primary" @click="updateRole">Save</button>
      </div>
    </SfModal>
    <SfDeleteModal title="Delete Role" closeOnOverlay :show.sync="isShowModalDelete">
      <div class="content-delete">
        Deleting this role. Are you sure you want to delete this one?
      </div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShowModalDelete = false">Cancel</button>
        <button class="sf-primary" @click="deleteRole">OK</button>
      </div>
    </SfDeleteModal>

    <SfModal
      :title="'Confirm Edit Role'"
      :width="400"
      closeOnOverlay
      :show.sync="isShownModalConfirm"
    >
      <div class="content-delete">
        Any changes to this role will have an impact on
        <a :href="`${API_URL}/admin/roles/${roleID}/export`" download
          >{{ roleDataUpdate.role.total_user }}
          {{ roleDataUpdate.role.total_user > 1 ? ' users' : 'user' }}</a
        >. Would you like to proceed?
      </div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShownModalConfirm = false">Cancel</button>
        <button class="sf-primary" @click="confirmUpdateRole">OK</button>
      </div>
    </SfModal>
  </div>
</template>
<script>
import SfModal from '@/components/SfModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import AdminRoleService from '@/services/admin-role.service'
import libraryIcon from '@/assets/images/library-rule.svg'
import loopIcon from '@/assets/images/role/loops-tree.svg'
import campaignIcon from '@/assets/images/role/overview-campaign.svg'
import playerIcon from '@/assets/images/role/overview-player.svg'
import folderIcon from '@/assets/images/role/folder-tree.svg'
import accountIcon from '@/assets/images/role/account-role.svg'
import organizationIcon from '@/assets/images/role/organization-role.svg'
import { mapState, mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { API_URL } from '@/.env'
import Select2 from 'v-select2-component'
import SfInputSearch from '@/components/SfInputSearch.vue'

export default {
  name: 'roleTable',
  components: {
    SfModal,
    SfDeleteModal,
    Select2,
    SfInputSearch
  },
  data() {
    return {
      API_URL,
      searchRoleValue: '',
      isShowModalEditRole: false,
      isShowModalDelete: false,
      inShownModalCreateRole: false,
      isShownModalExport: false,
      icon: {
        libraryIcon,
        loopIcon,
        campaignIcon,
        playerIcon,
        folderIcon,
        accountIcon,
        organizationIcon
      },
      roleID: '',
      roleData: {
        role: {
          organization_id: '',
          role_id: ''
        },
        permissions: []
      },
      roleDataUpdate: {
        role: {
          displayname: '',
          organization_id: '',
          total_user: ''
        },
        permissions: []
      },

      submitted: false,
      fields: [
        {
          key: 'displayname',
          class: 'f-displayname',
          colType: 'displayname',
          label: 'Display Name',
          sortable: true
        },
        {
          key: 'name',
          class: 'f-displayname',
          colType: 'name',
          label: 'Role Name',
          sortable: true
        },
        {
          key: 'role_type',
          colType: 'role_type',
          label: 'Type',
          sortable: true
        },
        {
          key: 'Actions',
          colType: 'button'
        }
      ],
      currentPage: 1,
      isShownModalConfirm: false,
      loadingEditRole: true
    }
  },
  props: {
    show: {},
    org_id: {}
  },
  validations: {
    roleData: {
      role: {
        role_id: {
          required
        }
      }
    },
    roleDataUpdate: {
      role: {
        displayname: {
          required
        }
      }
    }
  },
  watch: {
    show(value) {
      if (value === 'roles') {
        this.fetchData(this.currentPage, this.searchRoleValue)
        this.$store.dispatch('adminorganization/getPermission')
      }
    }
  },
  methods: {
    ...mapActions('adminorganization', {
      getDataTableOfOrganization: 'getDataTableOfOrganization',
      getRoleListNoPaging: 'getRoleListNoPaging'
    }),
    createRole() {
      this.inShownModalCreateRole = true
      this.getRoleListNoPaging('custom')
      this.resetData()
    },
    searchInputChange(val) {
      this.searchRoleValue = val
      this.currentPage = 1
      this.fetchData(this.currentPage, this.searchRoleValue)
    },
    fetchData(currentPage, search) {
      this.getDataTableOfOrganization({
        id: this.org_id,
        type: 'roles',
        page: currentPage,
        limit: 10,
        search: this.searchRoleValue
      })
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.searchRoleValue)
    },

    formartPermission(page, formatType) {
      let icon = ''
      let name = ''
      switch (page) {
        case 'SmartAsset':
          icon = this.icon.libraryIcon
          name = 'Library (Assets)'
          break
        case 'LoopAsset':
          icon = this.icon.loopIcon
          name = 'Loops'
          break
        case 'Campaign':
          icon = this.icon.campaignIcon
          name = 'Campaigns'
          break
        case 'Player':
          icon = this.icon.playerIcon
          name = 'Players'
          break
        case 'FolderSmartAsset':
          icon = this.icon.folderIcon
          name = 'Library Folders'
          break
        case 'FolderLoop':
          icon = this.icon.folderIcon
          name = 'Loop Folders'
          break
        case 'User':
          icon = this.icon.accountIcon
          name = 'User'
          break
        case 'Account':
          icon = this.icon.accountIcon
          name = 'Account'
          break
        case 'Organization':
          icon = this.icon.organizationIcon
          name = 'Organization'
          break
        default:
          break
      }
      return formatType === 'icon' ? icon : name
    },
    saveRole() {
      this.submitted = true
      if (this.$v.roleData.role.$invalid) {
        return
      }
      AdminRoleService.assignRole({
        organization_id: this.org_id,
        role_id: this.roleData.role.role_id
      })
        .then((data) => {
          this.$toast.success('New role created')
          this.fetchData(this.currentPage, this.searchRoleValue)
        })
        .catch((error) => {
          this.$toast.error(error.message)
        })
        .finally(() => {
          this.inShownModalCreateRole = false
        })
    },
    updateRole() {
      this.submitted = true
      if (this.$v.roleDataUpdate.role.$invalid) {
        return
      }
      this.roleDataUpdate.role.organization_id = this.org_id
      if (!this.roleDataUpdate.role.total_user) {
        AdminRoleService.updateRole(this.roleID, this.roleDataUpdate)
          .then((data) => {
            this.fetchData(this.currentPage, this.searchRoleValue)
            this.$toast.success('Role updated')
          })
          .catch(() => {
            this.$toast.error('Something went wrong. Please try again')
          })
          .finally(() => {
            this.isShowModalEditRole = false
          })
      } else {
        this.isShownModalConfirm = true
        this.isShowModalEditRole = false
      }
    },
    confirmUpdateRole() {
      AdminRoleService.updateRole(this.roleID, this.roleDataUpdate)
        .then((data) => {
          this.fetchData(this.currentPage, this.searchRoleValue)
          this.$toast.success('Role updated')
        })
        .catch(() => {
          this.$toast.error('Something went wrong. Please try again')
        })
        .finally(() => {
          this.isShownModalConfirm = false
        })
    },
    resetData() {
      this.roleData.permissions = []
      this.roleData.role.displayname = ''
      this.roleData.role.role_id = ''
      this.roleDataUpdate.permissions = []
      this.roleDataUpdate.role.displayname = ''
      this.submitted = false
    },
    showModalEditRole(id) {
      const arrPermission = []
      this.loadingEditRole = true
      AdminRoleService.getRole(id).then((res) => {
        this.loadingEditRole = false
        for (const item in res.data.permissions) {
          res.data.permissions[item].forEach((el) => {
            if (el.checked) {
              arrPermission.push(el)
            }
          })
        }
        this.roleDataUpdate.permissions = arrPermission.map((i) => {
          return { id: i.id, permission: i.permission, active: true }
        })
        this.roleDataUpdate.role.displayname = res.data.displayname
        this.roleID = id
        this.roleDataUpdate.role.total_user = res.data.total_user
      })
      this.isShowModalEditRole = true
    },
    showModalDeleteRole(id) {
      this.isShowModalDelete = true
      this.roleID = id
    },
    deleteRole() {
      AdminRoleService.unassignRole({ organization_id: this.org_id, role_id: this.roleID })
        .then((res) => {
          this.isShowModalDelete = false
          this.$toast.success('Deleted role successfully')
          if (this.roleListByOrg.items.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          this.fetchData(this.currentPage, this.searchRoleValue)
        })
        .catch((err) => {
          this.isShowModalDelete = false
          this.$toast.error(err.message)
        })
    }
  },
  computed: {
    ...mapState('adminorganization', {
      permissionList: 'permissionList',
      roleListByOrg: 'roleListByOrg',
      loadingRole: 'loadingRole'
    }),
    ...mapGetters('adminorganization', {
      roleListNoPaging: 'roleListNoPaging'
    })
  }
}
</script>
<style lang="scss" scoped>
@import './bTable.scss';
@import '@/pages/account/accountPage.scss';
@import '@/pages/admin/Adminpage.scss';
.role-name {
  font: 14px 'Opens Sans', sans-serif;
  font-weight: 600;
  color: #525f7f;
  padding-bottom: 10px;
}
.field-name {
  height: 46px;
  border-radius: 2px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  font: 14px 'Opens Sans', sans-serif;
  border: 0;
  padding: 10px 12px;
}
.custom-row {
  flex-direction: column;
}

.note-role {
  font: 14px 'OpenSans', sans-serif;
  color: #8999aa;
}
.table-set-role {
  .header-role {
    height: 30px;
  }
  tr {
    td {
      height: 40px;
    }
    .title-role {
      display: flex;
      align-items: center;
      p {
        padding-left: 10px;
      }
    }
  }
}
.name-asset {
  font: 14px 'Opens Sans', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #525f7f;
}
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -15px;
  left: 2px;
  width: 25px;
  height: 25px;
  border-radius: 2px;
  border: solid 1px #8999aa;
  background-color: #ffffff;
  -webkit-user-select: none;
}

/* On mouse-over, add a grey background color */
// .container:hover input ~ .checkmark {
//   background-color: #ccc;
// }

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #ffffff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 7px;
  top: 2px;
  width: 10px;
  height: 15px;
  border: solid #8999aa;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.text-soon {
  height: 100px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font: 35px 'Opens Sans', sans-serif;
  }
}
.image-role {
  width: 30px;
  height: 30px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
