<template>
  <div id="player-notes">
    <div v-html="notesHtml" class="notes-content"></div>
    <div class="edit-notes">
      <button @click="showEditNotesModal = true" class="button-edit-table" title="Edit"></button>
    </div>
    <SfModal
      title="Edit Notes"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="showEditNotesModal"
      @onClose="handleCancel"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-12 editor">
              <ckeditor :editor="editor" v-model="notes" :config="configEditor"></ckeditor>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="handleCancel">Cancel</button>
        <button class="sf-primary" @click="saveNotes()">Save</button>
      </div>
    </SfModal>
  </div>
</template>
<script>
import Vue from 'vue'
import SfModal from '@/components/SfModal.vue'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
Vue.use(CKEditor)
export default {
  name: 'Notes',
  props: {
    data: {
      type: String
    }
  },
  data() {
    return {
      showEditNotesModal: false,
      notes: this.data,
      editor: ClassicEditor,
      configEditor: {
        toolbar: {
          items: [
            'undo',
            'redo',
            '|',
            'heading',
            '|',
            'bold',
            'italic',
            '|',
            'link',
            // 'insertImage',
            // 'insertTable',
            // 'mediaEmbed',
            'blockQuote',
            '|',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent'
          ]
        }
      }
    }
  },
  components: {
    SfModal
  },
  computed: {
    notesHtml() {
      return this.data
    }
  },
  watch: {
    data(value) {
      this.notes = value
    }
  },
  methods: {
    saveNotes() {
      this.$emit('update', this.notes)
      this.showEditNotesModal = false
    },
    handleCancel() {
      this.showEditNotesModal = false
      this.notes = this.data
    }
  }
}
</script>
<style lang="scss" scoped>
#player-notes {
  position: relative;
  .notes-content {
    min-height: 100vh;
    width: 100%;
    background-color: #fff;
    margin-top: 56px;
    padding: 10px;
  }
  .edit-notes {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  .editor {
    min-height: 50vh;
  }
}
::v-deep .ck-editor__main .ck-content {
  min-height: 50vh;
}
::v-deep .ck.ck-content ul,
::v-deep .ck.ck-content ul li,
::v-deep .notes-content ul li {
  list-style-type: initial !important;
}

::v-deep .ck.ck-content ul {
  padding-left: 40px;
}

::v-deep .ck.ck-content ol,
::v-deep .ck.ck-content ol li,
::v-deep .notes-content ol li {
  list-style-type: decimal !important;
}
::v-deep .ck.ck-content ol,
::v-deep .notes-content ol,
::v-deep .notes-content ul {
  padding-left: 40px;
}
::v-deep .notes-content blockquote {
  border-left: 5px solid #ccc;
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  padding-left: 1.5em;
  padding-right: 1.5em;
  quotes: initial;
}
</style>
