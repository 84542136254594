<template>
  <div class="admin-page">
    <div class="admin-page-header">
      <div class="col-md-12 admin-page-menu">
        <ul class="nav nav-tabs nav-tabs-custom">
          <li>
            <router-link :to="{ path: '/admin/system/player_apps' }"
              >Player Applications</router-link
            >
          </li>
          <li>
            <router-link :to="{ path: '/admin/system/slide_templates' }"
              >Slide Templates</router-link
            >
          </li>
          <li>
            <router-link :to="{ path: '/admin/system/terms' }">Term And Conditions</router-link>
          </li>
          <li><router-link :to="{ path: '/admin/system/roles' }">Roles</router-link></li>
          <li><router-link :to="{ path: '/admin/system/update' }">Update</router-link></li>
          <li><router-link :to="{ path: '/admin/system/hardwares' }">Hardware</router-link></li>
          <li><router-link :to="{ path: '/admin/system/languages' }">Language</router-link></li>
          <li>
            <router-link :to="{ path: '/admin/system/template_categories' }"
              >Template Category</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'admin-terms',

  components: {},
  data() {
    return {}
  },
  methods: {},
  computed: {},

  mounted() {}
}
</script>

<style lang="scss" scoped>
@import '../Adminpage.scss';
.term-nowrap {
  p {
    width: 100vh;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.text-area-control {
  width: 100%;
  border: 1px solid #ced4da;
  outline: none;
  height: 500px;
}
</style>
