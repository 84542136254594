import AdminOrganizationService from '../../../../services/admin-organization.service'
import CountriesService from '../../../../services/countries.service'
import StatesService from '../../../../services/states.service'
import AdminPermission from '../../../../services/admin-permission.service'
import AdminRoleService from '../../../../services/admin-role.service'

export default {
  getAllAdminOrganizations({ state, commit, rootState }, payload) {
    commit('SET_LOADING', { data: true })
    return AdminOrganizationService.getAllAdminOrganizations(
      payload.activePage,
      payload.limit,
      payload.search
    )
      .then((response) => {
        commit('SET_ITEMS', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', { data: false }))
  },

  getParentOrganizations({ state, commit, rootState }) {
    return AdminOrganizationService.getAllParentOrganizationNoPaging()
      .then((response) => {
        const data = [{ id: 0, text: 'Select a Organization' }]
        response.data.map((item) => {
          data.push({ id: item.id, text: item.name })
        })
        commit('SET_ERROR', '')
        commit('SET_PARENT_ITEMS', data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', { data: false }))
  },

  getListPricing({ state, commit, rootState }, payload) {
    return AdminOrganizationService.getListPricing()
      .then((response) => {
        const data = [{ id: 0, text: 'Select Pricing' }]
        response.data.map((exception) => {
          data.push({ id: exception.value, text: exception.name })
        })
        commit('GET_LIST_PRICING', data)
        commit('SET_ERROR', '')
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', { data: false }))
  },

  getListOrgtype({ state, commit, rootState }, payload) {
    return AdminOrganizationService.getListOrgtype()
      .then((response) => {
        const data = [{ id: 0, text: 'Select Org Type' }]
        response.data.items.map((exception) => {
          data.push({ id: exception.id, text: exception.name })
        })
        commit('GET_LIST_ORG_TYPE', data)
        commit('SET_ERROR', '')
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', { data: false }))
  },

  getListCountries({ state, commit, rootState }, payload) {
    return CountriesService.getAllCountries()
      .then((response) => {
        const data = [{ id: 0, text: 'Select a Country' }]
        response.data.map((exception) => {
          data.push({ id: exception.id, text: exception.name })
        })
        commit('SET_ERROR', '')
        commit('GET_LIST_COUNTRIES', data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', { data: false }))
  },

  getListStates({ state, commit, rootState }, payload) {
    return StatesService.getAllStates(payload)
      .then((response) => {
        const data = [{ id: 0, text: 'Select a Province/State' }]
        response.data.map((exception) => {
          data.push({ id: exception.code, text: exception.name })
        })
        commit('SET_ERROR', '')
        commit('GET_LIST_STATE', data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', { data: false }))
  },

  updateListStates({ state, commit, rootState }, payload) {
    commit('UPDATE_LIST_STATE', { id: payload.id, text: payload.text })
  },

  getDetailOrganization({ state, commit, rootState }, payload) {
    commit('SET_LOADING', { data: true })
    return AdminOrganizationService.detailOrganization(payload)
      .then((response) => {
        commit('SET_ERROR', '')
        commit('SET_ITEM_BY_ID', response.data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', { data: false }))
  },

  getDataTableOfOrganization({ commit }, payload) {
    commit('SET_LOADING', { data: true, type: payload.type })
    return AdminOrganizationService.getDataTableOfOrganization(
      payload.id,
      payload.type,
      payload.page,
      payload.limit,
      payload.search
    )
      .then((response) => {
        if (payload.type === 'players') {
          commit('SET_DATA_TABLE_PlAYER', response.data)
        } else if (payload.type === 'campaigns') {
          commit('SET_DATA_TABLE_CAMPAIGNS', response.data)
        } else if (payload.type === 'users') {
          commit('SET_DATA_TABLE_USERS', response.data)
        } else if (payload.type === 'roles') {
          commit('SET_ROLE_LIST_BY_ORG', response.data)
        }
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', { data: false, type: payload.type }))
  },

  getPermission({ commit }) {
    commit('SET_LOADING', { data: true, type: 'roles' })
    return AdminPermission.getPermissions()
      .then((response) => {
        const resData = response.data
        for (const item in resData) {
          resData[item] = resData[item].map((i) => {
            const newItem = {
              id: i.id,
              permission: i.permission,
              active: i.active
            }
            return newItem
          })
        }
        commit('SET_ERROR', '')
        commit('SET_PERMISSION_LIST', response.data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', { data: false, type: 'roles' }))
  },

  getRoleList({ commit }, payload) {
    commit('SET_LOADING', { data: true })
    return AdminRoleService.getRoleList(payload.page, payload.limit, payload.search)
      .then((response) => {
        commit('SET_ERROR', '')
        commit('SET_ROLE_LIST', response.data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', { data: false }))
  },

  getRoleListByOrganizationNoPaging({ commit }, payload) {
    return AdminRoleService.getRolesListByOrganizationsNoPaging(payload)
      .then((response) => {
        commit('SET_ROLE_LIST_BY_ORG_NO_PAGING', response.data.items)
      })
      .catch((error) => commit('SET_ERROR', error.message))
  },

  getRoleListNoPaging({ commit }, payload) {
    return AdminRoleService.getRolesListNoPaging(payload)
      .then((response) => {
        commit('SET_ROLE_LIST_NO_PAGING', response.data.items)
      })
      .catch((error) => commit('SET_ERROR', error.message))
  }
}
