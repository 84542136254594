<template>
  <div class="container-fluid">
    <div class="card-header top-bar">
      <div class="header-left">
        <div class="admin-search-box">
          <SfInputSearch
            placeholder="Search"
            v-debounce:500ms="searchInputChange"
            class="sf-input-search"
          />
        </div>
      </div>
      <div class="header-right">
        <button class="sf-primary-add" @click="showModalCreate">Add New Email</button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 sf-table">
          <b-table
            show-empty
            striped
            hover
            :items="items"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
            :busy="loading"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty="isEmpty">
              <p class="d-flex justify-content-center">No data available in table</p>
            </template>

            <template v-slot:head(email)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(email)="field">
              <p>{{ field.item.email }}</p>
            </template>

            <template v-slot:cell(action)="field">
              <button @click="shownModalEdit(field.item)" class="button-edit-table"></button>
              <button
                @click="shownModalDelete(field.item.id)"
                class="button-delete-table"
                title="Delete"
              ></button>
            </template>
          </b-table>

          <div v-if="!isEmpty" class="my-3">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="pagination.limit * pagination.total"
              :per-page="pagination.limit"
              @change="changePage"
              :limit="6"
              v-model="currentPage"
            />
          </div>
        </div>
      </div>
    </div>
    <SfModal
      title="Add New Email"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalCreate"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Email</label>
                </div>
                <input
                  v-model="newSetting.setting.email"
                  placeholder="Email..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newSetting.setting.email.required">
                Email Address required
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newSetting.setting.email.maxLength">
                Use less than 100 characters.
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newSetting.setting.email.email">
                Must be valid Email Address
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalCreate = false">Cancel</button>
        <button class="sf-primary" @click="saveSetting()">Save</button>
      </div>
    </SfModal>

    <SfModal
      title="Edit Customer"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingSettings">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Email</label>
                </div>
                <input
                  v-on:keyup="checkEmailExist(currentSettingID, currentSetting.setting.email)"
                  v-model="currentSetting.setting.email"
                  placeholder="Email..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentSetting.setting.email.required"
              >
                Email Address required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentSetting.setting.email.maxLength"
              >
                Use less than 100 characters.
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentSetting.setting.email.email">
                Must be valid Email Address
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEdit = false">Cancel</button>
        <button class="sf-primary" @click="updateSetting()">Save</button>
      </div>
    </SfModal>

    <SfDeleteModal :title="'Confirm Delete'" closeOnOverlay :show.sync="isShownModalDelete">
      <div class="content-delete">
        Deleting this email. Are you sure you want to delete this one?
      </div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShownModalDelete = false">Cancel</button>
        <button class="sf-primary" @click="removeSetting">OK</button>
      </div>
    </SfDeleteModal>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import AdminSettingsService from '@/services/admin-settings.service'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import SfInputSearch from '@/components/SfInputSearch.vue'
export default {
  name: 'admin-settings',
  components: {
    SfModal,
    SfDeleteModal,
    SfInputSearch
  },

  data() {
    return {
      msg: 'Welcome to Index!!!',
      isShownModalCreate: false,
      isShownModalDelete: false,
      isShownModalEdit: false,
      currentPage: 1,
      currentSettingID: '',
      submitted: false,
      fields: [
        {
          key: 'email',
          sortable: true,
          label: 'Email',
          colType: 'email'
        },
        {
          key: 'action',
          colType: 'button',
          label: 'Actions'
        }
      ],
      newSetting: {
        setting: {
          email: ''
        }
      },
      currentSetting: {
        setting: {
          email: ''
        }
      },
      defaultSetting: {
        setting: {
          email: ''
        }
      },
      dataSearch: '',
      loadingSettings: false
    }
  },

  validations: {
    newSetting: {
      setting: {
        email: {
          required,
          maxLength: maxLength(100),
          email
        }
      }
    },
    currentSetting: {
      setting: {
        email: {
          required,
          maxLength: maxLength(100),
          email
        }
      }
    }
  },

  methods: {
    fetchData(activePage, search) {
      this.$store.dispatch('adminsettings/getAllSettings', {
        page: activePage,
        limit: 10,
        search
      })
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage)
    },
    showModalCreate() {
      this.submitted = false
      this.setDataSetting(this.newSetting.setting, this.defaultSetting.setting)
      this.isShownModalCreate = true
    },
    shownModalDelete(setting_id) {
      this.isShownModalDelete = true
      this.currentSettingID = setting_id
    },
    shownModalEdit(setting) {
      this.submitted = false
      this.isShownModalEdit = true
      this.currentSettingID = setting.id
      this.setDataSetting(this.currentSetting.setting, setting)
      //   this.getItemByID()
    },
    saveSetting() {
      this.submitted = true
      if (this.$v.newSetting.$invalid) {
        return
      }
      AdminSettingsService.createSetting(this.newSetting)
        .then((response) => {
          this.$toast.success('Successfully created')
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalCreate = false
    },
    // getItemByID () {
    //   this.loadingSettings = true
    //   AdminSettingsService.detailSetting(this.currentSettingID)
    //     .then(response => {
    //       this.loadingSettings = false
    //       this.setDataSetting(this.currentSetting.setting, response.data)
    //     })
    //     .catch(err => {
    //       this.$toast.error(err.message)
    //     })
    // },
    updateSetting() {
      this.submitted = true
      if (this.$v.currentSetting.$invalid) {
        return
      }
      AdminSettingsService.updateSetting(this.currentSettingID, this.currentSetting)
        .then((response) => {
          this.$toast.success('Successfully updated')
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalEdit = false
    },
    setDataSetting(setting, new_setting) {
      setting.email = new_setting.email
    },
    removeSetting() {
      AdminSettingsService.removeSetting(this.currentSettingID)
        .then((response) => {
          this.$toast.success('Successfully deleted')
          if (this.items.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalDelete = false
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    }
  },

  computed: {
    ...mapState('adminsettings', {
      items: 'items',
      pagination: 'pagination',
      organizationOptions: 'organizationOptions',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('adminsettings', ['isEmpty'])
  },

  mounted() {
    this.fetchData(this.currentPage)
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    })
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  }
}
</script>

<style lang="scss" scoped>
@import '../../Adminpage.scss';
</style>
