<template>
  <div class="uikit">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="material-name">
            <h2>Modal</h2>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-4">
          <div class="material-title">Example of table</div>
        </div>
        <div class="col-6">
          <button @click="showModal = true" class="sf-primary">Example of table</button>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-4">
          <div class="material-title">Example of exception</div>
        </div>
        <div class="col-6">
          <button @click="showModalException = true" class="sf-primary">Add exception</button>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-4">
          <div class="material-title">Confirm delete modal</div>
        </div>
        <div class="col-6">
          <button @click="showDeleteModal = true" class="sf-primary">Confirm delete modal</button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="material-name">
            <h2>Cards</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="material-title">Card</div>
        </div>
        <div class="col-6">
          <div class="asset-container">
            <div draggable="true" class="element-item item image" style="width: 190px">
              <div class="detail-section sf-asset-card">
                <div class="img-name">
                  <div class="wrap-img image-img">
                    <div
                      data-fancybox="gallery"
                      href="https://simple-portal-media-staging.s3.amazonaws.com/smart_asset_details/asset_files/000/034/899/original/bb0c6d7f-5690-4b7e-835d-a34f98b61389.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Credential=AKIAIYZRPNHJEEXBHW3A%2F20200908%2Fus-east-1%2Fs3%2Faws4_request&amp;X-Amz-Date=20200908T102925Z&amp;X-Amz-Expires=86400&amp;X-Amz-SignedHeaders=host&amp;X-Amz-Signature=f8a0d5e5efb78f48a91f45e565398dd41aa4a426ca951a87be41bdf7abe3c136"
                      class="bg-gradient"
                    >
                      <div class="background-opacity"></div>
                      <img
                        src="https://simple-portal-media-staging.s3.amazonaws.com/smart_asset_details/asset_files/000/034/899/thumb/bb0c6d7f-5690-4b7e-835d-a34f98b61389.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Credential=AKIAIYZRPNHJEEXBHW3A%2F20200908%2Fus-east-1%2Fs3%2Faws4_request&amp;X-Amz-Date=20200908T102925Z&amp;X-Amz-Expires=86400&amp;X-Amz-SignedHeaders=host&amp;X-Amz-Signature=268e8fb5cbe89dca62388a8b3b5911110c7f4d75af7dd04278a1b547b7d4a817"
                        class="data-image"
                      />
                    </div>
                    <!---->
                  </div>
                  <div class="background-text"></div>
                  <div class="name asset-info">
                    <div class="wrap-p">
                      <p class="asset-name">
                        <span title="a9e720f4-6128-4d04-b77a-e42416a21784.jpg">Image name</span>
                      </p>
                      <p>2 months ago</p>
                    </div>
                    <div class="group-tags"></div>
                  </div>
                </div>
                <div class="date-action">
                  <div class="table-row">
                    <div class="wrap-size-date">
                      <div class="date-section">
                        <img src="@/assets/images/image-mini-icon.svg" />
                      </div>
                      <!---->
                    </div>
                    <div class="action-section">
                      <div role="group" class="btn-group btn-group-icon">
                        <button
                          title="Edit"
                          type="button"
                          class="btn btn-control-asset btn-edit edit-smart-asset"
                        >
                          <img src="@/assets/images/pencil-edit.svg" />
                        </button>
                        <!---->
                        <button
                          title="Delete"
                          type="button"
                          class="btn btn-control-asset btn-del btn-remove-media-asset"
                        >
                          <img src="@/assets/images/delete.svg" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="material-name">
            <h2>Chips</h2>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <div class="material-title">Chip 1</div>
        </div>
        <div class="col-4">
          <div class="sf-chip">
            <div class="d-flex flex-1 align-items-center">
              <img src="@/assets/images/close-x.svg" />
            </div>
            <div class="d-flex flex-10 flex-column">
              <label class="sf-primary-label">Primary label</label>
            </div>
            <div class="d-flex flex-1 align-items-center justify-content-end">
              <img src="@/assets/images/smart-assets/plus.svg" />
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-4">
          <div class="material-title">Chip 2</div>
        </div>
        <div class="col-4">
          <div class="sf-chip">
            <div class="d-flex flex-1 align-items-center">
              <img src="@/assets/images/close-x.svg" />
            </div>
            <div class="d-flex flex-10 flex-column">
              <label class="sf-primary-label">Primary label</label>
              <label class="sf-secondary-label">Secondary label</label>
            </div>
            <div class="d-flex flex-1 align-items-center justify-content-end">
              <img src="@/assets/images/smart-assets/plus.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <SfModal
      closeOnOverlay
      :title="'Edit picture'"
      @close="showModal = false"
      :width="768"
      :show.sync="showModal"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-12">
              <ul class="sf-tabs">
                <li>
                  <a class="active" tabindex="1">
                    <img :src="add" />
                    <span>Add</span>
                  </a>
                </li>
                <li>
                  <a tabindex="2">
                    <img :src="playlistEditor" />
                    <span>Customise</span>
                  </a>
                </li>
                <li>
                  <a tabindex="3">
                    <img :src="previewEye" />
                    <span>Overview</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-6 mt-3">
              <div class="row">
                <div class="col-3">Show</div>
                <div class="col-9">
                  <label class="sf-radio mb-1">
                    Loop
                    <input type="radio" checked="checked" name="radio" />
                    <span class="checkmark"></span>
                  </label>
                  <label class="sf-radio">
                    Campaign
                    <input type="radio" name="radio" />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-6 mt-3">
              <div class="sf-search-wrap">
                <input
                  type="text"
                  v-model="searchValue"
                  placeholder="Search loop"
                  class="sf-input-search"
                />
                <span
                  @click="onSearchClick"
                  v-bind:style="{ backgroundImage: `url(${getSearchIcon})` }"
                  class="sf-input-search-icon"
                ></span>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 sf-table">
              <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                head-variant="none"
                table-variant="none"
                thead-class="custom-b-table"
              >
                <template v-slot:empty="isEmpty">
                  <p class="d-flex justify-content-center">No data available in table</p>
                </template>
                <template v-slot:head(first_name)="field">
                  <span class="custome-arrows">{{ field.label }}</span>
                </template>
                <template v-slot:cell(first_name)="field">
                  <p>{{ field.item.first_name }} {{ field.item.last_name }}</p>
                </template>

                <template v-slot:head(email)="field">
                  <span class="custome-arrows">{{ field.label }}</span>
                </template>
                <template v-slot:cell(email)="field">
                  <p>{{ field.item.email }}</p>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
      <div class="sf-modal-footer">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-primary">Primary</button>
                <button class="sf-secondary">Secondary</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>

    <SfModal
      :title="'Add Exception'"
      closeOnOverlay
      @close="showModalException = false"
      :width="768"
      :show.sync="showModalException"
    >
      <div class="sf-modal-body sf-modal-exception">
        <div class="container">
          <div class="row mt-24">
            <div class="col-10">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Exception name</label>
                </div>
                <input type="text" placeholder="placeholder text" class="sf-input-text" />
              </div>
            </div>
          </div>
          <div class="row mt-24">
            <div class="col-8">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Exception name</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="selectValue"
                    :options="[
                      { id: '1', text: 'Option 1' },
                      { id: '2', text: 'Option 2' },
                      { id: '3', text: 'Option 3' }
                    ]"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-24">
            <div class="col-10">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Date range</label>
                </div>
                <div class="sf-checkbox-group">
                  <label class="sf-checkbox">
                    <span class="check-title">Yes</span>
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-24">
            <div class="col-12">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Date range</label>
                </div>
                <div class="sf-exception-time-picker">
                  <div class="time-picker">
                    <div class="body-date">
                      <div class="d-flex input-date">
                        <input value="00" type="text" />
                        <p>:</p>
                        <input value="00" type="text" />
                      </div>
                      <div class="d-flex justify-content-center align-items-center content-date">
                        <div>
                          <button type="button" class="up-time"></button>
                          <p>11</p>
                          <button type="button" class="down-time"></button>
                        </div>
                        <div>:</div>
                        <div>
                          <button type="button" class="up-time"></button>
                          <p>02</p>
                          <button type="button" class="down-time"></button>
                        </div>
                      </div>
                      <!---->
                      <!---->
                    </div>
                  </div>
                  <div class="word-time">to</div>
                  <div class="time-picker">
                    <div class="body-date">
                      <div class="d-flex input-date">
                        <input type="text" value="00" />
                        <p>:</p>
                        <input value="00" type="text" />
                      </div>
                      <div class="d-flex justify-content-center align-items-center content-date">
                        <div>
                          <button type="button" class="up-time"></button>
                          <p>00</p>
                          <button type="button" class="down-time"></button>
                        </div>
                        <div>:</div>
                        <div>
                          <button type="button" class="up-time"></button>
                          <p>00</p>
                          <button type="button" class="down-time"></button>
                        </div>
                      </div>
                      <!---->
                      <!---->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-24">
            <div class="col-10">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Day</label>
                </div>
                <div class="sf-checkbox-group">
                  <label class="sf-checkbox">
                    <span class="check-title">Every day</span>
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-1 mb-24">
            <div class="col-12">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label"></label>
                </div>
                <div class="sf-checkbox-group">
                  <div class="d-flex sf-day-picker">
                    <div class>
                      <p>Sun</p>
                    </div>
                    <div class>
                      <p>Mon</p>
                    </div>
                    <div class>
                      <p>Tue</p>
                    </div>
                    <div class>
                      <p>Wed</p>
                    </div>
                    <div class>
                      <p>Thu</p>
                    </div>
                    <div class>
                      <p>Fri</p>
                    </div>
                    <div class>
                      <p>Sat</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sf-modal-footer sf-modal-exception">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-primary">Primary</button>
                <button class="sf-secondary">Secondary</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>
    <SfDeleteModal :title="'Confirm Delete'" closeOnOverlay :show.sync="showDeleteModal">
      <div class="sf-modal-body-delete">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <p class="mb-3">
                Deleting a folder will also delete the uploaded files in that folder as well.
              </p>
              <p>Are you sure you want to delete this folder?</p>
            </div>
          </div>
        </div>
      </div>
      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" @click="showDeleteModal = false">Cancel</button>
                <button class="sf-secondary sf-btn-confirm-delete" @click="confirmDelete">
                  Confirm delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfDeleteModal>
    <router-view></router-view>
  </div>
</template>

<script>
import SfModal from '@/components/SfModal'
import SfDeleteModal from '@/components/SfDeleteModal'
import closeIcon from '@/assets/images/close-x.svg'
import folderIcon from '@/assets/images/folder.svg'
import pencilEditIcon from '@/assets/images/pencil-edit.svg'
import shareIcon from '@/assets/images/share.svg'
import playlistEditor from '@/assets/images/playlist-editor.svg'
import previewEye from '@/assets/images/preview-eye.svg'
import add from '@/assets/images/add.svg'
import searchIcon from '@/assets/images/sf-search-icon.svg'
import Select2 from 'v-select2-component'
export default {
  name: 'account',
  components: {
    SfModal,
    SfDeleteModal,
    Select2
  },
  data() {
    return {
      showModal: false,
      showModalException: false,
      showDeleteModal: false,
      closeIcon,
      folderIcon,
      pencilEditIcon,
      playlistEditor,
      shareIcon,
      previewEye,
      add,
      searchValue: '',
      items: [
        {
          age: 40,
          first_name: 'Dickerson',
          last_name: 'Macdonald',
          email: 'test@gmail.com'
        },
        {
          age: 21,
          first_name: 'Larsen',
          last_name: 'Shaw',
          email: 'test@gmail.com'
        },
        {
          age: 89,
          first_name: 'Geneva',
          last_name: 'Wilson',
          email: 'test@gmail.com'
        },
        {
          age: 38,
          first_name: 'Jami',
          last_name: 'Carney',
          email: 'test@gmail.com'
        },
        {
          age: 40,
          first_name: 'Dickerson',
          last_name: 'Macdonald',
          email: 'test@gmail.com'
        },
        {
          age: 21,
          first_name: 'Larsen',
          last_name: 'Shaw',
          email: 'test@gmail.com'
        },
        {
          age: 89,
          first_name: 'Geneva',
          last_name: 'Wilson',
          email: 'test@gmail.com'
        },
        {
          age: 38,
          first_name: 'Jami',
          last_name: 'Carney',
          email: 'test@gmail.com'
        }
      ],
      fields: [
        {
          key: 'first_name',
          colType: 'first_name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'email',
          colType: 'email',
          label: 'Email',
          sortable: true
        },
        {
          key: 'headercell',
          colType: 'headercell',
          label: 'Header cell 1'
        },
        {
          key: 'headercell2',
          colType: 'headercell2',
          label: 'Header cell 2'
        },
        {
          key: 'headercell3',
          colType: 'headercell3',
          label: 'Header cell 3'
        }
      ]
    }
  },
  created() {
    // window.location.href = '/user/organization';
  },
  methods: {
    openModal() {},
    confirmDelete() {
      this.showDeleteModal = false
    },
    onSearchClick() {
      if (this.searchValue) {
        this.searchValue = ''
      }
    }
  },
  mounted() {},
  computed: {
    getSearchIcon() {
      if (this.searchValue) {
        return closeIcon
      } else {
        return searchIcon
      }
    }
  }
}
</script>

<style lang="scss"></style>
