<template>
  <div>
    <div class="d-flex justify-content-between my-3">
      <!-- <input v-debounce:400ms="searchInputChange" :value="searchValue" class="search-input" type="text" placeholder="Search"> -->
      <SfInputSearch
        v-model="searchValue"
        placeholder="Search"
        v-debounce:500ms="searchInputChange"
        class="sf-input-search"
      />
      <div>
        <button class="sf-primary" @click="shownModalAddUser">Add New</button>
      </div>
    </div>
    <div class="sf-table">
      <b-table
        :items="dataTableUser.items"
        show-empty
        hover
        :fields="fields"
        head-variant="none"
        table-variant="none"
        thead-class="custom-b-table"
        :busy="loadingOfUser"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:head(first_name)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(first_name)="field">
          <p>
            <router-link :to="`/users/${field.item.id}/view-user`"
              >{{ field.item.first_name }} {{ field.item.last_name }}
            </router-link>
          </p>
        </template>

        <template v-slot:head(email)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(email)="field">
          <p>{{ field.item.email }}</p>
        </template>

        <template v-slot:head(role)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(role)="field">
          <p>{{ field.item.role ? field.item.role.displayname : '' }}</p>
        </template>

        <template v-slot:head(direct_login)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(direct_login)="field">
          <p>{{ field.item.direct_login ? 'Enabled' : 'Disabled' }}</p>
        </template>

        <template v-slot:head(is_2fa)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(is_2fa)="field">
          <p>{{ field.item.is_2fa ? 'Enabled' : 'Disabled' }}</p>
        </template>

        <template v-slot:head(actions)="field">
          <span>{{ field.label }}</span>
        </template>

        <template v-slot:cell(actions)="field">
          <button
            @click="showModalEditUser(field.item.id)"
            title="Edit"
            class="button-edit-table"
          ></button>
          <button
            @click="showModalDeleteUser(field.item.id)"
            title="Delete"
            class="button-delete-table"
          ></button>
        </template>
      </b-table>
    </div>

    <div class="my-3">
      <b-pagination
        class="sf-b-pagination"
        v-model="currentPage"
        :total-rows="10 * dataTableUser.total_pages"
        :per-page="10"
        @change="changePage"
        :limit="6"
      />
    </div>

    <!-- Modal Add new User -->
    <SfModal
      title="Add New User"
      :icon="'fas fa-plus-circle'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalAddUser"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">First Name</label>
                </div>
                <input
                  v-model="newUser.user.first_name"
                  placeholder="First Name..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.first_name.required">
                First Name required
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.first_name.maxLength">
                Use less than 250 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Last Name</label>
                </div>
                <input
                  v-model="newUser.user.last_name"
                  placeholder="Last Name..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.last_name.required">
                Last Name required
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.last_name.maxLength">
                Use less than 250 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Email</label>
                </div>
                <input
                  v-on:keyup="checkEmailExist('', newUser.user.email)"
                  v-model="newUser.user.email"
                  placeholder="Email..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.email.required">
                Email Address required
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.email.maxLength">
                Use less than 100 characters
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.email.email">
                Must be valid Email Address
              </div>
              <div class="invalid-error" v-if="!emailCanUsed">Email is currently used</div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Role</label>
                </div>
                <div
                  class="sf-select-group"
                  :class="{ 'input-error': submitted && $v.newUser.user.role_id.$invalid }"
                >
                  <Select2
                    class="sf-select2"
                    v-model="newUser.user.role_id"
                    :options="roleListNoPagingOptions"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.role_id.required">
                Select a Role
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Password</label>
                </div>
                <input
                  v-model="newUser.user.password"
                  :type="'password'"
                  placeholder="Create a password"
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newUser.user.password.strongPassword"
              >
                Password must be at least 8 characters, with at least 1 capital and 1 number
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Confirm Password</label>
                </div>
                <input
                  v-model="newUser.user.password_confirm"
                  :type="'password'"
                  placeholder="Retype your Password"
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newUser.user.password_confirm.sameAsPassword"
              >
                Passwords must be identical
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalAddUser = false">Cancel</button>
        <button class="sf-primary" @click="addNewUser">Save</button>
      </div>
    </SfModal>

    <!-- Admin Modal Export -->
    <SfModal
      title="Export File"
      :width="400"
      :icon="'fas fa-plus-circle'"
      closeOnOverlay
      :show.sync="isShownModalExport"
    >
      <div class="row exception-row custom-row d-flex justify-content-center">
        <p>Do you want to export file?</p>
      </div>
      <div class="footer-button d-flex justify-content-center">
        <button @click="isShownModalExport = false">Cancel</button>
        <button>Save</button>
      </div>
    </SfModal>

    <!-- Admin Modal Edit -->
    <SfModal title="Edit User" :width="768" closeOnOverlay :show.sync="isShownModalEditUser">
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingUser">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">First Name</label>
                </div>
                <input
                  v-model="currentUser.user.first_name"
                  placeholder="First Name ..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.first_name.required"
              >
                First Name required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.first_name.maxLength"
              >
                Use less than 250 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingUser">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Last Name</label>
                </div>
                <input
                  v-model="currentUser.user.last_name"
                  placeholder="Last Name ..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.last_name.required"
              >
                Last Name required.
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.last_name.maxLength"
              >
                Use less than 250 characters.
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingUser">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Email</label>
                </div>
                <input
                  v-on:keyup="checkEmailExist(currentUserID, currentUser.user.email)"
                  v-model="currentUser.user.email"
                  placeholder="Email..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentUser.user.email.required">
                Email Address required.
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentUser.user.email.maxLength">
                Use less than 100 characters.
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentUser.user.email.email">
                Must be valid Email Address.
              </div>
              <div class="invalid-error" v-if="!emailCanUsed">Email is currently used</div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingUser">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Direct Login</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="currentUser.user.direct_login"
                    :sync="true"
                    @change="onChangeToggleDetail"
                    :width="44"
                    :height="22"
                    :margin="2"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3" v-if="show2FaToogle">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingUser">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">2FA</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="currentUser.user.is_2fa"
                    :sync="true"
                    @change="onChangeToggleDetail2fa"
                    :width="44"
                    :height="22"
                    :margin="2"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingUser">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Role</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="currentUser.user.role_id"
                    :options="roleListNoPagingOptions"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentUser.user.role_id.required">
                Please select Role
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingUser">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Password</label>
                </div>
                <input
                  :type="'password'"
                  v-model="currentUser.user.password"
                  placeholder="Create a password"
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.password.strongPassword"
              >
                Password must be at least 8 characters, with at least 1 capital and 1 number
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingUser">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Confirm Password</label>
                </div>
                <input
                  :type="'password'"
                  v-model="currentUser.user.password_confirm"
                  placeholder="Retype your Password"
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.password_confirm.sameAsPassword"
              >
                Passwords must be identical
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEditUser = false">Cancel</button>
        <button class="sf-primary" @click="updateUser">Save</button>
      </div>
    </SfModal>

    <!-- Admin Delete Modal -->
    <SfDeleteModal :title="'Delete User'" closeOnOverlay :show.sync="isShownModalDelete">
      <div class="content-delete">
        {{ 'confirm-delete-message' }}
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalDelete = false">Cancel</button>
        <button class="sf-primary" @click="deleteUser">OK</button>
      </div>
    </SfDeleteModal>
  </div>
</template>
<script>
import SfModal from '@/components/SfModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import { required, maxLength, email, sameAs, requiredIf } from 'vuelidate/lib/validators'
import AdminUserService from '@/services/admin-user.service'
import UsersService from '@/services/users.service'
import { mapActions, mapState, mapGetters } from 'vuex'
import Select2 from 'v-select2-component'
import SfInputSearch from '@/components/SfInputSearch.vue'
export default {
  name: 'userTable',
  components: {
    SfModal,
    SfDeleteModal,
    Select2,
    SfInputSearch
  },
  props: {
    dataTable: {},
    org_id: {},
    show: {}
  },
  data() {
    return {
      fields: [
        {
          key: 'first_name',
          colType: 'first_name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'email',
          colType: 'email',
          label: 'Email',
          sortable: true
        },
        {
          key: 'role',
          sortable: true,
          colType: 'role',
          label: 'Role'
        },
        {
          key: 'direct_login',
          sortable: true,
          colType: 'direct_login',
          label: 'Direct Login'
        },
        {
          key: 'is_2fa',
          sortable: true,
          colType: 'is_2fa',
          label: '2FA'
        },
        {
          key: 'ACTIONS',
          colType: 'button',
          label: 'Actions'
        }
      ],
      isShownModalAddUser: false,
      isShownModalExport: false,
      isShownModalEditUser: false,
      isShownModalDelete: false,
      currentPage: 1,
      newUser: {
        user: {
          first_name: '',
          last_name: '',
          email: '',
          role_id: '',
          organization_id: '',
          user_type: 'customer',
          password: '',
          password_confirm: ''
        }
      },
      defaultUser: {
        user: {
          first_name: '',
          last_name: '',
          email: '',
          role_id: '',
          organization_id: '',
          password: '',
          password_confirm: '',
          user_type: 'customer',
          direct_login: false,
          is_2fa: false
        }
      },
      currentUser: {
        user: {
          first_name: '',
          last_name: '',
          email: '',
          role_id: '',
          password: '',
          password_confirm: '',
          user_type: '',
          direct_login: false,
          is_2fa: false
        }
      },
      submitted: false,
      emailCanUsed: true,
      currentUserID: '',
      searchValue: '',
      show2FaToogle: false,
      loadingUser: true
    }
  },
  validations: {
    newUser: {
      user: {
        first_name: {
          required,
          maxLength: maxLength(250)
        },
        last_name: {
          required,
          maxLength: maxLength(250)
        },
        email: {
          required,
          maxLength: maxLength(100),
          email
        },
        role_id: {
          required
        },
        password: {
          strongPassword(password) {
            return (
              /[A-Z]/.test(password) && // checks for A-Z
              /[0-9]/.test(password) && // checks for 0-9
              password.length >= 8
            )
          }
        },
        password_confirm: {
          sameAsPassword: sameAs('password')
        }
      }
    },
    currentUser: {
      user: {
        first_name: {
          required,
          maxLength: maxLength(250)
        },
        last_name: {
          required,
          maxLength: maxLength(250)
        },
        email: {
          required,
          maxLength: maxLength(100),
          email
        },
        role_id: {
          required: requiredIf(function (data) {
            return data.user_type !== 'employee'
          })
        },
        password: {
          strongPassword(password) {
            return (
              (/[A-Z]/.test(password) && // checks for A-Z
                /[0-9]/.test(password) && // checks for 0-9
                password.length >= 8 &&
                password !== '') ||
              password === ''
            )
          }
        },
        password_confirm: {
          sameAsPassword: sameAs('password')
        }
      }
    }
  },
  methods: {
    ...mapActions('adminorganization', {
      getDataTableOfOrganization: 'getDataTableOfOrganization',
      getRoleListByOrganizationNoPaging: 'getRoleListByOrganizationNoPaging'
    }),
    shownModalAddUser() {
      this.isShownModalAddUser = true
      this.submitted = false
      this.setDataUser(this.newUser.user, this.defaultUser.user)
      this.getRoleListByOrganizationNoPaging(this.org_id)
    },
    showModalEditUser(id) {
      this.isShownModalEditUser = true
      this.emailCanUsed = true
      this.currentUserID = id
      this.getItemByID()
      this.getRoleListByOrganizationNoPaging(this.org_id)
      this.submitted = false
    },
    getItemByID() {
      this.loadingUser = true
      AdminUserService.detailUser(this.currentUserID)
        .then((response) => {
          this.loadingUser = false
          this.setDataUser(this.currentUser.user, response.data)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
    },
    showModalDeleteUser(id) {
      this.isShownModalDelete = true
      this.currentUserID = id
    },
    addNewUser() {
      this.submitted = true
      this.newUser.user.organization_id = this.org_id
      if (this.$v.newUser.$invalid || !this.emailCanUsed) {
        return
      }
      delete this.newUser.user.direct_login
      delete this.newUser.user.is_2fa
      AdminUserService.createUser(this.newUser).then((_) => {
        this.isShownModalAddUser = false
        this.$toast.success('Successfully created')
        this.fetchData(this.currentPage, this.searchValue)
      })
    },
    setDataUser(user, new_user) {
      user.first_name = new_user.first_name
      user.last_name = new_user.last_name
      user.email = new_user.email
      user.role_id = new_user.role_id ? new_user.role_id : ''
      user.user_type = new_user.user_type
      user.direct_login = new_user.direct_login
      user.is_2fa = new_user.is_2fa
      this.show2FaToogle = new_user.is_2fa
    },
    checkEmailExist(id, email) {
      UsersService.checkEmailExist({ user_id: id, email: email }).then((res) => {
        this.emailCanUsed = res.data.can_use
      })
    },
    updateUser() {
      this.submitted = true
      if (this.$v.currentUser.$invalid || !this.emailCanUsed) {
        return
      }
      AdminUserService.updateUser(this.currentUserID, this.currentUser)
        .then((response) => {
          this.$toast.success('Successfully updated')
          this.isShownModalEditUser = false
          this.fetchData(this.currentPage, this.searchValue)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalEdit = false
    },
    searchInputChange(val) {
      this.searchValue = val
      this.currentPage = 1
      this.fetchData(this.currentPage, this.searchValue)
    },
    fetchData(currentPage, search) {
      this.getDataTableOfOrganization({
        id: this.org_id,
        type: 'users',
        page: currentPage,
        limit: 25,
        search: this.searchValue
      })
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.searchValue)
    },
    deleteUser() {
      UsersService.deleteUser(this.currentUserID).then((_) => {
        this.$toast.success('Successfully delete')
        this.isShownModalDelete = false
        if (this.dataTableUser.length === 1 && this.currentPage > 1) {
          this.currentPage = this.currentPage - 1
        }
        this.fetchData(this.currentPage, this.searchValue)
      })
    },
    onChangeToggleDetail(value) {
      this.currentUser.user.direct_login = value.value
    },
    onChangeToggleDetail2fa(value) {
      this.currentUser.user.is_2fa = value.value
    }
  },
  watch: {
    show(value) {
      if (value === 'users') {
        this.fetchData(this.currentPage, this.searchValue)
      }
    }
  },
  computed: {
    ...mapState('adminorganization', {
      dataTableUser: 'dataTableUser',
      loadingOfUser: 'loadingOfUser'
    }),
    ...mapGetters('adminorganization', {
      roleListNoPagingOptions: 'roleListNoPagingOptions'
    })
  }
}
</script>
<style lang="scss" scoped>
@import './bTable.scss';
@import '@/pages/admin/Adminpage.scss';
</style>
