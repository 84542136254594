<template>
  <div class="admin-page">
    <div class="admin-page-header">
      <div class="col-md-12 admin-page-menu">
        <ul class="nav nav-tabs nav-tabs-custom">
          <li>
            <router-link :to="{ path: '/admin/management/org_types' }">ORG Types</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/admin/management/users/employees' }">Employees</router-link>
          </li>
          <li><router-link :to="{ path: '/admin/management/users/posts' }">Posts</router-link></li>
          <li>
            <router-link :to="{ path: '/admin/management/users/templates' }">Templates</router-link>
          </li>
        </ul>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'adminTemplate',

  components: {},

  data() {
    return {}
  },

  methods: {},

  computed: {},

  mounted() {}
}
</script>

<style lang="scss" scoped>
@import '../Adminpage.scss';
.alt {
  border-top: 1px dashed #cccccc;
}
.short {
  margin: 5px 0;
}
</style>
