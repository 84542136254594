<template>
  <div class="container-fluid">
    <div class="card-header">
      <div class="header-left">Tax</div>
      <div class="header-right">
        <button
          class="ajax-popup-add-new-post btn btn-primary btn-success hidden-xs pull-right"
          @click="showModalCreate()"
        >
          <i aria-hidden="true" class="fa fa-plus-circle fa-lg"></i>
          Add New Tax
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 table-wraper custom-wrapper">
          <b-table
            :busy="loading"
            show-empty
            striped
            hover
            :items="items"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty="isEmpty">
              <p class="d-flex justify-content-center">No data available in table</p>
            </template>
            <template v-slot:head(country_name)="field">
              <span class="custome-arrows"></span><span>{{ field.label.toUpperCase() }}</span>
            </template>
            <template v-slot:cell(country_name)="field">
              <p>{{ field.item.country_name }}</p>
            </template>

            <template v-slot:head(tax_region)="field">
              <span class="custome-arrows"></span><span>{{ field.label.toUpperCase() }}</span>
            </template>
            <template v-slot:cell(tax_region)="field">
              <p>{{ field.item.tax_region }}</p>
            </template>

            <template v-slot:head(state_rate)="field">
              <span class="custome-arrows"></span><span>{{ field.label.toUpperCase() }}</span>
            </template>
            <template v-slot:cell(state_rate)="field">
              <p>{{ field.item.state_rate }}</p>
            </template>

            <template v-slot:head(combined_rate)="field">
              <span class="custome-arrows"></span><span>{{ field.label.toUpperCase() }}</span>
            </template>
            <template v-slot:cell(combined_rate)="field">
              <p>{{ field.item.combined_rate }}</p>
            </template>

            <template v-slot:head(country_rate)="field">
              <span class="custome-arrows"></span><span>{{ field.label.toUpperCase() }}</span>
            </template>
            <template v-slot:cell(country_rate)="field">
              <p>{{ field.item.country_rate }}</p>
            </template>

            <template v-slot:head(city_rate)="field">
              <span class="custome-arrows"></span><span>{{ field.label.toUpperCase() }}</span>
            </template>
            <template v-slot:cell(city_rate)="field">
              <p>{{ field.item.city_rate }}</p>
            </template>

            <template v-slot:head(special_rate)="field">
              <span class="custome-arrows"></span><span>{{ field.label.toUpperCase() }}</span>
            </template>
            <template v-slot:cell(special_rate)="field">
              <p>{{ field.item.special_rate }}</p>
            </template>

            <template v-slot:cell(action)="field">
              <button
                @click="shownModalEdit(field.item.id)"
                class="button-edit-table"
                title="Edit"
              ></button>
              <button
                @click="shownModalDelete(field.item.id)"
                class="button-delete-table"
                title="Delete"
              ></button>
            </template>
          </b-table>

          <div v-if="!isEmpty" class="pagination-campaign">
            <b-pagination
              :total-rows="pagination.limit * pagination.total"
              :per-page="pagination.limit"
              @change="changePage"
              :limit="6"
              v-model="currentPage"
              prev-text="Previous"
              next-text="Next"
            />
          </div>
        </div>
      </div>
    </div>
    <AdminCreateModal
      :title="'Create New Tax'"
      :icon="'fa fa-user'"
      :width="1000"
      closeOnOverlay
      :show.sync="isShownModalCreate"
    >
      <div class="row exception-row">
        <div class="col-4">
          <div
            class="select-group mb-1"
            :class="{ 'input-error': submitted && $v.newTax.tax.country_id.$invalid }"
          >
            <Select2
              v-model="newTax.tax.country_id"
              :options="countryOptions"
              @change="onChangeCountry($event)"
            />
            <span class="lb-right caret"></span>
          </div>
          <div class="invalid-error" v-if="submitted && !$v.newTax.tax.country_id.required">
            Change country
          </div>
        </div>
        <div class="col-4">
          <div
            class="select-group mb-1"
            :class="{ 'input-error': submitted && $v.newTax.tax.state_id.$invalid }"
          >
            <Select2 v-model="newTax.tax.state_id" :options="provinceStateOptions" />
            <span class="lb-right caret"></span>
          </div>
          <div class="invalid-error" v-if="submitted && !$v.newTax.tax.state_id.required">
            Change state
          </div>
        </div>
      </div>

      <div class="row exception-row">
        <div class="col-4">
          <div
            class="input-group mb-1"
            :class="{ 'input-error': submitted && $v.newTax.tax.tax_region.$invalid }"
          >
            <input
              v-model="newTax.tax.tax_region"
              placeholder="Tax region..."
              class="form-control"
            />
          </div>
          <div class="invalid-error" v-if="submitted && !$v.newTax.tax.tax_region.required">
            Tax region required
          </div>
          <div class="invalid-error" v-if="submitted && !$v.newTax.tax.tax_region.maxLength">
            Use less than 250 characters.
          </div>
        </div>
        <div class="col-4">
          <div
            class="input-group mb-1"
            :class="{ 'input-error': submitted && $v.newTax.tax.state_rate.$invalid }"
          >
            <input
              v-model="newTax.tax.state_rate"
              placeholder="State rate..."
              class="form-control"
            />
          </div>
          <div class="invalid-error" v-if="submitted && !$v.newTax.tax.state_rate.required">
            State rate required
          </div>
          <div class="invalid-error" v-if="submitted && !$v.newTax.tax.state_rate.maxLength">
            Use less than 250 characters
          </div>
        </div>
        <div class="col-4">
          <div
            class="input-group mb-1"
            :class="{ 'input-error': submitted && $v.newTax.tax.combined_rate.$invalid }"
          >
            <input
              v-model="newTax.tax.combined_rate"
              placeholder="Combined rate..."
              class="form-control"
            />
          </div>
          <div class="invalid-error" v-if="submitted && !$v.newTax.tax.combined_rate.required">
            Combined required
          </div>
          <div class="invalid-error" v-if="submitted && !$v.newTax.tax.combined_rate.maxLength">
            Use less than 250 characters
          </div>
        </div>
      </div>

      <div class="row exception-row">
        <div class="col-4">
          <div
            class="input-group mb-1"
            :class="{ 'input-error': submitted && $v.newTax.tax.country_rate.$invalid }"
          >
            <input
              v-model="newTax.tax.country_rate"
              placeholder="Tax region..."
              class="form-control"
            />
          </div>
          <div class="invalid-error" v-if="submitted && !$v.newTax.tax.country_rate.required">
            Tax region required
          </div>
          <div class="invalid-error" v-if="submitted && !$v.newTax.tax.country_rate.maxLength">
            Use less than 250 characters
          </div>
        </div>
        <div class="col-4">
          <div
            class="input-group mb-1"
            :class="{ 'input-error': submitted && $v.newTax.tax.city_rate.$invalid }"
          >
            <input v-model="newTax.tax.city_rate" placeholder="City rate..." class="form-control" />
          </div>
          <div class="invalid-error" v-if="submitted && !$v.newTax.tax.city_rate.required">
            State rate required
          </div>
          <div class="invalid-error" v-if="submitted && !$v.newTax.tax.city_rate.maxLength">
            Use less than 250 characters.
          </div>
        </div>
        <div class="col-4">
          <div
            class="input-group mb-1"
            :class="{ 'input-error': submitted && $v.newTax.tax.special_rate.$invalid }"
          >
            <input
              v-model="newTax.tax.special_rate"
              placeholder="Special rate..."
              class="form-control"
            />
          </div>
          <div class="invalid-error" v-if="submitted && !$v.newTax.tax.special_rate.required">
            Combined required
          </div>
          <div class="invalid-error" v-if="submitted && !$v.newTax.tax.special_rate.maxLength">
            Use less than 250 characters.
          </div>
        </div>
      </div>
      <div class="footer-button">
        <button @click="isShownModalCreate = false">Cancel</button>
        <button @click="saveTax()">Save</button>
      </div>
    </AdminCreateModal>
    <AdminEditModal
      :title="'Edit Tax'"
      :icon="'fa fa-user'"
      :width="1000"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="row exception-row">
        <div class="col-4">
          <div
            class="select-group mb-1"
            :class="{ 'input-error': submitted && $v.currentTax.tax.country_id.$invalid }"
          >
            <Select2
              v-model="currentTax.tax.country_id"
              :options="countryOptions"
              @change="onChangeCountry($event)"
            />
            <span class="lb-right caret"></span>
          </div>
          <div class="invalid-error" v-if="submitted && !$v.currentTax.tax.country_id.required">
            Change country
          </div>
        </div>
        <div class="col-4">
          <div
            class="select-group mb-1"
            :class="{ 'input-error': submitted && $v.currentTax.tax.state_id.$invalid }"
          >
            <Select2 v-model="currentTax.tax.state_id" :options="provinceStateOptions" />
            <span class="lb-right caret"></span>
          </div>
          <div class="invalid-error" v-if="submitted && !$v.currentTax.tax.state_id.required">
            Change state
          </div>
        </div>
      </div>

      <div class="row exception-row">
        <div class="col-4">
          <div
            class="input-group mb-1"
            :class="{ 'input-error': submitted && $v.currentTax.tax.tax_region.$invalid }"
          >
            <input
              v-model="currentTax.tax.tax_region"
              placeholder="Tax region..."
              class="form-control"
            />
          </div>
          <div class="invalid-error" v-if="submitted && !$v.currentTax.tax.tax_region.required">
            Tax region is required
          </div>
          <div class="invalid-error" v-if="submitted && !$v.currentTax.tax.tax_region.maxLength">
            Use less than 250 characters.
          </div>
        </div>
        <div class="col-4">
          <div
            class="input-group mb-1"
            :class="{ 'input-error': submitted && $v.currentTax.tax.state_rate.$invalid }"
          >
            <input
              v-model="currentTax.tax.state_rate"
              placeholder="State rate..."
              class="form-control"
            />
          </div>
          <div class="invalid-error" v-if="submitted && !$v.currentTax.tax.state_rate.required">
            State rate is required
          </div>
          <div class="invalid-error" v-if="submitted && !$v.currentTax.tax.state_rate.maxLength">
            Use less than 250 characters.
          </div>
        </div>
        <div class="col-4">
          <div
            class="input-group mb-1"
            :class="{ 'input-error': submitted && $v.currentTax.tax.combined_rate.$invalid }"
          >
            <input
              v-model="currentTax.tax.combined_rate"
              placeholder="Combined rate..."
              class="form-control"
            />
          </div>
          <div class="invalid-error" v-if="submitted && !$v.currentTax.tax.combined_rate.required">
            Combined is required
          </div>
          <div class="invalid-error" v-if="submitted && !$v.currentTax.tax.combined_rate.maxLength">
            Use less than 250 characters.
          </div>
        </div>
      </div>

      <div class="row exception-row">
        <div class="col-4">
          <div
            class="input-group mb-1"
            :class="{ 'input-error': submitted && $v.currentTax.tax.country_rate.$invalid }"
          >
            <input
              v-model="currentTax.tax.country_rate"
              placeholder="Tax region..."
              class="form-control"
            />
          </div>
          <div class="invalid-error" v-if="submitted && !$v.currentTax.tax.country_rate.required">
            Tax region is required
          </div>
          <div class="invalid-error" v-if="submitted && !$v.currentTax.tax.country_rate.maxLength">
            Use less than 250 characters.
          </div>
        </div>
        <div class="col-4">
          <div
            class="input-group mb-1"
            :class="{ 'input-error': submitted && $v.currentTax.tax.city_rate.$invalid }"
          >
            <input
              v-model="currentTax.tax.city_rate"
              placeholder="City rate..."
              class="form-control"
            />
          </div>
          <div class="invalid-error" v-if="submitted && !$v.currentTax.tax.city_rate.required">
            State rate is required
          </div>
          <div class="invalid-error" v-if="submitted && !$v.currentTax.tax.city_rate.maxLength">
            Use less than 250 characters.
          </div>
        </div>
        <div class="col-4">
          <div
            class="input-group mb-1"
            :class="{ 'input-error': submitted && $v.currentTax.tax.special_rate.$invalid }"
          >
            <input
              v-model="currentTax.tax.special_rate"
              placeholder="Special rate..."
              class="form-control"
            />
          </div>
          <div class="invalid-error" v-if="submitted && !$v.currentTax.tax.special_rate.required">
            Combined is required
          </div>
          <div class="invalid-error" v-if="submitted && !$v.currentTax.tax.special_rate.maxLength">
            Use less than 250 characters.
          </div>
        </div>
      </div>

      <div class="footer-button">
        <button @click="isShownModalEdit = false">Cancel</button>
        <button @click="updateTax()">Save</button>
      </div>
    </AdminEditModal>
    <AdminDeleteModal :title="'Confirm Delete'" closeOnOverlay :show.sync="isShownModalDelete">
      <div class="content-delete">
        Deleting this post. Are you sure you want to delete this one?
      </div>
      <div class="footer-button delete-confirm">
        <button @click="isShownModalDelete = false">Cancel</button>
        <button @click="removeTax()">OK</button>
      </div>
    </AdminDeleteModal>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import AdminCreateModal from '@/components/admin/AdminCreateModal.vue'
import AdminEditModal from '@/components/admin/AdminEditModal.vue'
import AdminDeleteModal from '@/components/admin/AdminDeleteModal.vue'
import AdminTaxService from '@/services/admin-tax.service'
import Select2 from 'v-select2-component'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'admin-taxes',

  components: {
    AdminCreateModal,
    AdminEditModal,
    AdminDeleteModal,
    Select2
  },
  data() {
    return {
      msg: 'Welcome to Index!!!',
      isShownModalCreate: false,
      isShownModalDelete: false,
      isShownModalEdit: false,
      submitted: false,
      currentPage: 1,
      fields: [
        {
          key: 'country_name',
          sortable: true,
          label: 'COUNTRY',
          colType: 'country_name'
        },
        {
          key: 'tax_region',
          sortable: true,
          label: 'TAX REGION',
          colType: 'tax_region'
        },
        {
          key: 'state_rate',
          sortable: true,
          label: 'STATE RATE',
          colType: 'state_rate'
        },
        {
          key: 'combined_rate',
          sortable: true,
          label: 'COMBINED RATE',
          colType: 'combined_rate'
        },
        {
          key: 'country_rate',
          sortable: true,
          label: 'COUNTRY RATE',
          colType: 'country_rate'
        },
        {
          key: 'city_rate',
          sortable: true,
          label: 'CITY RATE',
          colType: 'city_rate'
        },
        {
          key: 'special_rate',
          sortable: true,
          label: 'SPECIAL RATE',
          colType: 'special_rate'
        },
        {
          key: 'ACTION',
          colType: 'button'
        }
      ],
      newTax: {
        tax: {
          country_id: '',
          state_id: '',
          tax_region: '',
          state_rate: '',
          combined_rate: '',
          country_rate: '',
          city_rate: '',
          special_rate: ''
        }
      },
      currentTax: {
        tax: {
          country_id: '',
          state_id: '',
          tax_region: '',
          state_rate: '',
          combined_rate: '',
          country_rate: '',
          city_rate: '',
          special_rate: ''
        }
      },
      defaultTax: {
        tax: {
          country_id: '',
          state_id: '',
          tax_region: '',
          state_rate: '',
          combined_rate: '',
          country_rate: '',
          city_rate: '',
          special_rate: '',
          country: {
            id: 0
          }
        }
      },
      dataSearch: ''
    }
  },
  validations: {
    newTax: {
      tax: {
        country_id: {
          required
        },
        state_id: {
          required
        },
        tax_region: {
          required,
          maxLength: maxLength(250)
        },
        state_rate: {
          required,
          maxLength: maxLength(250)
        },
        combined_rate: {
          required,
          maxLength: maxLength(250)
        },
        country_rate: {
          required,
          maxLength: maxLength(250)
        },
        city_rate: {
          required,
          maxLength: maxLength(250)
        },
        special_rate: {
          required,
          maxLength: maxLength(250)
        }
      }
    },
    currentTax: {
      tax: {
        country_id: {
          required
        },
        state_id: {
          required
        },
        tax_region: {
          required,
          maxLength: maxLength(250)
        },
        state_rate: {
          required,
          maxLength: maxLength(250)
        },
        combined_rate: {
          required,
          maxLength: maxLength(250)
        },
        country_rate: {
          required,
          maxLength: maxLength(250)
        },
        city_rate: {
          required,
          maxLength: maxLength(250)
        },
        special_rate: {
          required,
          maxLength: maxLength(250)
        }
      }
    }
  },
  methods: {
    fetchData(activePage, search) {
      this.$store.dispatch('admintax/getAllTaxes', { page: activePage, limit: 10, search })
    },
    loadCountries() {
      this.$store.dispatch('admintax/getListCountries')
    },
    loadProvinceState(country) {
      this.$store.dispatch('admintax/getListStates', country)
    },
    showModalCreate() {
      this.isShownModalCreate = true
      this.setDataTax(this.newTax.tax, this.defaultTax.tax)
      this.loadCountries()
      this.loadProvinceState(this.newTax.tax.country_id)
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.dataSearch)
    },
    onChangeCountry(val) {
      this.newTax.tax.state_id = ''
      this.currentTax.tax.state_id = ''
      this.loadProvinceState(val)
    },
    saveTax() {
      this.submitted = true
      if (this.$v.newTax.$invalid) {
        return
      }
      AdminTaxService.createTax(this.newTax)
        .then((response) => {
          this.$toast.success('Tax successfully created.')
          this.fetchData(this.currentPage)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalCreate = false
    },
    shownModalEdit(tax_id) {
      this.setDataTax(this.currentTax.tax, this.defaultTax.tax)
      this.submitted = false
      this.isShownModalEdit = true
      this.currentTaxID = tax_id
      this.currentTax.tax.state_id = ''
      this.getItemByID()
      this.loadCountries()
      this.loadProvinceState(this.currentTax.tax.country_id)
    },
    shownModalDelete(tax_id) {
      this.isShownModalDelete = true
      this.currentTaxID = tax_id
    },
    getItemByID() {
      AdminTaxService.detailTax(this.currentTaxID)
        .then((response) => {
          this.setDataTax(this.currentTax.tax, response.data)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
    },
    setDataTax(tax, res) {
      tax.country_id = res.country_id
      tax.state_id = res.state_id
      tax.tax_region = res.tax_region
      tax.state_rate = res.tax_region
      tax.combined_rate = res.combined_rate
      tax.country_rate = res.country_rate
      tax.city_rate = res.city_rate
      tax.special_rate = res.special_rate
    },
    updateTax() {
      this.submitted = true
      if (this.$v.currentTax.$invalid) {
        return
      }
      AdminTaxService.updateTax(this.currentTaxID, this.currentTax)
        .then((response) => {
          this.$toast.success('Tax successfully updated.')
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalEdit = false
    },
    removeTax() {
      AdminTaxService.removeTax(this.currentTaxID)
        .then((response) => {
          this.$toast.success('Tax successfully deleted.')
          if (this.items.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalDelete = false
    }
  },
  computed: {
    ...mapState('admintax', {
      items: 'items',
      pagination: 'pagination',
      countryOptions: 'countryOptions',
      provinceStateOptions: 'provinceStateOptions',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('admintax', ['isEmpty'])
  },

  mounted() {
    this.fetchData(this.currentPage)
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    })
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  }
}
</script>

<style lang="scss" scoped>
@import '../../Adminpage.scss';
</style>
