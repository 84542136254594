import AdminFirmwareService from '../../../../services/admin-firmware.service'
export default {
  getAllFirmwares({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return AdminFirmwareService.getAllFirmwares(payload.page, payload.limit, payload.search)
      .then((response) => {
        commit('GET_ALL_FIRMWARE_ADMIN', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  },

  getAllFirmwaresNoPaging({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return AdminFirmwareService.getAllFirmwaresNoPaging()
      .then((response) => {
        const data = [{ id: 0, text: 'Select Player Application' }]
        if (response.data.length > 0) {
          response.data.map((exception) => {
            data.push({
              id: exception.id,
              text: `${exception.name} - ${exception.version} (${exception.version_code})`,
              version: exception.version,
              version_code: exception.version_code
            })
          })
        }
        commit('GET_ALL_FIRMWARE_ADMIN_NO_PAGING', data)
        commit('SET_ERROR', '')
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  }
}
